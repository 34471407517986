import React, { createContext, useContext, useState } from 'react';

type ProductPhaseContextType = {
  phaseId: number;
  productId: number;
};

export const ProductPhaseContext = createContext<ProductPhaseContextType>({
  phaseId: 0,
  productId: 0,
});

export const useProductPhaseIds = () => useContext(ProductPhaseContext);
