import { CheckIcon } from '@chakra-ui/icons';
import {
  Flex,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import GsBtn from '../../../components/button/GsBtn';
import GsIconBtn from '../../../components/button/GsIconBtn';
import { TableNavigation } from '../../../components/TabeNavigation/TableNavigation';
import { convertDateTime } from '../warehouse/warehouse-api';
import { useTranslation } from 'react-i18next';
import {
  getNotifications,
  getTopic,
  handleNotificationMessage,
  markAllAsSeenNotifications,
  markAsSeenNotification,
  Notification,
} from './notificationApi';

const NotificationsTable = () => {
  const [tableData, setTableData] = useState<Notification[]>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { t } = useTranslation();

  const handleMarkAsSeen = async (notificationId: number) => {
    await markAsSeenNotification(notificationId);
    callGetTableData(0);
  };

  const handleMarkAllAsSeen = async () => {
    const topic = getTopic();
    await markAllAsSeenNotifications(topic);
    callGetTableData(0);
  };

  const callGetTableData = async (pageNo: number) => {
    const topic = getTopic();

    if (topic) {
      const notifications: Notification[] = await getNotifications(
        topic,
        pageNo
      );
      setTableData(notifications);
    }
  };

  useEffect(() => {
    callGetTableData(0);
  }, []);

  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    callGetTableData(newPageNumber);
    setPageNumber(newPageNumber);
  };

  return (
    <TableContainer>
      <Table variant='simple'>
        <TableCaption>{t('notifications')}</TableCaption>
        <Thead>
          <Tr>
            <Th textAlign='center'>{t('message')}</Th>
            <Th textAlign='center'>{t('sentAt')}</Th>
            <Th textAlign='center'>{t('markAsSeen')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData?.map((n) => (
            <NotificationsTableRow
              key={n.id}
              notification={n}
              handleMarkAsSeen={handleMarkAsSeen}
            />
          ))}
        </Tbody>
      </Table>
      <Flex justifyContent={'flex-end'} mr={10}>
        <GsBtn
          text={'Oznaci sve kao procitano'}
          onClick={() => handleMarkAllAsSeen()}
        ></GsBtn>
      </Flex>
      <TableNavigation
        pageNumber={pageNumber}
        pageNumberHandler={pageNumberHandler}
      />
    </TableContainer>
  );
};

const NotificationsTableRow: FC<{
  notification: Notification;
  handleMarkAsSeen: (notificationId: number) => void;
}> = ({ notification, handleMarkAsSeen }) => {
  return (
    <>
      <Tr key={notification.id}>
        <Td textAlign='center'>
          {handleNotificationMessage(JSON.parse(notification.message))}
        </Td>
        <Td textAlign='center'>{convertDateTime(notification.sendAt)}</Td>
        <Td textAlign='center'>
          <GsIconBtn
            label={'Pogledano'}
            onClick={() => handleMarkAsSeen(notification.id)}
            icon={<CheckIcon />}
          />
        </Td>
      </Tr>
    </>
  );
};

export default NotificationsTable;
