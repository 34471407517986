import {
  useColorModeValue,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Box,
  Select,
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import { Sector, User, getSectors } from '../sector/sector-apis';
import { saveCustomTask } from './customTaskApi';
import { Order, OrderItem, createOrder } from '../order/order-apis';

const CustomTaskForm = () => {
  const [sectors, setSectors] = useState<Sector[]>();
  useEffect(() => {
    getSectors(setSectors);
  }, [setSectors]);

  const [selectedSector, setSelectedSector] = useState<Sector>({
    id: -1,
    name: 'unknown',
    employee: {} as User,
    minWorkTime: 0,
  });

  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  const [taskInstructions, setTaskInstructions] = useState<string>('');
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);

  const instructionsRef = useRef<HTMLInputElement>(null);
  const sectorRef = useRef<HTMLSelectElement>(null);
  const estimatedStartRef = useRef<HTMLInputElement>(null);
  const estimatedEndRef = useRef<HTMLInputElement>(null);
  const priorityRef = useRef<HTMLSelectElement>(null);

  return (
    <Box px='5' height='100%'>
      <FormLabel>Unesite instrukcije</FormLabel>
      <Input
        type='text'
        marginBottom={2}
        ref={instructionsRef}
        onChange={(e) => {
          if (!e.target.value || e.target.value.length === 0) return;
          setTaskInstructions(e.target.value);
        }}
      />

      <Flex flexDirection='row' mb='50' align={'center'}>
        <FormControl mr='50'>
          <FormLabel>Datum pristizanja</FormLabel>
          <Input placeholder='' type='date' ref={estimatedStartRef} />
          <FormHelperText fontStyle='italic'>
            Unesite datum pristizanja porudzbenice
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>Datum isporuke</FormLabel>
          <Input placeholder='' type='date' ref={estimatedEndRef} />
          <FormHelperText fontStyle='italic'>
            Unesite datum isporuke za porudzbenicu
          </FormHelperText>
        </FormControl>
      </Flex>
      <FormControl mt={5}>
        <FormLabel>Izaberite prioritet</FormLabel>
        <Select placeholder='Prioritet' ref={priorityRef}>
          <option key={1} value='LOW'>
            Nizak
          </option>
          <option key={2} value='MEDIUM'>
            Srednji
          </option>
          <option key={2} value='HIGH'>
            Visok
          </option>
        </Select>
      </FormControl>
      <FormLabel mt={5}>Sektor:</FormLabel>
      <Select
        placeholder='Izaberite sektor'
        ref={sectorRef}
        onChange={(e) => {
          if (!e.target.value || e.target.value.length === 0) return;
          let newSector: Sector = {
            id: -1,
            name: 'unknown',
            employee: {} as User,
            minWorkTime: 0,
          };
          sectors?.forEach((s) => {
            if (s.id === Number(e.target.value)) newSector = s;
          });
          setSelectedSector(newSector);
        }}
      >
        {sectors?.map((s: Sector) => (
          <option key={s.id} value={s.id}>
            {s.name}
          </option>
        ))}
      </Select>

      <Button
        w='100%'
        mt='150'
        mb='50'
        textAlign='center'
        _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
        variant='outline'
        onClick={async (e) => {
          if (
            estimatedStartRef === undefined ||
            estimatedStartRef.current === null ||
            estimatedEndRef === undefined ||
            estimatedEndRef.current === null ||
            sectorRef === null ||
            sectorRef.current === null ||
            priorityRef === null ||
            priorityRef.current === null ||
            instructionsRef === null ||
            instructionsRef.current === null
          )
            return;

          if (estimatedStartRef.current?.value === '') {
            alert('Unesite vreme prijema naloga');
            return;
          }

          if (estimatedEndRef.current?.value === '') {
            alert('Unesite vreme isporuke naloga');
            return;
          }

          if (priorityRef.current.value === '') {
            alert('Izaberite prioritet');
            return;
          }

          if (sectorRef.current?.value === '') {
            alert('Izaberite sektor');
            return;
          }

          if (taskInstructions === '') {
            alert('Unesite instrukcije za radni nalog');
            return;
          }

          const sectorId = parseInt(sectorRef.current?.value);

          if (!Number.isInteger(sectorId)) {
            alert('Sector Id nije int');
            return;
          }

          const estStartDate: string = estimatedStartRef.current?.value;
          const estEndDate: string = estimatedEndRef.current?.value;

          const success = await saveCustomTask({
            sectorId: sectorId,
            description: taskInstructions,
            estimatedStart: estStartDate,
            estimatedEnd: estEndDate,
            priority: priorityRef.current.value,
          });

          if (success) {
            estimatedStartRef.current.value = '';
            estimatedEndRef.current.value = '';
            sectorRef.current.value = '';
            priorityRef.current.value = '';
            instructionsRef.current.value = '';
            setTaskInstructions('');

            alert('Radni nalog je sacuvan');
          }
        }}
      >
        Kreiraj nalog
      </Button>
    </Box>
  );
};

export default CustomTaskForm;
