import { GET, POST } from '../../../api/requests';
import { SERVER_URL } from '../../../api/config';
import { getPaginationParams } from '../../../components/TabeNavigation/table-navigation';

export type Sector = {
  id?: number;
  name: string | undefined;
  minWorkTime: number;
  userId?: number;
  employee?: User; // ovo treba obrisati u sledecem refaktoringu
};

export type User = {
  id: number;
  name: string;
  username: string;
};

// i ovo mi se ne svidja tri ista api poziva sve treba jedan metod da bude
//samo ovaj metod treba pozivati i prilagoditi logiku za ova dva poziva ispod(Posle ga rename u getSectors)
export const getSectorsByName = async (pageNo: number, filter: string) => {
  try {
    const url = new URL(SERVER_URL + '/api/sectors/name');
    let params = new URLSearchParams();

    params = getPaginationParams(params, pageNo);
    if (filter) {
      params.append('filter', filter);
    }
    url.search = params.toString();

    const sectors = await GET<Sector[]>(url.toString());
    console.log(sectors);
    return sectors;
  } catch (error) {
    console.log('Error while fetching sectors.');
    console.error(error);
  }
};

//treba refaktoring
export const getSectors = async (setSectors: any) => {
  const sectors = await GET<Sector[]>(SERVER_URL + '/api/sectors');
  setSectors(sectors); // Skloniti ove setere svuda i onda za ovaj metod mogu obrisati ovaj ispod
  return sectors;
};

export const getSectors1 = async () => {
  const sectors = await GET<Sector[]>(SERVER_URL + '/api/sectors');
  return sectors;
};

export const getSectorsByUSerId = async (userId: number) => {
  const sectors = await GET<Sector[]>(
    SERVER_URL + '/api/sectors/user/' + userId
  );
  return sectors;
};

export const createSector = async (sector: Sector) => {
  try {
    await POST<Sector>(SERVER_URL + '/api/sector/save', sector);
    return true;
  } catch (err) {
    console.log('Error while creating user.');
    console.error(err);
    return false;
  }
};

export const deleteSector = async (
  sector: Sector
) => {
  try {
    const deletedSector = await POST<Sector>(
      SERVER_URL + '/api/sector/delete',
      sector
    );
    return true;
  } catch (error) {
    const em = error as Error;
    console.log(em.message);
    alert(em.message);
    return false;
  }
};

export const deleteSectorFromEdit = async (sector: Sector, setSectors: any) => {
  const deletedSector = await POST<Sector>(
    SERVER_URL + '/api/sector/delete',
    sector
  );
  setSectors([]);
};
