import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  Icon,
  Collapse,
} from '@chakra-ui/react';
import React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MenuItemType } from '../models';

type ManagerMenuItemProps = {
  item: MenuItemType;
};

const ManagerMenuItem: React.FC<ManagerMenuItemProps> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const itemBgColor = useColorModeValue('#EBF5FB', '#2D3748');
  const textColor = useColorModeValue('black', 'white');

  const handleItemClick = (route: string) => {
    navigate(route);
  };

  return (
    <Box w='100%'>
      <Flex
        alignItems='center'
        justifyContent='space-between'
        cursor='pointer'
        _hover={{ bg: itemBgColor, borderRadius: '10px' }}
        onClick={() => setIsOpen(!isOpen)}
        p={2}
        position='relative'
      >
        {item.icon && <Icon as={item.icon} mr={2} />}
        <Text fontWeight='bold' flex='1' color={textColor} whiteSpace='nowrap'>
          {item.name}
        </Text>
        <Box position='absolute' right={-3}>
          {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </Box>
      </Flex>
      <Collapse in={isOpen}>
        {item.subItems.map((subItem) => (
          <Link
            to={subItem.route}
            key={subItem.name}
            style={{ textDecoration: 'none' }}
          >
            <Text
              key={subItem.name}
              px={8}
              py={2}
              cursor='pointer'
              _hover={{ bg: itemBgColor, borderRadius: '10px' }}
              onClick={() => handleItemClick(subItem.route)}
              color={textColor}
              whiteSpace='nowrap'
            >
              {subItem.icon && <Icon as={subItem.icon} mr={2} />}

              {subItem.name}
            </Text>
          </Link>
        ))}
      </Collapse>
    </Box>
  );
};

export default ManagerMenuItem;
