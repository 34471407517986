import { BellIcon } from '@chakra-ui/icons';
import { ToastId, useToast } from '@chakra-ui/react';
import React from 'react';
import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import GsIconBtn from '../../../components/button/GsIconBtn';
import { useNotificationConnection } from '../../../contexts/notificationsConnectionContext';
import { subscribeToTopic } from '../../../notifications/notificationManager';
import { getTopic, handleNotificationMessage } from './notificationApi';

const NotificationButton = () => {
  const navigate = useNavigate();
  const { client, setClient } =  useNotificationConnection();

  const toast = useToast();
  const toastIdRef = React.useRef<ToastId | undefined>(undefined);

  const showNotification = (message: string) => {
    if (toastIdRef.current !== undefined) {
      toast.close(toastIdRef.current);
    }

    toastIdRef.current = toast({
      description: message,
      position: 'top',
      isClosable: true,
    });
  };

  const handleMessage = (notification: any) => {
    let finalMessage = handleNotificationMessage(notification);
    showNotification(finalMessage);
  };

  useEffect(() => {
    if (client === null) {
      const topic = getTopic();

      subscribeToTopic(client, topic, setClient, handleMessage);
    }
  }, [client]);

  return (
    <GsIconBtn
      label='notification-button'
      icon={<BellIcon />}
      onClick={() => {
        navigate('/notifications');
      }}
    />
  );
};

export default NotificationButton;
