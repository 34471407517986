import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Select,
  Text,
} from '@chakra-ui/react';
import { FC, ReactNode, useEffect, useState } from 'react';
import GsBtn from '../../../../../components/button/GsBtn';
import GsHeaderPopOver from '../../../../../components/heading/GsHeaderPopOver';
import { getWorkersBySector, WorkerBySectorDTO } from '../../order-apis';

type AssignWorkerPopOverProps = {
  buttonTrigger: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  workerName: string;
  sectorId: number;
  taskStartTime: string;
  onAssignWorker: (worker: WorkerBySectorDTO) => void;
};

const AssignWorkerPopOver: FC<AssignWorkerPopOverProps> = ({
  buttonTrigger,
  isOpen,
  onClose,
  sectorId,
  taskStartTime,
  workerName,
  onAssignWorker,
}) => {
  const [workers, setWorkers] = useState<WorkerBySectorDTO[]>();
  const [selectedWorker, setSelectedWorker] = useState<WorkerBySectorDTO>();

  useEffect(() => {
    if (isOpen && !taskStartTime)
      getWorkersBySector(sectorId).then((workers) => setWorkers(workers));
  }, [isOpen]);

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement='left' trigger='hover'>
      <PopoverTrigger>{buttonTrigger}</PopoverTrigger>
      <PopoverContent>
        <GsHeaderPopOver title='Izvođač radova' />
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Flex
            direction='column'
            gap='5%'
            alignItems='flex-start'
            justifyContent='space-between'
          >
            {workerName && (
              <Text whiteSpace='nowrap'>Izabran za rad: {workerName}</Text>
            )}
            {workers && !taskStartTime && (
              <Select
                placeholder='Izaberite radnika'
                onChange={(e) => {
                  const newWorkerId = Number(e.target.value);
                  const newWorker = workers.find(
                    (worker) => worker.userId === newWorkerId
                  );
                  setSelectedWorker(newWorker);
                }}
              >
                {workers.map((worker) => (
                  <option key={worker.userId} value={worker.userId}>
                    {worker.workerName}
                  </option>
                ))}
              </Select>
            )}
          </Flex>
        </PopoverBody>
        <PopoverFooter display='flex' justifyContent='flex-end'>
          {!taskStartTime && (
            <GsBtn
              text='Sačuvaj'
              onClick={() => {
                if (workerName !== selectedWorker?.workerName)
                  onAssignWorker(selectedWorker as WorkerBySectorDTO);
                onClose();
              }}
            />
          )}
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default AssignWorkerPopOver;
