import {
  Box,
  Text,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useChakra,
  useDisclosure,
  ModalFooter,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { CustomHeading } from '../../../../components/heading/custom-heading';
import GsHeaderModal from '../../../../components/heading/GsHeaderModal';
import GsSpinner from '../../../../components/spinner/gsSpinner';
import {
  ALERT_RED,
  useGsDialog,
} from '../../../../contexts/GsConfirmDialogContext';
import CopyProductModal from '../components/form/modal/CopyProductModal';

import ProductForm from '../components/form/ProductForm';
import ProductTable from '../components/table/ProductTable';
import TemporaryComponent from '../components/TemporaryComponent';
import { ReadOnlyContext } from '../contexts/ReadOnlyContext';
import {
  deleteProduct,
  getProduct,
  getProducts,
  getProductsByFilter,
  getProductsNeizmenjeni,
  isProductActiveInOrder,
  Product,
  SaveProductDTO,
} from '../product-api';

const EditProductPage = () => {
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [productToEdit, setProductToEdit] = useState<SaveProductDTO>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenCopy,
    onOpen: onOpenCopy,
    onClose: onCloseCopy,
  } = useDisclosure();
  const { theme, colorMode } = useChakra();
  const { confirmDialog, alertDialog } = useGsDialog();

  const [radioBtnValue, setRadioBtnValue] = useState('1'); //TODO Obrisati kad Olgica zavrsi sa migracijom
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (radioBtnValue === '1')
      getProducts(setProducts, pageNumber, setIsLoadingProducts);
    else
      getProductsNeizmenjeni(
        filter,
        setProducts,
        pageNumber,
        setIsLoadingProducts
      );
  }, [pageNumber, refreshPage, radioBtnValue]);

  const handleOnEditProduct = async (product: Product) => {
    await getProduct(setProductToEdit, product.id as number);
    /* 
      Za sada sve moze da bude editable tj nista nije readOnly
      Ideja je da se proveri do koje faze je stigao produkt sa izradom.
      Ako ga ima u vise porudzbina, uzeti najvisu fazu proizvodnje kao poslednju od koje se produkt moze editovati.
    
    */
    // const isActive = await isProductActiveInOrder(product);
    // setIsReadOnly(isActive as boolean);
    onOpenEdit();
  };

  const handleOnCopyProduct = async (product: Product) => {
    await getProduct(setProductToEdit, product.id as number);
    onOpenCopy();
  };

  const handleOnDeleteProduct = async (product: Product) => {
    if (await isProductActiveInOrder(product)) {
      alertDialog(
        'Greška',
        'Ne možete obrisati proizvod koji se trenutno proizvodi.',
        ALERT_RED
      );
      return;
    }
    confirmDialog(
      'Brisanje proizvoda',
      'Da li sigurno želite da obrišete proizvod?',
      async () => {
        const sucess = await deleteProduct(product);
        if (sucess) getProducts(setProducts, 0, setIsLoadingProducts);
      }
    );
  };

  const onChangePageHandler = (pageNo: number) => {
    if (pageNumber === 0 && pageNo === -1) return;
    setPageNumber((pageNumber) => pageNumber + pageNo);
  };

  return (
    <Box
      mb={1}
      height='100%'
      p='20'
      bg={theme.colors.bg.colorMode(colorMode)}
      borderRadius='10'
      borderWidth={2}
    >
      <CustomHeading text='Pregled proizvoda' />
      <Flex
        flexDir='row'
        justifyContent='space-between'
        gap='5%'
        alignItems='center'
      >
        <FormControl width='30%'>
          <FormLabel>Pretražite proizvod</FormLabel>
          <InputGroup alignItems='center'>
            <InputLeftElement
              pointerEvents='none'
              children={
                <Icon
                  as={FaSearch}
                  color='gray.500'
                  fontSize='20px'
                  marginTop='5px'
                />
              }
            />
            <Input
              placeholder='Šifra proizvoda'
              type='text'
              onChange={(e) => {
                setPageNumber(0);
                setFilter(e.target.value);
                if (radioBtnValue === '1') {
                  if (e.target.value.length === 0) {
                    getProducts(setProducts, 0, isLoadingProducts);
                  } else {
                    getProductsByFilter(
                      e.target.value,
                      setProducts,
                      0,
                      setIsLoadingProducts
                    );
                  }
                } else {
                  getProductsNeizmenjeni(
                    e.target.value,
                    setProducts,
                    0,
                    setIsLoadingProducts
                  );
                }
              }}
            />
          </InputGroup>
          <FormHelperText fontStyle='italic'>
            Pretražite proizvod po šifri
          </FormHelperText>
        </FormControl>
        <FormControl>
          <RadioGroup onChange={setRadioBtnValue} value={radioBtnValue}>
            <Stack direction='row'>
              <Radio value='1'>Svi</Radio>
              <Radio value='2'>Neizmenjeni</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </Flex>
      {isLoadingProducts ? (
        <GsSpinner />
      ) : (
        <ProductTable
          products={products}
          pageNumber={pageNumber}
          onChangePage={onChangePageHandler}
          onEditProduct={handleOnEditProduct}
          onCopyProduct={handleOnCopyProduct}
          onDeleteProduct={handleOnDeleteProduct}
        />
      )}
      <Modal isOpen={isOpenEdit} onClose={onCloseEdit}>
        <ModalOverlay />
        <ModalContent
          minWidth={'70%'}
          bg={theme.colors.bg.colorMode(colorMode)}
        >
          <GsHeaderModal title='Izmeni proizvod' />
          <ModalCloseButton />
          <ModalBody pb={6}>
            {productToEdit?.product.requiredResources && // TODO Obrisati kad Olgica zavrs prebacivanje resursa
              productToEdit?.product.requiredResources.length > 0 && (
                <TemporaryComponent
                  requiredResources={productToEdit.product.requiredResources}
                  onDeleteProductResources={() =>
                    (productToEdit.product.requiredResources = [])
                  }
                />
              )}
            <ReadOnlyContext.Provider value={{ isReadOnly }}>
              <ProductForm
                product={productToEdit}
                onSaveProduct={() => {
                  onCloseEdit();
                  setRefreshPage(!refreshPage);
                }}
              />
            </ReadOnlyContext.Provider>
          </ModalBody>
          <ModalFooter>
            <Flex>
              {isReadOnly && (
                <Text fontStyle='italic' color='red.500'>
                  Ne možete menjati proizvod koji se trenutno proizvodi.
                </Text>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <CopyProductModal
        isOpen={isOpenCopy}
        onClose={() => {
          onCloseCopy();
          pageNumber === 0 ? setRefreshPage(!refreshPage) : setPageNumber(0);
        }}
        productToCopy={productToEdit as SaveProductDTO}
      />
    </Box>
  );
};

export default EditProductPage;
