import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
} from '@chakra-ui/react';
import { FC } from 'react';
import FileTableRow from './FileTableRow';

const FileTable: FC<{
  productId: number;
  files: string[];
}> = ({ files, productId }) => {
  return (
    <TableContainer marginTop='5%' marginBottom='5%'>
      <Table>
        <TableCaption>Crteži</TableCaption>
        <Thead>
          <Tr borderBottom='1px solid'>
            <Th textAlign='center'>Naziv</Th>
            <Th textAlign='center'>Preuzmi</Th>
          </Tr>
        </Thead>
        <Tbody>
          {files.map((file) => (
            <FileTableRow productId={productId} key={file} file={file} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default FileTable;
