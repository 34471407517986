import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';
import GsSpinner from '../../../../components/spinner/gsSpinner';

export interface AddUnitModalProps {
  onOpen: any;
  onClose: any;
  isOpen: any;
  setUnit: any;
  newUnit: any;
  handleAddNewUnit: any;
  header: string;
  label: string;
  isSaving: boolean;
}

const AddUnitModal = ({
  onOpen,
  onClose,
  isOpen,
  setUnit,
  newUnit,
  handleAddNewUnit,
  header,
  label,
  isSaving,
}: AddUnitModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={React.useRef(null)}
    >
      <ModalOverlay />
      <ModalContent minWidth='500px'>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel htmlFor='newUnit'>{label}</FormLabel>
            <Input
              id='newUnit'
              placeholder='Unesite jedinicu'
              value={newUnit}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setUnit(e.target.value)
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          {isSaving ? (
            <GsSpinner />
          ) : (
            <Button
              variant='outline'
              onClick={handleAddNewUnit}
              _hover={{
                textDecor: 'none',
              }}
            >
              Dodaj
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddUnitModal;
