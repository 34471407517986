import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Text,
  Box,
  ModalHeader,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  getMaterialsByRequests,
  RequestedMaterialByPhase,
  RequestedMaterialForPhase,
} from '../../warehouse-api';
import MaterialByRequestTable from '../table/materialsByRequest/MaterialByRequestTable';
import MaterialByRequestTableHistory from '../table/materialsByRequestHistory/MaterialsByRequestTableHistory';
import UnusedMaterialsTable from '../table/unusedMaterials/UnusedMaterialsTable';

export interface MaterialRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  request: RequestedMaterialByPhase;
  refreshRequestTable: any;
  requestType: string;
}

const MaterialsByRequestModal = ({
  isOpen,
  onClose,
  request,
  refreshRequestTable,
  requestType,
}: MaterialRequestModalProps) => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [pageNo, setPageNo] = useState<number>(0);
  const [materialsByRequest, setMaterialsByRequest] = useState<
    RequestedMaterialForPhase[]
  >([]);
  const [isActive, setIsActive] = useState<string>(
    requestType === 'active_requests' ? 'true' : 'false'
  );

  const fetchData = async () => {
    if (requestType === 'unused_request') {
      try {
        const materials = await getMaterialsByRequests(
          request.productId,
          request.phaseId,
          request.orderCode,
          'true',
          pageNumber,
          'true'
        );
        setMaterialsByRequest(materials as RequestedMaterialForPhase[]);
        if (materials?.length === 0) {
          onClose();
          refreshRequestTable();
        }
      } catch (error) {
        console.error('Error fetching materials:', error);
      }
    } else {
      try {
        const materials = await getMaterialsByRequests(
          request.productId,
          request.phaseId,
          request.orderCode,
          isActive,
          pageNumber
        );
        setMaterialsByRequest(materials as RequestedMaterialForPhase[]);
        if (materials?.length === 0) {
          onClose();
          refreshRequestTable();
        }
      } catch (error) {
        console.error('Error fetching materials:', error);
      }
    }
  };

  const refreshTable = async () => {
    await fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [shouldRefresh, pageNumber]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='85%'>
        <ModalHeader
          fontFamily='Source Sans Pro, sans-serif'
          fontWeight='bold'
          fontSize='24px'
          lineHeight='32px'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          mb={6}
        >
          {requestType === 'active_requests'
            ? 'Zahtev za zaduživanje materijala'
            : requestType === 'unused_request'
            ? 'Neiskoriscen materijal'
            : 'Prikaz odobrenog zahteva za zaduzivanje materijala'}
          {requestType === 'active_requests' ? (
            <Flex alignItems='center' mr={10}>
              <Box mr={2} w='15px' h='15px' bg='#006400' borderRadius='50%' />
              <Text fontSize='xl'>Dovoljno materijala na stanju</Text>
              <Box
                ml={5}
                mr={2}
                w='15px'
                h='15px'
                bg='#8B0000'
                borderRadius='50%'
              />
              <Text fontSize='xl'>
                Potrebno narucivanje dodatnih materijala
              </Text>
            </Flex>
          ) : (
            <></>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex flexDir='row' mb={10}>
            <Box
              w='30%'
              mr='5%'
              border='1px solid'
              borderColor='gray.300'
              p={2}
              borderRadius='md'
            >
              <Text>Šifra porudžbenice</Text>
              <Box borderTop='1px solid' borderColor='gray.300' mt={2} pt={2}>
                <Text textAlign='center' fontSize='md'>
                  {request.orderCode}
                </Text>
              </Box>
            </Box>

            <Box
              w='30%'
              mr='5%'
              border='1px solid'
              borderColor='gray.300'
              p={2}
              borderRadius='md'
            >
              <Text>Šifra proizvoda</Text>
              <Box borderTop='1px solid' borderColor='gray.300' mt={2} pt={2}>
                <Text textAlign='center' fontSize='md'>
                  {request.productCode}
                </Text>
              </Box>
            </Box>

            <Box
              w='25%'
              mr='5%'
              border='1px solid'
              borderColor='gray.300'
              p={2}
              borderRadius='md'
            >
              <Text>Sektor</Text>
              <Box borderTop='1px solid' borderColor='gray.300' mt={2} pt={2}>
                <Text textAlign='center' fontSize='md'>
                  {request.sectorName}
                </Text>
              </Box>
            </Box>
          </Flex>
          {requestType === 'active_requests' ? (
            <MaterialByRequestTable
              pageNumber={pageNo}
              setPageNumber={setPageNo}
              request={request}
              materialsByRequest={materialsByRequest}
              setMaterialsByRequest={setMaterialsByRequest}
              refreshTable={refreshTable}
            />
          ) : requestType === 'unused_request' ? (
            <UnusedMaterialsTable
              pageNumber={pageNo}
              setPageNumber={setPageNo}
              request={request}
              unusedMaterials={materialsByRequest}
              setUnusedMaterials={setMaterialsByRequest}
              refreshTable={refreshTable}
            />
          ) : (
            <MaterialByRequestTableHistory
              pageNumber={pageNo}
              setPageNumber={setPageNo}
              request={request}
              materialsByRequest={materialsByRequest}
              setMaterialsByRequest={setMaterialsByRequest}
            />
          )}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MaterialsByRequestModal;
