import { createContext } from 'react';
import { Node } from 'reactflow';
import { ResourceRequiredAmmount } from '../product-api';

export type NodeData = {
  sectorId: number;
  sector: string;
  description: string;
  resources: ResourceRequiredAmmount[];
};

type NodeContextType = {
  handleOnSaveNode: (newData: NodeData, nodeId?: number) => void;
  handleOnDeleteNode: (nodeId: number) => void;
  nodes: Node[];
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
};

export const NodeContext = createContext<NodeContextType>({
  handleOnSaveNode: (newData: NodeData, nodeId?: number) => {},
  handleOnDeleteNode: (nodeId: number) => {},
  nodes: [],
  setNodes: () => {},
});
