import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Flex,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { getWorkerNote } from '../../fallout-api';

const WorkerNoteModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  taskId: number;
}> = ({ isOpen, onClose, taskId }) => {
  const [workerNote, setWorkerNote] = useState('');

  useEffect(() => {
    const callGetWorkerNote = async () => {
      setWorkerNote((await getWorkerNote(taskId)).note);
    };
    callGetWorkerNote();

    return () => {
      setWorkerNote('');
    };
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>Napomena od radnika</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl py='3'>
            <Flex>
              <Text>{workerNote}</Text>
            </Flex>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='gray'
            onClick={onClose}
            variant='solid'
            marginBottom='2px'
          >
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WorkerNoteModal;
