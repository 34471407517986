import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Flex,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';

const NoteModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  note: string;
}> = ({ isOpen, onClose, note }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>Napomena od radnika</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl py='3'>
            <Flex>
              <Text>{note}</Text>
            </Flex>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='gray'
            onClick={onClose}
            variant='solid'
            marginBottom='2px'
          >
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NoteModal;
