import { Td, Tr } from '@chakra-ui/react';
import { FC } from 'react';
import {
  convertDate,
  convertToTimestamp,
} from '../../../manager/order/order-apis';
import TaskPriority from './task-priority';

const TaskRow: FC<{
  dataRow: {
    sectorName: string;
    productCode: string;
    taskStartTime: Date;
    estimatedItemEnd: Date;
    priority: string;
    expectedAmount: number;
    orderCode: string;
    estimatedOrderEnd: Date;
  };
}> = ({ dataRow }) => {
  return (
    <Tr>
      <Td textAlign='center'>{dataRow.sectorName}</Td>
      <Td textAlign='center'>{dataRow.orderCode}</Td>
      <Td textAlign='center'>{dataRow.productCode}</Td>
      <Td textAlign='center'>
        {convertToTimestamp(dataRow.taskStartTime.toLocaleString())}
      </Td>
      <Td textAlign='center'>
        {convertDate(
          dataRow.estimatedItemEnd === null
            ? dataRow.estimatedOrderEnd.toLocaleString()
            : dataRow.estimatedItemEnd.toLocaleString()
        )}
      </Td>
      <Td textAlign='center'>{dataRow.expectedAmount}</Td>
      <Td textAlign='center'>
        <TaskPriority priority={dataRow.priority} />
      </Td>
    </Tr>
  );
};

export default TaskRow;
