import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useColorModeValue,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import { copyProduct, SaveProductDTO } from '../../../product-api';
import React, { useRef, useState } from 'react';
import CopyProductForm from '../CopyProductForm';
import GsBtn from '../../../../../../components/button/GsBtn';
import GsHeaderModal from '../../../../../../components/heading/GsHeaderModal';

export interface CopyProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  productToCopy: SaveProductDTO;
}

const CopyProductModal = ({
  isOpen,
  onClose,
  productToCopy,
}: CopyProductModalProps) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const productCodeRef = useRef<HTMLInputElement>(null);
  const productDescRef = useRef<HTMLInputElement>(null);

  const handleCopyClick = async () => {
    const newProduct = { ...productToCopy };
    newProduct!.product.productCode = productCodeRef.current!.value;
    newProduct!.product.description = productDescRef.current!.value;
    setIsLoading(true);
    await copyProduct(newProduct, undefined);
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <GsHeaderModal title='Kopiranje proizvoda' />
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex>
            <CopyProductForm
              productToCopy={productToCopy}
              productCodeRef={productCodeRef}
              productDescriptionRef={productDescRef}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          {isLoading ? (
            <Spinner marginRight={'5'}></Spinner>
          ) : (
            <GsBtn text='Kopiraj' onClick={handleCopyClick} />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CopyProductModal;
