import {
  Box,
  Button,
  Flex,
  Input,
  FormErrorMessage,
  useColorModeValue,
  Select,
} from '@chakra-ui/react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useState, useEffect, useRef, FC } from 'react';
import { PasswordInput } from '../../../../login/log-in-form';
import {
  ChangePassword,
  createUser,
  editUserPassword,
  User,
} from '../../user-api';
import GsSpinner from '../../../../../components/spinner/gsSpinner';
import MultiSelectWithCheckbox from '../multi-select/multi-select-drop-down';
import { getSectors1, Sector } from '../../../sector/sector-apis';

const EditPwForm: FC<{
  selectedUser?: User;
  onSubmit?: () => Promise<void>;
}> = ({ selectedUser, onSubmit }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedUser) {
      nameRef.current!.value = selectedUser.name;
    }
  }, []);

  const validateInputsAndEditPassword = async () => {
    if (passwordRef === null || passwordRef.current?.value === '') {
      alert('Unesite sifru');
      return;
    }
    if (
      confirmPasswordRef === null ||
      confirmPasswordRef.current?.value === ''
    ) {
      alert('Unesite potvrdu sifre');
      return;
    }
    if (passwordRef.current?.value !== confirmPasswordRef.current?.value) {
      alert('Sifre se ne poklapaju');
      return;
    }

    const newChangePassword: ChangePassword = {
      username: selectedUser?.username as string,
      password: passwordRef.current?.value as string,
    };

    setIsLoading(true);
    const success = await editUserPassword(newChangePassword);
    setIsLoading(false);

    if (success) {
      if (passwordRef.current != null) passwordRef.current.value = '';
      if (confirmPasswordRef.current != null)
        confirmPasswordRef.current.value = '';
      alert('Korisnik uspesno sacuvan');
    } else {
      alert('Greska pri cuvanju korisnika');
    }

    if (onSubmit) await onSubmit();
  };

  return (
    <Flex
      px='5'
      flexDirection='column'
      justifyContent='space-between'
      alignItems='flex-end'
    >
      <FormControl>
        <FormControl>
          <FormLabel>Ime i prezime korisnika</FormLabel>
          <Input
            type='text'
            ref={nameRef}
            marginBottom='5'
            disabled={true}
            sx={{
              '::placeholder': {
                fontStyle: 'italic',
              },
            }}
          />
        </FormControl>
        <FormLabel>Unesite sifru</FormLabel>
        <Input
          type='password'
          placeholder='Unesite novu sifru'
          marginBottom='10'
          autoComplete=''
          ref={passwordRef}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Potvrdite sifru</FormLabel>
        <Input
          type='password'
          placeholder='Potvrdite sifru'
          marginBottom='10'
          autoComplete=''
          ref={confirmPasswordRef}
        />
      </FormControl>
      {isLoading ? (
        <GsSpinner />
      ) : (
        <Button
          _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
          variant='outline'
          w='30%'
          onClick={async (e) => {
            if (
              window.confirm('Da li ste sigurni da želite da izmenite sifru?')
            ) {
              validateInputsAndEditPassword();
            }
          }}
        >
          Sačuvaj
        </Button>
      )}
    </Flex>
  );
};

export default EditPwForm;
