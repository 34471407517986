import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import GsHeaderModal from '../../../../components/heading/GsHeaderModal';
import {
  getTaskAssignments,
  TaskAssignmentDTO,
  WorkerBySectorDTO,
} from '../order-apis';

type ShowWorkersForTaskModalProps = {
  isOpen: boolean;
  onClose: () => void;
  sectorName: string;
  taskId: number;
  productCode: string;
  orderCode: string;
};
const ShowWorkersForTaskModal: FC<ShowWorkersForTaskModalProps> = ({
  isOpen,
  onClose,
  sectorName,
  taskId,
  productCode,
  orderCode,
}) => {
  const [assignments, setAssignments] = useState<TaskAssignmentDTO[]>();

  useEffect(() => {
    if (isOpen) {
      getTaskAssignments(taskId).then((assignments) =>
        setAssignments(assignments)
      );
    }
  }, [isOpen]);

  const calculateDuration = (start: Date, end: Date): string => {
    const duration = end.getTime() - start.getTime();
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration / (1000 * 60)) % 60);

    return `${hours}h ${minutes}m`;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='900'>
        <GsHeaderModal title={`Pregled radnika`} />
        <ModalCloseButton />
        <ModalBody>
          <Flex direction='column'>
            <TableContainer whiteSpace='normal'>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th textAlign='center'>Početak rada</Th>
                    <Th textAlign='center'>Trajanje rada </Th>
                    <Th textAlign='center'>Završetak rada</Th>
                    <Th textAlign='center'>Dodelio task</Th>
                    <Th textAlign='center'> Radnik </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {assignments?.map((assignment) => (
                    <Tr>
                      <Td textAlign='center'>
                        {assignment.assignmentStart &&
                          new Date(assignment.assignmentStart)
                            .toLocaleString('en-GB', {
                              year: 'numeric',
                              day: '2-digit',
                              month: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                            })
                            .replace(/\//g, '-')}
                      </Td>
                      <Td>
                        {assignment.assignmentStart &&
                          assignment.assignmentEnd &&
                          calculateDuration(
                            new Date(assignment.assignmentStart),
                            new Date(assignment.assignmentEnd)
                          )}
                      </Td>
                      <Td textAlign='center'>
                        {assignment.assignmentStart &&
                          assignment.assignmentEnd &&
                          new Date(assignment.assignmentEnd)
                            .toLocaleString('en-GB', {
                              year: 'numeric',
                              day: '2-digit',
                              month: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                            })
                            .replace(/\//g, '-')}
                      </Td>
                      <Td textAlign='center'>
                        {assignment.assignedBy.workerName}
                      </Td>
                      <Td textAlign='center'>{assignment.worker.workerName}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex direction='column' alignContent='flex-end' mt='10'>
              <Text as='i'>
                Porudžbenica: <Text as='b'>{orderCode}</Text>
              </Text>
              <Text as='i'>
                Proizvod: <Text as='b'>{productCode}</Text>{' '}
              </Text>
              <Text as='i'>
                Sektor: <Text as='b'>{sectorName}</Text>
              </Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default ShowWorkersForTaskModal;
