import React, { useState } from 'react';
import {
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  ModalFooter,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  getCustomSectorReport,
  SectorReport,
  SectorWorkTimeReportModalProps,
} from '../../report-api';
import GsSpinner from '../../../../../components/spinner/gsSpinner';
import SectorReportTableModal from './SectorWorkTimeReportTableModal';

function SectorWorkTimeReportModal({
  onClose,
  setIsModalOpen,
}: SectorWorkTimeReportModalProps) {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [modalTableVisible, setModalTableVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  const [reportName, setReportName] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const [customSectorReport, setCustomSectorReport] = useState<SectorReport[]>(
    []
  );

  const handleButtonClick = async () => {
    if (startDate === null || endDate === null) {
      console.log('Undefined');
      return;
    }

    if (reportName === '') {
      alert('Unesite naziv izvestaja');
      return;
    }

    if (startDate === '') {
      alert('Unesite pocetni datum');
      return;
    }

    if (endDate === '') {
      alert('Unesite krajnji datum');
      return;
    }

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (endDateObj < startDateObj) {
      alert('Pocetni datum ne moze biti posle krajnjeg datuma');
      return;
    }

    const data = await getCustomSectorReport(
      startDate,
      endDate,
      setCustomSectorReport,
      setIsLoading
    );
    setIsLoading(false);

    if (
      data === null ||
      (data !== false && Array.isArray(data) && data.length === 0)
    ) {
      alert('Ne postoje podaci za traženi izveštaj');
      return;
    } else {
      setModalTableVisible(true);
    }
  };

  return (
    <>
      <ModalBody>
        <FormControl>
          <FormLabel>Naziv izveštaja</FormLabel>
          <Input
            type='text'
            value={reportName}
            onChange={(e) => setReportName(e.target.value)}
          />
          <FormHelperText fontStyle='italic'>
            Unesite naziv izveštaja za radne sate po sektoru.
          </FormHelperText>
        </FormControl>
        <FormControl mt={7}>
          <FormLabel>Početni datum</FormLabel>
          <Input
            placeholder=''
            type='date'
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <FormHelperText fontStyle='italic'>
            Izaberite početni datum za izveštaj
          </FormHelperText>
        </FormControl>
        <FormControl mt={7}>
          <FormLabel>Krajnji datum</FormLabel>
          <Input
            placeholder=''
            type='date'
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <FormHelperText fontStyle='italic'>
            Izaberite krajni datum za izveštaj
          </FormHelperText>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        {isLoading ? (
          <GsSpinner></GsSpinner>
        ) : (
          <Button
            _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
            variant='outline'
            ml={5}
            onClick={handleButtonClick}
            w='40%'
          >
            Kreiraj
          </Button>
        )}
        <SectorReportTableModal
          isModalOpen={modalTableVisible}
          setIsModalOpen={setModalTableVisible}
          reportName={reportName}
          customSectorReport={customSectorReport}
          startDate={startDate}
          endDate={endDate}
          setIsLoading={setIsLoading}
        />
      </ModalFooter>
    </>
  );
}

export default SectorWorkTimeReportModal;
