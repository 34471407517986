import { Tr, Td, FormControl, Input, FormErrorMessage } from '@chakra-ui/react';
import { useState } from 'react';
import {
  calculateUsedResourcesForWorker,
  ResourceReservation,
} from '../../../worker-api';

export interface ResourceRequiredTableRowProps {
  reservationResource: ResourceReservation;
  index: number;
  onUpdateRequestedAmount: (index: number, newAmount: number) => void;
}

const ResourceRequiredTableRow = ({
  reservationResource,
  index,
  onUpdateRequestedAmount,
}: ResourceRequiredTableRowProps) => {
  let initRequiredAmount = reservationResource?.requestedAmount;

  const [isAmountInvalid, setIsAmountInvalid] = useState(false);
  const [productValue, setProductValue] = useState<number>(initRequiredAmount);

  let measureUnit = reservationResource.resource?.workerMeasureUnit?.unitName;
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = parseFloat(e.target.value);
    const updatedAmount = isNaN(newAmount) ? 0 : newAmount;
    setProductValue(updatedAmount);
    onUpdateRequestedAmount(index, newAmount);
  };
  return (
    <Tr borderBottom='1px'>
      <Td textAlign='center' w='40%'>
        {reservationResource.resource?.name}
      </Td>
      <Td textAlign='center' w='25%'>
        {measureUnit}
      </Td>
      <Td textAlign='center' w='35%'>
        <FormControl mt={4} isInvalid={isAmountInvalid}>
          <Input
            placeholder='Količina'
            textAlign={'center'}
            disabled={false}
            borderWidth='2px'
            value={productValue}
            onChange={handleAmountChange}
          />
          <FormErrorMessage>{'Unesite ispravan broj'}</FormErrorMessage>
        </FormControl>
      </Td>
    </Tr>
  );
};

export default ResourceRequiredTableRow;
