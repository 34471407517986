import { GET, POST } from '../../../api/requests';
import { getPaginationParams } from '../../../components/TabeNavigation/table-navigation';
import { SERVER_URL } from '../../../api/config';
import { useNavigate } from 'react-router-dom';

export type User = {
  id?: number;
  name: string;
  username: string;
  password: string;
  role?: string;
  roles?: Role[]; //jednu od ove dve treba obrisati i prilagoditi api
  sectors?: string[];
};

export type Role = {
  id?: number;
  name: string;
};

export type ChangePassword = {
  username: string;
  password: string;
}

export const createUser = async (user: User) => {
  try {
    await POST<User>(SERVER_URL + '/register', user);
    return true;
  } catch (error) {
    console.log('Error while creating user.');
    console.error(error);
    return false;
  }
};

export const editUserPassword = async (changePassword : ChangePassword) => {
  try {
    await POST<ChangePassword>(SERVER_URL + '/api/user/password-change', changePassword);
    return true;
  } catch (error) {
    console.log('Error while creating user.');
    console.error(error);
    return false;
  }
};

export const getUsers = async (pageNo: number, filter: string) => {
  try {
    const url = new URL(SERVER_URL + '/api/users');
    let params = new URLSearchParams();

    params = getPaginationParams(params, pageNo);
    if (filter) {
      params.append('filter', filter);
    }
    url.search = params.toString();

    const users = await GET<User[]>(url.toString());
    return users;
  } catch (error) {
    console.log('Error while fetching users.');
    console.error(error);
  }
};

export const deleteUser = async (user: User) => {
  try {
    const deletedUser = await POST<User>(
      SERVER_URL + '/api/user/delete',
      user
    );
    return true;
  } catch (error) {
    const em = error as Error;
    console.log('Error while deleting user. ' + em.message);
    alert('Error while deleting user. ' + em.message);
    return false;
  }
};

export function convertRole(unit: string) {
  let role = '';
  if (unit == 'ROLE_WORKER') {
    role = 'Radnik';
  }
  if (unit == 'ROLE_MANAGER') {
    role = 'Menadzer';
  }
  return role;
}

export function isCurrentUser(user: User) {
  const currentUser = JSON.parse(localStorage.getItem('user') as string);
  console.log(currentUser);
  return currentUser.userId === user.id?.toString() ? true : false;
}


