import { useChakra, Flex } from '@chakra-ui/react';
import { CustomHeading } from '../../../components/heading/custom-heading';
import AppLayout from '../../../components/layout/app-layout';
import NotificationsTable from './notificationsTable';
import { useTranslation } from 'react-i18next';

const NotificationsPage = () => {
  const { theme, colorMode } = useChakra();
  const { t } = useTranslation();

  return (
    <AppLayout>
      <Flex flexDir='column' w='100%'>
        <Flex
          flexDirection='column'
          w='100%'
          h='100%'
          mb={1}
          p='20'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
        >
          <CustomHeading text={t('notifications')} />
          <NotificationsTable />
        </Flex>
      </Flex>
    </AppLayout>
  );
};

export default NotificationsPage;
