import React from 'react';
import { Flex, Heading, Divider, useChakra } from '@chakra-ui/react';
import { MenuItemType } from '../../menu/models';
import ManagerMenuItem from './ManagerMenuItem';

const ManagerMenu: React.FC<{ menuItems: MenuItemType[] }> = ({
  menuItems,
}) => {
  const { colorMode, theme } = useChakra();

  return (
    <Flex
      pos='sticky'
      top={0}
      w='200px'
      h='full'
      flexDir='column'
      justifyContent='flex-start'
      borderRadius={10}
      borderWidth={2}
      bg={theme.colors.bg.colorMode(colorMode)}
      overflowY='auto'
    >
      <Flex
        mx='3'
        my='1'
        p='2'
        flexDir='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Heading
          as='h3'
          size='lg'
          style={{
            color: 'white',
            fontFamily: 'Source Sans Pro, sans-serif',
            fontSize: '28px',
            fontWeight: 'bold',
            lineHeight: '32px',
          }}
        >
          Meni
        </Heading>
      </Flex>
      <Divider />
      <Flex direction='column' as='nav' p={4}>
        {menuItems.map((item) => (
          <ManagerMenuItem key={item.id} item={item} />
        ))}
      </Flex>
    </Flex>
  );
};

export default ManagerMenu;
