import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useChakra,
} from '@chakra-ui/react';
import AppLayout from '../../../../components/layout/app-layout';
import { CustomHeading } from '../../../../components/heading/custom-heading';
import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import {
  getMaterialRequests,
  RequestedMaterialByPhase,
} from '../warehouse-api';
import MaterialRequestTable from '../components/table/materialRequests/MaterialRequestsTable';
import { useTranslation } from 'react-i18next';

const MaterialRequestsPage = () => {
  const { theme, colorMode } = useChakra();

  return (
    <AppLayout>
      <Flex flexDir='column' w='100%'>
        <Box
          flexDirection='row'
          width='full'
          height='full'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
          mb={1}
          p='20'
        >
          <CustomHeading text='Zahtevi za zaduživanje materijala' />
          <MaterialRequests />
        </Box>
      </Flex>
    </AppLayout>
  );
};

const MaterialRequests = () => {
  const [requestedMaterials, setRequestedMaterials] = useState<
    RequestedMaterialByPhase[]
  >([]);
  const [historyRequestedMaterials, setHistoryRequestedMaterials] = useState<
    RequestedMaterialByPhase[]
  >([]);
  const [unusedMaterials, setUnusedMaterials] = useState<
    RequestedMaterialByPhase[]
  >([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [pageNo, setPageNo] = useState<number>(0);
  const [pageNoHistory, setPageNoHistory] = useState<number>(0);
  const [pageNoUnused, setPageNoUnused] = useState<number>(0);
  const [isActiveRequests, setIsActiveRequests] = useState<string>('true');
  const [isUnusedRequests, setIsUnusedRequests] = useState<string>('false');
  const [filter, setFilter] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    getMaterialRequests(
      isActiveRequests,
      'false',
      pageNumber,
      setRequestedMaterials
    );
  }, [shouldRefresh, pageNumber]);

  const refreshTable = async () => {
    setFilter('');
    getMaterialRequests('true', 'false', pageNumber, setRequestedMaterials);
  };

  const refreshHistoryTable = async () => {
    setFilter('');
    getMaterialRequests(
      'false',
      'false',
      pageNumber,
      setHistoryRequestedMaterials
    );
  };

  const refreshUnusedTabke = async () => {
    setFilter('');
    getMaterialRequests('true', 'true', pageNumber, setUnusedMaterials);
  };

  const onChangeTab = (index: number) => {
    setFilter('');
    if (index === 0) {
      setIsActiveRequests('true');
      setIsUnusedRequests('false');
      getMaterialRequests('true', 'false', pageNumber, setRequestedMaterials);
    }
    if (index === 1) {
      setIsActiveRequests('false');
      setIsUnusedRequests('false');
      getMaterialRequests(
        'false',
        'false',
        pageNumber,
        setHistoryRequestedMaterials
      );
    }
    if (index === 2) {
      setIsActiveRequests('true');
      setIsUnusedRequests('true');
      getMaterialRequests('true', 'true', pageNumber, setUnusedMaterials);
      console.log(unusedMaterials);
    }
  };

  return (
    <Flex flexDir='column' w='100%'>
      <Flex flexDir='row' w='100%'>
        <InputGroup alignItems='center'>
          <InputLeftElement
            pointerEvents='none'
            children={<Icon as={FaSearch} color='gray.500' fontSize='20px' />}
          />
          <Input
            width='25%'
            marginBottom={5}
            placeholder='Pretraži po proizvodu'
            style={{ height: '40px' }}
            value={filter}
            onChange={async (e) => {
              if (e.target.value.length === 0) {
                setFilter('');
                if (isUnusedRequests === 'true') {
                  await getMaterialRequests(
                    isActiveRequests,
                    isUnusedRequests,
                    pageNumber,
                    setUnusedMaterials
                  );
                } else {
                  if (isActiveRequests === 'true') {
                    await getMaterialRequests(
                      isActiveRequests,
                      isUnusedRequests,
                      pageNumber,
                      setRequestedMaterials
                    );
                  } else {
                    await getMaterialRequests(
                      isActiveRequests,
                      isUnusedRequests,
                      pageNumber,
                      setHistoryRequestedMaterials
                    );
                  }
                }
              } else {
                setFilter(e.target.value);
                if (isUnusedRequests === 'true') {
                  await getMaterialRequests(
                    isActiveRequests,
                    isUnusedRequests,
                    pageNumber,
                    setUnusedMaterials,
                    e.target.value
                  );
                } else {
                  if (isActiveRequests === 'true') {
                    await getMaterialRequests(
                      isActiveRequests,
                      'false',
                      pageNumber,
                      setRequestedMaterials,
                      e.target.value
                    );
                  } else {
                    await getMaterialRequests(
                      isActiveRequests,
                      'false',
                      pageNumber,
                      setHistoryRequestedMaterials,
                      e.target.value
                    );
                  }
                }
              }
            }}
          ></Input>
        </InputGroup>
      </Flex>

      <Tabs variant='enclosed' mt={5} onChange={(index) => onChangeTab(index)}>
        <TabList mb='1em'>
          <Tab w='33%'>{t('activeRequests')}</Tab>
          <Tab w='33%'>{t('requestHistory')}</Tab>
          <Tab w='33%'>{t('unusedMaterial')}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel w='100%'>
            <MaterialRequestTable
              pageNumber={pageNo}
              setPageNumber={setPageNo}
              requestedMaterials={requestedMaterials}
              setRequestedMaterials={setRequestedMaterials}
              refreshRequestTable={refreshTable}
              requestType={'active_requests'}
            />
          </TabPanel>
          <TabPanel>
            <MaterialRequestTable
              pageNumber={pageNoHistory}
              setPageNumber={setPageNoHistory}
              requestedMaterials={historyRequestedMaterials}
              setRequestedMaterials={setHistoryRequestedMaterials}
              refreshRequestTable={refreshHistoryTable}
              requestType={'history_requests'}
            />
          </TabPanel>
          <TabPanel>
            <MaterialRequestTable
              pageNumber={pageNoUnused}
              setPageNumber={setPageNoUnused}
              requestedMaterials={unusedMaterials}
              setRequestedMaterials={setUnusedMaterials}
              refreshRequestTable={refreshUnusedTabke}
              requestType={'unused_request'}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default MaterialRequestsPage;
