import { AddIcon, DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import GsSpinner from '../../../../components/spinner/gsSpinner';
import { TableNavigation } from '../../../../components/TabeNavigation/TableNavigation';
import {
  Resource,
  getResourcesByFilter,
  getResources,
} from '../../../manager/resource/resource-api';

interface WorkerChooseResourceModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAdditionalResource: (resource: Resource, amount: number) => void;
}

const WorkerChooseResourceModal = ({
  isOpen,
  onClose,
  handleAdditionalResource,
}: WorkerChooseResourceModalProps) => {
  const resourceNameRef = useRef<HTMLInputElement>(null);
  const amountRef = useRef<HTMLInputElement>(null);
  const [resources, setResources] = useState<Resource[]>([]);
  const [inputResource, setInputResource] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isLoadingResources, setIsLoadingResources] = useState<boolean>(false);
  const [showAmountInput, setShowAmountInput] = useState<boolean>(false);
  const [isAmountInvalid, setIsAmountInvalid] = useState(false);
  const [additionalResource, setAdditionalResource] = useState<Resource>();

  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    if (resourceNameRef && resourceNameRef.current) {
      resourceNameRef.current.value === ''
        ? getResources(setResources, newPageNumber, setIsLoadingResources)
        : getResourcesByFilter(
            resourceNameRef.current.value,
            setResources,
            newPageNumber,
            setIsLoadingResources
          );
    }

    setPageNumber(newPageNumber);
  };

  const handleAdditionalResourceAndShowInput = (resource: Resource) => {
    setShowAmountInput(true);
    setAdditionalResource(resource);
  };

  const handleDeleteAdditionalResourceAndShowInput = () => {
    setShowAmountInput(false);
    setAdditionalResource(undefined);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent minWidth={'35%'}>
        <ModalHeader>Izaberite materijal</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mt={4}>
            <FormLabel>Naziv materijala</FormLabel>
            <InputGroup>
              <InputRightElement
                children={
                  <IconButton
                    aria-label='theme'
                    icon={<SearchIcon />}
                    onClick={(e) => {
                      if (!inputResource || inputResource.length === 0) return;
                      getResourcesByFilter(
                        inputResource,
                        setResources,
                        0,
                        setIsLoadingResources
                      );
                      setPageNumber(0);
                    }}
                  ></IconButton>
                }
              />
              <Input
                placeholder='Pretražite materijal'
                ref={resourceNameRef}
                onChange={(e) => {
                  if (!e.target.value || e.target.value.length === 0) return;
                  getResourcesByFilter(
                    e.target.value,
                    setResources,
                    0,
                    setIsLoadingResources
                  );
                  console.log(resources);
                  setInputResource(e.target.value);
                  setPageNumber(0);
                }}
              />
            </InputGroup>
          </FormControl>
          {!showAmountInput && isLoadingResources ? (
            <GsSpinner />
          ) : (
            <TableContainer>
              {showAmountInput ? (
                <></>
              ) : (
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th textAlign='center'>Naziv</Th>
                      <Th textAlign='center'>Dodaj</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {resources.map((r) => (
                      <WorkerChooseResourceModalTableRow
                        key={r.id}
                        resource={r}
                        handleAdditionalResourceAndShowInput={
                          handleAdditionalResourceAndShowInput
                        }
                      ></WorkerChooseResourceModalTableRow>
                    ))}
                  </Tbody>
                </Table>
              )}
              {showAmountInput ? (
                <>
                  <Table>
                    <Tr>
                      <Td>
                        <Text fontSize='2xl' marginRight={'10%'}>
                          {additionalResource?.name}
                        </Text>
                      </Td>
                      <Td textAlign='center'>
                        <FormControl
                          isInvalid={isAmountInvalid}
                          alignContent={'center'}
                        >
                          <Input
                            placeholder='Količina'
                            textAlign={'center'}
                            ref={amountRef}
                            onChange={(e) => {
                              if (e.target.value === '') return;
                              if (!isNaN(Number(e.target.value))) {
                                if (isAmountInvalid) setIsAmountInvalid(false);
                              } else setIsAmountInvalid(true);
                            }}
                          />
                          <FormErrorMessage>
                            {'Unesite ispravan broj'}
                          </FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <Text fontSize='2xl' marginRight={'10%'}>
                          {additionalResource?.workerMeasureUnit?.unitName}
                        </Text>
                      </Td>
                      <Td>
                        <IconButton
                          aria-label={'resources-add'}
                          icon={<DeleteIcon />}
                          variant='outline'
                          marginLeft={'10%'}
                          onClick={() => {
                            handleDeleteAdditionalResourceAndShowInput();
                          }}
                        />
                      </Td>
                    </Tr>
                  </Table>
                </>
              ) : (
                <></>
              )}
              <TableNavigation
                pageNumber={pageNumber}
                pageNumberHandler={pageNumberHandler}
              />
            </TableContainer>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            variant='outline'
            w='20%'
            isDisabled={isAmountInvalid}
            onClick={(e) => {
              if (
                amountRef.current?.value === '' ||
                Number(amountRef.current?.value) < 0
              ) {
                setIsAmountInvalid(true);
                return;
              }

              if (additionalResource) {
                let amount = Number(amountRef.current?.value);
                handleAdditionalResource(additionalResource, amount);
                setShowAmountInput(false);
                setAdditionalResource(undefined);
                onClose();
              }
            }}
          >
            Dodaj
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface WorkerChooseResourceModalTableRowProps {
  resource: Resource | undefined;
  handleAdditionalResourceAndShowInput: (resource: Resource) => void;
}

const WorkerChooseResourceModalTableRow = ({
  resource,
  handleAdditionalResourceAndShowInput,
}: WorkerChooseResourceModalTableRowProps) => {
  return (
    <Tr>
      <Td textAlign='center'>{resource?.name}</Td>
      <Td textAlign='center'>
        <IconButton
          aria-label={'resources-add'}
          icon={<AddIcon />}
          variant='outline'
          onClick={() => {
            if (resource) handleAdditionalResourceAndShowInput(resource);
          }}
        />
      </Td>
    </Tr>
  );
};

export default WorkerChooseResourceModal;
