import { PopoverHeader } from '@chakra-ui/react';
import { FC } from 'react';

const GsHeaderPopOver: FC<{ title: string }> = ({ title }) => {
  return (
    <PopoverHeader
      mb='6'
      fontFamily='Source Sans Pro, sans-serif'
      fontSize='22px'
      lineHeight='32px'
      margin='0 0 24px'
    >
      {title}
    </PopoverHeader>
  );
};

export default GsHeaderPopOver;
