import { Box, Flex, FormLabel, Tooltip, useDisclosure } from '@chakra-ui/react';
import { memo, useContext } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { NodeDataWorker } from './ProductDiagramModal';
import { useProductPhaseIds } from './ProductPhaseContext';

const FlowDiagramWorkerNode: React.FC<NodeProps<NodeDataWorker>> = ({
  data,
}) => {
  const { productId, phaseId } = useProductPhaseIds();

  return (
    <Box
      bg={
        productId == data.productId && phaseId == data.phaseId
          ? 'white'
          : 'whiteAlpha'
      }
      color={
        productId == data.productId && phaseId == data.phaseId
          ? 'black'
          : 'white'
      }
      fontFamily='Source Sans Pro, sans-serif'
      borderRadius='10px'
      padding={2}
      shadow='dark-lg'
    >
      <Handle type='target' position={Position.Bottom} isConnectable={true} />
      <Handle
        type='source'
        position={Position.Top}
        id='a'
        isConnectable={true}
      />
      <Flex direction='column'>
        <FormLabel textAlign='center'>
          <Box
            as='span'
            fontWeight='bold'
            fontFamily='Source Sans Pro, sans-serif'
          >
            {data.sector}
          </Box>
        </FormLabel>
      </Flex>
    </Box>
  );
};

export default memo(FlowDiagramWorkerNode);
