import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
} from '@chakra-ui/react';
import { FC } from 'react';
import { RequestedResourceForTask } from '../../../worker-api';
import ReservedResourceReviewTableRow from './ReservedResourceReviewTableRow';

const ReservedResourceReviewTable: FC<{
  reservedResources: RequestedResourceForTask[];
}> = ({ reservedResources }) => {
  let tableCaption;
  if (reservedResources.length > 0) {
    tableCaption = 'Zaduženi materijali';
  } else {
    tableCaption =
      'Ne postoje zaduženi materijali, možete dodati materijal i poslati zahtev za zaduživanje dodatnih materijala';
  }
  return (
    <TableContainer marginTop='1%' marginBottom='0%'>
      <Table>
        <TableCaption fontSize='l' color={'white'}>
          {tableCaption}{' '}
        </TableCaption>
        <Thead>
          <Tr borderBottom='1px solid'>
            <Th textAlign='center'>Naziv materijala</Th>
            <Th textAlign='center'>Zadužena količina</Th>
            <Th textAlign='center'>Odobrena količina</Th>
            <Th textAlign='center'>Merna jedinica</Th>
          </Tr>
        </Thead>
        <Tbody>
          {reservedResources.map((reservedResource, index) => (
            <ReservedResourceReviewTableRow
              reservedResource={reservedResource}
              key={reservedResource.id}
              index={index}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ReservedResourceReviewTable;
