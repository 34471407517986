import { Flex, useChakra } from '@chakra-ui/react';
import { FC } from 'react';

import ReactFlow, { Controls, Node, Edge } from 'reactflow';
import FlowDiagramWorkerNode from './FlowDiagramWorkerNode';
import { NodeDataWorker } from './ProductDiagramModal';

type FlowDiagramWorkerProps = {
  nodes: Node<NodeDataWorker, string | undefined>[];
  edges: Edge<Edge<any>>[];
};

const nodeTypes = {
  selectorNode: FlowDiagramWorkerNode,
};

const FlowDiagramWorker: FC<FlowDiagramWorkerProps> = ({ nodes, edges }) => {
  const { theme } = useChakra();

  return (
    <Flex
      bg={theme.colors.gray[700]}
      width='100%'
      height='100vh'
      alignItems='center'
      borderWidth='2px'
      borderRadius='5px'
      p='10px'
      overflow='hidden'
      direction='column'
      shadow='dark-lg'
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        snapToGrid
        nodeTypes={nodeTypes}
        fitView
        proOptions={{ hideAttribution: true }}
        deleteKeyCode={null}
      >
        <Controls />
      </ReactFlow>
    </Flex>
  );
};

export default FlowDiagramWorker;
