import { Box, Flex } from '@chakra-ui/layout';
import { useChakra } from '@chakra-ui/system';
import AppLayout from '../../../components/layout/app-layout';
import CustomTaskForm from './customTaskForm';
import { Input } from '@chakra-ui/input';
import { CustomHeading } from '../../../components/heading/custom-heading';

const CustomTaskPage = () => {
  const { theme, colorMode } = useChakra();

  return (
    <AppLayout>
      <Flex
        flexDir='column'
        w='100%'
        h='100%'
      >
        <Box
          flexDirection='row'
          width='full'
          height='100%'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
          mb={1}
          p='20'
        >
          <CustomHeading text='Kreiranje radnog naloga' />
          <CustomTaskForm></CustomTaskForm>
        </Box>
      </Flex>
    </AppLayout>
  );
};

export default CustomTaskPage;
