import { TableContainer, Table, Thead, Th, Tbody, Tr } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableNavigation } from '../../../../../../components/TabeNavigation/TableNavigation';
import {
  getMaterialsByRequests,
  RequestedMaterialByPhase,
  RequestedMaterialForPhase,
} from '../../../warehouse-api';
import MaterialByRequestTableRow from './MaterialByRequestTableRow';

const MaterialByRequestTable: FC<{
  pageNumber: number;
  setPageNumber: any;
  request: RequestedMaterialByPhase;
  materialsByRequest: RequestedMaterialForPhase[];
  setMaterialsByRequest: any;
  refreshTable: any;
}> = ({
  pageNumber,
  setPageNumber,
  request,
  materialsByRequest,
  setMaterialsByRequest,
  refreshTable,
}) => {
  const { t } = useTranslation();
  const pageNumberHandler = async (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;
    const materials = await getMaterialsByRequests(
      request.productId,
      request.phaseId,
      request.orderCode,
      'true',
      newPageNumber
    );
    setMaterialsByRequest(materials);
    setPageNumber(newPageNumber);
  };
  return (
    <TableContainer marginTop={2} width='100%'>
      <Table variant='simple' mb={10}>
        <Thead>
          <Tr borderBottom='1px solid'>
            <Th textAlign='center'>{t('materialName')}</Th>
            <Th textAlign='center'>{t('order')}</Th>
            <Th textAlign='center'>{t('sentAt')}</Th>
            <Th textAlign='center'>{t('user')}</Th>
            <Th textAlign='center'>{t('available')} količina</Th>
            <Th textAlign='center'>{t('previouslyApproved')}</Th>
            <Th textAlign='center'>{t('requested')}</Th>
            <Th textAlign='center'>{t('used')}</Th>
            <Th textAlign='center'>{t('measureUnit')}</Th>
            <Th textAlign='center'>{t('approve')}</Th>
            <Th textAlign='center'></Th>
          </Tr>
        </Thead>
        <Tbody>
          {materialsByRequest.map((r, index) => (
            <MaterialByRequestTableRow
              key={index}
              requestItem={r}
              refreshTable={refreshTable}
            />
          ))}
        </Tbody>
      </Table>
      <TableNavigation
        pageNumber={pageNumber}
        pageNumberHandler={pageNumberHandler}
      />
    </TableContainer>
  );
};

export default MaterialByRequestTable;
