import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Th,
  Thead,
  useDisclosure,
} from '@chakra-ui/react';
import { TableNavigation } from '../../../../../components/TabeNavigation/TableNavigation';
import {
  getResources,
  getResourcesByFilter,
  Resource,
  ResourceTableProps,
} from '../../resource-api';
import EditResourceModal from '../modals/EditResourceModal';
import ResourceTableRow from './ResourceTableRow';

function ResourceTable({
  selectedResource,
  setSelectedResource,
  setAmmountAvailabe,
  pageNumber,
  setPageNumber,
  fieldRef,
  setState,
  setIsLoading,
  resources,
  qualityUnits,
  measureUnits,
  setMeasurementUnits,
  setQualityUnits,
  handleGetResource,
}: ResourceTableProps) {
  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    fieldRef.current.value === ''
      ? getResources(setState, newPageNumber, setIsLoading)
      : getResourcesByFilter(
          fieldRef.current.value,
          setState,
          newPageNumber,
          setIsLoading
        );

    setPageNumber(newPageNumber);
  };

  const handleOnCloseEditResourceModal = () => {
    handleGetResource();
    onCloseEditResourceModal();
  };

  const handleEditResource = (resource: Resource) => {
    setSelectedResource(resource);
    setAmmountAvailabe(resource?.available);
    onOpenEditResourceModal();
  };

  const {
    isOpen: isOpenEditResourceModal,
    onOpen: onOpenEditResourceModal,
    onClose: onCloseEditResourceModal,
  } = useDisclosure();

  const tableCaption = resourceTableCaptionGenerator(resources);
  return (
    <>
      <TableContainer marginTop='30px' marginBottom='70px'>
        <Table variant='simple'>
          <TableCaption>{tableCaption}</TableCaption>
          <Thead>
            <Th>Šifra materijala</Th>
            <Th>Naziv materijala</Th>
            <Th>Merna jedinica</Th>
            <Th>Radnikova jedinica</Th>
            <Th>Koef.</Th>
            <Th>Kvalitet</Th>
            <Th>Količina</Th>
            <Th>Izmeni</Th>
            <Th>Obriši</Th>
          </Thead>
          <Tbody>
            {resources.map((r) => (
              <ResourceTableRow
                key={r.id}
                resource={r}
                setState={setState}
                setIsLoading={setIsLoading}
                handleEditResource={handleEditResource}
              />
            ))}
          </Tbody>
        </Table>
        <TableNavigation
          pageNumber={pageNumber}
          pageNumberHandler={pageNumberHandler}
        />
      </TableContainer>
      <EditResourceModal
        isOpen={isOpenEditResourceModal}
        onClose={handleOnCloseEditResourceModal}
        selectedResource={selectedResource}
        setSelectedResource={setSelectedResource}
        qualityUnits={qualityUnits}
        measurementUnits={measureUnits}
        setMeasurementUnits={setMeasurementUnits}
        setQualityUnits={setQualityUnits}
      />
    </>
  );
}

export default ResourceTable;

const resourceTableCaptionGenerator = (resources: any) => {
  if (resources.length === 0) {
    return 'Ne postoji nijedan materijal sa unetim nazivom';
  } else {
    return 'Materijali';
  }
};
