import i18next from 'i18next';

type ExceptionResponse = {
  message: string;
  params: [];
};

const createParamsObject = (translationKey: string, values: any[]) => {
  const translationString = i18next.getResource(
    'sr',
    'translation',
    translationKey
  );
  const regex = /{{(.*?)}}/g;
  let match;
  const params: Record<string, any> = {};
  let valuesIndex = 0;

  while ((match = regex.exec(translationString)) !== null) {
    const paramName: string = match[1].trim();
    params[paramName] = values[valuesIndex] || '';
    valuesIndex++;
  }

  return params;
};

export const getErrorMessage = (message: string) => {
  try {
    const exceptionResponse: ExceptionResponse = JSON.parse(message);
    let params = createParamsObject(
      exceptionResponse.message,
      exceptionResponse.params
    );

    const translation: any = i18next.t(exceptionResponse.message, params);

    if (typeof translation !== 'string') {
      return 'Error';
    }

    return translation;
  } catch (error: any) {
    return message;
  }
};
