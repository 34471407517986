import React, { useState } from 'react';
import { Box, Text, Icon, Button } from '@chakra-ui/react';
import { ReportBoxProps } from '../report-api';

const ReportBox = ({
  icon,
  text,
  description,
  onButtonClick,
  ml,
}: ReportBoxProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      borderWidth='1px'
      borderRadius='lg'
      p={4}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      position='relative'
      cursor='pointer'
      onClick={onButtonClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      w='18%'
      height='270px'
      transition='background-color 0.2s ease-in-out'
      _hover={{ backgroundColor: '#2D3748' }}
      ml={ml}
    >
      <Text
        fontSize='lg'
        fontWeight='bold'
        mb={2}
      >
        {text}
      </Text>
      <Icon
        as={icon}
        fontSize='9xl'
        color='#EBF5FB'
        mb={2}
      />
      <Text
        fontSize='sm'
        color='#EBF5FB'
        w='75%'
      >
        {description}
      </Text>
    </Box>
  );
};

export default ReportBox;
