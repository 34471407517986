import { Box, Flex, useChakra } from '@chakra-ui/react';
import AppLayout from '../../../../components/layout/app-layout';
import { CustomHeading } from '../../../../components/heading/custom-heading';
import SectorForm from '../components/form/sector-form';

const CreateSectorPage = () => {
  const { theme, colorMode } = useChakra();

  return (
    <AppLayout>
      <Flex flexDir='column' w='100%'>
        <Box
          flexDirection='row'
          width='full'
          height='full'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
          mb={1}
          p='20'
        >
          <CustomHeading text='Kreiranje sektora' />
          <SectorForm />
        </Box>
      </Flex>
    </AppLayout>
  );
};

export default CreateSectorPage;
