import { Exception409 } from '../exceptions/Exception409';
import { Exception510 } from '../exceptions/Exception510';
import { NoContentException } from '../exceptions/NoContentException';

import { LOGIN_PAGE } from './config';
import { getErrorMessage } from './exceptionUtil';

type ErrorResonse = {
  message: string;
};

export const GET = async <T>(url: string): Promise<T> => {
  const jwt = localStorage.getItem('token');
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${jwt}` },
  });

  if (response.status == 204) {
    throw new NoContentException();
  }

  if (response.status == 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    alert('Sesija je istekla');
    window.location.replace(LOGIN_PAGE);
  }

  if (response.status == 403) {
    alert('Greska');
    console.log(response);
    const { message }: ErrorResonse = await response.json();
    console.log(message);
  }

  return await response.json();
};

export const POST = async <T>(url: string, data: any): Promise<T> => {
  const jwt = localStorage.getItem('token');
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status == 501) {
    const { message }: ErrorResonse = await response.json();
    console.log(message);
    throw new Exception510(message);
  }

  if (response.status == 510) {
    const { message }: ErrorResonse = await response.json();
    console.log(message);
    throw new Error(message);
  }

  if (response.status == 409) {
    const { message }: ErrorResonse = await response.json();
    const final: string = getErrorMessage(message);
    console.log(final);
    throw new Exception409(final);
  }

  if (response.status == 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    alert('Sesija je istekla');
    window.location.replace(LOGIN_PAGE);
  }

  if (response.status == 403) {
    alert('Greska');
    console.log(response);
    const { message }: ErrorResonse = await response.json();
    console.log(message);
  }

  return await response.json();
};
