import {
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Stack,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  IconButton,
} from '@chakra-ui/react';
import { FaSearch, FaChevronDown } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { Sector, getSectors1 } from '../../sector/sector-apis';

export interface TaskSearchProps {
  setSelectedOption: (value: string) => void;
  searchRef: React.RefObject<HTMLInputElement>;
  pageNumberHandler: (num: number) => void;
  isChecked: boolean;
  setIsChecked: (value: boolean) => void;
  handleInitalAndRefreshCalls: (isChecked: boolean) => void;
  selectedOption: string;
  onSectorsSelected: (selectedSectors: string[]) => void;
}

const TaskSearch = ({
  setSelectedOption,
  pageNumberHandler,
  searchRef,
  isChecked,
  setIsChecked,
  handleInitalAndRefreshCalls,
  selectedOption,
  onSectorsSelected
}: TaskSearchProps) => {
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [selectedSectors, setSelectedSectors] = useState<string[]>([]);

  useEffect(() => {
    getSectors1().then(setSectors);
  }, []);

  const handleSectorChange = (selection: string[]) => {
    setSelectedSectors(selection);
  };

  useEffect(() => {
    onSectorsSelected(selectedSectors);
  }, [selectedSectors, onSectorsSelected]);

  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  const handleButtonClick = () => {
    setIsChecked(!isChecked);
    handleInitalAndRefreshCalls(!isChecked);
  };

  return (
    <Flex justifyContent="space-between" align="center" w="100%">
      <Flex direction="column">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={FaSearch} color="gray.500" />
          </InputLeftElement>
          <Input
            placeholder="Pretraži"
            ref={searchRef}
            onChange={() => pageNumberHandler(0)}
          />
        </InputGroup>
        <RadioGroup onChange={setSelectedOption} value={selectedOption} mt="2">
          <Stack direction="row">
            <Radio value="Order">Porudzbenica</Radio>
            <Radio value="Product">Proizvod</Radio>
          </Stack>
        </RadioGroup>
      </Flex>

      <Flex align="center">
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} rightIcon={<FaChevronDown />} variant="outline" mr="4">
            Izaberi sektore
          </MenuButton>
          <MenuList>
            <MenuOptionGroup
              value={selectedSectors}
              title="Sektori"
              type="checkbox"
              onChange={(e) => handleSectorChange(e as string[])}
            >
              {sectors.map((sector) => (
                <MenuItemOption value={sector.name} key={sector.id}>
                  {sector.name}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>

        <Button
          onClick={handleButtonClick}
          _hover={{ bgColor: logoutBtnClr }}
          variant="outline"
        >
          {isChecked ? 'Sortiraj po prioritetu' : 'Sortiraj po datumu isporuke'}
        </Button>
      </Flex>
    </Flex>
  );
};

export default TaskSearch;
