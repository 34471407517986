import React, { useRef, useState } from 'react';
import {
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import { ConsumedMaterialReportModalProps } from '../../report-api';
import GsSpinner from '../../../../../components/spinner/gsSpinner';
import { SearchIcon } from '@chakra-ui/icons';
import SearchedOrderTable from './SearchedOrderTable';
import { getOrdersByFilter, Order } from '../../../order/order-apis';

function ConsumedMaterialReportModal({
  onClose,
  setIsModalOpen,
}: ConsumedMaterialReportModalProps) {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const orderNameRef = useRef<HTMLInputElement>(null);
  const [orders, setOrders] = useState<Order[]>([]);
  const [productPageNo, setProductPageNo] = useState<number>(0);

  return (
    <>
      <ModalBody>
        <FormControl>
          <FormLabel>Porudžbenica</FormLabel>
          <InputGroup>
            <InputRightElement
              children={
                <IconButton
                  aria-label='theme'
                  icon={<SearchIcon />}
                  onClick={() => {}}
                ></IconButton>
              }
            />
            <Input
              placeholder='Pretražite porudžbenicu'
              ref={orderNameRef}
              onChange={(e) => {
                if (!e.target.value) return;
                getOrdersByFilter(e.target.value, setOrders, 0, 'all');
              }}
            />
          </InputGroup>
        </FormControl>
        {isLoading ? (
          <GsSpinner />
        ) : (
          (orders.length > 0 || productPageNo !== 0) && (
            <SearchedOrderTable
              orders={orders}
              setOrders={setOrders}
              fieldRef={orderNameRef}
              pageNumber={productPageNo}
              setPageNumber={setProductPageNo}
            />
          )
        )}
      </ModalBody>
      <ModalFooter></ModalFooter>
    </>
  );
}

export default ConsumedMaterialReportModal;
