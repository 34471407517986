import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Th,
  Tbody,
} from '@chakra-ui/react';
import { FC } from 'react';
import { User } from '../../user-api';
import UserTableRow from './user-table-row';

const UsersTable: FC<{
  users: User[];
  onUserSelect: (user: User) => void;
  onUserSelectforPW: (user: User) => void;
}> = ({ users, onUserSelect, onUserSelectforPW }) => {
  return (
    <TableContainer marginTop='30px' marginBottom='30px' width='100%'>
      <Table variant='simple'>
        <TableCaption>{users ? 'Korisnici' : 'Nema korisnika'}</TableCaption>
        <Thead>
          <Th>Ime i prezime</Th>
          <Th>Korisničko ime</Th>
          <Th>Rola</Th>
          <Th>Izmeni korisnika</Th>
          <Th>Izmeni sifru</Th>
          <Th>Obriši korisnika</Th>
        </Thead>
        <Tbody>
          {users.map((u) => (
            <UserTableRow
              key={u.id}
              user={u}
              onUserSelect={onUserSelect}
              onUserSelectforPW={onUserSelectforPW}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
