import * as XLSX from 'xlsx';
import FileSaver, { saveAs } from 'file-saver';

export function exportToExcel(headers: any, data: any, reportName: string) {
    const ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, [headers]);
      XLSX.utils.sheet_add_json(ws, data, {
        origin: 'A2',
        skipHeader: true,
      });

      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true,
      });

      const fileFormat =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const finalData = new Blob([excelBuffer], { type: fileFormat });
      const fullReportName = `${reportName}.xlsx`;
      console.log(data);
      FileSaver.saveAs(finalData, fullReportName);
  }