import { Route, Routes } from 'react-router-dom';
import WorkerPage from './worker-page';

const WorkerRoutes = () => {
  return (
    <Routes>
      <Route path='/worker/*' element={<WorkerPage />} />
    </Routes>
  );
};

export default WorkerRoutes;
