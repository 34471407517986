import { DeleteIcon } from '@chakra-ui/icons';
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import GsIconBtn from '../../../../components/button/GsIconBtn';
import { useGsDialog } from '../../../../contexts/GsConfirmDialogContext';
import { convertMeasureUnit } from '../../resource/resource-api';
import { ResourceRequiredAmmount } from '../product-api';

type TemporaryComponentProps = {
  requiredResources: ResourceRequiredAmmount[];
  onDeleteProductResources: () => void;
};

const TemporaryComponent: FC<TemporaryComponentProps> = ({
  requiredResources,
  onDeleteProductResources,
}) => {
  const { confirmDialog } = useGsDialog();

  useEffect(() => {
    console.log(requiredResources);
  }, []);

  return (
    <Flex px='5'>
      <TableContainer marginBottom='30px'>
        <Flex mb='5'>
          <Text>Dodaj navedene materijale fazama proizvoda</Text>
        </Flex>
        <Table variant='simple'>
          <Thead>
            <Tr borderBottom='solid'>
              <Th textAlign='center'>Šifra materijala</Th>
              <Th textAlign='center'>Naziv materijala</Th>
              <Th textAlign='center'>Merna jedinica</Th>
              <Th textAlign='center'>Kvalitet</Th>
              <Th textAlign='center'>Količina</Th>
            </Tr>
          </Thead>
          <Tbody>
            {requiredResources.map((resource) => (
              <Tr borderBottom='solid' key={resource.id}>
                <Td textAlign='center'>{resource.resource?.resourceCode}</Td>
                <Td textAlign='center'>{resource.resource?.name}</Td>
                <Td textAlign='center'>
                  {convertMeasureUnit(resource.resource?.measureUnit?.unitName)}
                </Td>
                <Td textAlign='center'>
                  {resource.resource?.quality?.qualityName}
                </Td>
                <Td textAlign='center'>{resource.amount}</Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td colSpan={6}>
                <Flex alignItems='center' justifyContent='flex-end'>
                  <GsIconBtn
                    icon={<DeleteIcon />}
                    label='delete resources'
                    onClick={() =>
                      confirmDialog(
                        'Brisanje Materijala',
                        'Da li sigurno želite da obrišete materijale vezane za proizvod? Bitno je da ste ih prethodno dodali fazama proizvoda. Ukoliko ste to uradili, možete ih obrisati i sačuvati proizvod.',
                        () => {
                          onDeleteProductResources();
                        }
                      )
                    }
                  />
                </Flex>
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default TemporaryComponent;
