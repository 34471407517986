import {
  Text,
  Flex,
  Menu,
  Link,
  MenuButton,
  Icon,
  useColorModeValue,
  MenuItem as MnuItem,
} from '@chakra-ui/react';
import { FC } from 'react';
import { BgClrBtn, TxtClr } from '../../../../components/theme/custom-theme';
import { Item } from '../models';

export type MenuItemProps = {
  item: Item;
  navigate: (item: Item, create: boolean) => void;
};

const WorkerMenuItem: FC<MenuItemProps> = ({ item, navigate }) => {
  const itemClr = useColorModeValue('#EBF5FB', '#2D3748');

  return (
    <Flex mt='30' flexDir='column' w='100%' alignItems='flex-start'>
      <Menu placement='right'>
        <Link
          bgColor={item.active ? itemClr : BgClrBtn()}
          borderRadius='10'
          p={1}
          borderWidth={item.active ? 2 : undefined}
          _hover={{ textDecor: 'none', bgColor: itemClr }}
          variant='outline'
        >
          <MenuButton
            w='140px'
            h='40px'
            onClick={item.route ? () => navigate(item, true) : undefined}
          >
            <Flex>
              <Icon as={item.icon} fontSize='2xl' color={TxtClr()} />
              <Text as='b' size='sm' ml='3' color={TxtClr()} isTruncated>
                {item.name}
              </Text>
            </Flex>
          </MenuButton>
        </Link>
      </Menu>
    </Flex>
  );
};

export default WorkerMenuItem;
