import {
  Text,
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  useColorModeValue,
  MenuDivider,
  Avatar,
  Flex,
} from '@chakra-ui/react';
import { FC } from 'react';
import { FaSignOutAlt, FaUser } from 'react-icons/fa';
import { User } from '../../pages/login/login-api';
import GsIconBtn from '../button/GsIconBtn';

const UserButton: FC<{ currentUser: User; userLogout: () => void }> = ({
  currentUser,
  userLogout,
}) => {
  const btnClr = useColorModeValue('#EBF5FB', '#2D3748');

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label='User Menu'
        icon={<Icon as={FaUser} />}
        _hover={{
          textDecor: 'none',
          bgColor: btnClr,
          textColor: 'white',
          borderColor: 'white',
          borderWidth: '1px',
        }}
        size='sm'
      />
      <MenuList>
        <MenuGroup>
          <Flex px={4} py={2} alignItems='center'>
            <Avatar size='sm' name={currentUser?.name} mr='3' />
            <Text fontWeight='bold' fontStyle='italic '>
              {currentUser?.name}
            </Text>
          </Flex>
        </MenuGroup>
        <MenuDivider />
        <MenuItem
          fontStyle='italic '
          icon={<Icon as={FaSignOutAlt} />}
          onClick={userLogout}
        >
          Odjavi se
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserButton;
