import { Tr, Td, useDisclosure } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { convertDate } from '../../../../order/order-apis';
import {
  convertDateTime,
  RequestedMaterialByPhase,
} from '../../../warehouse-api';
import MaterialsByRequestModal from '../../modals/MaterialsByRequestModal';

const MaterialRequestTableRow: FC<{
  request: RequestedMaterialByPhase;
  refreshRequestTable: any;
  requestType: string;
}> = ({ request, refreshRequestTable, requestType }) => {
  const [isHover, setIsHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const boxStyle = {
    borderBottom: '1px solid #ccc',
    backgroundColor: isHover ? '#1A365D' : '', //'#383838'
  };
  const handleMouseEnter = () => {
    if (!isOpenMaterialRequestModal) {
      setIsHover(true);
    }
  };
  const handleMouseLeave = () => {
    if (!isOpenMaterialRequestModal) {
      setIsHover(false);
    }
  };

  const {
    isOpen: isOpenMaterialRequestModal,
    onOpen: onOpenMaterialRequestModal,
    onClose: onCloseMaterialRequestModal,
  } = useDisclosure();
  return (
    <Tr
      style={boxStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        setIsOpen(!isOpen);
        onOpenMaterialRequestModal();
      }}
    >
      <Td alignItems='center' justifyContent='center'>
        {convertDateTime(request.latestRequestedAt)}
      </Td>
      <Td alignItems='center' justifyContent='center'>
        {request.orderCode}
      </Td>
      <Td alignItems='center' justifyContent='center'>
        {request.productCode}
      </Td>
      <Td alignItems='center' justifyContent='center'>
        {request.sectorName}
      </Td>
      <Td alignItems='center' justifyContent='center'>
        {request.phaseId}
      </Td>
      <Td alignItems='center' justifyContent='center'>
        {convertDate(request.estimatedEnd)}
      </Td>
      {isOpenMaterialRequestModal && (
        <MaterialsByRequestModal
          isOpen={isOpenMaterialRequestModal}
          onClose={onCloseMaterialRequestModal}
          request={request}
          refreshRequestTable={refreshRequestTable}
          requestType={requestType}
        />
      )}
    </Tr>
  );
};

export default MaterialRequestTableRow;
