import { Flex } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

const AppLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    // Ovo treba obrisati iz svake komponente tj stranice - ne sluzi vise nicemu
    <Flex>{children}</Flex>
  );
};

export default AppLayout;
