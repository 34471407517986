import { Box, Flex, Heading, useChakra } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TaskTable from './components/task-table';
import { getTaskData, TaskInProgressDTO } from './task-api';
import { TableNavigation } from '../../../components/TabeNavigation/TableNavigation';
import TaskSearch from './components/task-search';
import GsSpinner from '../../../components/spinner/gsSpinner';

const TaskInProgressPage = () => {
  const url = useLocation().pathname.split('/');
  const sectorId = url.at(url.length - 1) as string;
  const { theme, colorMode } = useChakra();
  const [tableData, setTableData] = useState<TaskInProgressDTO>([]);
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const fiveMinsRefresh = 5 * 60 * 1000;
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedOption, setSelectedOption] = useState('Order');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const [selectedSectors, setSelectedSectors] = useState<string[]>([]);

  const handleSelectedSectors = (sectors: string[]) => {
    setSelectedSectors(sectors);
  };

  const callGetTableData = async (
    orderCode: string,
    productCode: string,
    isChecked: boolean,
    sectorNames?: string[]
  ) => {
    setIsLoading(true);
    const response = await getTaskData(
      pageNumber,
      orderCode,
      productCode,
      isChecked,
      sectorNames
    );

    setTableData(response);
    setIsLoading(false);
  };

  const handleInitalAndRefreshCalls = async (updatedIsChecked: boolean) => {
    let searchValue = searchRef.current?.value;
    if (searchValue == '') callGetTableData('', '', updatedIsChecked, selectedSectors);
    else if (selectedOption == 'Order' && searchValue !== undefined)
      callGetTableData(searchValue, '', updatedIsChecked, selectedSectors);
    else if (selectedOption == 'Product' && searchValue !== undefined)
      callGetTableData('', searchValue, updatedIsChecked, selectedSectors);
  };

  useEffect(() => {
    if (pageNumber === 0) setTableDataChanged(!tableDataChanged);
    else setPageNumber(0);
  }, [sectorId]);

  useEffect(() => {
    handleInitalAndRefreshCalls(isChecked);

    const refreshInterval = setInterval(() => {
      console.log('refreshing');
      handleInitalAndRefreshCalls(isChecked);
    }, fiveMinsRefresh);

    return () => {
      setTableData([]);
      clearInterval(refreshInterval);
    };
  }, [tableDataChanged, pageNumber, selectedSectors]);

  const pageNumberHandler = (num: number) => {
    if (num === 1) setPageNumber((prevPageNumber) => prevPageNumber + 1);
    else if (num === -1)
      setPageNumber((prevPageNumber) =>
        prevPageNumber > 0 ? prevPageNumber - 1 : 0
      );
    else {
      if (pageNumber === 0) setTableDataChanged(!tableDataChanged);
      else setPageNumber(0);
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  
  return (
    <Flex flexDir='column' w='100%'>
      <Box
        flexDirection='row'
        width='full'
        bg={theme.colors.bg.colorMode(colorMode)}
        borderRadius='10'
        borderWidth={3}
        p='20'
      >
        <TaskSearch
          setSelectedOption={setSelectedOption}
          pageNumberHandler={pageNumberHandler}
          searchRef={searchRef}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          handleInitalAndRefreshCalls={handleInitalAndRefreshCalls}
          selectedOption={selectedOption}
          onSectorsSelected={handleSelectedSectors}
        ></TaskSearch>

        <Box>
          {isLoading ? (
            <GsSpinner></GsSpinner>
          ) : (
            <TaskTable
              data={tableData}
            />
          )}
        </Box>

        <Box marginTop={10}>
          <TableNavigation
            pageNumberHandler={pageNumberHandler}
            pageNumber={pageNumber}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default TaskInProgressPage;
