import { NavigateFunction } from 'react-router-dom';
import { SERVER_URL } from '../../api/config';

export type User = {
  userId: string;
  name?: string;
  role: string;
  sectorIds: string[];
};

type UserLogin = {
  username: string;
  password: string;
};

type Response = {
  user: User;
  token: string;
};

export const login = async (
  username: string,
  password: string,
  navigate: NavigateFunction,
  setUser: (user: User) => void
) => {
  try {
    const response = await fetch(SERVER_URL + '/authenticate', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      } as UserLogin),
    });

    if (response.ok) {
      const { user, token }: Response = await response.json();

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
      localStorage.setItem('token', JSON.parse(JSON.stringify(token)));

      if (user.role === 'ROLE_WORKER') navigate(`/worker/${user.sectorIds[0]}`);
      else navigate('/home');
    } else {
      alert('Pogrešna lozinka ili korisnicko ime');
    }
  } catch (error) {
    console.log('Error while loging in');
    console.error(error);
    alert('Proverite da li je server uključen.');
  }
};
