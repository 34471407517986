import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import {
  Flex,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Product } from '../../product-api';
import ProductTableRow from './ProductTableRow';

type ProductTableProps = {
  products: Product[];
  pageNumber: number;
  onChangePage: (pageNumber: number) => void;
  onEditProduct: (p: Product) => void;
  onDeleteProduct: (p: Product) => void;
  onCopyProduct: (p: Product) => void;
};

const ProductTable: FC<ProductTableProps> = ({
  products,
  pageNumber,
  onChangePage,
  onEditProduct,
  onDeleteProduct,
  onCopyProduct,
}) => {
  return (
    <TableContainer marginTop='5%' marginBottom='5%'>
      <Table>
        <Thead>
          <Tr borderBottom='1px'>
            <Th textAlign='center'>Šifra proizvoda</Th>
            <Th textAlign='center'>Naziv proizvoda</Th>
            <Th textAlign='center'>Merna jedinica</Th>
            <Th textAlign='center'>Izmeni</Th>
            <Th textAlign='center'>Obriši</Th>
            <Th textAlign='center'>Kopiraj</Th>
          </Tr>
        </Thead>
        <Tbody>
          {products?.map((p) => (
            <ProductTableRow
              key={p.id}
              product={p}
              onEditProduct={onEditProduct}
              onDeleteProduct={onDeleteProduct}
              onCopyProduct={onCopyProduct}
            />
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td colSpan={6}>
              <Flex alignItems='center' justifyContent='center'>
                <ArrowLeftIcon
                  marginRight={10}
                  onClick={() => onChangePage(-1)}
                />
                {pageNumber + 1}
                <ArrowRightIcon
                  marginLeft={10}
                  onClick={() => onChangePage(+1)}
                />
              </Flex>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;
