import { MouseEvent, useRef } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  addFalloutToResource,
  getResources,
  Item,
  Resource,
  ResourceTaskDTO,
} from '../../fallout-api';
import ResourceTable from './resource-table';

const FalloutModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  item: Item;
  renderTable: () => void;
}> = ({ isOpen, onClose, item, renderTable }) => {
  const [searchField, setSearchField] = useState('');
  const [addText, setAddText] = useState<
    Array<{ content: string; color?: string }>
  >([]);
  const [filteredResources, setFilteredResources] = useState<Resource[]>([]);
  const [selectedResource, setSelectedResource] = useState<Resource>();
  const [isInputValid, setIsInputValid] = useState(true);
  const [errMsg, setErrMsg] = useState('');
  const selectedFallout = useRef<HTMLInputElement>(null);

  const onSubmit = async () => {
    if (validateInput(item.fallout)) {
      const resourceTask: ResourceTaskDTO = {
        resource: selectedResource as Resource,
        taskId: item.taskId,
      };
      await addFalloutToResource(resourceTask);
      renderTable();
    }
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (searchField === '') setAddText([]);
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  };

  const onAdd = (resource: Resource) => {
    if (validateInput(item.fallout)) {
      const selectedAmount = parseInt(selectedFallout.current?.value as string);
      setAddText([
        {
          content: `Škart proizvoda ${item.prodCode} ${item.prodDesc}`,
        },
        {
          content: `Dodato u materijal ${resource.name}: ${selectedAmount}`,
          color: 'green',
        },
        { content: `Odbačeno: ${item.fallout - selectedAmount}`, color: 'red' },
      ]);
      resource.available += selectedAmount;
      setSelectedResource(resource);
    }
  };

  const validateInput = (fallout: number) => {
    const selectedAmount = parseInt(selectedFallout.current?.value as string);
    if (isNaN(selectedAmount)) {
      setErrMsg('Mora biti broj.');
      setIsInputValid(false);
      setSearchField('');
      return false;
    }
    if (selectedAmount > fallout) {
      setErrMsg(`Broj ne sme biti veći od ukupne količine (${fallout}).`);
      setIsInputValid(false);
      setSearchField('');
      return false;
    }
    return true;
  };

  useEffect(() => {
    const callGetResources = async () => {
      if (searchField !== '')
        setFilteredResources(await getResources(searchField));
    };
    callGetResources();

    return () => {
      setFilteredResources([]);
    };
  }, [searchField]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>Pronađite materijal</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!isInputValid} py="3">
            <Flex>
              <FormLabel marginRight="5">Količina:</FormLabel>
              <Input
                marginRight="10"
                ref={selectedFallout}
                placeholder="Količina za materijal"
                onFocus={() => setIsInputValid(true)}
              />
            </Flex>
            <FormErrorMessage>{errMsg}</FormErrorMessage>
          </FormControl>
          <FormControl py="3">
            <Flex>
              <FormLabel marginRight="9">Naziv:</FormLabel>
              <Input onChange={onSearchChange} placeholder="Materijal" />
              <IconButton
                aria-label="search resource"
                icon={<SearchIcon />}
                variant="outline"
                marginLeft="1"
                marginRight="0"
              />
            </Flex>
          </FormControl>
          <ResourceTable resources={filteredResources} onAddHanlder={onAdd} />
        </ModalBody>

        <ModalFooter>
          {searchField && (
            <Flex align="center" justify="space-between">
              <VStack spacing={1} align="start" marginRight="20">
                {addText.map((line, index) => (
                  <Text key={index} color={line.color} as="b">
                    {line.content}
                  </Text>
                ))}
              </VStack>
              <Button
                colorScheme="green"
                onClick={onSubmit}
                variant="outline"
                marginBottom="2px"
              >
                Sačuvaj
              </Button>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FalloutModal;
