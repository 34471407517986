import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Flex,
  ModalFooter,
  Button,
  Text,
  FormLabel,
  Select,
  FormHelperText,
  Input,
} from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import {
  getOrderItems,
  Order,
  OrderItem,
  updateOrderItem,
} from '../order-apis';

const EditOrderItemModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  orderItem?: OrderItem;
  orderItems: OrderItem[];
  setOrderItems: any;
  orderToEdit: Order;
}> = ({
  isOpen,
  onClose,
  orderItem,
  orderItems,
  setOrderItems,
  orderToEdit,
}) => {
  const priorityOrderItemRef = useRef<HTMLSelectElement>(null);
  const estimatedEndRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdateOrderItem = async () => {
    try {
      let estimatedEnd: string;
      let priority: string;

      if (estimatedEndRef != null && estimatedEndRef != undefined) {
        estimatedEnd = estimatedEndRef.current?.value as string;
      } else {
        estimatedEnd = orderItem?.estimatedEnd as string;
      }

      if (priorityOrderItemRef != null && priorityOrderItemRef != undefined) {
        priority = priorityOrderItemRef.current?.value as string;
      } else {
        priority = orderItem?.priority as string;
      }

      const success = await updateOrderItem(
        {
          id: orderItem?.id,
          products: orderItem?.products,
          amount: orderItem?.amount,
          estimatedEnd: estimatedEnd,
          priority: priority,
        } as OrderItem,
        setIsLoading
      );
      setIsLoading(false);
      if (success === true) {
        if (estimatedEndRef.current != null) {
          estimatedEndRef.current.value = '';
        }
        if (priorityOrderItemRef.current != null) {
          priorityOrderItemRef.current.value = '';
        }
        alert('Stavka porudzbine izmenjena uspesno');
        onClose();
        if (orderToEdit.id) {
          let orderItems = await getOrderItems(orderToEdit.id);
          setOrderItems(orderItems);
        } else console.log('Order id is null');
      }
    } catch (error) {
      console.error('Error updating orderItem: ', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>Izmena stavke porudžbenice</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={25}>
            <FormLabel>Prioritet</FormLabel>
            <Select
              placeholder='Prioritet'
              defaultValue={orderItem?.priority ? orderItem?.priority : ''}
              ref={priorityOrderItemRef}
            >
              <option key={1} value='LOW'>
                Nizak
              </option>
              <option key={2} value='MEDIUM'>
                Srednji
              </option>
              <option key={2} value='HIGH'>
                Visok
              </option>
            </Select>
          </FormControl>
          <FormControl mb={25}>
            <FormLabel>Datum isporuke</FormLabel>
            <Input
              placeholder=''
              type='date'
              ref={estimatedEndRef}
              defaultValue={
                orderItem?.estimatedEnd
                  ? orderItem?.estimatedEnd.split('T', 1)
                  : ''
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='gray'
            onClick={handleUpdateOrderItem}
            variant='solid'
            marginBottom='2px'
          >
            Izmeni
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditOrderItemModal;
