import { CheckIcon } from '@chakra-ui/icons';
import {
  Tr,
  Td,
  FormControl,
  Input,
  FormErrorMessage,
  Box,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import GsIconBtn from '../../../../../../components/button/GsIconBtn';
import {
  approveReservedResource,
  convertDateTime,
  RequestedMaterialForPhase,
} from '../../../warehouse-api';
import { FaShoppingCart } from 'react-icons/fa';
import OrderingResourceModal from '../../modals/OrderingResourceModal';

const MaterialByRequestTableRow: FC<{
  requestItem: RequestedMaterialForPhase;
  refreshTable: any;
}> = ({ requestItem, refreshTable }) => {
  const [isAmountInvalid, setIsAmountInvalid] = useState(false);

  let approvedAmountState = +(
    requestItem?.requestedAmount - requestItem.approvedAmount
  ).toFixed(2);

  // Adjust approvedAmount if requestedAmount is greater than resourcesAvailable
  if (approvedAmountState > requestItem.resourcesAvailable) {
    if (requestItem.resourcesAvailable <= 0) approvedAmountState = 0;
    else approvedAmountState = +requestItem.resourcesAvailable.toFixed(2);
  }
  const [approvedAmount, setApprovedAmount] =
    useState<number>(approvedAmountState);

  const {
    isOpen: isOpenOrderingResourceModal,
    onOpen: onOpenOrderingResourceModal,
    onClose: onCloseOrderingResourceModal,
  } = useDisclosure();

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = parseFloat(e.target.value);
    const updatedAmount = isNaN(newAmount) ? 0 : newAmount;
    setApprovedAmount(updatedAmount);
  };

  const handleApprove = async () => {
    if (approvedAmount < 0) {
      alert('Količina za odobravanje mora biti veća od 0');
      return;
    }

    if (approvedAmount > requestItem.requestedAmount) {
      alert('Količina za odobravanje ne može biti veća od zahtevane količine');
      return;
    }
    const difference = Number(
      requestItem.requestedAmount - requestItem.approvedAmount
    );
    let roundedDifference = parseFloat(difference.toFixed(2));
    if (approvedAmount > roundedDifference) {
      alert('Količina za odobravanje je veća od preostale neodobrene količine');
      return;
    }
    if (
      requestItem.requestedAmount > requestItem.resourcesAvailable &&
      approvedAmount > requestItem.resourcesAvailable
    ) {
      alert(
        'Ne možete odobriti količinu koja je veća od raspoloživosti resursa, poručite materijal i povećajte stanje'
      );
      return;
    }
    const success = await approveReservedResource(
      requestItem.id,
      approvedAmount
    );
    if (success) {
      let message = 'Uspesno odobren materijal: ' + requestItem.resourceName;
      alert(message);
      setApprovedAmount(
        +(
          requestItem.requestedAmount -
          requestItem.approvedAmount -
          approvedAmount
        ).toFixed(2)
      );
      refreshTable();
    }
  };

  const handleOrderMaterial = () => {
    onOpenOrderingResourceModal();
  };

  return (
    <Tr
      borderBottom='2px'
      style={{
        backgroundColor:
          requestItem.resourcesAvailable >= approvedAmount
            ? '#006400'
            : '#8B0000',
      }}
    >
      <Td textAlign='center' w='18%'>
        {requestItem.resourceName}
      </Td>
      <Td textAlign='center' w='5%' fontSize='xl'>
        <Box mt={2}>
          <GsIconBtn
            icon={<FaShoppingCart />}
            label='ordering'
            onClick={handleOrderMaterial}
          />
        </Box>
      </Td>
      <Td textAlign='center' w='14%'>
        {convertDateTime(requestItem.requestedAt)}
      </Td>
      <Td textAlign='center' w='14%'>
        {requestItem.nameUser}
      </Td>
      <Td textAlign='center' w='9%' fontSize='xl'>
        {requestItem.resourcesAvailable.toFixed(2)}
      </Td>
      <Td textAlign='center' w='9%' fontSize='xl'>
        {requestItem.approvedAmount !== null
          ? requestItem.approvedAmount.toFixed(2)
          : 0}
      </Td>
      <Td textAlign='center' w='9%' fontSize='xl' fontWeight='bold'>
        {requestItem.requestedAmount.toFixed(2)}
      </Td>
      <Td textAlign='center' w='9%' fontSize='xl'>
        {requestItem.usedAmount ? requestItem.usedAmount.toFixed(2) : 0}
      </Td>
      <Td textAlign='center' w='3%' fontSize='xl' fontWeight='bold'>
        {requestItem.measureUnit}
      </Td>
      <Td w='50px'>
        <FormControl isInvalid={isAmountInvalid}>
          <Input
            placeholder='Količina'
            textAlign={'center'}
            disabled={false}
            width='100%'
            minWidth={'100px'}
            type='number'
            value={approvedAmount}
            onChange={handleAmountChange}
          />
          <FormErrorMessage>{'Unesite ispravan broj'}</FormErrorMessage>
        </FormControl>
      </Td>
      <Td textAlign='center' w='4%'>
        <Flex flexDir='row'>
          <Box>
            <GsIconBtn
              icon={<CheckIcon />}
              label='approve'
              onClick={handleApprove}
            />
          </Box>
        </Flex>
      </Td>
      <OrderingResourceModal
        isOpen={isOpenOrderingResourceModal}
        onClose={onCloseOrderingResourceModal}
        requestItem={requestItem}
        refreshTable={refreshTable}
      />
    </Tr>
  );
};

export default MaterialByRequestTableRow;
