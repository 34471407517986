export interface Response {
  message: string;
}


const API = '/api';
const PRODUCT = '/product';
const SECTOR = '/sector';
const ORDER_ITEM = '/order-item';
const RESOURCE = '/resource';
const RESOURCES = '/resources';
const ORDER = '/order';
const NOTIFICATIONS = '/notifications/';

//product
export const PRODUCT_COPY = API + PRODUCT + '/copy';
export const GET_PREVIOUS_SECTORS = API + PRODUCT + '/phase/previous-sectors';

//custom task
export const GET_CUSTOM_TASKS = API + SECTOR + '/custom-tasks';
export const SAVE_CUSTOM_TASK = API + SECTOR + '/save/custom-task';
export const DELETE_CUSTOM_TASK = API + SECTOR + '/custom-task/delete';

//order-item
export const GET_ORDER_ITEMS = API + ORDER_ITEM + '/';
//resource
export const GET_PHASE_RESOURCE_REQUIRED =
  API + RESOURCE + '/phase-resource-required';
  export const GET_RESERVED_RESOURCE_FOR_TASK =
  API + RESOURCE + '/reserve/requested/task';
export const GET_RESOURCE_BY_NAME = API + RESOURCES + '/name'
//order
export const UPDATE_ORDER_STATUS = API + ORDER + '/updateStatus/';

//notifications
export const GET_NOTIFICATIONS = API + NOTIFICATIONS;
export const MARK_AS_SEEN_NOTIFICATION = API + NOTIFICATIONS + 'seen/';
export const MARK_ALL_AS_SEEN_NOTIFICATIONS = API + NOTIFICATIONS + 'seen-all/';
