import { Flex, Spinner } from '@chakra-ui/react';

export function GsSpinner() {
  return (
    <Flex
      flexDirection='row'
      marginTop='100px'
      align={'center'}
      justifyContent={'center'}
    >
      <Spinner
        alignSelf={'center'}
        size='xl'
      />
    </Flex>
  );
}

export default GsSpinner;
