import { GET, POST } from '../../../api/requests';
import { SERVER_URL } from '../../../api/config';

export type QualityUnit = {
  id?: number;
  timeAdded?: Date;
  qualityName: string;
};

export const getQualityUnits = async () => {
  try {
    let url = new URL(SERVER_URL + '/api/material-quality');

    const qualityUnits = await GET<QualityUnit[]>(url.toString());
    return qualityUnits;
  } catch (error) {
    console.log('Error while fetching quality units.');
    console.error(error);
    return [];
  }
};

export const createQualityUnit = async (
  qualityUnit: QualityUnit,
  setIsLoading: any
) => {
  try {
    setIsLoading(true);
    const savedQualityUnit = await POST<QualityUnit>(
      SERVER_URL + '/api/material-quality/save',
      qualityUnit
    );
    setIsLoading(false);
    return savedQualityUnit;
  } catch (error) {
    setIsLoading(false);
    console.log('Error while creating quality unit.');
    console.error(error);
    return false;
  }
};

export const getQualityUnitByUnitName = async (unitName: string) => {
  try {
    let url = new URL(SERVER_URL + '/api/material-quality/name');
    url.searchParams.append('unitName', unitName);

    const qualityUnit = await GET<QualityUnit>(url.toString());
    return qualityUnit;
  } catch (error) {
    console.log(`Error while fetching quality unit with name ${unitName}.`);
    console.error(error);
  }
};

export const createQualityUnitObject = (
  unitName: string | undefined
): QualityUnit => {
  return {
    id: 0,
    timeAdded: new Date(),
    qualityName: unitName ?? '',
  };
};
