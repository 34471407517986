import React, { useState } from 'react';
import { createMeasurementUnit, MeasurementUnit } from '../measurementUnit-api';
import { levenshteinDistance } from '../../../../functions/levenshteinDistance';
import AddUnitModal from './unit';
import { findSimilarUnits, validateNewUnit } from '../util';
import { createQualityUnit, QualityUnit } from '../qualityUnit-api';

export interface AddQualityUnitModalProps {
  onOpen: any;
  onClose: any;
  isOpen: any;
  setQualityUnits: React.Dispatch<React.SetStateAction<QualityUnit[]>>;
  qualityUnits: QualityUnit[];
}

const AddQualityUnitModal = ({
  onOpen,
  onClose,
  isOpen,
  setQualityUnits,
  qualityUnits,
}: AddQualityUnitModalProps) => {
  const [newUnit, setNewUnit] = useState('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  async function addNewUnit(newUnit: string): Promise<void> {
    const qualityUnit: QualityUnit = {
      qualityName: newUnit,
    };

    try {
      const addedUnit = await createQualityUnit(qualityUnit, setIsSaving);
      if (addedUnit) {
        alert('Kvalitet uspesno dodat.');
        setQualityUnits((prevUnits) => [...prevUnits, addedUnit]);
        setNewUnit('');
        onClose();
      } else {
        alert('Postoji kvalitet sa ovakvim imenom');
      }
    } catch (error) {
      console.error('Error adding quality unit:', error);
      alert('Došlo je do greške pri dodavanju kvaliteta.');
    }
  }

  // Function to handle adding the new quality unit
  const handleAddNewUnit = async () => {
    if (!validateNewUnit(newUnit)) return;

    const unitNames = qualityUnits.map((unit) => unit.qualityName);

    if (!findSimilarUnits(newUnit, unitNames)) return;

    await addNewUnit(newUnit);
  };

  return (
    <AddUnitModal
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
      newUnit={newUnit}
      setUnit={setNewUnit}
      handleAddNewUnit={handleAddNewUnit}
      header='Dodajte novi kvalitet'
      label='Novi Kvalitet'
      isSaving={isSaving}
    ></AddUnitModal>
  );
};

export default AddQualityUnitModal;
