import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Th,
  Tbody,
  Tr,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { TableNavigation } from '../../../../../../components/TabeNavigation/TableNavigation';
import { tableCaptionGenerator } from '../../../../../../utilility/util';
import {
  getMaterialRequests,
  RequestedMaterialByPhase,
} from '../../../warehouse-api';
import MaterialRequestTableRow from './MaterialRequestsTableRow';

const MaterialRequestTable: FC<{
  pageNumber: number;
  setPageNumber: any;
  requestedMaterials: RequestedMaterialByPhase[];
  setRequestedMaterials: any;
  refreshRequestTable: any;
  requestType: string;
}> = ({
  pageNumber,
  setPageNumber,
  requestedMaterials,
  setRequestedMaterials,
  refreshRequestTable,
  requestType,
}) => {
  const [isActive, setIsActive] = useState<string>(
    requestType === 'history_requests' ? 'false' : 'true'
  );
  const [isReturned, setIsReturned] = useState<string>(
    requestType === 'unused_request' ? 'true' : 'false'
  );
  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;
    getMaterialRequests(
      isActive,
      isReturned,
      newPageNumber,
      setRequestedMaterials
    );
    setPageNumber(newPageNumber);
  };
  let tableCaption = tableCaptionGenerator(
    requestedMaterials,
    'Ne postoje zahtevi',
    'Zahtevi'
  );
  return (
    <TableContainer marginTop={5} marginBottom={5} width='100%'>
      <Table variant='simple'>
        <TableCaption>{tableCaption}</TableCaption>
        <Thead>
          <Tr>
            <Th>Vreme slanja</Th>
            <Th>Šifra porudžbenice</Th>
            <Th>Šifra proizvoda</Th>
            <Th>Sektor</Th>
            <Th>Redni broj faze</Th>
            <Th>Datum isporuke</Th>
          </Tr>
        </Thead>
        <Tbody>
          {requestedMaterials.map((r, index) => (
            <MaterialRequestTableRow
              key={index}
              request={r}
              refreshRequestTable={refreshRequestTable}
              requestType={requestType}
            />
          ))}
        </Tbody>
      </Table>
      <TableNavigation
        pageNumber={pageNumber}
        pageNumberHandler={pageNumberHandler}
      />
    </TableContainer>
  );
};

export default MaterialRequestTable;
