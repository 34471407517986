import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
} from '@chakra-ui/react';
import { FC } from 'react';
import { ResourceReservation } from '../../../worker-api';
import ResourceRequiredTableRow from './ResourceRequiredTableRow';

const ResourceRequiredTable: FC<{
  reservationResources: ResourceReservation[];
  setReservationResources: any;
  onUpdateRequestedAmount: (index: number, newAmount: number) => void;
}> = ({
  reservationResources,
  setReservationResources,
  onUpdateRequestedAmount,
}) => {
  let tableCaption;
  if (reservationResources.length > 0) {
    tableCaption = 'Potrebni materijali';
  } else {
    tableCaption =
      'Ne postoje potrebni materijali, možete dodati materijal i poslati zahtev za zaduživanje dodatnih materijala';
  }
  return (
    <TableContainer marginTop='1%' marginBottom='0%'>
      <Table>
        <TableCaption fontSize='l' color={'white'}>
          {tableCaption}{' '}
        </TableCaption>
        <Thead>
          <Tr borderBottom='1px solid'>
            <Th textAlign='center'>Naziv materijala</Th>
            <Th textAlign='center'>Merna jedinica</Th>
            <Th textAlign='center'>Potrebna količina</Th>
          </Tr>
        </Thead>
        <Tbody>
          {reservationResources.map((reservationResources, index) => (
            <ResourceRequiredTableRow
              reservationResource={reservationResources}
              key={reservationResources.id}
              index={index}
              onUpdateRequestedAmount={onUpdateRequestedAmount}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ResourceRequiredTable;
