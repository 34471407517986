import { Flex, Heading, useChakra } from '@chakra-ui/react';
import { CustomHeading } from '../../../components/heading/custom-heading';
import AppLayout from '../../../components/layout/app-layout';
import { TxtClr } from '../../../components/theme/custom-theme';
import FalloutTable from './table/fallout-table';

const FalloutPage = () => {
  const { theme, colorMode } = useChakra();

  return (
    <AppLayout>
      <Flex flexDir='column' w='100%'>
        <Flex
          flexDirection='column'
          w='100%'
          h='100%'
          mb={1}
          p='20'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
        >
          <CustomHeading text='Pregled škarta' />
          <FalloutTable />
        </Flex>
      </Flex>
    </AppLayout>
  );
};

export default FalloutPage;
