import {
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { getWorkerData, TableDataDTO } from '../worker-api';
import { exportToExcel } from '../../../functions/export';
import { User } from '../../login/login-api';

export interface WorkerSearchProps {
  setSelectedOption: any;
  searchRef: any;
  pageNumberHandler: (num: number) => void;
  isChecked: boolean;
  setIsChecked: any;
  handleInitalAndRefreshCalls: any;
  sectorId: string;
  selectedOption: string;
}

const WorkerSearch = ({
  setSelectedOption,
  pageNumberHandler,
  searchRef,
  isChecked,
  setIsChecked,
  handleInitalAndRefreshCalls,
  sectorId,
  selectedOption,
}: WorkerSearchProps) => {
  const handleRadioChange = (value: string) => {
    console.log(value);
    setSelectedOption(value);
  };

  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const [tableDataExport, setTableDataExport] = useState<
    TableDataDTO['workerPageDTO']
  >([]);
  const [sectorNameExport, setSectorNameExport] = useState('');
  const currentUser: User = JSON.parse(localStorage.getItem('user') as string);

  const handleButtonClick = () => {
    console.log(isChecked);
    setIsChecked((prevIsChecked: any) => {
      console.log(prevIsChecked); // Logs the current value
      const updatedIsChecked = !prevIsChecked;
      console.log(updatedIsChecked); // Logs the updated value
      handleInitalAndRefreshCalls(updatedIsChecked);
      return updatedIsChecked;
    });
  };

  const callGetTableDataAndExport = async (
    orderCode: string,
    productCode: string,
    instructions: string,
    isChecked: boolean
  ) => {
    try {
      const response = await getWorkerData(
        Number(sectorId),
        0,
        orderCode,
        productCode,
        currentUser.userId,
        instructions,
        isChecked,
        'true'
      );

      console.log(response);
      console.log(response.sectorName);
      console.log(response.workerPageDTO);

      const headers = [
        'Šifra porudžbine',
        'Šifra proizvoda',
        'Datum isporuke',
        'Instrukcije',
        'Količina',
        'Prioritet',
        'Crtež',
      ];

      const data = response.workerPageDTO.map((item) => [
        item.orderCode,
        item.productCode,
        item.estimatedItemEnd
          ? new Date(item.estimatedItemEnd)
              .toLocaleString('en-GB', {
                year: 'numeric',
                day: '2-digit',
                month: '2-digit',
              })
              .replace(/\//g, '-')
          : new Date(item.estimatedOrderEnd)
              .toLocaleString('en-GB', {
                year: 'numeric',
                day: '2-digit',
                month: '2-digit',
              })
              .replace(/\//g, '-'),
        item.phaseDescription,
        item.expectedAmount,
        item.priority,
        item.hasFile ? 'Da' : 'Ne',
      ]);

      const reportName = `${response.sectorName}_zadaci`;
      exportToExcel(headers, data, reportName);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const handleExportButtonClick = async () => {
    let searchValue = searchRef.current?.value;
    if (searchValue == '') callGetTableDataAndExport('', '', '', isChecked);
    else if (selectedOption == 'Order' && searchValue !== undefined)
      callGetTableDataAndExport(searchValue, '', '', isChecked);
    else if (selectedOption == 'Product' && searchValue !== undefined)
      callGetTableDataAndExport('', searchValue, '', isChecked);
    else if (selectedOption == 'Instructions' && searchValue !== undefined)
      callGetTableDataAndExport('', '', searchValue, isChecked);
  };

  return (
    <Flex flexDirection='row'>
      <InputGroup flexDirection='column' marginBottom='30px' w='60%'>
        <InputLeftElement
          pointerEvents='none'
          children={
            <Icon
              as={FaSearch}
              color='gray.500'
              fontSize='20px'
              marginTop='10px'
            />
          }
        />
        <Input
          width='460px'
          placeholder='Pretraži'
          style={{ height: '50px' }}
          ref={searchRef}
          onChange={() => pageNumberHandler(0)}
        />
        <Flex flexDirection='column' alignItems='flex-start'>
          <RadioGroup defaultValue='Order' onChange={handleRadioChange}>
            <Stack spacing={5} direction='row' mt={4}>
              <Radio colorScheme='green' value='Order' size='lg'>
                Porudzbenica
              </Radio>
              <Radio colorScheme='green' value='Product' size='lg'>
                Proizvod
              </Radio>
              <Radio colorScheme='green' value='Instructions' size='lg'>
                Instrukcije
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>
      </InputGroup>
      <Flex flexDirection='row' w='40%' marginTop='10px' marginRight={5}>
        <Button
          w='45%'
          ml={5}
          textAlign='center'
          _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
          variant='outline'
          onClick={handleButtonClick}
        >
          {isChecked ? 'Sortiraj po prioritetu' : 'Sortiraj po datumu'}
        </Button>
        <Button
          w='45%'
          ml={5}
          textAlign='center'
          _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
          variant='outline'
          onClick={handleExportButtonClick}
        >
          Exportuj zadatke
        </Button>
      </Flex>
    </Flex>
  );
};

export default WorkerSearch;
