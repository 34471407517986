import { levenshteinDistance } from '../../../functions/levenshteinDistance';
import { MeasurementUnit } from './measurementUnit-api';

export function validateNewUnit(newUnit: string): boolean {
  if (newUnit.trim() === '') {
    alert('Naziv jedinice ne sme biti prazan.');
    return false;
  } else if (newUnit.length > 20) {
    alert('Naziv jedinice ne sme imati više od 20 karaktera.');
    return false;
  }
  return true;
}

export function findSimilarUnits(
  newUnit: string,
  presentUnits: string[]
): boolean {
  const similarUnits = levenshteinDistance(newUnit, presentUnits, 1);

  if (similarUnits.length > 0) {
    const confirmAdd = window.confirm(
      'Pronađene su slične jedinice: \n' +
        similarUnits.join(', ') +
        ' .\nDa li želite da nastavite?'
    );
    if (!confirmAdd) return false;
  }
  return true;
}
