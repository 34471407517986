import React, { useRef, useState } from 'react';
import {
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  ModalFooter,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  getCustomNoteReport,
  NoteReport,
  NoteReportModalProps,
} from '../../report-api';
import GsSpinner from '../../../../../components/spinner/gsSpinner';
import NoteReportTableModal from './NoteReportTableModal';

function NoteReportModal({ onClose, setIsModalOpen }: NoteReportModalProps) {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [modalTableVisible, setModalTableVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [customNoteReports, setCustomNoteReports] = useState<NoteReport[]>([]);

  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  const [reportName, setReportName] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [productPageNo, setProductPageNo] = useState<number>(0);

  const handleButtonClick = async () => {
    if (startDate === null || endDate === null) {
      console.log('Undefined');
      return;
    }

    if (reportName === '') {
      alert('Unesite naziv izvestaja');
      return;
    }

    if (startDate === '') {
      alert('Unesite pocetni datum');
      return;
    }

    if (endDate === '') {
      alert('Unesite krajnji datum');
      return;
    }

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (endDateObj < startDateObj) {
      alert('Pocetni datum ne moze biti posle krajnjeg datuma');
      return;
    }

    const data = await getCustomNoteReport(
      startDate,
      endDate,
      setCustomNoteReports,
      setIsLoading,
      productPageNo
    );

    setIsLoading(false);
    if (
      data === null ||
      (data !== false && Array.isArray(data) && data.length === 0)
    ) {
      alert('Ne postoje podaci za traženi izveštaj');
      return;
    } else {
      setModalTableVisible(true);
    }
  };

  return (
    <>
      <ModalBody>
        <FormControl>
          <FormLabel>Naziv izveštaja</FormLabel>
          <Input
            type='text'
            value={reportName}
            onChange={(e) => setReportName(e.target.value)}
          />
          <FormHelperText fontStyle='italic'>
            Unesite naziv izveštaja za napomene.
          </FormHelperText>
        </FormControl>
        <FormControl mt={7}>
          <FormLabel>Početni datum</FormLabel>
          <Input
            placeholder=''
            type='date'
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <FormHelperText fontStyle='italic'>
            Izaberite početni datum za izveštaj
          </FormHelperText>
        </FormControl>
        <FormControl mt={7}>
          <FormLabel>Krajnji datum</FormLabel>
          <Input
            placeholder=''
            type='date'
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <FormHelperText fontStyle='italic'>
            Izaberite krajni datum za izveštaj
          </FormHelperText>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        {isLoading ? (
          <GsSpinner></GsSpinner>
        ) : (
          <Button
            _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
            variant='outline'
            ml={5}
            onClick={handleButtonClick}
            w='40%'
          >
            Kreiraj
          </Button>
        )}
        <NoteReportTableModal
          isModalOpen={modalTableVisible}
          setIsModalOpen={setModalTableVisible}
          reportName={reportName}
          onClose={undefined}
          customNoteReports={customNoteReports}
          setCustomNoteReports={setCustomNoteReports}
          getCustomNoteReport={getCustomNoteReport}
          pageNo={productPageNo}
          setProductPageNo={setProductPageNo}
          startDate={startDate}
          endDate={endDate}
          setIsLoading={setIsLoading}
        />
      </ModalFooter>
    </>
  );
}

export default NoteReportModal;
