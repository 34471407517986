import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';

const OrderPriority: FC<{ priority: string }> = ({ priority }) => {
  let priorityNumber;
  let priorityColor;
  if (priority === '1') {
    priorityNumber = 1;
    priorityColor = 'red.700';
  } else if (priority === '3') {
    priorityNumber = 3;
    priorityColor = 'green.700';
  } else {
    priorityNumber = 2;
    priorityColor = 'yellow.700';
  }

  return (
    <Box
      display='inline-flex'
      alignItems='center'
      justifyContent='center'
      borderRadius='full'
      width='50px'
      height='50px'
      backgroundColor={priorityColor}
      color='white'
    >
      <Text fontSize='s' fontWeight='bold'>
        {priorityNumber}
      </Text>
    </Box>
  );
};

export default OrderPriority;
