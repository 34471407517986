import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Textarea,
} from '@chakra-ui/react';
import { FC } from 'react';
import GsHeaderModal from '../../../../components/heading/GsHeaderModal';

const InstructionsModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  instructions: string;
}> = ({ isOpen, onClose, instructions }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='30%'>
        <GsHeaderModal title='Instrukcije' />
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Textarea disabled={true} color='white' fontSize='xl'>
            {instructions}
          </Textarea>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InstructionsModal;
