import { useDisclosure } from '@chakra-ui/react';
import React, { useCallback, useContext, useState } from 'react';
import GSAlertDialog from '../components/alert/GsAlertDialog';
import GsConfirmDialog from '../components/alert/GsConfirmDialog';

export const ALERT_GREEN = 'green.400';
export const ALERT_RED = 'red.400';

const GsDialogContext = React.createContext({
  confirmDialog: (header: string, msg: string, handler: () => void) => {},
  alertDialog: (header: string, msg: string, color?: string) => {},
});

type GsDialogContextProps = {
  children?: React.ReactNode;
};

export const GsDialogContextProvider = ({ children }: GsDialogContextProps) => {
  const confirmDisclosure = useDisclosure();
  const alertDisclosure = useDisclosure();
  const [confirmHeader, setConfirmHeader] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');
  const [alertHeader, setAlertHeader] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [handler, setHandler] = useState<() => void>(() => {});
  const [alertClr, setAlertClr] = useState<string>();

  const confirmDialog = useCallback(
    (header: string, msg: string, handler: () => void) => {
      setConfirmHeader(header);
      setConfirmMsg(msg);
      setHandler(() => handler);
      confirmDisclosure.onOpen();
    },
    [confirmDisclosure]
  );

  const alertDialog = useCallback(
    (header: string, msg: string, color?: string) => {
      setAlertHeader(header);
      setAlertMsg(msg);
      setAlertClr(color);
      alertDisclosure.onOpen();
    },
    [alertDisclosure]
  );

  return (
    <>
      <GsDialogContext.Provider value={{ confirmDialog, alertDialog }}>
        {children}
      </GsDialogContext.Provider>
      <GsConfirmDialog
        header={confirmHeader}
        text={confirmMsg}
        isOpen={confirmDisclosure.isOpen}
        onClose={confirmDisclosure.onClose}
        onCLick={handler}
      />
      <GSAlertDialog
        header={alertHeader}
        text={alertMsg}
        color={alertClr}
        isOpen={alertDisclosure.isOpen}
        onClose={alertDisclosure.onClose}
      />
    </>
  );
};

export const useGsDialog = () => useContext(GsDialogContext);
