import React, { useState } from 'react';
import {
  ModalBody,
  Button,
  ModalFooter,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  useColorModeValue,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
} from '@chakra-ui/react';
import {
  ConsumedMaterialReport,
  ConsumedMaterialReportTableModalProps,
  CustomConsumedMaterialReportTableRowProps,
} from '../../report-api';
import { exportToExcel } from '../../../../../functions/export';
import { TableNavigation } from '../../../../../components/TabeNavigation/TableNavigation';

function ConsumedMaterialReportTableModal({
  isModalOpen,
  setIsModalOpen,
  customConsumedMaterialReports,
  setCustomConsumedMaterialReports,
  getCustomConsumedMaterialReport,
  order_code,
}: ConsumedMaterialReportTableModalProps) {
  const closeModal = () => {
    setIsModalOpen(false);
    setPageNumber(0);
  };

  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  const tableCaptionGenerator = (customNoteReports: any) => {
    if (customNoteReports === 0) {
      return 'Ne postoji nijedna stavka izvestaja';
    } else {
      return '';
    }
  };

  const handleExportButtonClick = async () => {
    try {
      const exportData = await getCustomConsumedMaterialReport(
        order_code,
        setCustomConsumedMaterialReports,
        0,
        'true'
      );
      const headers = [
        'Šifra resursa',
        'Naziv resursa',
        'Ukupna količina',
        'Merna jedinica',
      ];
      const data = exportData.map((item: ConsumedMaterialReport) => [
        item.resource_code,
        item.resource_name,
        item.total_amount.toFixed(2),
        item.measurable_unit,
      ]);
      const reportName = order_code + '-materijal';
      exportToExcel(headers, data, reportName);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const [pageNumber, setPageNumber] = useState<number>(0);

  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    getCustomConsumedMaterialReport(
      order_code,
      setCustomConsumedMaterialReports,
      newPageNumber
    );
    setPageNumber(newPageNumber);
  };

  const tableCaption = tableCaptionGenerator(customConsumedMaterialReports);

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent minWidth='1200px'>
        <ModalHeader
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '20px',
            marginRight: '40px',
          }}
        >
          <div>{order_code}</div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer marginTop='10px' maxWidth='1200px'>
            <Table variant='simple'>
              <TableCaption> {tableCaption} </TableCaption>
              <Thead>
                <Tr>
                  <Th>Šifra resursa</Th>
                  <Th>Naziv resursa</Th>
                  <Th>Ukupna količina</Th>
                  <Th>Merna jedinica</Th>
                </Tr>
              </Thead>
              <Tbody>
                {customConsumedMaterialReports?.map((c) => (
                  <CustomConsumedMaterialReportTableRow
                    customConsumedMaterial={c}
                  />
                ))}
              </Tbody>
            </Table>
            <TableNavigation
              pageNumber={pageNumber}
              pageNumberHandler={pageNumberHandler}
            />
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button
            _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
            variant='outline'
            w='15%'
            onClick={handleExportButtonClick}
          >
            {' '}
            Exportuj
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function CustomConsumedMaterialReportTableRow({
  customConsumedMaterial,
}: CustomConsumedMaterialReportTableRowProps) {
  return (
    <Tr>
      <Td>{customConsumedMaterial?.resource_code}</Td>
      <Td>{customConsumedMaterial?.resource_name}</Td>
      <Td>{customConsumedMaterial?.total_amount.toFixed(2)}</Td>
      <Td>{customConsumedMaterial?.measurable_unit}</Td>
    </Tr>
  );
}

export default ConsumedMaterialReportTableModal;
