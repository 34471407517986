import { EditIcon, DeleteIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Tr,
  Td,
  IconButton,
  useStyleConfig,
  FormLabel,
} from '@chakra-ui/react';
import { useState } from 'react';
import {
  COLOR_ACTIVE_ORDER,
  COLOR_DEACTIVE_ORDER,
} from '../../../../utilility/colorConstatns';
import { Resource } from '../../resource/resource-api';
import MissingResourcesModal from '../modal/missingResourcesModal';
import {
  OrderTableProps,
  convertDate,
  deleteOrder,
  getOrders,
  getOrderItems,
  convertPriority,
  convertOrderStatus,
  updateOrderStatus,
} from '../order-apis';

function OrderTableRow({
  order,
  setOrder,
  setOrders,
  setOrderToEdit,
  setOrderItems,
  onOpen,
  setOrdersLoading: setIsLoading,
}: OrderTableProps) {
  let active: string = order?.active ? 'Aktivan' : 'Neaktivan';
  const textColor = order?.active ? COLOR_ACTIVE_ORDER : COLOR_DEACTIVE_ORDER;

  const [isMissingResourceModalOpen, setIsMissingResourceModalOpen] =
    useState(false);
  const [missingResourcesState, setMissingResoucesState] = useState<Resource[]>(
    []
  );

  return (
    <Tr>
      <Td w='16%'>{order?.orderCode}</Td>
      <Td w='12%'>{order?.companyName}</Td>
      <Td w='10%'>{convertDate(order?.estimatedStart)}</Td>
      <Td w='10%'>{convertDate(order?.realEnd)}</Td>
      <Td w='10%'> {convertPriority(order?.priority)}</Td>
      <Td w='10%'>
        <FormLabel color={textColor}>{active}</FormLabel>
      </Td>
      <Td w='8%'>
        {order?.active ? (
          <IconButton
            aria-label='Dectivate Order'
            alignItems='center'
            borderRadius='30%'
            icon={<CloseIcon />}
            disabled={order?.status === 'COMPLETE' || order?.status === 'MANUALLY COMPLETE'}
            onClick={async (e) => {
              const confirmationMessage = `Da li ste sigurni da želite da deaktivirate porudžbinu "${order?.orderCode}"?`;
              if (window.confirm(confirmationMessage)) {
                if (order === undefined) return;
                const missingResources = await updateOrderStatus(
                  order,
                  'deactivate'
                );
                if (missingResources !== undefined) {
                  const message = `Porudžbina "${order?.orderCode}" je uspešno deaktivirana, radnici neće moći da je vide`;
                  alert(message);
                  window.location.reload();
                }
              }
            }}
          />
        ) : (
          <IconButton
            aria-label='Activate Order'
            alignItems='center'
            borderRadius='30%'
            icon={<CheckIcon />}
            disabled={order?.status === 'COMPLETE' || order?.status === 'MANUALLY COMPLETE'}
            onClick={async (e) => {
              const confirmationMessage = `Da li ste sigurni da želite da aktivirate porudžbinu "${order?.orderCode}"?`;
              if (window.confirm(confirmationMessage)) {
                if (order === undefined) return;
                const missingResources: Resource[] | undefined =
                  await updateOrderStatus(order, 'activate');
                if (missingResources !== undefined) {
                  const message = `Porudžbina "${order?.orderCode}" je uspešno puštena u proizvodnju`;
                  alert(message);
                  if (missingResources?.length > 0) {
                    setIsMissingResourceModalOpen(true);
                    setMissingResoucesState(missingResources);
                  } else {
                    window.location.reload();
                  }
                }
              }
            }}
          />
        )}
      </Td>
      <Td w='8%'>
        <IconButton
          aria-label='theme'
          alignItems='center'
          icon={<EditIcon />}
          onClick={async (e) => {
            if (order !== undefined) {
              onOpen();
              setOrderToEdit(order);
              setOrder(true);

              if (order.id) {
                let orderItems = await getOrderItems(order.id);
                setOrderItems(orderItems);
              } else console.log('Order id is null');
            }
          }}
        />
      </Td>
      <Td w='8%'>
        <IconButton
          aria-label='theme'
          alignItems='center'
          icon={<DeleteIcon />}
          onClick={async (e) => {
            if (
              window.confirm(
                'Da li ste sigurni da želite da obrišete porudžbenicu?'
              )
            ) {
              if (order === undefined) return;
              const sucess = await deleteOrder(order, setOrders);

              if (sucess === true) {
                alert('Porudžbenica je obrisana');
                getOrders(setOrders, 0, setIsLoading);
              }
            }
          }}
        />
      </Td>
      <MissingResourcesModal
        isOpen={isMissingResourceModalOpen}
        onClose={() => {
          setIsMissingResourceModalOpen(false);
          window.location.reload();
        }}
        resources={missingResourcesState}
        orderCode={order?.orderCode as string}
      ></MissingResourcesModal>
      <Td w='12%'>{convertOrderStatus(order?.status)}</Td>
    </Tr>
  );
}

export default OrderTableRow;
