import { GET, POST } from '../../../api/requests';
import { getPaginationParams } from '../../../components/TabeNavigation/table-navigation';
import { SERVER_URL } from '../../../api/config';

export type Item = {
  orderCode: string;
  itemCode: string;
  itemDesc: string;
  prodId: number;
  prodCode: string;
  prodDesc: string;
  phaseDesc: string;
  sectorName: string;
  fallout: number;
  taskId: number;
};

export type Resource = {
  id: number;
  name: string;
  available: number;
};

export type ResourceTaskDTO = {
  resource: Resource;
  taskId: number;
};

export type WorkerNoteDTO = {
  note: string;
};

export const getFalloutNoti = async () => {
  return await GET<boolean>(SERVER_URL + '/api/task/fallout/exists');
};

export const getFallouts = async (setState: any, pageNo: number) => {
  let url = new URL(SERVER_URL + '/api/task/fallouts');
  let params = new URLSearchParams();
  params = getPaginationParams(params, pageNo);
  url.search = params.toString();

  const data = await GET<Item[]>(url.toString());
  setState(data);
};

export const getResources = async (filter: string) => {
  return await GET<Resource[]>(SERVER_URL + '/api/resources/' + filter);
};

export const addFalloutToResource = async (
  resourceTaskDTO: ResourceTaskDTO
) => {
  return await POST<Resource>(
    SERVER_URL + '/api/resource/fallout/add',
    resourceTaskDTO
  );
};

export const getWorkerNote = async (taskId: number) => {
  return await GET<WorkerNoteDTO>(SERVER_URL + '/api/task/note/' + taskId);
};
