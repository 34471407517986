import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Flex, FormLabel, Tooltip, useDisclosure } from '@chakra-ui/react';
import { memo, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import GsIconBtn from '../../../../../components/button/GsIconBtn';
import { NodeContext } from '../../contexts/NodeContext';
import { ReadOnlyContext } from '../../contexts/ReadOnlyContext';
import ProductPhaseModal from './phase/ProductPhaseModal';

export default memo(({ id, data, isConnectable }: any) => {
  const {
    isOpen: isOpenPhase,
    onOpen: onOpenPhase,
    onClose: onClosePhase,
  } = useDisclosure();

  const { handleOnDeleteNode } = useContext(NodeContext);
  const { isReadOnly } = useContext(ReadOnlyContext);

  return (
    <Box
      bg='whiteAlpha.600'
      color='black'
      fontFamily='Source Sans Pro, sans-serif'
      borderRadius='10px'
      padding={2}
      shadow='dark-lg'
    >
      <Handle
        type='target'
        position={Position.Bottom}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type='source'
        position={Position.Top}
        id='a'
        isConnectable={isConnectable}
      />
      <Flex direction='column'>
        <FormLabel textAlign='center'>
          <Box
            as='span'
            fontWeight='bold'
            fontFamily='Source Sans Pro, sans-serif'
          >
            {' '}
            {data.sector}
          </Box>
        </FormLabel>
        <Flex gap='5px'>
          <GsIconBtn
            label='edit phase'
            icon={<EditIcon />}
            onClick={onOpenPhase}
          />
          <GsIconBtn
            label='delete phase'
            icon={<DeleteIcon />}
            onClick={() => handleOnDeleteNode(id)}
            disabled={isReadOnly}
          />
        </Flex>
      </Flex>

      <ProductPhaseModal
        isOpen={isOpenPhase}
        onClose={onClosePhase}
        nodeId={id}
      />
    </Box>
  );
});
