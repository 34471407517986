import {
  GET_NOTIFICATIONS,
  MARK_ALL_AS_SEEN_NOTIFICATIONS,
  MARK_AS_SEEN_NOTIFICATION,
  Response,
} from '../../../api/apiConstants';
import { SERVER_URL } from '../../../api/config';
import { GET } from '../../../api/requests';
import { getPaginationParams } from '../../../components/TabeNavigation/table-navigation';
import { User } from '../user/user-api';
import i18n from '../../../internationalization/i18n';

export type Notification = {
  id: number;
  message: string;
  sendAt: string;
};

export const getNotifications = async (topic: string, pageNo: number) => {
  let url = new URL(SERVER_URL + GET_NOTIFICATIONS + topic);
  let params = new URLSearchParams();
  params = getPaginationParams(params, pageNo);
  url.search = params.toString();

  return await GET<Notification[]>(url.toString());
};

export const markAsSeenNotification = async (notificationId: number) => {
  return await GET<Response>(
    SERVER_URL + MARK_AS_SEEN_NOTIFICATION + notificationId
  );
};

export const markAllAsSeenNotifications = async (topic: string) => {
  return await GET<Response>(
    SERVER_URL + MARK_ALL_AS_SEEN_NOTIFICATIONS + topic
  );
};

export const getTopic = () => {
  const userFromStorage: User = JSON.parse(
    localStorage.getItem('user') as string
  );
  return userFromStorage.username;
};

export const handleNotificationMessage = (notification: any) => {
  let finalMessage = '';
  if (notification.type === 'finishedTask') {
    finalMessage = i18n.t('finishedTask', {
      amount: notification.amount,
      sector: notification.sector,
      order: notification.order,
      product: notification.product,
    });
  } else if (notification.type === 'approvedResource') {
    finalMessage = i18n.t('approvedResource', {
      resourceName: notification.resourceName,
      amount: notification.amount,
    });
  } else if (notification.type === 'finishedOrderItem') {
    finalMessage = i18n.t('finishedOrderItem', {
      order: notification.order,
      product: notification.product,
    });
  } else if (notification.type === 'finishedOrder') {
    finalMessage = i18n.t('finishedOrder', {
      order: notification.order,
    });
  } else if (notification.type === 'simpleNotification') {
    finalMessage = i18n.t(notification.message);
  }

  return finalMessage;
};
