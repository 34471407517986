import { ModalHeader } from '@chakra-ui/react';
import { FC } from 'react';

const GsHeaderModal: FC<{ title: string }> = ({ title }) => {
  return (
    <ModalHeader
      mb='6'
      fontFamily='Source Sans Pro, sans-serif'
      fontWeight='bold'
      fontSize='24px'
      lineHeight='32px'
      margin='0 0 24px'
    >
      {title}
    </ModalHeader>
  );
};

export default GsHeaderModal;
