import {
  useColorModeValue,
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
  Flex,
} from '@chakra-ui/react';
import { useState, useRef, FC, useEffect } from 'react';
import { createSector, Sector } from '../../sector-apis';
import GsSpinner from '../../../../../components/spinner/gsSpinner';
import { checkTwoSpaces } from '../../../../../utilility/util';

const SectorForm: FC<{
  selectedSector?: Sector;
  onSubmit?: () => Promise<void>;
}> = ({ selectedSector, onSubmit }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const saveBtnColor = useColorModeValue('#EBF5FB', '#2D3748');
  const sectorNameRef = useRef<HTMLInputElement>(null);
  const sectorMinWorkTimeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedSector) {
      sectorNameRef.current!.value = selectedSector.name as string;
      sectorMinWorkTimeRef.current!.valueAsNumber =
        selectedSector.minWorkTime as number;
    }
  }, []);

  const validateInputsAndSaveSector = async () => {
    if (sectorNameRef === null || sectorNameRef.current?.value === '') {
      alert('Unesite ime');
      return;
    }

    if (
      sectorNameRef &&
      sectorNameRef.current?.value &&
      checkTwoSpaces(sectorNameRef.current?.value)
    ) {
      alert(
        'Nisu dozvoljena dva uzastopna znaka razmaka kod naziva materijala'
      );
      return;
    }

    const sectorMinWorkTime = parseFloat(
      sectorMinWorkTimeRef.current?.value as string
    );

    if (isNaN(sectorMinWorkTime)) {
      alert('Minimalno vreme radnika nije validno ili ne postoji');
      return;
    }

    if (sectorMinWorkTime < 0) {
      alert('Minimalno vreme rada ne moze biti manje od 0');
      return;
    }

    const sector: Sector = {
      name: sectorNameRef.current!.value,
      minWorkTime: sectorMinWorkTimeRef.current!.valueAsNumber,
      id: selectedSector?.id,
    };
    setIsLoading(true);
    const success = await createSector(sector);
    setIsLoading(false);

    if (success) {
      sectorNameRef.current!.value = '';
      sectorMinWorkTimeRef.current!.value = '';
      alert('Sektor uspesno sacuvan');
    } else {
      alert('Greska pri cuvanju sektora');
    }

    //for the edit sector page
    if (onSubmit) await onSubmit();
  };

  return (
    <Box px='5'>
      <Flex flexDirection='row' width='80%'>
        <FormControl width='35%'>
          <FormLabel>Sektor</FormLabel>
          <Input
            type='text'
            ref={sectorNameRef}
            placeholder='Unesite ime za novi sektor'
            sx={{ '::placeholder': { fontStyle: 'italic' } }}
          />
        </FormControl>
        <FormControl width='35%' ml='5%'>
          <FormLabel>Minimalno vreme rada (minuti)</FormLabel>
          <Input
            type='number'
            ref={sectorMinWorkTimeRef}
            placeholder='Unesite minimalno vreme rada za radnike'
            sx={{ '::placeholder': { fontStyle: 'italic' } }}
          />
        </FormControl>
        {isLoading ? (
          <GsSpinner />
        ) : (
          <Button
            alignSelf='flex-end'
            textAlign='center'
            left='50px'
            width={100}
            _hover={{ textDecor: 'none', bgColor: saveBtnColor }}
            variant='outline'
            onClick={validateInputsAndSaveSector}
          >
            Sačuvaj
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default SectorForm;
