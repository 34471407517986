import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { getFallouts, Item } from '../fallout-api';
import FalloutRow from './fallout-row';
import { TableNavigation } from '../../../../components/TabeNavigation/TableNavigation';

const FalloutTable: FC = () => {
  const [tableData, setTableData] = useState<Item[]>();
  const [tableDataChanged, setTableDataChanged] = useState(false);
  let prodCounter = 0;

  const onRenderTable = () => {
    setTableDataChanged(!tableDataChanged);
  };

  useEffect(() => {
    const callGetTableData = async () => {
      await getFallouts(setTableData, 0);
    };
    callGetTableData();

    return () => {
      setTableData([]);
    };
  }, [tableDataChanged]);

  const [pageNumber, setPageNumber] = useState<number>(0);

  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    getFallouts(setTableData, newPageNumber);
    setPageNumber(newPageNumber);
  };

  return (
    <TableContainer>
      <Table variant='simple'>
        <TableCaption>Proizvodi</TableCaption>
        <Thead>
          <Tr>
            <Th textAlign='center'>Šifra porudžbine</Th>
            <Th textAlign='center'>Šifra proizvoda</Th>
            <Th textAlign='center'>Opis</Th>
            <Th textAlign='center'>Sektor</Th>
            <Th textAlign='center'>Škart</Th>
            <Th textAlign='center'>Napomena</Th>
            <Th textAlign='center'>Materijal</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData?.map((row) => (
            <FalloutRow
              key={prodCounter++ + row.prodId}
              item={row}
              renderTable={onRenderTable}
            />
          ))}
        </Tbody>
      </Table>
      <TableNavigation
        pageNumber={pageNumber}
        pageNumberHandler={pageNumberHandler}
      />
    </TableContainer>
  );
};

export default FalloutTable;
