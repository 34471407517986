import { Heading } from '@chakra-ui/react';

interface CustomHeadingProps {
  text: string;
}

export const CustomHeading = ({ text }: CustomHeadingProps) => {
  return (
    <Heading
      as='h2'
      mb={2}
      pb='10'
      size='2xl'
      style={{
        color: 'white',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: '28px',
        fontWeight: 'bold',
        lineHeight: '32px',
        margin: '0 0 24px',
      }}
    >
      {text}
    </Heading>
  );
};
