import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
} from '@chakra-ui/react';
import { OrderItemsTabelProps } from '../order-apis';
import OrderItemsTableRow from './orderItemTableRow';

function OrderItemsTable({
  orderItems,
  setOrderItems,
  orderToEdit,
}: OrderItemsTabelProps) {
  const tableCaption = tableCaptionGenerator(orderItems);
  const isEdit = orderToEdit != undefined;
  return (
    <TableContainer mt='30' mb='70'>
      <Table variant='simple'>
        <TableCaption>{tableCaption}</TableCaption>
        <Thead>
          <Tr>
            <Th>Šifra Proizvoda</Th>
            <Th isNumeric>Količina</Th>
            <Th> Merna jedinica </Th>
            <Th>Datum isporuke</Th>
            <Th>Prioritet</Th>
            {isEdit && <Th>Izmeni stavku</Th>}
            <Th>Obriši stavku</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orderItems?.map((oi, index) => (
            <OrderItemsTableRow
              key={index}
              orderItem={oi}
              orderItems={orderItems}
              setOrderItems={setOrderItems}
              orderToEdit={orderToEdit}
            ></OrderItemsTableRow>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

const tableCaptionGenerator = (orderItems: any) => {
  if (orderItems.length === 0) {
    return 'Ne postoji nijedna stavka porudzbine';
  } else {
    return 'Stavke porudzbine';
  }
};

export default OrderItemsTable;
