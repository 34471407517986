import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, MenuList, Button, FormControl, FormLabel, Flex } from '@chakra-ui/react';
import { FC, useState } from 'react';
import SectorCheckbox from './check-box';

const MultiSelectWithCheckbox: FC<{
  selectedSectors: string[];
  sectors: string[];
  toggleSector: (sector: string) => void;
}> = ({ selectedSectors, sectors, toggleSector }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <FormControl>
      <FormLabel>Dodeljeni sektori</FormLabel>
      <Menu isOpen={menuIsOpen} onClose={() => setMenuIsOpen(false)}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
          width={500}
          textAlign='left'
          color='white'
          backgroundColor='#2d3748'
          _hover={{ bg: '#2d3748' }}
          _active={{
            bg: '#2d3748',
          }}
          overflow='hidden'
        >
          {selectedSectors.includes('Svi sektori') ? (
            'Svi sektori'
          ) : selectedSectors.length === 0 ? (
            <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}>Izaberite sektore</span>
          ) : (
            selectedSectors.join(', ')
          )}
        </MenuButton>
        <MenuList>
          <Flex direction='column' width='100%'>
            {sectors.map((sector) => (
              <SectorCheckbox
                key={sector}
                sectorName={sector}
                isChecked={selectedSectors.includes(sector)}
                toggleSector={toggleSector}
              />
            ))}
          </Flex>
        </MenuList>
      </Menu>
    </FormControl>
  );
};

export default MultiSelectWithCheckbox;
