import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Box,
} from '@chakra-ui/react';
import CustomTaskTableRow from './customTaskTableRow';
import { useState, useEffect } from 'react';
import {
  CustomTaskDisplay,
  CustomTaskTableProps,
  getCustomTasks,
} from './customTaskApi';
import { TableNavigation } from '../../../components/TabeNavigation/TableNavigation';
import { tableCaptionGenerator } from '../../../utilility/util';

function CustomTaskTable({ customTasks, getTableData }: CustomTaskTableProps) {
  //const tableCaption = tableCaptionGenerator(sectors);

  let tableCaption = tableCaptionGenerator(
    customTasks,
    'Ne postoje raspolozvi radni nalozi',
    'Radni nalozi'
  );

  return (
    <TableContainer marginTop='30px' marginBottom='70px'>
      <Table variant='simple'>
        <TableCaption>{tableCaption}</TableCaption>
        <Thead>
          <Tr>
            <Th>Šifra naloga</Th>
            <Th>Instrukcije</Th>
            <Th>Sektor</Th>
            <Th>Obriši</Th>
          </Tr>
        </Thead>
        <Tbody>
          {customTasks?.map((ct) => (
            <CustomTaskTableRow
              customTask={ct}
              getTableData={getTableData}
            ></CustomTaskTableRow>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default CustomTaskTable;
