import {
  Box,
  Flex,
  Input,
  useChakra,
  InputGroup,
  InputLeftElement,
  Icon,
} from '@chakra-ui/react';
import { FormControl, FormLabel, FormHelperText } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import AppLayout from '../../../../components/layout/app-layout';
import { Resource, getResourcesByFilter, getResources } from '../resource-api';
import { FaSearch } from 'react-icons/fa';
import { CustomHeading } from '../../../../components/heading/custom-heading';
import GsSpinner from '../../../../components/spinner/gsSpinner';
import {
  getMeasurementUnits,
  MeasurementUnit,
  createMeasurementUnitObject,
} from '../../measurementUnit/measurementUnit-api';
import ResourceTable from '../components/tables/ResourceTable';
import {
  createQualityUnitObject,
  getQualityUnits,
  QualityUnit,
} from '../../measurementUnit/qualityUnit-api';

const EditResourcePage = () => {
  const { theme, colorMode } = useChakra();

  return (
    <AppLayout>
      <Flex flexDir='column' w='100%'>
        <Box
          flexDirection='row'
          width='full'
          height='100%'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
          mb={1}
          p='20'
        >
          <CustomHeading text='Pregled materijala' />
          <EditResourceForm />
        </Box>
      </Flex>
    </AppLayout>
  );
};

const EditResourceForm = () => {
  const [ammountAvailable, setAmmountAvailabe] = useState<number>(-1);
  const [searcedResources, setSearchedResources] = useState<Resource[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<Resource>({
    name: '',
    available: -1,
    measureUnit: createMeasurementUnitObject(''),
    workerMeasureUnit: createMeasurementUnitObject(''),
    quality: createQualityUnitObject(''),
    resourceCode: '',
    measureUnitRatio: -1,
  });
  const resourceNameRef = useRef<HTMLInputElement>(null);
  const [resourcePageNo, setresourcePageNo] = useState<number>(0);
  const [measurementUnits, setMeasurementUnits] = useState<MeasurementUnit[]>(
    []
  );
  const [qualityUnits, setQualityUnits] = useState<QualityUnit[]>([]);

  const loadMeasurementUnits = async () => {
    try {
      const units = await getMeasurementUnits();

      setMeasurementUnits(units);
    } catch (error) {
      console.error('Failed to load measurement units:', error);
    }
  };

  const loadQualityUnits = async () => {
    const units = await getQualityUnits();

    setQualityUnits(units);
  };

  const handleGetResource = async () => {
    getResources(setSearchedResources, 0, setIsLoading);
    setresourcePageNo(0);
  };

  useEffect(() => {
    getResources(setSearchedResources, 0, setIsLoading);
    loadMeasurementUnits();
    loadQualityUnits();
  }, []);

  return (
    <Box px='5'>
      <Flex flexDirection='row' mb='50' align={'center'}>
        <FormControl>
          <FormLabel>Pretražite materijal</FormLabel>
          <InputGroup alignItems='center'>
            <InputLeftElement
              pointerEvents='none'
              children={
                <Icon
                  as={FaSearch}
                  color='gray.500'
                  fontSize='20px'
                  marginTop='5px'
                />
              }
            />
            <Input
              type='text'
              ref={resourceNameRef}
              placeholder='Naziv materijala'
              width='50%'
              onChange={(e) => {
                // if (!e.target.value || e.target.value.length === 0) return;
                if (e.target.value.length === 0) {
                  handleGetResource();
                } else {
                  getResourcesByFilter(
                    e.target.value,
                    setSearchedResources,
                    0,
                    setIsLoading
                  );
                  setresourcePageNo(0);
                }
              }}
            />
          </InputGroup>
          <FormHelperText fontStyle='italic'>
            Pretrazite po nazivu materijala.
          </FormHelperText>
        </FormControl>
      </Flex>
      {isLoading ? (
        <GsSpinner />
      ) : (
        <ResourceTable
          selectedResource={selectedResource}
          resources={searcedResources}
          setSelectedResource={setSelectedResource}
          setAmmountAvailabe={setAmmountAvailabe}
          pageNumber={resourcePageNo}
          fieldRef={resourceNameRef}
          setPageNumber={setresourcePageNo}
          setState={setSearchedResources}
          setIsLoading={setIsLoading}
          measureUnits={measurementUnits}
          setMeasurementUnits={setMeasurementUnits}
          qualityUnits={qualityUnits}
          setQualityUnits={setQualityUnits}
          handleGetResource={handleGetResource}
        ></ResourceTable>
      )}
    </Box>
  );
};

export default EditResourcePage;
