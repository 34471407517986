import { GET, POST } from '../../../api/requests';
import { getPaginationParams } from '../../../components/TabeNavigation/table-navigation';
import { SERVER_URL } from '../../../api/config';
import { Exception409 } from '../../../exceptions/Exception409';
import { GET_RESOURCE_BY_NAME } from '../../../api/apiConstants';
import { checkTwoSpaces } from '../../../utilility/util';
import { MeasurementUnit } from '../measurementUnit/measurementUnit-api';
import { QualityUnit } from '../measurementUnit/qualityUnit-api';

export type Resource = {
  id?: string;
  name: string;
  available: number;
  measureUnit: MeasurementUnit;
  workerMeasureUnit: MeasurementUnit;
  resourceCode: string;
  quality: QualityUnit;
  measureUnitRatio: number;
};

export const createResource = async (resource: Resource, setIsLoading: any) => {
  try {
    setIsLoading(true);
    const savedResource = await POST<Resource>(
      SERVER_URL + '/api/resource/save',
      resource
    );
    setIsLoading(false);
    return true;
  } catch (error) {
    if (error instanceof Exception409) {
      const em = error as Error;
      console.log('Error while creating order. ' + em.message);
      alert('Postoji materijal sa ovakom sifrom ili imenom');
      setIsLoading(false);
      return false;
    }

    setIsLoading(false);
    console.log('Error while creating resource.');
    console.error(error);
    return false;
  }
};

export const getResources = async (
  setResources: any,
  pageNo: number,
  setIsLoading?: any
) => {
  try {
    let url = new URL(SERVER_URL + '/api/resources');

    let params = new URLSearchParams();
    params = getPaginationParams(params, pageNo);
    url.search = params.toString();

    setIsLoading ? setIsLoading(true) : console.log('Ne postoji setIsLoading');
    const resources = await GET<Resource[]>(url.toString());
    console.log(resources);
    setResources(resources);
    setIsLoading ? setIsLoading(false) : console.log('Ne postoji setIsLoading');
  } catch (error) {
    setIsLoading(false);
    console.log('Error while fetching resources.');
    console.error(error);
  }
};

export const getResourcesByFilter = async (
  filter: string,
  setResources: any,
  pageNo: number,
  setIsLoading?: any
) => {
  try {
    let url = new URL(SERVER_URL + GET_RESOURCE_BY_NAME);

    let params = new URLSearchParams();
    params = getPaginationParams(params, pageNo);
    if (filter || filter !== '') params.append('filter', filter);
    url.search = params.toString();
    setIsLoading ? setIsLoading(true) : console.log('Ne postoji setIsLoading');
    const resources = await GET<Resource[]>(url.toString());
    setResources(resources);
    setIsLoading ? setIsLoading(false) : console.log('Ne postoji setIsLoading');
  } catch (error) {
    setIsLoading(false);
    console.log('Error while fetching resources.');
    console.error(error);
  }
};

export const deleteResource = async (resource: Resource) => {
  try {
    const deletedOrder = await POST<Resource>(
      SERVER_URL + '/api/resource/delete',
      resource
    );
    return true;
  } catch (error) {
    const em = error as Error;
    console.log('Error while deleting order. ' + em.message);
    alert('Error while deleting order. ' + em.message);
    return false;
  }
};

export interface ResourceTableProps {
  resources: Resource[];
  setSelectedResource: any;
  selectedResource: Resource;
  setAmmountAvailabe: any;
  fieldRef?: any;
  pageNumber: number;
  setPageNumber?: any;
  setState?: any;
  setIsLoading: any;
  measureUnits: MeasurementUnit[];
  setMeasurementUnits: any;
  qualityUnits: QualityUnit[];
  setQualityUnits: any;
  handleGetResource: () => void;
}

export interface ResourceTableRowProps {
  resource: Resource;
  setState: any;
  setIsLoading: any;
  handleEditResource: (resource: Resource) => void;
}

export function isResourceFormValid(
  ammountString: string | undefined,
  resourceName: string | undefined,
  measureUnit: MeasurementUnit | undefined,
  workerUnit: MeasurementUnit | undefined,
  resourceCode: string | undefined,
  quality: QualityUnit | undefined,
  measureUnitRatio: string | undefined,
  create: boolean
) {
  if (
    ammountString === undefined ||
    resourceName === undefined ||
    measureUnit === undefined ||
    workerUnit === undefined ||
    resourceCode == undefined ||
    quality === undefined ||
    measureUnitRatio === undefined
  ) {
    console.log('Undefined');
    return false;
  }
  if (resourceName === '') {
    alert('Ime materijala ne sme biti prazno');
    return false;
  }

  if (checkTwoSpaces(resourceName)) {
    alert('Nisu dozvoljena dva uzastopna znaka razmaka kod naziva materijala');
    return false;
  }

  if (ammountString === '' && create) {
    alert('Unesite kolicinu materijala');
    return false;
  }

  if (measureUnit.unitName === '') {
    alert('Izaberite mernu jedinicu');
    return false;
  }

  if (workerUnit.unitName === '') {
    alert('Izaberite mernu jedinicu za radnika');
    return false;
  }

  if (measureUnitRatio === '') {
    alert('Unesite koeficijent za mernu jedinicu');
    return false;
  }

  if (quality.qualityName === '') {
    alert('Izaberite kvalitet');
    return false;
  }

  if (resourceCode === '') {
    alert('Izaberite sifru materijala');
    return false;
  }

  if (checkTwoSpaces(resourceCode)) {
    alert('Nisu dozvoljena dva uzastopna znaka razmaka kod sifre materijala');
    return false;
  }

  let ratio: Number = Number(measureUnitRatio);
  if (Number.isNaN(ratio)) {
    alert('Koeficijent mora biti broj');
    return false;
  }

  if (create) {
    let amount: Number = Number(ammountString);

    if (Number.isNaN(amount)) {
      alert('Kolicina materijala mora biti broj');
      return false;
    }
    if (amount <= 0) {
      alert('Kolicina materijala mora biti veca od 0');
      return false;
    }
    if (amount > 100000) {
      alert('Kolicina materijala mora biti manja od 100 000');
      return false;
    }
  }

  return true;
}

export function isAmountValid(ammountAvailable: string | undefined) {
  if (ammountAvailable === undefined) return false;

  let amount: Number = Number(ammountAvailable);

  if (Number.isNaN(amount)) {
    alert('Kolicina materijala mora biti broj');
    return false;
  }
  return true;
}

export function convertMeasureUnit(unit: string | undefined) {
  let measureUnit = '';
  if (unit === undefined) return measureUnit;
  if (unit == 'PIECE') {
    measureUnit = 'KOM';
  } else {
    measureUnit = unit;
  }
  return measureUnit;
}

export function convertQuality(quality: string | undefined) {
  let finalQuality = '';
  if (quality === undefined) return finalQuality;

  if (quality == 'Plastic') {
    finalQuality = 'Plastika';
  } else if (quality == 'Rubber') {
    finalQuality = 'Guma';
  } else {
    finalQuality = quality;
  }
  return finalQuality;
}

const resourceTableCaptionGenerator = (resources: any) => {
  if (resources.length === 0) {
    return 'Ne postoji nijedan materijal sa unetim nazivom';
  } else {
    return 'Materijali';
  }
};
