import { AddIcon, ChatIcon } from '@chakra-ui/icons';
import {
  Tr,
  Td,
  Button,
  Flex,
  useDisclosure,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Item } from '../fallout-api';
import FalloutModal from './modal/fallout-modal';
import WorkerNoteModal from './modal/worker-note-modal';

const FalloutRow: FC<{ item: Item; renderTable: () => void }> = ({
  item,
  renderTable,
}) => {
  const {
    isOpen: isAddModalOpen,
    onOpen: onAddModalOpen,
    onClose: onAddModalClose,
  } = useDisclosure();
  const {
    isOpen: isShowModalOpen,
    onOpen: onShowModalOpen,
    onClose: onShowModalClose,
  } = useDisclosure();

  return (
    <>
      <Tr>
        <Td textAlign='center'>{item.orderCode}</Td>
        <Td textAlign='center'>{item.prodCode}</Td>
        <Td textAlign='center'>{item.phaseDesc}</Td>
        <Td textAlign='center'>{item.sectorName}</Td>
        <Td textAlign='center'>{item.fallout}</Td>
        <Td textAlign='center'>
          <Flex justifyContent='center'>
            <IconButton
              aria-label='addFalloutToMaterial'
              icon={<ChatIcon />}
              variant='solid'
              colorScheme='gray'
              onClick={() => onShowModalOpen()}
            />
          </Flex>
        </Td>
        <Td textAlign='center'>
          <Flex justifyContent='center'>
            <IconButton
              aria-label='addFalloutToMaterial'
              icon={<AddIcon />}
              variant='outline'
              colorScheme='green'
              onClick={() => onAddModalOpen()}
            />
          </Flex>
        </Td>
      </Tr>
      {isAddModalOpen && (
        <FalloutModal
          isOpen={isAddModalOpen}
          onClose={onAddModalClose}
          item={item}
          renderTable={renderTable}
        />
      )}
      {isShowModalOpen && (
        <WorkerNoteModal
          isOpen={isShowModalOpen}
          onClose={onShowModalClose}
          taskId={item.taskId}
        />
      )}
    </>
  );
};

export default FalloutRow;
