import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  ModalHeader,
  Flex,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import {
  ALERT_GREEN,
  useGsDialog,
} from '../../../../contexts/GsConfirmDialogContext';
import { User } from '../../../login/login-api';
import { Resource } from '../../../manager/resource/resource-api';
import {
  RequestedResourceForTask,
  reserveResource,
  ResourceReservation,
  UserWithIDOnly,
} from '../../worker-api';
import WorkerChooseResourceModal from '../resourceReservation/WorkerChooseResourceModal';
import ReservedResourceReviewTable from './table/ReservedResourceReviewTable';

const ReservedResourceReviewModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  reservedResources: RequestedResourceForTask[];
  setReservedResources: any;
  taskId: number;
}> = ({ isOpen, onClose, reservedResources, setReservedResources, taskId }) => {
  const { confirmDialog, alertDialog } = useGsDialog();
  const disclosureResource = useDisclosure();
  const {
    isOpen: isOpenResource,
    onOpen: onOpenResource,
    onClose: onCloseResource,
  } = disclosureResource;

  const currentUser: User = JSON.parse(localStorage.getItem('user') as string);
  const [reservationResources, setReservationResources] = useState<
    ResourceReservation[]
  >([]);

  const handleAdditionalResource = (resource: Resource, amount: number) => {
    if (resource.id) {
      // Add to list for sending new reservation
      let newReservationResource: ResourceReservation = {
        taskId: taskId,
        requestedAmount: amount,
        requestedBy: { id: currentUser.userId } as UserWithIDOnly,
        resource: resource,
      };
      setReservationResources([
        ...reservationResources,
        newReservationResource,
      ]);

      // Expand display list for Reserved Resources
      let newRequestedResourcesForTask: RequestedResourceForTask = {
        requestedAmount: amount,
        resource: resource,
        status: 'NEW',
      };
      setReservedResources([
        ...reservedResources,
        newRequestedResourcesForTask,
      ]);
    }
  };

  const submitResourceRequest = async () => {
    const success = await reserveResource(reservationResources);
    if (success) {
      alertDialog(
        'Izmena sačuvana ',
        'Zahtev za materijal je uspesno poslat.',
        ALERT_GREEN
      );
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='1300px'>
        <ModalHeader
          fontFamily='Source Sans Pro, sans-serif'
          fontWeight='bold'
          fontSize='24px'
          lineHeight='32px'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          mb={6}
        >
          {'Pregled zaduženih materijala'}
          <Flex alignItems='center' mr={10}>
            <Box mr={2} w='15px' h='15px' bg='#006400' borderRadius='50%' />
            <Text fontSize='xl'>Potpuno odobreno</Text>
            <Box
              ml={5}
              mr={2}
              w='15px'
              h='15px'
              bg='yellow.600'
              borderRadius='50%'
            />
            <Text fontSize='xl'>Delimično odobreno</Text>
            <Box
              ml={5}
              mr={2}
              w='15px'
              h='15px'
              bg='#8B0000'
              borderRadius='50%'
            />
            <Text fontSize='xl'>Čeka se na odobravanje</Text>
            <Box
              ml={5}
              mr={2}
              w='15px'
              h='15px'
              bg='darkBlue'
              borderRadius='50%'
            />
            <Text fontSize='xl'>Nov resurs</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex flexDir='column'>
            <Flex flexDir='row'>
              <Button onClick={onOpenResource} variant='outline' w='20%'>
                Dodaj nov materijal
              </Button>
            </Flex>
            <ReservedResourceReviewTable
              reservedResources={reservedResources}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            variant='outline'
            w='20%'
            disabled={reservationResources.length === 0}
            title={
              reservationResources.length === 0
                ? 'Morate dodati nov materijal'
                : ''
            }
            onClick={submitResourceRequest}
          >
            Proširi zahtev
          </Button>
        </ModalFooter>
      </ModalContent>
      <WorkerChooseResourceModal
        isOpen={isOpenResource}
        onClose={onCloseResource}
        handleAdditionalResource={handleAdditionalResource}
      ></WorkerChooseResourceModal>
    </Modal>
  );
};

export default ReservedResourceReviewModal;
