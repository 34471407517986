import { Box, Flex, useChakra, Image, Divider, Button } from '@chakra-ui/react';
import { FC, useContext, useEffect, useState } from 'react';
import logoLight from '../../images/gs-logo-light.png';
import logoDark from '../../images/gs-logo-dark.png';
import UserButton from './UserButton';
import { UserContext } from '../../contexts/user-context';
import { useNavigate } from 'react-router-dom';
import FalloutBtn from '../../pages/manager/fallout/fallout-btn';
import GsIconBtn from '../button/GsIconBtn';
import { HamburgerIcon } from '@chakra-ui/icons';
import NotificationButton from '../../pages/manager/notifications/notificationsButton';
import { useNotificationConnection } from '../../contexts/notificationsConnectionContext';

export const Header: FC<{ setShowMenu: Function; showMenu: boolean }> = ({
  setShowMenu,
  showMenu,
}) => {
  const { colorMode, theme } = useChakra();
  const { currentUser, setUser } = useContext(UserContext);
  const { client, setClient } =  useNotificationConnection();

  const navigate = useNavigate();

  const handleUserLogOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    client?.disconnect(function () {
      console.log('Disconnected from WebSocket');
    });
    setClient(null);
    navigate('/');
    if (showMenu) setShowMenu(false);
  };

  return (
    <Box
      h='75'
      bg={theme.colors.bg.colorMode(colorMode)}
      boxSizing='border-box'
      borderWidth={2}
      borderRadius='10'
      left='0'
      right='0'
      maxWidth='calc(100% -2rem)'
      zIndex='99'
      position='fixed'
      boxShadow='dark-lg'
    >
      <Flex mx='5' flexDirection='row'>
        {currentUser && (
          <Flex justifyContent='left' mt='5'>
            {currentUser?.role !== 'ROLE_OFFICE_WORKER' && (
              <Flex mr='2.5'>
                <GsIconBtn
                  label='menu-button'
                  icon={<HamburgerIcon />}
                  onClick={setShowMenu as () => void}
                />
              </Flex>
            )}
            {currentUser?.role === 'ROLE_MANAGER' && (
              <Flex mr='2.5'>
                <FalloutBtn />
              </Flex>
            )}
            <Flex mr='2.5'>
              <NotificationButton />
            </Flex>
            <UserButton
              currentUser={currentUser}
              userLogout={handleUserLogOut}
            />
          </Flex>
        )}
        <Divider />
        <Flex justifyContent='right' mt='3' marginRight='20px'>
          <Image
            src={colorMode === 'light' ? logoLight : logoDark}
            boxSize='50px'
            width='250px'
          />
        </Flex>
      </Flex>
    </Box>
  );
};
