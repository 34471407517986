import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import GsHeaderModal from '../../../../components/heading/GsHeaderModal';
import { User } from '../../../login/login-api';
import { ResourceRequiredAmmount } from '../../../manager/product/product-api';
import { Resource } from '../../../manager/resource/resource-api';
import {
  calculateUsedResourcesForWorker,
  reserveResource,
  ResourceReservation,
  UserWithIDOnly,
} from '../../worker-api';
import WorkerChooseResourceModal from './WorkerChooseResourceModal';
import ResourceRequiredTable from './table/ResourceRequiredTable';
import {
  ALERT_GREEN,
  useGsDialog,
} from '../../../../contexts/GsConfirmDialogContext';

const ResourceReservationModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  setIsAnyModalOpen: any;
  isAnyModalOpen: boolean;
  phaseRequiredResources: ResourceRequiredAmmount[];
  productAmount: number;
  taskId: number;
}> = ({
  isOpen,
  onClose,
  setIsAnyModalOpen,
  isAnyModalOpen,
  phaseRequiredResources,
  productAmount,
  taskId,
}) => {
  const [reservationResources, setReservationResources] = useState<
    ResourceReservation[]
  >([]);
  const currentUser: User = JSON.parse(localStorage.getItem('user') as string);

  useEffect(() => {
    setIsAnyModalOpen(true);
    const newReservations: ResourceReservation[] = phaseRequiredResources.map(
      (phaseResource) => ({
        taskId: taskId,
        requestedAmount: Number(
          calculateUsedResourcesForWorker(
            phaseResource.amount * productAmount,
            phaseResource.resource?.measureUnitRatio
          )
        ),
        requestedBy: { id: currentUser.userId } as UserWithIDOnly,
        resource: phaseResource.resource as Resource,
      })
    );

    // Set the state of Reservation Resources
    setReservationResources(newReservations);
  }, [phaseRequiredResources, productAmount, taskId]);

  const disclosureResource = useDisclosure();
  const {
    isOpen: isOpenResource,
    onOpen: onOpenResource,
    onClose: onCloseResource,
  } = disclosureResource;
  const { confirmDialog, alertDialog } = useGsDialog();

  const handleAdditionalResource = (resource: Resource, amount: number) => {
    if (resource.id) {
      let newReservationResource: ResourceReservation = {
        taskId: taskId,
        requestedAmount: amount,
        requestedBy: { id: currentUser.userId } as UserWithIDOnly,
        resource: resource,
      };
      setReservationResources([
        ...reservationResources,
        newReservationResource,
      ]);
    }
  };

  const handleUpdateRequestedAmount = (index: number, newAmount: number) => {
    setReservationResources((prevReservations) => {
      const updatedReservations = [...prevReservations];
      updatedReservations[index].requestedAmount = newAmount;
      return updatedReservations;
    });
  };

  const submitResourceRequest = async () => {
    const success = await reserveResource(reservationResources);
    if (success) {
      alertDialog(
        'Izmena sačuvana ',
        'Zahtev za materijal je uspesno poslat.',
        ALERT_GREEN
      );
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='1000'>
        <GsHeaderModal title='Zaduživanje Materijala' />
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex flexDir='column' mb={3}>
            <Flex flexDir='row'>
              <Button onClick={onOpenResource} variant='outline'>
                Dodaj potreban materijal
              </Button>
            </Flex>
            <ResourceRequiredTable
              reservationResources={reservationResources}
              setReservationResources={setReservationResources}
              onUpdateRequestedAmount={(index, newAmount) =>
                handleUpdateRequestedAmount(index, newAmount)
              }
            ></ResourceRequiredTable>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={submitResourceRequest} variant='outline'>
            Pošalji zahtev
          </Button>
        </ModalFooter>
      </ModalContent>
      <WorkerChooseResourceModal
        isOpen={isOpenResource}
        onClose={onCloseResource}
        handleAdditionalResource={handleAdditionalResource}
      ></WorkerChooseResourceModal>
    </Modal>
  );
};

export default ResourceReservationModal;
