import { DeleteIcon, AddIcon } from '@chakra-ui/icons';
import { Tr, Td, Input } from '@chakra-ui/react';
import { FC, useRef, useState, useEffect, useContext } from 'react';
import GsIconBtn from '../../../../../../../../components/button/GsIconBtn';
import { isNumberValidAndGreaterThenZero } from '../../../../../../../../utilility/util';
import { calculateUsedResourcesForWorker } from '../../../../../../../worker/worker-api';
import { convertMeasureUnit } from '../../../../../../resource/resource-api';
import { ReadOnlyContext } from '../../../../../contexts/ReadOnlyContext';
import { Resource, ResourceRequiredAmmount } from '../../../../../product-api';

const isResource = (obj: any): obj is Resource => {
  return 'measureUnit' in obj && 'quality' in obj;
};

const ResourceTableRow: FC<{
  isResourceAdded: boolean;
  resource: Resource | ResourceRequiredAmmount;
  onAddResource: (resource: ResourceRequiredAmmount) => void;
}> = ({ isResourceAdded, resource, onAddResource }) => {
  const quantityRef = useRef<HTMLInputElement>(null);
  const workerQuantityRef = useRef<HTMLInputElement>(null);
  const [quantity, setQuantity] = useState('');
  const [workerQuantity, setWorkerQuantity] = useState('');
  const [isQuantityValid, setIsQuantityValid] = useState<boolean>(true);
  const [isWorkerQuantityValid, setIsWorkerQuantityValid] =
    useState<boolean>(true);
  const resourceDetails = isResource(resource) ? resource : resource.resource;
  let measureUnit = convertMeasureUnit(resourceDetails?.measureUnit?.unitName);
  let workerUnit = convertMeasureUnit(
    resourceDetails?.workerMeasureUnit?.unitName
  );
  const { isReadOnly } = useContext(ReadOnlyContext);

  useEffect(() => {
    if (isResourceAdded && 'amount' in resource) {
      setQuantity(resource.amount.toString());
      if (resource.resource?.measureUnitRatio) {
        let workerAmount: Number = Number(
          calculateUsedResourcesForWorker(
            resource.amount,
            resource.resource.measureUnitRatio
          )
        );
        setWorkerQuantity(workerAmount.toFixed(2));
      } else {
        setWorkerQuantity('');
      }
    } else {
      setQuantity('');
      setWorkerQuantity('');
    }
  }, [resource, isResourceAdded]);

  const handleAddClick = () => {
    const quantity = quantityRef.current?.value;
    const workerQuantity = workerQuantityRef.current?.value;

    const isValidQuantity = isNumberValidAndGreaterThenZero(quantity);

    const isValidWorkerQuantity =
      isNumberValidAndGreaterThenZero(workerQuantity);

    if (!isResourceAdded && !isValidQuantity) {
      setIsQuantityValid(false);
      quantityRef.current?.focus();
      return;
    }

    if (!isResourceAdded && !isValidWorkerQuantity) {
      setIsWorkerQuantityValid(false);
      workerQuantityRef.current?.focus();
      return;
    }

    const requiredResoource: ResourceRequiredAmmount = {
      id: Number(resourceDetails?.id),
      amount: Number(quantity),
      resource: resourceDetails,
    };
    setIsQuantityValid(true);
    setIsWorkerQuantityValid(true);
    if (quantityRef.current) {
      quantityRef.current.value = '';
    }
    if (workerQuantityRef.current) {
      workerQuantityRef.current.value = '';
    }
    onAddResource(requiredResoource);
  };

  const handleBlur = () => {
    if (isNumberValidAndGreaterThenZero(quantity)) {
      let tempQuantity: Number = Number(quantity);
      setQuantity(tempQuantity.toFixed(2));
    }
    if (isNumberValidAndGreaterThenZero(workerQuantity)) {
      let tempWorkersQuantity: Number = Number(workerQuantity);
      setWorkerQuantity(tempWorkersQuantity.toFixed(2));
    }
  };

  const calculateQuantity = (
    setTyped: React.Dispatch<React.SetStateAction<string>>,
    setCalulated: React.Dispatch<React.SetStateAction<string>>,
    value: string,
    isWorkersAmount: boolean
  ) => {
    const ratio = Number(resourceDetails?.measureUnitRatio);
    const currentQuantity = Number(value);
    setTyped(value);
    if (isNaN(currentQuantity) || isNaN(ratio)) {
      setCalulated('');
      return;
    }
    let quantity: Number = -1;
    if (isWorkersAmount) {
      quantity = currentQuantity / ratio;
    } else {
      quantity = currentQuantity * ratio;
    }
    setCalulated(quantity.toFixed(2));
  };

  return (
    <Tr borderBottom='solid'>
      <Td textAlign='center'>
        <GsIconBtn
          icon={isResourceAdded ? <DeleteIcon /> : <AddIcon />}
          label='add or remove resource'
          onClick={handleAddClick}
          disabled={isReadOnly}
        />
      </Td>
      <Td textAlign='center'>{resourceDetails?.id}</Td>
      <Td textAlign='center'>{resourceDetails?.name}</Td>
      <Td textAlign='center'>{measureUnit}</Td>
      <Td textAlign='center'>{workerUnit}</Td>
      <Td textAlign='center'>{resourceDetails?.quality?.qualityName}</Td>
      <Td textAlign='center' width='20%'>
        <Input
          textAlign='center'
          borderWidth='2px'
          ref={quantityRef}
          isDisabled={isResourceAdded}
          isInvalid={!isQuantityValid}
          _invalid={{ borderColor: 'red.500' }}
          borderColor={isResourceAdded ? 'green.500' : undefined}
          value={quantity}
          onBlur={() => handleBlur()}
          onChange={(e) =>
            calculateQuantity(
              setQuantity,
              setWorkerQuantity,
              e.target.value,
              false
            )
          }
        />
      </Td>
      <Td textAlign='center' width='20%'>
        <Input
          textAlign='center'
          borderWidth='2px'
          ref={workerQuantityRef}
          isDisabled={isResourceAdded}
          isInvalid={!isWorkerQuantityValid}
          _invalid={{ borderColor: 'red.500' }}
          borderColor={isResourceAdded ? 'green.500' : undefined}
          value={workerQuantity}
          onBlur={() => handleBlur()}
          onChange={(e) =>
            calculateQuantity(
              setWorkerQuantity,
              setQuantity,
              e.target.value,
              true
            )
          }
        />
      </Td>
    </Tr>
  );
};

export default ResourceTableRow;
