import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  useColorModeValue,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Box,
  Select,
  FormHelperText,
  HStack,
  Text,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import GsSpinner from '../../../../../components/spinner/gsSpinner';
import AddMeasurementUnitModal from '../../../measurementUnit/components/addMeasurementUnit';
import AddQualityUnitModal from '../../../measurementUnit/components/addQualityUnit';
import {
  getMeasurementUnits,
  MeasurementUnit,
  createMeasurementUnitObject,
} from '../../../measurementUnit/measurementUnit-api';
import {
  createQualityUnitObject,
  getQualityUnits,
  QualityUnit,
} from '../../../measurementUnit/qualityUnit-api';
import {
  createResource,
  getResources,
  isAmountValid,
  isResourceFormValid,
  Resource,
} from '../../resource-api';

export interface EditResourceModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedResource: Resource;
  setSelectedResource: any;
  qualityUnits: QualityUnit[];
  setQualityUnits: any;
  measurementUnits: MeasurementUnit[];
  setMeasurementUnits: any;
}

const EditResourceModal = ({
  isOpen,
  onClose,
  selectedResource,
  setSelectedResource,
  qualityUnits,
  measurementUnits,
  setMeasurementUnits,
  setQualityUnits,
}: EditResourceModalProps) => {
  const [searcedResources, setSearchedResources] = useState<Resource[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [resourceName, setResourceName] = useState<string>('');
  const [resourcePageNo, setresourcePageNo] = useState<number>(0);
  const [ratioMessage, setRatioMessage] = useState<string>('');
  const [ammountAvailable, setAmmountAvailabe] = useState<number>(-1);
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const resourceNameRef = useRef<HTMLInputElement>(null);
  const ammountAvailableRef = useRef<HTMLInputElement>(null);
  const resourceCodeRef = useRef<HTMLInputElement>(null);
  const measureUnitRef = useRef<HTMLSelectElement>(null);
  const workerMeasureUnitRef = useRef<HTMLSelectElement>(null);
  const qualityRef = useRef<HTMLSelectElement>(null);
  const ratioRef = useRef<HTMLInputElement>(null);

  const {
    isOpen: isOpenAddMeasurementUnitModal,
    onOpen: onOpenAddMeasurementUnitModal,
    onClose: onCloseAddMeasurementUnitModal,
  } = useDisclosure();

  const {
    isOpen: isOpenAddQualityUnitModal,
    onOpen: onOpenAddQualityUnitModal,
    onClose: onCloseAddQualityUnitModal,
  } = useDisclosure();

  const handleRatioChange = () => {
    let output =
      measureUnitRef.current?.value +
      ' = ' +
      ratioRef.current?.value +
      ' ' +
      workerMeasureUnitRef.current?.value;

    setRatioMessage(output);
  };

  useEffect(() => {
    setAmmountAvailabe(selectedResource.available);
  }, [selectedResource]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='1100px'>
        <ModalHeader>Izmena materijala</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex flexDirection='row' mb='50' align={'center'}>
            <FormControl mr='20'>
              <FormLabel>Naziv materijala</FormLabel>
              <Input
                type='text'
                ref={resourceNameRef}
                defaultValue={selectedResource?.name}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Sifra materijala</FormLabel>
              <Input
                type='text'
                ref={resourceCodeRef}
                defaultValue={selectedResource?.resourceCode}
              />
            </FormControl>
          </Flex>

          <Flex flexDirection='row' mb='5' align={'center'}>
            <FormControl>
              <Box display='flex' alignItems='center'>
                <FormLabel mt={2} mb={2} mr={2}>
                  Merna jedinica
                </FormLabel>
                <Button
                  size='xs'
                  rounded='md'
                  textAlign='center'
                  _hover={{ textDecor: 'none', bgColor: 'logoutBtnClr' }}
                  variant='outline'
                  onClick={onOpenAddMeasurementUnitModal}
                >
                  +
                </Button>
              </Box>
              <Select
                placeholder='Izaberite mernu jedinicu'
                ref={measureUnitRef}
                defaultValue={selectedResource.measureUnit?.unitName}
                onChange={handleRatioChange}
              >
                {measurementUnits.map((unit, index) => (
                  <option key={index} value={unit.unitName}>
                    {unit.unitName}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Box display='flex' alignItems='center'>
                <FormLabel mt={2} mb={2} mr={2}>
                  Merna jedinica za radnika
                </FormLabel>
                <Button
                  size='xs'
                  rounded='md'
                  textAlign='center'
                  _hover={{ textDecor: 'none', bgColor: 'logoutBtnClr' }}
                  variant='outline'
                  onClick={onOpenAddMeasurementUnitModal}
                >
                  +
                </Button>
              </Box>
              <Select
                placeholder='Izaberite mernu jedinicu'
                ref={workerMeasureUnitRef}
                defaultValue={selectedResource.workerMeasureUnit?.unitName}
                onChange={handleRatioChange}
              >
                {measurementUnits.map((unit, index) => (
                  <option key={index} value={unit.unitName}>
                    {unit.unitName}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel mt={2} mb={2} mr={2}>
                Koeficijent {ratioMessage}
              </FormLabel>
              <Input
                placeholder='Unesi koeficijent'
                type='text'
                ref={ratioRef}
                defaultValue={
                  selectedResource?.measureUnitRatio?.toString() ?? ''
                }
                onChange={handleRatioChange}
              />
            </FormControl>
            <AddMeasurementUnitModal
              onOpen={onOpenAddMeasurementUnitModal}
              onClose={onCloseAddMeasurementUnitModal}
              isOpen={isOpenAddMeasurementUnitModal}
              setMeasurementUnits={setMeasurementUnits}
              measurementUnits={measurementUnits}
            />
          </Flex>
          <Flex mb='50'>
            <FormControl>
              <Box display='flex' alignItems='center'>
                <FormLabel mt={2} mb={2} mr={2}>
                  {' '}
                  {/* Added marginRight (mr) to push the button closer to the label */}
                  Kvalitet
                </FormLabel>
                <Button
                  size='xs'
                  rounded='md'
                  textAlign='center'
                  _hover={{ textDecor: 'none', bgColor: 'logoutBtnClr' }}
                  variant='outline'
                  onClick={onOpenAddQualityUnitModal}
                >
                  +
                </Button>
              </Box>
              <Select
                placeholder='Izaberite kvalitet'
                ref={qualityRef}
                defaultValue={selectedResource.quality?.qualityName}
              >
                {qualityUnits.map((unit, index) => (
                  <option key={index} value={unit.qualityName}>
                    {unit.qualityName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>
          <AddQualityUnitModal
            isOpen={isOpenAddQualityUnitModal}
            onClose={onCloseAddQualityUnitModal}
            onOpen={onOpenAddQualityUnitModal}
            qualityUnits={qualityUnits}
            setQualityUnits={setQualityUnits}
          ></AddQualityUnitModal>
          <HStack spacing='24px' width='100%' marginBottom={10}>
            <div key={ammountAvailable}>
              <Tooltip
                label='Merna jedinica koja je uneta u polje merna jedinica'
                fontSize='md'
              >
                <Text fontSize='2xl'>
                  Raspoloživa količina:{' '}
                  {ammountAvailable === -1 ? '' : ammountAvailable}
                  {' ' + measureUnitRef.current?.value}
                </Text>
              </Tooltip>
            </div>
            <Flex flexDirection='row' mb='50' align={'center'}>
              <FormControl>
                <Input
                  placeholder='Unesi kolicinu'
                  type='text'
                  ref={ammountAvailableRef}
                />
              </FormControl>
            </Flex>

            <Button
              leftIcon={<TriangleUpIcon />}
              _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
              variant='outline'
              onClick={(e) => {
                if (
                  ammountAvailableRef.current === undefined ||
                  ammountAvailableRef.current?.value === ''
                )
                  return;

                if (!isAmountValid(ammountAvailableRef.current?.value)) return;

                setAmmountAvailabe(
                  Number(ammountAvailableRef.current?.value) + ammountAvailable
                );
              }}
            >
              Povecaj stanje materijala
            </Button>

            <Button
              leftIcon={<TriangleDownIcon />}
              _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
              variant='outline'
              onClick={(e) => {
                if (
                  ammountAvailableRef.current === undefined ||
                  ammountAvailableRef.current?.value === ''
                )
                  return;

                if (!isAmountValid(ammountAvailableRef.current?.value)) return;

                setAmmountAvailabe(
                  ammountAvailable - Number(ammountAvailableRef.current?.value)
                );
              }}
            >
              Smanji stanje materijala
            </Button>
          </HStack>
          {isLoading ? (
            <GsSpinner></GsSpinner>
          ) : (
            <Button
              w='100%'
              mb='50'
              marginTop='40px'
              textAlign='center'
              _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
              variant='outline'
              onClick={async (e) => {
                const measurementUnit = createMeasurementUnitObject(
                  measureUnitRef.current?.value
                );

                const workerUnit = createMeasurementUnitObject(
                  workerMeasureUnitRef.current?.value
                );

                const qualityUnit = createQualityUnitObject(
                  qualityRef.current?.value
                );

                if (
                  !isResourceFormValid(
                    ammountAvailableRef.current?.value,
                    resourceNameRef.current?.value,
                    measurementUnit,
                    workerUnit,
                    resourceCodeRef.current?.value,
                    qualityUnit,
                    ratioRef.current?.value,
                    false
                  )
                ) {
                  return;
                }

                if (
                  resourceNameRef.current === null ||
                  measureUnitRef.current?.value === undefined ||
                  resourceCodeRef.current?.value === undefined ||
                  qualityRef.current?.value === undefined ||
                  workerMeasureUnitRef.current?.value === undefined
                ) {
                  return;
                }
                let finalAmmountAvailable = ammountAvailable;
                if (finalAmmountAvailable === -1)
                  finalAmmountAvailable = selectedResource?.available;

                let finalResourceName = resourceNameRef.current.value;
                if (finalResourceName === '')
                  finalResourceName = selectedResource?.name;

                let ratio: number = Number(ratioRef.current?.value);

                const success = await createResource(
                  {
                    id: selectedResource?.id,
                    name: finalResourceName,
                    available: Number(finalAmmountAvailable),
                    measureUnit: measurementUnit,
                    workerMeasureUnit: workerUnit,
                    resourceCode: resourceCodeRef.current.value,
                    quality: qualityUnit,
                    measureUnitRatio: ratio,
                  },
                  setIsLoading
                );

                setIsLoading(false);
                if (success === true) {
                  alert('Materijal izmenjen');
                  setAmmountAvailabe(-1);
                  setResourceName('');
                  setSelectedResource({
                    name: '',
                    available: -1,
                    measureUnit: '',
                    resourceCode: '',
                    quality: '',
                  });
                  getResources(setSearchedResources, 0, setIsLoading);
                  setresourcePageNo(0);
                  setRatioMessage('');
                  if (resourceNameRef.current != null) {
                    resourceNameRef.current.value = '';
                  }
                  if (ammountAvailableRef.current != null) {
                    ammountAvailableRef.current.value = '';
                  }
                  if (measureUnitRef.current != null) {
                    measureUnitRef.current.value = '';
                  }
                  if (workerMeasureUnitRef.current != null) {
                    workerMeasureUnitRef.current.value = '';
                  }
                  if (qualityRef.current != null) {
                    qualityRef.current.value = '';
                  }
                  if (ratioRef.current != null) {
                    ratioRef.current.value = '';
                  }

                  onClose();
                }
              }}
            >
              Izmeni materijal
            </Button>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditResourceModal;
