import { GET, POST } from '../../../api/requests';
import { SERVER_URL } from '../../../api/config';
import { getPaginationParams } from '../../../components/TabeNavigation/table-navigation';

export type Report = {
    id?: number;
    name: string | undefined;
    type: string;
    crateTime: string;
    startTime: string;
    endTime: string;
  };

export type ReportType = {
  id: number;
  name: string;

};

export interface ReportListProps {
    reports: Report[];
    setReports: any;
    setReport: any;
    report?: Report;
    onOpen?: any;
  }

  export interface ReportBoxProps {
    icon: any;
    text: string;
    description: string;
    onButtonClick: any;
    ml: number;
  }

  
  export interface NoteReportModalProps {
    onClose: any;
    setIsModalOpen: any;
  }

  export interface SectorWorkTimeReportModalProps {
    onClose: any;
    setIsModalOpen: any;
  }

  export interface CompletedTasksReportModalProps {
    onClose: any;
    setIsModalOpen: any;
  }

  export interface ConsumedMaterialReportModalProps {
    onClose: any;
    setIsModalOpen: any;
  }

  export interface NoteReportTableModalProps {
    onClose: any;
    isModalOpen: any;
    setIsModalOpen: any;
    reportName: string;
    customNoteReports: NoteReport[],
    setCustomNoteReports:any,
    getCustomNoteReport: any,
    pageNo: number
    setProductPageNo: any
    startDate: string
    endDate: string
    setIsLoading: any
  }

  export interface CompletedTasksReportTableModalProps {
    onClose: any;
    isModalOpen: any;
    setIsModalOpen: any;
    reportName: string;
    customCompletedTasksReports: CompletedTasksReport[],
    setCustomCompletedTasksReports:any,
    getCustomCompletedTasksReport: any,
    pageNo: number
    setProductPageNo: any
    startDate: string
    endDate: string
    setIsLoading: any
  }

  export interface SectorReportTableModalProps {
    isModalOpen: any;
    setIsModalOpen: any;
    reportName: string;
    customSectorReport: SectorReport[],
    startDate: string
    endDate: string
    setIsLoading: any
  }

  export interface ConsumedMaterialReportTableModalProps {
    isModalOpen: any;
    setIsModalOpen: any;
    customConsumedMaterialReports: ConsumedMaterialReport[];
    setCustomConsumedMaterialReports: any,
    getCustomConsumedMaterialReport: any,
    pageNo: number,
    setProductPageNo: any,
    order_code: string;
  }


  export interface CustomNoteReportTableRowProps {
    customNote: NoteReport
  }

  export interface CustomSectorReportTableRowProps {
    customSector: SectorReport
  }

  export interface CustomCompletedTasksReportTableRowProps {
    customCopletedTask: CompletedTasksReport
  }

  export interface CustomConsumedMaterialReportTableRowProps {
    customConsumedMaterial: ConsumedMaterialReport
  }
  

  export type NoteReport = {
    note: string;
    input_time: string;
    order_code: string;
    product_code: string;
    sector_name: string;
  };

  export type SectorReport = {
    sector_name: string;
    total_time: number;
    average_time: number;
    max_time: number;
    min_time: number;
  };

  export type CompletedTasksReport = {
    sector_name: string;
    order_code: string;
    product_code: string;
    product_name: string;
    completed_amount: number;
    expected_amount : number;
    note: string;
    start_time: Date;
    end_time: Date;
    estimate_time: Date;
  };

  
  export type ConsumedMaterialReport = {
    resource_code: string;
    resource_name: string;
    total_amount: number;
    measurable_unit: string;
  };



  export const getCustomNoteReport = async (
    dateFrom: string,
    dateTo: string,
    setCustomNoteReport: any,
    setIsLoading: any,
    pageNo: number,
    isExport?: string
  ) => {
    try {
      if(setIsLoading != undefined) {
        setIsLoading(true);
      }

      
      let url = new URL(SERVER_URL + '/api/custom-report/note');
      let params = new URLSearchParams();
      params = getPaginationParams(params, pageNo);
      params.append('dateFrom', dateFrom.toString().substring(0, 10));
      params.append('dateTo', dateTo.toString().substring(0, 10));
      if(isExport != undefined && isExport != null) {
        params.append('isExport', isExport);
      }
      url.search = params.toString();
      console.log(url.toString());

      const noteReport = await GET<NoteReport[]>(url.toString());
      setCustomNoteReport(noteReport);
      console.log(noteReport);

      if(setIsLoading != undefined) {
        setIsLoading(false);
      }

      return noteReport;
    } catch (error) {
      console.log('Error while fetching note report.');
      console.error(error);
      return false;
    }
  };

  export const getCustomSectorReport = async (
    dateFrom: string,
    dateTo: string,
    setCustomSectorReport: any,
    setIsLoading: any
  ) => {
    try {

      if(setIsLoading != undefined) {
        setIsLoading(true);
      }
      
      let url = new URL(SERVER_URL + '/api/custom-report/sector');
      let params = new URLSearchParams();
  
      params.append('dateFrom', dateFrom.toString().substring(0, 10));
      params.append('dateTo', dateTo.toString().substring(0, 10));
      url.search = params.toString();
      console.log(url.toString());

      const sectorReport = await GET<NoteReport[]>(url.toString());
      setCustomSectorReport(sectorReport);
      console.log(sectorReport);

      if(setIsLoading != undefined) {
        setIsLoading(false);
      }

      return sectorReport;
    } catch (error) {
      console.log('Error while fetching sector report.');
      console.error(error);
      return false;
    }
  };

  export const getCustomCompletedTasksReport = async (
    dateFrom: string,
    dateTo: string,
    setCustomCompletedTasksReport: any,
    setIsLoading: any,
    pageNo: number,
    isExport?: string
  ) => {
    try {
      if(setIsLoading != undefined) {
        setIsLoading(true);
      }

      
      let url = new URL(SERVER_URL + '/api/custom-report/completed-tasks');
      let params = new URLSearchParams();
      params = getPaginationParams(params, pageNo);
      params.append('dateFrom', dateFrom.toString().substring(0, 10));
      params.append('dateTo', dateTo.toString().substring(0, 10));
      if(isExport != undefined && isExport != null) {
        params.append('isExport', isExport);
      }
      url.search = params.toString();
      console.log(url.toString());

      const completedTasksReport = await GET<CompletedTasksReport[]>(url.toString());
      setCustomCompletedTasksReport(completedTasksReport);
      console.log(completedTasksReport);

      if(setIsLoading != undefined) {
        setIsLoading(false);
      }

      return completedTasksReport;
    } catch (error) {
      console.log('Error while fetching completed tasks report.');
      console.error(error);
      return false;
    }
  };

  export const getCustomConsumedMaterialReport = async (
    order_code: string,
    setCustomConsumedMaterialReport: any,
    pageNo: number,
    isExport?: string
  ) => {
    try {
      
      let url = new URL(SERVER_URL + '/api/custom-report/consumed-material');
      let params = new URLSearchParams();
      params = getPaginationParams(params, pageNo);
      params.append('order_code', order_code);
      if(isExport != undefined && isExport != null) {
        params.append('isExport', isExport);
      }
      url.search = params.toString();
      console.log(url.toString());

      const consumedMaterialReport = await GET<ConsumedMaterialReport[]>(url.toString());
      setCustomConsumedMaterialReport(consumedMaterialReport);
      console.log(consumedMaterialReport);

      return consumedMaterialReport;
    } catch (error) {
      console.log('Error while fetching consumed material report.');
      console.error(error);
      return false;
    }
  };
  


