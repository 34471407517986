import SockJS from 'sockjs-client';
import { CompatClient, Stomp } from '@stomp/stompjs';
import { SERVER_URL } from '../api/config';

export function subscribeToTopic(
  stompClient: CompatClient | null,
  topic: string,
  setStompClient: (arg: CompatClient) => void,
  handleMessage: (message: any) => void,
) {
  const jwt = localStorage.getItem('token');
  if (!stompClient && jwt) {
    console.log('Connecting to WebSocket for user ' + topic);

    var client = Stomp.over(function () {
      return new SockJS(SERVER_URL + '/ws');
    });

    client.reconnectDelay = 5000; // Reconnect after 25 seconds (adjust as needed)
    //client.debug = null; // Disable debug logs if not needed

    client.connect(
      { Authorization: `Bearer ${jwt}` },
      (frame: any) => {
        console.log('Connected:', frame);
        setStompClient(client);
        client.subscribe('/topic/' + topic, (message) => {
          const receivedMessage = JSON.parse(message.body);
          handleMessage(receivedMessage);
        });
      },
      (error: any) => {
        console.error('STOMP error:', error);
      }
    );
  }
}
