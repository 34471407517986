import { IconButton, useColorModeValue } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';

const GsIconBtn: FC<{
  label: string;
  onClick: () => void;
  icon: ReactElement;
  disabled?: boolean;
}> = ({ label, onClick, icon, disabled }) => {
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  return (
    <IconButton
      aria-label={label}
      icon={icon}
      onClick={onClick}
      size='sm'
      _hover={{
        textDecor: 'none',
        bgColor: logoutBtnClr,
        textColor: 'white',
        borderColor: 'white',
        borderWidth: '1px',
      }}
      disabled={disabled}
    />
  );
};

export default GsIconBtn;
