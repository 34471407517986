import { Box, Flex } from '@chakra-ui/layout';
import { useChakra } from '@chakra-ui/system';
import AppLayout from '../../../components/layout/app-layout';
import { Input } from '@chakra-ui/input';
import { CustomHeading } from '../../../components/heading/custom-heading';
import CustomTaskTable from './customTaskTable';
import {
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Icon,
  Select,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { TableNavigation } from '../../../components/TabeNavigation/TableNavigation';
import { CustomTaskDisplay, getCustomTasks } from './customTaskApi';
import GsSpinner from '../../../components/spinner/gsSpinner';

const CustomTaskOverview = () => {
  const SELECT_CODE = 'CODE';
  const SELECT_INSTRUCTION = 'INSTRUCTION';

  const [selectedValue, setSelectedValue] = useState(SELECT_CODE);
  const [filter, setFilter] = useState('');

  const { theme, colorMode } = useChakra();

  const handleSelectChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedValue(event.target.value);
  };

  const handleSearchChange = async (newFilter: string) => {
    setFilter(newFilter);
    setIsLoading(true);
    setCustomTasks(await getCustomTasks(newFilter, selectedValue, pageNumber));
    setIsLoading(false);
  };

  const [customTasks, setCustomTasks] = useState<CustomTaskDisplay[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pageNumberHandler = async (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    setIsLoading(true);
    setCustomTasks(await getCustomTasks(filter, selectedValue, newPageNumber));
    setIsLoading(false);
    setPageNumber(newPageNumber);
  };

  const callGetTableData = async () => {
    setIsLoading(true);
    setCustomTasks(await getCustomTasks('', selectedValue, 0));
    setIsLoading(false);
    setPageNumber(0);
  };

  useEffect(() => {
    callGetTableData();
  }, []);

  return (
    <AppLayout>
      <Flex flexDir='column' w='100%' h='100%'>
        <Box
          flexDirection='row'
          width='full'
          height='100%'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
          mb={1}
          p='20'
        >
          <CustomHeading text='Pregled radnih naloga' />
          <Flex flexDirection='row'>
            <Box width='50%'>
              <FormControl>
                <FormLabel>Pretražite radne naloge</FormLabel>
                <InputGroup alignItems='center'>
                  <InputLeftElement
                    pointerEvents='none'
                    children={
                      <Icon
                        as={FaSearch}
                        color='gray.500'
                        fontSize='20px'
                        marginTop='5px'
                      />
                    }
                  />
                  <Input
                    type='text'
                    width={600}
                    placeholder='Pretražite'
                    onChange={(e) => handleSearchChange(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
            </Box>
            <Box width='50%'>
              <FormControl width='180px' mr='8' ml={2}>
                <FormLabel> Izbor načina pretrage</FormLabel>
                <Select
                  marginRight='30px'
                  value={selectedValue}
                  onChange={handleSelectChange}
                >
                  <option value={SELECT_CODE}>Šifra naloga</option>
                  <option value={SELECT_INSTRUCTION}>Instrukcije</option>
                </Select>
              </FormControl>
            </Box>
          </Flex>
          {isLoading ? (
            <GsSpinner />
          ) : (
            <CustomTaskTable
              customTasks={customTasks}
              getTableData={callGetTableData}
            ></CustomTaskTable>
          )}
          <Box marginTop={3}>
            <TableNavigation
              pageNumber={pageNumber}
              pageNumberHandler={pageNumberHandler}
            />
          </Box>
        </Box>
      </Flex>
    </AppLayout>
  );
};

export default CustomTaskOverview;
