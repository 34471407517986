import {
  useChakra,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Box,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react';
import React, { useState, useRef, FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/user-context';
import { login, User } from './login-api';

const LoginArea = () => {
  const { theme, colorMode, toggleColorMode } = useChakra();
  const currentUser: User = JSON.parse(localStorage.getItem('user') as string);
  const currentRole = currentUser?.role;
  const navigate = useNavigate();

  useEffect(() => {
    if (colorMode === 'light') toggleColorMode();
    if (currentUser) {
      if (currentRole === 'ROLE_WORKER')
        navigate(`/worker/${currentUser.sectorIds[0]}`);
      else navigate('/home');
    }
  }, []);

  return (
    <Flex minHeight='100vh' width='full' align='center' justifyContent='center'>
      <Box
        boxSize='lg'
        borderWidth={3}
        px={4}
        width='full'
        maxWidth='500px'
        textAlign='center'
        boxShadow='dark-lg'
        borderRadius='10'
        bg={theme.colors.bg.colorMode(colorMode)}
      >
        <Box paddingTop='20' px='8'>
          <LoginHeader />
          <LoginForm />
        </Box>
      </Box>
    </Flex>
  );
};

const LoginHeader = () => {
  return (
    <Box textAlign='center' marginBottom='5'>
      <Heading>General Solutions</Heading>
      <Text>Prijavite se na sistem.</Text>
    </Box>
  );
};

const LoginForm = () => {
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [isUserInputInvalid, setUserInputInvalid] = useState(false);
  const [isPassInputInvalid, setPassInputInvalid] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleOnSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const username = usernameRef.current?.value as string;
    const password = passwordRef.current?.value as string;

    if (username === '') setUserInputInvalid(true);
    else if (password === '') setPassInputInvalid(true);
    else login(username, password, navigate, setUser);
  };

  return (
    <Box>
      <form onSubmit={handleOnSubmit}>
        <FormControl mt={4} isInvalid={isUserInputInvalid}>
          <FormLabel>Korisničko ime</FormLabel>
          <Input
            placeholder='Unesite Vaše korisničko ime'
            sx={{
              '::placeholder': {
                fontStyle: 'italic',
              },
            }}
            ref={usernameRef}
            onFocus={() => setUserInputInvalid(false)}
          />
          <FormErrorMessage>{'Popunite polje.'}</FormErrorMessage>
        </FormControl>

        <FormControl mt={4} isInvalid={isPassInputInvalid}>
          <FormLabel>Lozinka</FormLabel>
          <PasswordInput
            passwordRef={passwordRef}
            setInputInvalid={setPassInputInvalid}
            placeholderText='Unesite Vašu lozinku'
          />
          <FormErrorMessage>{'Popunite polje.'}</FormErrorMessage>
        </FormControl>

        <Button width='200px' type='submit' marginTop='10'>
          Uloguj me
        </Button>
      </form>
    </Box>
  );
};

export type PasswordInputProps = {
  passwordRef: React.RefObject<HTMLInputElement>;
  setInputInvalid: (value: boolean) => void;
  placeholderText: string;
};

export const PasswordInput: FC<PasswordInputProps> = ({
  passwordRef,
  setInputInvalid,
  placeholderText,
}) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup>
      <Input
        pr='4.5rem'
        type={show ? 'text' : 'password'}
        placeholder={placeholderText}
        ref={passwordRef}
        sx={{
          '::placeholder': {
            fontStyle: 'italic',
          },
        }}
        onFocus={() => setInputInvalid(false)}
      />
      <InputRightElement width='4.5rem'>
        <Button h='1.75rem' size='sm' onClick={handleClick}>
          {show ? 'Sakrij' : 'Prikaži'}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default LoginArea;
