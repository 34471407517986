import { FC, ReactNode, createContext, useState } from 'react';
import { ResourceRequiredAmmount } from '../product-api';

type RequiredResourceType = {
  requiredResources: ResourceRequiredAmmount[] | null;
  setRequiredResources: (
    requiredResources: ResourceRequiredAmmount[] | null
  ) => void;
};

export const RequiredResourceContext = createContext<RequiredResourceType>({
  requiredResources: null,
  setRequiredResources: (requiredResources: ResourceRequiredAmmount[] | null) =>
    null,
});

const RequiredResourceProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [requiredResources, setRequiredResources] = useState<
    ResourceRequiredAmmount[] | null
  >(null);
  const value = { requiredResources, setRequiredResources };

  return (
    <RequiredResourceContext.Provider value={value}>
      {children}
    </RequiredResourceContext.Provider>
  );
};

export default RequiredResourceProvider;
