import { DeleteIcon, DownloadIcon } from '@chakra-ui/icons';
import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';
import { FC, useContext } from 'react';
import GsIconBtn from '../../../../../../components/button/GsIconBtn';
import { ReadOnlyContext } from '../../../contexts/ReadOnlyContext';
import { deleteFile, downloadPdf, SaveProductDTO } from '../../../product-api';
import FileTableRow from './FileTableRow';

const FileTable: FC<{
  files: File[];
  setFiles: () => void;
  existingFiles: string[];
  setExistingFiles: any;
  productToEdit: SaveProductDTO;
}> = ({ files, setFiles, existingFiles, productToEdit, setExistingFiles }) => {
  const { isReadOnly } = useContext(ReadOnlyContext);

  return (
    <TableContainer marginTop='5%' marginBottom='5%'>
      <Table>
        <TableCaption>Crteži</TableCaption>
        <Thead>
          <Tr borderBottom='1px solid'>
            <Th textAlign='center'>Naziv</Th>
            <Th textAlign='center'>Preuzmi</Th>
            <Th textAlign='center'>Obriši</Th>
          </Tr>
        </Thead>
        <Tbody>
          {existingFiles &&
            existingFiles.map((fileName) => (
              <Tr borderBottom='1px' key={fileName}>
                <Td textAlign='center'>{fileName}</Td>
                <Td textAlign='center'>
                  <GsIconBtn
                    label='download-file-icon'
                    icon={<DownloadIcon />}
                    onClick={() =>
                      downloadPdf(productToEdit.product.id as number, fileName)
                    }
                  />
                </Td>
                <Td textAlign='center'>
                  <GsIconBtn
                    label='delete-file-icon'
                    icon={<DeleteIcon />}
                    onClick={() =>
                      deleteFile(
                        productToEdit.product.id as number,
                        fileName
                      ).then(
                        setExistingFiles(
                          existingFiles.filter((fName) => fName !== fileName)
                        )
                      )
                    }
                    disabled={isReadOnly}
                  />
                </Td>
              </Tr>
            ))}
          {files.map((f) => (
            <FileTableRow
              key={f.name}
              file={f}
              files={files}
              setFiles={setFiles}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default FileTable;
