import { SearchIcon } from '@chakra-ui/icons';
import {
  InputGroup,
  Input,
  InputRightElement,
  FormControl,
  FormErrorMessage,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { FC, useContext, useRef, useState } from 'react';
import ResourceTable from './table/ResourceTable';
import {
  getResourceByFilter,
  Resource,
  ResourceRequiredAmmount,
} from '../../../../product-api';
import GsIconBtn from '../../../../../../../components/button/GsIconBtn';
import { RequiredResourceContext } from '../../../../contexts/RequiredResourceContext';
import { ReadOnlyContext } from '../../../../contexts/ReadOnlyContext';

const PhaseResource: FC = () => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const resourceNameRef = useRef<HTMLInputElement>(null);
  const [isResourceAdded, setIsResourceAdded] = useState<boolean>(true);
  const { requiredResources, setRequiredResources } = useContext(
    RequiredResourceContext
  );
  const { isReadOnly } = useContext(ReadOnlyContext);

  const getResources = async (pageNo: number) => {
    if (!resourceNameRef.current?.value) return;
    const filteredResources = await getResourceByFilter(
      resourceNameRef.current?.value as string,
      pageNo
    );
    setResources(filteredResources);
    setPageNumber(pageNo);
    setIsResourceAdded(false);
  };

  const handleOnCHangePage = (num: number) => {
    if (pageNumber === 0 && num === -1) return;
    getResources(pageNumber + num);
  };

  const handleOnAddResource = (resource: ResourceRequiredAmmount) => {
    if (!isResourceAdded) {
      const alreadyAdded = requiredResources!.some(
        (p) => p.resource!.id === resource.resource!.id
      );
      if (!alreadyAdded)
        setRequiredResources([...requiredResources!, resource]);
      setResources([]);
      setIsResourceAdded(!isResourceAdded);
    } else {
      setRequiredResources(
        requiredResources!.filter(
          (p) => p.resource!.id !== resource.resource!.id
        )
      );
    }
  };

  return (
    <Flex
      flexDir='column'
      borderWidth='2px'
      borderRadius='5px'
      p='10px'
      mb='20px'
    >
      <Flex pt='15px' pl='15px'>
        <Heading
          as='h3'
          pb='10'
          whiteSpace='nowrap'
          color='white'
          fontFamily='Source Sans Pro, sans-serif'
          fontSize='22px'
          fontWeight='400'
          mr='5'
        >
          Materijali
        </Heading>
      </Flex>
      <Flex pl='15px'>
        <FormControl maxWidth='30%'>
          <InputGroup>
            <InputRightElement
              children={
                <GsIconBtn
                  icon={<SearchIcon />}
                  label='search resource'
                  onClick={() => getResources(0)}
                  disabled={isReadOnly}
                />
              }
            />
            <Input
              placeholder='Naziv materijala'
              ref={resourceNameRef}
              disabled={isReadOnly}
              onKeyPress={(e) => {
                if (e.key === 'Enter') getResources(0);
              }}
            />
          </InputGroup>
          <FormErrorMessage>{'Izaberite materijal'}</FormErrorMessage>
        </FormControl>
      </Flex>
      <ResourceTable
        filteredResources={resources}
        onAddResource={handleOnAddResource}
        isResourceAdded={isResourceAdded}
        pageNumber={pageNumber}
        onChangePage={handleOnCHangePage}
      />
    </Flex>
  );
};
export default PhaseResource;
