import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TableContainer,
  useDisclosure,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  InputGroup,
  InputRightElement,
  Heading,
  useColorModeValue,
  Select,
  Spinner,
} from '@chakra-ui/react';
import { useChakra } from '@chakra-ui/react';
import { FormControl, FormLabel, FormHelperText } from '@chakra-ui/react';
import { DeleteIcon, SearchIcon, AddIcon } from '@chakra-ui/icons';
import React from 'react';
import {
  Product,
  OrderItem,
  createOrder,
  Order,
  OrderItemsTabelProps,
  ProductTableProps,
  convertPriority,
  convertDate,
} from './order-apis';
import { getProductsByFilter } from '../product/product-api';
import { useState, useRef, useEffect } from 'react';
import { TxtClr } from '../../../components/theme/custom-theme';
import AppLayout from '../../../components/layout/app-layout';
import { CustomHeading } from '../../../components/heading/custom-heading';
import { convertMeasureUnit, Resource } from '../resource/resource-api';
import GsSpinner from '../../../components/spinner/gsSpinner';
import { TableNavigation } from '../../../components/TabeNavigation/TableNavigation';
import { checkTwoSpaces } from '../../../utilility/util';
import OrderItemsTable from './components/orderItemTable';
import MissingResourcesModal from './modal/missingResourcesModal';
import ConfirmationOrderModal from './modal/confirmation-order-modal';

const CreateOrderPage = () => {
  const { theme, colorMode } = useChakra();

  return (
    <AppLayout>
      <Flex flexDir='column' w='100%'>
        <Flex
          flexDirection='column'
          w='100%'
          mb={1}
          p='20'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
        >
          <CustomHeading text='Kreiranje porudžbine' />
          <CreateOrderForm />
        </Flex>
      </Flex>
    </AppLayout>
  );
};

const CreateOrderForm = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [addedProducts, setAddedProducts] = useState<Product[]>([]);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [productPageNo, setProductPageNo] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [missingResourcesState, setMissingResoucesState] = useState<Resource[]>(
    []
  );
  const [orderCode, setOrderCode] = useState<string>('');
  const orderCodeRef = useRef<HTMLInputElement>(null);
  const companyNameRef = useRef<HTMLInputElement>(null);
  const estimatedStartRef = useRef<HTMLInputElement>(null);
  const estimatedEndRef = useRef<HTMLInputElement>(null);
  const realEndRef = useRef<HTMLInputElement>(null);
  const priorityOrderRef = useRef<HTMLSelectElement>(null);
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isMissingResourceModalOpen, setIsMissingResourceModalOpen] =
    useState(false);

  const validateOrder = () => {
    if (
      orderCodeRef.current === null ||
      companyNameRef.current === null ||
      estimatedEndRef.current === null ||
      estimatedStartRef.current === null ||
      realEndRef.current === null ||
      priorityOrderRef === null
    ) {
      console.log('Undefined');
      return false;
    }

    if (orderCodeRef.current?.value === '') {
      alert('Unesite sifru porudzbenice');
      return false;
    }

    if (checkTwoSpaces(orderCodeRef.current.value)) {
      alert('Nisu dozvoljena dva uzastopna znaka razmaka');
      return false;
    }

    if (companyNameRef.current?.value === '') {
      alert('Unesite ime kompanije');
      return false;
    }

    if (estimatedStartRef.current?.value === '') {
      alert('Unesite vreme prijema porudzbenice');
      return false;
    }

    if (estimatedEndRef.current?.value === '') {
      alert('Unesite željeno vreme isporuke porudzbenice');
      return false;
    }

    if (realEndRef.current?.value === '') {
      alert('Unesite stvarno vreme isporuke porudzbenice');
      return false;
    }

    if (priorityOrderRef.current?.value === '') {
      alert('Izaberite prioritet');
      return false;
    }

    if (orderItems.length === 0) {
      alert('Unesite stavku porudzbenice');
      return false;
    }

    return true;
  };

  const onChooseOrderType = async (selectedOption: any, isActive: boolean) => {
    if (
      orderCodeRef.current === null ||
      companyNameRef.current === null ||
      estimatedEndRef.current === null ||
      estimatedStartRef.current === null ||
      realEndRef.current === null ||
      priorityOrderRef === null
    ) {
      console.log('Undefined');
      return;
    }

    const estStartDate: string = estimatedStartRef.current?.value;
    const estEndDate: string = estimatedEndRef.current?.value;
    const realEndDate: string = realEndRef.current?.value;
    const orderCode: string = orderCodeRef.current.value;
    const companyName: string = companyNameRef.current.value;

    const missingResources: Resource[] | undefined = await createOrder(
      {
        orderCode,
        companyName,
        items: orderItems,
        estimatedStart: estStartDate,
        estimatedEnd: estEndDate,
        realEnd: realEndDate,
        priority: priorityOrderRef.current?.value,
        active: isActive,
      } as Order,
      setIsLoading
    );

    setIsLoading(false);
    setOrderCode(orderCode);

    if (missingResources !== undefined) {
      setOrderItems([]);
      if (orderCodeRef.current !== null) {
        orderCodeRef.current.value = '';
      }
      if (companyNameRef.current !== null) {
        companyNameRef.current.value = '';
      }
      if (priorityOrderRef.current !== null) {
        priorityOrderRef.current.value = '';
      }
      if (estimatedEndRef.current !== null) {
        estimatedEndRef.current.value = '';
      }
      if (estimatedStartRef.current !== null) {
        estimatedStartRef.current.value = '';
      }
      if (realEndRef.current !== null) {
        realEndRef.current.value = '';
      }
      alert('Porudzbina sacuvana uspesno');

      if (missingResources?.length > 0 && isActive) {
        setIsConfirmationModalOpen(false);
        setIsMissingResourceModalOpen(true);
        setMissingResoucesState(missingResources);
      } else {
        setIsConfirmationModalOpen(false);
      }
    }
  };

  return (
    <Box px='5'>
      <>
        <Flex flexDirection='row' mb='50' align={'center'}>
          <FormControl mr='50' w='30%'>
            <FormLabel>Šifra porudžbine</FormLabel>
            <Input type='text' ref={orderCodeRef} />
            <FormHelperText fontStyle='italic'>
              Unesite šifru nove porudžbine
            </FormHelperText>
          </FormControl>
          <FormControl w='30%' mr='50'>
            <FormLabel>Naziv kompanije</FormLabel>
            <Input type='text' ref={companyNameRef} />
            <FormHelperText fontStyle='italic'>
              Unesite naziv kompanije koja je izdala porudžbinu
            </FormHelperText>
          </FormControl>
          <FormControl w='30%'>
            <FormLabel>Izaberite prioritet</FormLabel>
            <Select placeholder='Prioritet' ref={priorityOrderRef}>
              <option key={1} value='LOW'>
                Nizak
              </option>
              <option key={2} value='MEDIUM'>
                Srednji
              </option>
              <option key={2} value='HIGH'>
                Visok
              </option>
            </Select>
            <FormHelperText fontStyle='italic'>
              Izaberite prioritet porudžbenice
            </FormHelperText>
          </FormControl>
        </Flex>
        <Flex flexDirection='row' mb='50' align={'center'}>
          <FormControl mr='50' w='30%'>
            <FormLabel>Datum pristizanja</FormLabel>
            <Input placeholder='' type='date' ref={estimatedStartRef} />
            <FormHelperText fontStyle='italic'>
              Unesite datum pristizanja porudzbenice
            </FormHelperText>
          </FormControl>
          <FormControl mr='50' w='30%'>
            <FormLabel>Željeni datum isporuke</FormLabel>
            <Input placeholder='' type='date' ref={estimatedEndRef} />
            <FormHelperText fontStyle='italic'>
              Unesite željeni datum isporuke za porudžbenicu
            </FormHelperText>
          </FormControl>
          <FormControl w='30%'>
            <FormLabel>Stvarni datum isporuke</FormLabel>
            <Input placeholder='' type='date' ref={realEndRef} />
            <FormHelperText fontStyle='italic'>
              Unesite stvarni datum isporuke za porudžbenicu
            </FormHelperText>
          </FormControl>
        </Flex>
        <OrderItemModal
          products={products}
          setProducts={setProducts}
          setAddedProducts={setAddedProducts}
          addedProducts={addedProducts}
          setOrderItems={setOrderItems}
          orderItems={orderItems}
          pageNumber={productPageNo}
          setPageNumber={setProductPageNo}
        />

        <OrderItemsTable
          orderItems={orderItems}
          setOrderItems={setOrderItems}
        />

        {isLoading ? (
          <GsSpinner></GsSpinner>
        ) : (
          <>
            <Button
              w='100%'
              textAlign='center'
              alignItems='center'
              _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
              variant='outline'
              onClick={async (e) => {
                const isValid = validateOrder();
                if (isValid) {
                  setIsConfirmationModalOpen(true);
                }
              }}
            >
              Sačuvaj porudžbinu
            </Button>

            <ConfirmationOrderModal
              isOpen={isConfirmationModalOpen}
              onClose={() => setIsConfirmationModalOpen(false)}
              onSave={async (selectedOption: any) => {
                let isActive: boolean;
                selectedOption === 'sendToProduction'
                  ? (isActive = true)
                  : (isActive = false);
                await onChooseOrderType(selectedOption, isActive);
              }}
            ></ConfirmationOrderModal>
            <MissingResourcesModal
              isOpen={isMissingResourceModalOpen}
              onClose={() => setIsMissingResourceModalOpen(false)}
              resources={missingResourcesState}
              orderCode={orderCode}
            ></MissingResourcesModal>
          </>
        )}
      </>
    </Box>
  );
};

function ProductTable({
  products,
  setProducts,
  addedProducts,
  setAddedProducts,
  setOrderItems,
  orderItems,
  pageNumber,
  setPageNumber,
  fieldRef,
}: ProductTableProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    let searchValue = fieldRef.current.value;
    if (!searchValue || searchValue.trim().length === 0)
      searchValue = 'mostPopular';
    getProductsByFilter(
      searchValue,
      setProducts,
      newPageNumber,
      setIsLoading
    );
    setPageNumber(newPageNumber);
  };  

  return (
    <TableContainer marginTop='30px' maxWidth='1200px'>
      <Table variant='simple'>
        <TableCaption>Proizvodi</TableCaption>
        <Thead>
          <Tr>
            <Th>Šifra proizvoda</Th>
            <Th>Naziv Proizvoda</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {addedProducts?.map((p) => (
            <ProductTableRow
              key={p.productCode}
              product={p}
              addedProducts={addedProducts}
              setProducts={setProducts}
              setAddedProducts={setAddedProducts}
              products={[]}
              isAdded={true}
              setOrderItems={setOrderItems}
              orderItems={orderItems}
              pageNumber={pageNumber}
              fieldRef={fieldRef}
              setPageNumber={setPageNumber}
            />
          ))}
          {products.map((p) => (
            <ProductTableRow
              key={p.productCode}
              product={p}
              setProducts={setProducts}
              addedProducts={addedProducts}
              setAddedProducts={setAddedProducts}
              products={[]}
              isAdded={false}
              setOrderItems={setOrderItems}
              orderItems={orderItems}
              pageNumber={pageNumber}
              fieldRef={fieldRef}
              setPageNumber={setPageNumber}
            />
          ))}
        </Tbody>
      </Table>
      <TableNavigation
        pageNumber={pageNumber}
        pageNumberHandler={pageNumberHandler}
      />
    </TableContainer>
  );
}

function ProductTableRow({
  product,
  addedProducts,
  products,
  setProducts,
  isAdded,
  setAddedProducts,
}: ProductTableProps) {
  return (
    <Tr>
      <Td>{product?.productCode}</Td>
      <Td>{product?.description}</Td>
      <Td>
        <IconButton
          aria-label='theme'
          alignItems='center'
          icon={isAdded ? <DeleteIcon /> : <AddIcon />}
          onClick={(e) => {
            if (isAdded) {
              if (
                window.confirm(
                  'Da li ste sigurni da želite da obrišete proizvod iz tabele?'
                )
              ) {
                let newAdded = addedProducts?.filter(
                  (p) => p.productCode !== product?.productCode
                );
                setAddedProducts(newAdded);
              }
            } else {
              setAddedProducts([...addedProducts, product]);
              let newProducts = products?.filter(
                (p) => p.productCode !== product?.productCode
              );
              setProducts(newProducts);
            }
          }}
        />
      </Td>
    </Tr>
  );
}

function OrderItemModal({
  products,
  setProducts,
  addedProducts,
  setAddedProducts,
  setOrderItems,
  orderItems,
  pageNumber,
  setPageNumber,
}: ProductTableProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [orderId, setOrderId] = useState<string>('');
  const [amount, setAmount] = useState<number>(0.0);
  const [inputProduct, setInputProduct] = useState<string>('');
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const productNameRef = useRef<HTMLInputElement>(null);
  const priorityRef = useRef<HTMLSelectElement>(null);
  const itemEstimatedEndRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      getProductsByFilter(
        'mostPopular',
        setProducts,
        0,
        setIsLoading
      );
      setInputProduct('mostPopular'); 
      setPageNumber(0); 
    }
  }, [isOpen, setProducts, setIsLoading, setPageNumber]);

  return (
    <Flex w='100%'>
      <Button
        onClick={onOpen}
        w='30%'
        mt='30'
        textAlign='center'
        _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
        variant='outline'
      >
        Dodaj stavku
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent minWidth='800px'>
          <ModalHeader>Dodavanje stavke porudžbine</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl mt={4}>
              <FormLabel>Proizvod</FormLabel>
              <InputGroup>
                <InputRightElement
                  children={
                    <IconButton
                      aria-label='theme'
                      icon={<SearchIcon />}
                      onClick={(e) => {
                        let searchValue = inputProduct;
                        if (!searchValue || searchValue.trim().length === 0) 
                          searchValue = 'mostPopular';                    
                        getProductsByFilter(
                          searchValue,
                          setProducts,
                          0,
                          setIsLoading
                        );
                        setPageNumber(0);
                      }}
                    ></IconButton>
                  }
                />
                <Input
                  placeholder='Pretražite proizvod'
                  ref={productNameRef}
                  onChange={(e) => {
                    let searchValue = e.target.value
                    if (!searchValue || searchValue.trim().length === 0) 
                      searchValue = 'mostPopular';                    
                    getProductsByFilter(
                      searchValue,
                      setProducts,
                      0,
                      setIsLoading
                    );
                    setInputProduct(searchValue);
                    setPageNumber(0);
                  }}
                />
              </InputGroup>
            </FormControl>

            {isLoading ? (
              <GsSpinner />
            ) : (
              <ProductTable
                products={products}
                setProducts={setProducts}
                setAddedProducts={setAddedProducts}
                addedProducts={addedProducts}
                setOrderItems={setOrderItems}
                orderItems={orderItems}
                pageNumber={pageNumber}
                fieldRef={productNameRef}
                setPageNumber={setPageNumber}
              ></ProductTable>
            )}
            <FormControl>
              <FormLabel>
                Količina ( {convertMeasureUnit(addedProducts[0]?.measureUnit?.unitName)} )
              </FormLabel>
              <Input
                placeholder='Količina'
                onChange={(e) => {
                  if (!e.target.value || e.target.value.length === 0) return;
                  setAmount(Number(e.target.value));
                }}
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Datum isporuke stavke (opciono)</FormLabel>
              <Input
                placeholder='Datum isporuke stavke'
                type='date'
                ref={itemEstimatedEndRef}
              ></Input>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Izaberite prioritet (opciono)</FormLabel>
              <Select placeholder='Prioritet' ref={priorityRef}>
                <option key={1} value='LOW'>
                  Nizak
                </option>
                <option key={2} value='MEDIUM'>
                  Srednji
                </option>
                <option key={2} value='HIGH'>
                  Visok
                </option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
              variant='outline'
              mr={3}
              onClick={(e) => {
                if (amount === -1 || isNaN(amount)) {
                  alert('Unesite broj za kolicinu');
                  return;
                }
                if (amount < 0) {
                  alert('Kolicina ne sme biti negativan broj');
                  return;
                }

                if (addedProducts.length === 0) {
                  alert('Izaberite proizvod');
                  return;
                }
                // if (priorityRef.current?.value === '') {
                //   alert('Izaberite prioritet');
                //   return;
                // }

                setOrderItems([
                  ...orderItems,
                  {
                    products: addedProducts,
                    amount,
                    estimatedEnd: itemEstimatedEndRef.current?.value,
                    priority: priorityRef.current?.value,
                  } as OrderItem,
                ]);
                setAddedProducts([]);
                setOrderId('');
                setAmount(-1);
                onClose();
              }}
            >
              Dodaj
            </Button>
            <Button
              _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
              variant='outline'
              onClick={onClose}
            >
              Izadji
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default CreateOrderPage;
