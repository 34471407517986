import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { IconButton, Td, Tr } from '@chakra-ui/react';
import {
  convertMeasureUnit,
  convertQuality,
  deleteResource,
  getResources,
  ResourceTableRowProps,
} from '../../resource-api';

const ResourceTableRow = ({
  resource,
  setState,
  setIsLoading,
  handleEditResource,
}: ResourceTableRowProps) => {
  let measureUnit = convertMeasureUnit(resource?.measureUnit?.unitName);
  let quality = convertQuality(resource?.quality?.qualityName);
  let workerMeasureUnit = convertMeasureUnit(
    resource.workerMeasureUnit?.unitName
  );

  return (
    <Tr>
      <Td>{resource?.resourceCode}</Td>
      <Td>{resource?.name}</Td>
      <Td>{measureUnit}</Td>
      <Td>{workerMeasureUnit}</Td>
      <Td>{resource.measureUnitRatio?.toString() ?? ''}</Td>
      <Td>{quality}</Td>
      <Td>{resource?.available}</Td>
      <Td alignItems='center'>
        <IconButton
          aria-label='edit-icon'
          alignItems='center'
          icon={<EditIcon />}
          onClick={(e) => {
            if (resource) handleEditResource(resource);
          }}
        />
      </Td>
      <Td alignItems='center'>
        <IconButton
          aria-label='delete-icon'
          alignItems='center'
          icon={<DeleteIcon />}
          onClick={async (e) => {
            if (
              window.confirm(
                'Da li ste sigurni da želite da obrišete materijal?'
              )
            ) {
              if (resource === undefined) return;
              const sucess = await deleteResource(resource);

              if (sucess === true) {
                alert('Materijal je obrisan');
                getResources(setState, 0, setIsLoading);
              }
            }
          }}
        />
      </Td>
    </Tr>
  );
};

export default ResourceTableRow;
