import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorModeValue,
} from '@chakra-ui/react';
import { ResponsiveContainer } from 'recharts';
import GsHeaderModal from '../../../../components/heading/GsHeaderModal';
import CustomPieChart from '../components/customPieChart'; // Adjust the import path as necessary
import { TaskStatusBySectorDTO } from '../home-apis'; // Adjust the import path as necessary

interface PieChartModalProps {
  isOpen: boolean;
  onClose: () => void;
  taskStatusBySector: TaskStatusBySectorDTO[];
  caption: string;
}

const PieChartModal: React.FC<PieChartModalProps> = ({
  isOpen,
  onClose,
  taskStatusBySector,
  caption,
}) => {
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='max-content'>
        <GsHeaderModal title={caption} />
        <ModalCloseButton />
        <ModalBody>
          <ResponsiveContainer width='100%' height={700}>
            <CustomPieChart
              taskStatusBySector={taskStatusBySector}
              caption='Broj trenutno zadatih operacija po sektoru'
              showFilters={true}
            />
          </ResponsiveContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PieChartModal;
