import { BellIcon, DeleteIcon } from '@chakra-ui/icons';
import { Badge, IconButton, useColorModeValue } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GsIconBtn from '../../../components/button/GsIconBtn';
import { getFalloutNoti } from './fallout-api';

const FalloutBtn: FC = () => {
  const [showNoti, setShowNoti] = useState(false);
  const navigate = useNavigate();
  const fiveMins = 5 * 60 * 1000;
  const btnClr = useColorModeValue('#EBF5FB', '#2D3748');

  useEffect(() => {
    const intervalId = setInterval(() => {
      getFalloutNoti().then((res) => setShowNoti(res));
    }, fiveMins);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <GsIconBtn
      label='fallout-button'
      icon={
        <>
          {showNoti && (
            <>
              <Badge
                colorScheme='yellow'
                variant='solid'
                position='absolute'
                top='-18px'
                right='-10px'
                fontSize='20px'
                borderRadius='12px'
              >
                <BellIcon />
              </Badge>
            </>
          )}
          <DeleteIcon />
        </>
      }
      onClick={() => {
        setShowNoti(false);
        navigate('/fallout');
      }}
    />
  );
};

export default FalloutBtn;
