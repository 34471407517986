import LoginArea from './pages/login/log-in-form';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ManagerRoutes from './pages/manager/manager-routes';
import WorkerRoutes from './pages/worker/worker-routes';
import { useEffect, useState } from 'react';
import { Header } from './components/layout/header';
import { WorkerMenu } from './pages/worker/menu/components/WorkerMenu';
import { User } from './pages/login/login-api';
import { Box, Flex } from '@chakra-ui/react';
import { menuItems } from './pages/manager/menu/models';
import ManagerMenu from './pages/manager/menu/components/ManagerMenu';
import NotificationsPage from './pages/manager/notifications/notificationsPage';

const App = () => {
  const [showMenu, setShowMenu] = useState(true);
  const currentUser: User = JSON.parse(localStorage.getItem('user') as string);
  const currentRole = currentUser?.role;
  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (!currentUser) setShowMenu(false);
  }, []);

  return (
    <>
      <Header setShowMenu={handleShowMenu} showMenu={showMenu} />
      <Flex>
        <Box
          transition='transform 0.5s ease-out'
          transform={showMenu ? 'translateX(0)' : 'translateX(-200px)'}
          position={currentRole === 'ROLE_MANAGER' ? 'fixed' : 'absolute'}
          top='75px'
          bottom='0'
        >
          {currentRole === 'ROLE_MANAGER' && (
            <ManagerMenu menuItems={menuItems} />
          )}
          {currentRole === 'ROLE_WORKER' && <WorkerMenu />}
        </Box>
        <Box
          flex='1'
          ml={showMenu && currentUser ? '200px' : '0'}
          transition='margin-left 0.5s ease-out'
          mt='75px'
          overflowX='auto'
        >
          <Routes>
            <Route path='/' element={<LoginArea />} />
            <Route path='/notifications' element={<NotificationsPage />} />
          </Routes>
          <WorkerRoutes />
          <ManagerRoutes />
        </Box>
      </Flex>
    </>
  );
};

export default App;
