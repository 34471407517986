import { GET, POST } from '../../../api/requests';
import { getPaginationParams } from '../../../components/TabeNavigation/table-navigation';
import { SERVER_URL } from '../../../api/config';

export type TaskInProgressDTO = {
  sectorName: string;
  productCode: string;
  taskStartTime: Date;
  estimatedItemEnd: Date;
  priority: string;
  expectedAmount: number;
  orderCode: string;
  estimatedOrderEnd: Date;
}[];

export const getTaskData = async (
  pageNo: number,
  orderCode: string,
  productCode: string,
  isChecked: boolean,
  sectorNames?: string[]
) => {
  let url = new URL(SERVER_URL + '/api/task/in-progress');
  let params = new URLSearchParams();
  params = getPaginationParams(params, pageNo);

  if (productCode !== '') params.append('productCode', productCode);
  if (orderCode !== '') params.append('orderCode', orderCode);

  if (isChecked) params.append('isChecked', 'true');
  sectorNames?.forEach((name) => {
    params.append('sectorNames', name);
  });  

  url.search = params.toString();
  console.log(url.toString);
  let tasksInProgress = await GET<TaskInProgressDTO>(url.toString());
  console.log(tasksInProgress);
  return tasksInProgress;

};

