import { Box, useChakra } from '@chakra-ui/react';
import { CustomHeading } from '../../../../components/heading/custom-heading';

import ProductForm from '../components/form/ProductForm';

const CreateProductPage = () => {
  const { theme, colorMode } = useChakra();

  return (
    <Box
      mb={1}
      p='20'
      bg={theme.colors.bg.colorMode(colorMode)}
      borderRadius='10'
      borderWidth={2}
    >
      <CustomHeading text='Kreiranje proizvoda' />
      <ProductForm />
    </Box>
  );
};

export default CreateProductPage;
