import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { SaveProductDTO } from '../../product-api';

export interface CopyProductFormProps {
  productToCopy: SaveProductDTO | undefined;
  productCodeRef: React.RefObject<HTMLInputElement>;
  productDescriptionRef: React.RefObject<HTMLInputElement>;
}

const CopyProductForm = ({
  productToCopy,
  productCodeRef,
  productDescriptionRef,
}: CopyProductFormProps) => {
  let productToCopySuffix = ' - Copy';

  return (
    <Flex direction='row' justifyContent='space-between'>
      <FormControl mr='5'>
        <FormLabel>Šifra proizvoda</FormLabel>
        <Input
          defaultValue={
            productToCopy?.product.productCode + productToCopySuffix
          }
          type='text'
          ref={productCodeRef}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Naziv proizvoda</FormLabel>
        <Input
          defaultValue={
            productToCopy?.product.description + productToCopySuffix
          }
          type='text'
          ref={productDescriptionRef}
        />
      </FormControl>
    </Flex>
  );
};

export default CopyProductForm;
