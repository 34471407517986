import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from '@chakra-ui/react';
import { TaskActionDTO, taskOverrideFinish } from '../../../worker/worker-api';
import { TaskFinishModalProps } from '../order-apis';
import { useRef, useState } from 'react';
import GsBtn from '../../../../components/button/GsBtn';
import { User } from '../../../login/login-api';

function TaskFinishModal({
  isOpen,
  onClose,
  orderItemDTO,
  shouldCallStartTask,
  handleGetPhasesProgress,
}: TaskFinishModalProps) {
  const amountRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const loggedInUser: User = JSON.parse(localStorage.getItem('user') as string);

  const handleTaskFinish = async () => {
    if (!isAmountValid()) return;

    let amount: Number = Number(amountRef.current?.value);

    orderItemDTO.completedAmount = amount.valueOf();

    setIsLoading(true);

    //for now, we are not enabling change of resources for the phase here
    let taskActionDTO: TaskActionDTO = {
      itemPhaseDTO: { ...orderItemDTO, workerId: Number(loggedInUser.userId) },
      usedResources: [],
    };

    if (shouldCallStartTask) await taskOverrideFinish(taskActionDTO, 'true');
    else await taskOverrideFinish(taskActionDTO, 'false');

    setIsLoading(false);
    handleGetPhasesProgress(orderItemDTO.orderItemId);
    onClose();
  };

  const isAmountValid = () => {
    if (amountRef.current?.value == '') {
      alert('Unesite kolicinu');
      return false;
    }

    let amount: Number = Number(amountRef.current?.value);

    if (Number.isNaN(amount)) {
      alert('Kolicina materijala mora biti broj');
      return false;
    }
    if (amount < 1) {
      alert('Kolicina materijala mora biti veca od 0');
      return false;
    }
    if (amount > 100000) {
      alert('Kolicina materijala mora biti manja od 100 000');
      return false;
    }
    return true;
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalHeader>Završavanje radnog naloga</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mr='50'>
              <FormLabel>Količina</FormLabel>
              <Input type='text' ref={amountRef} />
              <FormHelperText fontStyle='italic'>
                Napomena: Nije moguce skinuti sa stanja dodante materijale.
                Ukoliko unesete manju kolicinu od ocekivane pojavice se novi
                radni nalog sa preostalom kolicinom. Zavrsavanje radnog naloga
                ce kreirati novi radni nalog za sledece faze izrade proizvoda.
              </FormHelperText>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            {isLoading ? (
              <Spinner marginRight={'5'} />
            ) : (
              <GsBtn onClick={handleTaskFinish} text='Završi' />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TaskFinishModal;
