import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Text,
  Box,
  ModalHeader,
  Input,
  Button,
  useColorModeValue,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  increaseResourceAvailable,
  RequestedMaterialForPhase,
} from '../../warehouse-api';

export interface OrderingResourceModalProps {
  isOpen: boolean;
  onClose: () => void;
  requestItem: RequestedMaterialForPhase;
  refreshTable: any;
}

const OrderingResourceModal = ({
  isOpen,
  onClose,
  requestItem,
  refreshTable,
}: OrderingResourceModalProps) => {
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const [increaseAmount, setIncreaseAmount] = useState<number>(0);
  const [resultValue, setResultValue] = useState<number>(
    +(requestItem.resourcesAvailable + increaseAmount).toFixed(2)
  );

  const onSubmitIncreaseValue = async () => {
    let message = `Da li ste sigurni da želite da povećate stanje materijala ${requestItem.resourceName} za ${increaseAmount} ${requestItem.measureUnit}?`;

    if (window.confirm(message)) {
      const success = await increaseResourceAvailable(
        requestItem.resourceName,
        increaseAmount
      );
      if (success) {
        alert('Uspesno povećano stanje materijala');
        refreshTable();
        onClose();
      }
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='50%'>
        <ModalHeader
          fontFamily='Source Sans Pro, sans-serif'
          fontWeight='bold'
          fontSize='24px'
          lineHeight='32px'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          mb={6}
        >
          {'Naručivanje materijala'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box>
            <Text fontSize='lg' fontWeight='semibold' mb={4}>
              Napomena:
            </Text>
            <Text fontSize='lg'>
              Ukoliko ste naručili materijal i on je dostavljen u magacin,
              možete povećati stanje materijala na magacinu za unetu količinu.
            </Text>
            <Text fontSize='lg'>
              Ukoliko materijal još nije dostavljen, sačekajte sa povećanjem
              stanja do trenutka dostavljanja.
            </Text>
          </Box>
          <Flex flexDir='row' mb={5} mt={10}>
            <Box
              w='30%'
              mr='5%'
              border='1px solid'
              borderColor='gray.300'
              p={2}
              borderRadius='md'
            >
              <Text>Naziv resursa</Text>
              <Box borderTop='1px solid' borderColor='gray.300' mt={2} pt={2}>
                <Text textAlign='center' fontSize='md'>
                  {requestItem.resourceName}
                </Text>
              </Box>
            </Box>
            <Box
              w='30%'
              mr='5%'
              border='1px solid'
              borderColor='gray.300'
              p={2}
              borderRadius='md'
            >
              <Text>Trenutna količina</Text>
              <Box borderTop='1px solid' borderColor='gray.300' mt={2} pt={2}>
                <Text textAlign='center' fontSize='md'>
                  {requestItem.resourcesAvailable.toFixed(2)}{' '}
                  {requestItem.measureUnit}
                </Text>
              </Box>
            </Box>
          </Flex>
          <Flex flexDir='row'>
            <FormControl mt={5} w='40%'>
              <FormLabel fontSize='lg' htmlFor='increaseAmount'>
                Unesi poručenu količinu
              </FormLabel>
              <Input
                type='number'
                id='increaseAmount'
                placeholder={`Unesite količinu u ${requestItem.measureUnit}`}
                value={increaseAmount}
                onChange={(e) => {
                  setIncreaseAmount(+Number(e.target.value).toFixed(2));
                  setResultValue(
                    +(
                      requestItem.resourcesAvailable + Number(e.target.value)
                    ).toFixed(2)
                  );
                }}
                mb={4}
              />
            </FormControl>
            <FormControl mt={5} w='40%' ml='15%'>
              <FormLabel fontSize='lg' htmlFor='resultAmount' opacity={0.7}>
                Količina nakon povećanja
              </FormLabel>
              <Input
                type='text'
                id='resultAmount'
                value={resultValue}
                readOnly
                mb={4}
                disabled
              />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onSubmitIncreaseValue}
            w='20%'
            mt={2}
            textAlign='center'
            _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
            variant='outline'
          >
            Povećaj stanje
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderingResourceModal;
