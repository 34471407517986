import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Text,
} from '@chakra-ui/react';
import GsIconBtn from '../../../../../components/button/GsIconBtn';
import { AddIcon } from '@chakra-ui/icons';
import FileTable from './table/FileTable';
import GsBtn from '../../../../../components/button/GsBtn';
import { useContext, useEffect, useState } from 'react';
import { ReadOnlyContext } from '../../contexts/ReadOnlyContext';
import { getExistingFiles, SaveProductDTO } from '../../product-api';
import GsSpinner from '../../../../../components/spinner/gsSpinner';

export interface UploadFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  addFile: () => void;
  fileList: File[];
  setFiles: any;
  productToEdit?: SaveProductDTO;
}

const UploadFileModal = ({
  isOpen,
  onClose,
  addFile,
  fileList,
  setFiles,
  productToEdit,
}: UploadFileModalProps) => {
  const { isReadOnly } = useContext(ReadOnlyContext);
  const [existingFilesNames, setExistingFilesNames] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen && productToEdit) {
      setIsLoading(true);
      getExistingFiles(productToEdit.product.id as number, 0).then(
        (existingFilenames) => {
          setExistingFilesNames(existingFilenames as string[]);
          setIsLoading(false);
        }
      );
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='900'>
        <Flex alignItems='center'>
          <ModalHeader>
            <Text whiteSpace='nowrap'>Dodaj crtež</Text>
          </ModalHeader>
          <GsIconBtn
            label='add drawing'
            onClick={addFile}
            icon={<AddIcon />}
            disabled={isReadOnly}
          />
        </Flex>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {isLoading ? (
            <GsSpinner />
          ) : (
            <FileTable
              files={fileList}
              setFiles={setFiles}
              existingFiles={existingFilesNames}
              setExistingFiles={setExistingFilesNames}
              productToEdit={productToEdit!}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <GsBtn text='Sačuvaj' onClick={onClose} disabled={isReadOnly} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadFileModal;
