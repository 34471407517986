import { Tr, Td, IconButton } from '@chakra-ui/react';
import {
  CustomTaskTableProps,
  CustomTaskTableRowProps,
  deleteCustomTask,
} from './customTaskApi';
import { DeleteIcon } from '@chakra-ui/icons';
import { FaWindows } from 'react-icons/fa';
import { getTaskColor } from '../../../utilility/util';

const CustomTaskTableRow = ({
  customTask,
  getTableData,
}: CustomTaskTableRowProps) => {
  let empName = '';

  const backgroundColor = getTaskColor(customTask.status);

  return (
    <Tr backgroundColor={backgroundColor}>
      <Td>{customTask.product_code}</Td>
      <Td>{customTask.description}</Td>
      <Td>{customTask.sector_name}</Td>
      <Td>
        <IconButton
          aria-label='delete-icon'
          alignItems='center'
          marginLeft='25'
          icon={<DeleteIcon />}
          onClick={async (e) => {
            if (
              window.confirm(
                'Da li ste sigurni da želite da obrišete radni nalog?'
              )
            ) {
              const success = await deleteCustomTask(customTask);
              if (success) {
                alert('Radni nalog je obrisan');
                getTableData();
              } else {
                alert('Greska pri brisanju radnog naloga');
              }
            }
          }}
        />
      </Td>
    </Tr>
  );
};

export default CustomTaskTableRow;
