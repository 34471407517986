import { GET, POST } from '../../../api/requests';
import { SERVER_URL } from '../../../api/config';


export type MeasurementUnit = {
    id?: number;
    timeAdded?: Date;
    unitName: string;
  };


export const getMeasurementUnits = async () => {
    try {
        let url = new URL(SERVER_URL + '/api/measurement-units');
        
        const measurementUnits = await GET<MeasurementUnit[]>(url.toString());
        return measurementUnits;
    } catch (error) {
        console.log('Error while fetching measurement units.');
        console.error(error);
        throw error; 
    }
  };

export const createMeasurementUnit = async (measurementUnit: MeasurementUnit, setIsLoading: any) => {
    try {
      setIsLoading(true);
      const savedMeasurementUnit = await POST<MeasurementUnit>(
        SERVER_URL + '/api/measurement-unit/save',
        measurementUnit
      );
      setIsLoading(false);
      return savedMeasurementUnit;
    } catch (error) {
      setIsLoading(false);
      console.log('Error while creating measurement unit.');
      console.error(error);
      return false;
    }
  };  

  export const getMeasurementUnitByUnitName = async (unitName: string) => {
    try {
        let url = new URL(SERVER_URL + '/api/measurement-unit/name');
        url.searchParams.append('unitName', unitName);
        
        const measurementUnit = await GET<MeasurementUnit>(url.toString());
        return measurementUnit;
    } catch (error) {
        console.log(`Error while fetching measurement unit with name ${unitName}.`);
        console.error(error);
        throw error;
    }
  };  

  export const createMeasurementUnitObject = (unitName: string | undefined): MeasurementUnit => {
    return {
        id: 0, 
        timeAdded: new Date(),
        unitName: unitName ?? '',
    };
  };  