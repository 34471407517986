import React, { useState } from 'react';
import {
  ModalBody,
  Button,
  ModalFooter,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  useColorModeValue,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
} from '@chakra-ui/react';
import {
  CompletedTasksReport,
  CompletedTasksReportTableModalProps,
  CustomCompletedTasksReportTableRowProps,
} from '../../report-api';
import { exportToExcel } from '../../../../../functions/export';
import { TableNavigation } from '../../../../../components/TabeNavigation/TableNavigation';

function CompletedTasksReportTableModal({
  isModalOpen,
  setIsModalOpen,
  reportName,
  customCompletedTasksReports,
  setCustomCompletedTasksReports,
  getCustomCompletedTasksReport,
  pageNo,
  setProductPageNo,
  startDate,
  endDate,
  setIsLoading,
}: CompletedTasksReportTableModalProps) {
  const closeModal = () => {
    setIsModalOpen(false);
    setPageNumber(0);
  };

  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  const [
    customCompletedTasksReportsExport,
    setCustomCompletedTasksReportsExport,
  ] = useState<CompletedTasksReport[]>([]);

  const tableCaptionGenerator = (customNoteReports: any) => {
    if (customNoteReports === 0) {
      return 'Ne postoji nijedna stavka izvestaja';
    } else {
      return '';
    }
  };

  const handleExportButtonClick = async () => {
    try {
      const exportData = await getCustomCompletedTasksReport(
        startDate,
        endDate,
        setCustomCompletedTasksReportsExport,
        undefined,
        0,
        'true'
      );

      const headers = [
        'Naziv sektora',
        'Sifra porudžbine',
        'Sifra proizvoda',
        'Naziv proizvoda',
        'Uradjeno',
        'Potrebno',
        'Napomena',
        'Pocetak rada',
        'Kraj rada',
        'Isporuka',
      ];

      const data = exportData.map((item: CompletedTasksReport) => [
        item.sector_name,
        item.order_code,
        item.product_code,
        item.product_name,
        item.completed_amount,
        item.expected_amount,
        item.note,
        item.start_time
          ? new Date(item.start_time)
              .toLocaleString('en-GB', {
                year: 'numeric',
                day: '2-digit',
                month: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })
              .replace(/\//g, '-')
          : '',
        item.end_time
          ? new Date(item.end_time)
              .toLocaleString('en-GB', {
                year: 'numeric',
                day: '2-digit',
                month: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })
              .replace(/\//g, '-')
          : '',
        item.estimate_time
          ? new Date(item.estimate_time)
              .toLocaleString('en-GB', {
                year: 'numeric',
                day: '2-digit',
                month: '2-digit',
              })
              .replace(/\//g, '-')
          : '',
      ]);

      exportToExcel(headers, data, reportName);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const [pageNumber, setPageNumber] = useState<number>(0);

  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    getCustomCompletedTasksReport(
      startDate,
      endDate,
      setCustomCompletedTasksReports,
      setIsLoading,
      newPageNumber
    );
    setPageNumber(newPageNumber);
  };

  const tableCaption = tableCaptionGenerator(customCompletedTasksReports);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <ModalOverlay />
      <ModalContent minWidth='90%'>
        <ModalHeader
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '20px',
            marginRight: '40px',
          }}
        >
          <div>{reportName}</div>
          <div>
            {startDate} - {endDate}
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer
            marginTop='10px'
            maxWidth='100%'
          >
            <Table variant='simple'>
              <TableCaption> {tableCaption} </TableCaption>
              <Thead>
                <Tr>
                  <Th>Naziv sektora</Th>
                  <Th>Sifra porudžbine</Th>
                  <Th>Sifra proizvoda</Th>
                  <Th>Uradjeno</Th>
                  <Th>Potrebno</Th>
                  <Th>Napomena</Th>
                  <Th>Pocetak rada</Th>
                  <Th>Kraj rada</Th>
                  <Th>Isporuka</Th>
                </Tr>
              </Thead>
              <Tbody>
                {customCompletedTasksReports?.map((c) => (
                  <CustomCompletedTasksReportTableRow customCopletedTask={c} />
                ))}
              </Tbody>
            </Table>
            <TableNavigation
              pageNumber={pageNumber}
              pageNumberHandler={pageNumberHandler}
            />
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button
            _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
            variant='outline'
            w='15%'
            onClick={handleExportButtonClick}
          >
            {' '}
            Exportuj
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function CustomCompletedTasksReportTableRow({
  customCopletedTask,
}: CustomCompletedTasksReportTableRowProps) {
  let formattedStartDate;
  if (customCopletedTask.start_time) {
    const start_date = new Date(customCopletedTask.start_time);
    formattedStartDate = start_date
      .toLocaleString('en-GB', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/\//g, '-');
  } else {
    formattedStartDate = '';
  }

  let formattedEndDate;
  if (customCopletedTask.end_time) {
    const end_date = new Date(customCopletedTask.end_time);
    formattedEndDate = end_date
      .toLocaleString('en-GB', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/\//g, '-');
  } else {
    formattedEndDate = '';
  }

  let formattedEstimateDate;
  if (customCopletedTask.estimate_time) {
    const estimate_date = new Date(customCopletedTask.estimate_time);
    formattedEstimateDate = estimate_date
      .toLocaleString('en-GB', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
      })
      .replace(/\//g, '-');
  } else {
    formattedEstimateDate = '';
  }

  return (
    <Tr>
      <Td>{customCopletedTask?.sector_name}</Td>
      <Td>{customCopletedTask?.order_code}</Td>
      <Td>{customCopletedTask?.product_code}</Td>
      <Td>{customCopletedTask?.completed_amount}</Td>
      <Td>{customCopletedTask?.expected_amount}</Td>
      <Td>{customCopletedTask?.note}</Td>
      <Td>{formattedStartDate}</Td>
      <Td>{formattedEndDate}</Td>
      <Td>{formattedEstimateDate}</Td>
    </Tr>
  );
}

export default CompletedTasksReportTableModal;
