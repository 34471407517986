import {
  FaTachometerAlt,
  FaHome,
  FaChartLine,
  FaPencilAlt,
  FaList,
  FaListAlt,
  FaTasks,
  FaBox,
  FaCube,
  FaHardHat,
  FaUser,
  FaHourglassHalf,
  FaWarehouse,
  FaClipboardList,
} from 'react-icons/fa';

export type SubItemType = {
  name: string;
  route: string;
  icon?: typeof FaTachometerAlt;
};

export type MenuItemType = {
  id: number;
  name: string;
  subItems: SubItemType[];
  icon?: typeof FaTachometerAlt;
};

export const menuItems: MenuItemType[] = [
  {
    id: 1,
    name: 'Kontrolna tabla',
    subItems: [
      { name: 'Početna', route: '/home', icon: FaHome },
      { name: 'Izveštaj', route: '/reports', icon: FaChartLine },
      { name: 'U izradi', route: '/task-in-progress', icon: FaHourglassHalf },
    ],
    icon: FaTachometerAlt,
  },
  {
    id: 2,
    name: 'Porudžbina',
    subItems: [
      { name: 'Kreiraj', route: '/create_order', icon: FaPencilAlt },
      { name: 'Pregled', route: '/edit_order', icon: FaList },
    ],
    icon: FaListAlt,
  },
  {
    id: 3,
    name: 'Radni nalog',
    subItems: [
      { name: 'Kreiraj', route: '/custom_task', icon: FaPencilAlt },
      { name: 'Pregled', route: '/overview_custom_task', icon: FaList },
    ],
    icon: FaTasks,
  },
  {
    id: 4,
    name: 'Proizvod',
    subItems: [
      { name: 'Kreiraj', route: '/create_product', icon: FaPencilAlt },
      { name: 'Pregled', route: '/edit_product', icon: FaList },
    ],
    icon: FaBox,
  },
  {
    id: 5,
    name: 'Materijal',
    subItems: [
      { name: 'Kreiraj', route: '/create_resource', icon: FaPencilAlt },
      { name: 'Pregled', route: '/edit_resource', icon: FaList },
    ],
    icon: FaCube,
  },
  {
    id: 6,
    name: 'Sektor',
    subItems: [
      { name: 'Kreiraj', route: '/create_sector', icon: FaPencilAlt },
      { name: 'Pregled', route: '/edit_sector', icon: FaList },
    ],
    icon: FaHardHat,
  },
  {
    id: 7,
    name: 'Korisnik',
    subItems: [
      { name: 'Kreiraj', route: '/create_user', icon: FaPencilAlt },
      { name: 'Pregled', route: '/edit_user', icon: FaList },
    ],
    icon: FaUser,
  },
  {
    id: 8,
    name: 'Magacin',
    subItems: [
      { name: 'Zahtevi', route: '/material_requests', icon: FaClipboardList },
    ],
    icon: FaWarehouse,
  },
];
