import { CopyIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Td, Tr } from '@chakra-ui/react';
import { FC } from 'react';
import GsIconBtn from '../../../../../components/button/GsIconBtn';
import { Product } from '../../product-api';

type ProductTableRowProps = {
  product: Product;
  onEditProduct: (p: Product) => void;
  onDeleteProduct: (p: Product) => void;
  onCopyProduct: (p: Product) => void;
};

const ProductTableRow: FC<ProductTableRowProps> = ({
  product,
  onEditProduct,
  onDeleteProduct,
  onCopyProduct,
}) => {
  return (
    <Tr borderBottom='1px'>
      <Td textAlign='center'>{product.productCode}</Td>
      <Td textAlign='center'>{product.description}</Td>
      <Td textAlign='center'>
        {product.measureUnit?.unitName === 'PIECE' ? 'KOM' : product.measureUnit?.unitName}
      </Td>
      <Td textAlign='center'>
        <GsIconBtn
          label='edit product'
          icon={<EditIcon />}
          onClick={() => onEditProduct(product)}
        />
      </Td>
      <Td textAlign='center'>
        <GsIconBtn
          label='delete product'
          icon={<DeleteIcon />}
          onClick={() => onDeleteProduct(product)}
        />
      </Td>
      <Td textAlign='center'>
        <GsIconBtn
          label='copy product'
          icon={<CopyIcon />}
          onClick={() => onCopyProduct(product)}
        />
      </Td>
    </Tr>
  );
};

export default ProductTableRow;
