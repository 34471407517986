import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useChakra,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IoMdDocument } from 'react-icons/io';
import { IoIosConstruct } from 'react-icons/io';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { FaCubes } from 'react-icons/fa';
import { CustomHeading } from '../../../components/heading/custom-heading';
import AppLayout from '../../../components/layout/app-layout';
import ReportBox from './components/ReportBox';
import CompletedTasksReportModal from './modals/completedTasksReport/CompletedTasksReportModal';
import NoteReportModal from './modals/noteReport/NoteReportModal';
import SectorWorkTimeReportModal from './modals/sectorWorkTimeReport/SectorWorkTimeReportModal';
import ConsumedMaterialReportModal from './modals/consumedMaterialReport/ConsumedMaterialModal';

const ReportPage = () => {
  const { theme, colorMode } = useChakra();

  useEffect(() => {});

  const [selectedReportType, setSelectedReportType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderModalHeader = () => {
    switch (selectedReportType) {
      case 'Izveštaj Napomene':
        return 'Izveštaj Napomene';
      case 'Izveštaj Sektori':
        return 'Izveštaj Sektori';
      case 'Izveštaj Odradjeni Taskovi':
        return 'Izveštaj Odradjeni Taskovi';
      case 'Izveštaj Utrošak Materijala':
        return 'Izveštaj Utrošak Materijala';
      // Add more cases for other modal headers
      default:
        return 'Izaberite izvestaj';
    }
  };

  const renderModalWidth = () => {
    switch (selectedReportType) {
      case 'Izveštaj Napomene':
        return '25%';
      case 'Izveštaj Sektori':
        return '25%';
      case 'Izveštaj Odradjeni Taskovi':
        return '25%';
      case 'Izveštaj Utrošak Materijala':
        return '45%';
      // Add more cases for other modal headers
      default:
        return 'Izaberite izvestaj';
    }
  };

  const renderModalContent = () => {
    switch (selectedReportType) {
      case 'Izveštaj Napomene':
        return (
          <NoteReportModal
            onClose={closeModal}
            setIsModalOpen={setIsModalOpen}
          />
        );
      case 'Izveštaj Sektori':
        return (
          <SectorWorkTimeReportModal
            onClose={closeModal}
            setIsModalOpen={setIsModalOpen}
          />
        );
      case 'Izveštaj Odradjeni Taskovi':
        return (
          <CompletedTasksReportModal
            onClose={closeModal}
            setIsModalOpen={setIsModalOpen}
          />
        );
      case 'Izveštaj Utrošak Materijala':
        return (
          <ConsumedMaterialReportModal
            onClose={closeModal}
            setIsModalOpen={setIsModalOpen}
          />
        );
      // Add more cases for other modal types
      default:
        return null;
    }
  };

  const noteBoxClick = () => {
    setIsModalOpen(true);
    setSelectedReportType('Izveštaj Napomene');
  };

  const sectorBoxClick = () => {
    setIsModalOpen(true);
    setSelectedReportType('Izveštaj Sektori');
  };

  const completedTasksBoxClick = () => {
    setIsModalOpen(true);
    setSelectedReportType('Izveštaj Odradjeni Taskovi');
  };

  const consumedMaterialBoxClick = () => {
    setIsModalOpen(true);
    setSelectedReportType('Izveštaj Utrošak Materijala');
  };

  return (
    <AppLayout>
      <Flex
        flexDir='column'
        w='100%'
      >
        <Box
          flexDirection='row'
          width='full'
          height='100%'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
          mb={1}
          p='20'
        >
          <CustomHeading text='Tipovi izveštaja'></CustomHeading>
          <Flex flexDir='row'>
            <ReportBox
              icon={IoMdDocument}
              text='Izveštaj Napomene'
              description='Izveštaj za praćenje napomena od strane radnika'
              onButtonClick={noteBoxClick}
              ml={0}
            />
            <ReportBox
              icon={IoIosConstruct}
              text='Izveštaj Sektori'
              description='Izveštaj za praćenje radnih sati po sektoru'
              onButtonClick={sectorBoxClick}
              ml={10}
            />
            <ReportBox
              icon={IoMdCheckmarkCircleOutline}
              text='Izveštaj Odradjeni Taskovi'
              description='Izveštaj završenih operacija od strane radnika'
              onButtonClick={completedTasksBoxClick}
              ml={10}
            />
            <ReportBox
              icon={FaCubes}
              text='Izveštaj Utrošak Materijala'
              description='Izveštaj za pregled potrebne količine materijala po porudžbenici'
              onButtonClick={consumedMaterialBoxClick}
              ml={10}
            />
          </Flex>
        </Box>
      </Flex>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <ModalOverlay />
        <ModalContent minWidth={renderModalWidth()}>
          <ModalHeader>{renderModalHeader()}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{renderModalContent()}</ModalBody>
        </ModalContent>
      </Modal>
    </AppLayout>
  );
};

export default ReportPage;
