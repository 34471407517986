import {
  Box,
  Button,
  Flex,
  Input,
  useChakra,
  useDisclosure,
  useColorModeValue,
  Select,
  HStack,
} from '@chakra-ui/react';
import { FormControl, FormLabel, FormHelperText } from '@chakra-ui/react';
import { useRef, useEffect, useState } from 'react';
import AppLayout from '../../../../components/layout/app-layout';
import { createResource, isResourceFormValid } from '../resource-api';
import { CustomHeading } from '../../../../components/heading/custom-heading';
import GsSpinner from '../../../../components/spinner/gsSpinner';
import AddMeasurementUnitModal from '../../measurementUnit/components/addMeasurementUnit';
import {
  getMeasurementUnits,
  MeasurementUnit,
  createMeasurementUnitObject,
} from '../../measurementUnit/measurementUnit-api';
import {
  createQualityUnitObject,
  getQualityUnits,
  QualityUnit,
} from '../../measurementUnit/qualityUnit-api';
import AddQualityUnitModal from '../../measurementUnit/components/addQualityUnit';

const CreateResourcePage = () => {
  const { theme, colorMode } = useChakra();

  return (
    <AppLayout>
      <Flex flexDir='column' w='100%'>
        <Box
          flexDirection='row'
          width='full'
          height='100%'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
          mb={1}
          p='20'
        >
          <CustomHeading text='Kreiranje materijala' />
          <CreateResourceForm />
        </Box>
      </Flex>
    </AppLayout>
  );
};

const CreateResourceForm = () => {
  const [resourceName, setResourceName] = useState<string>('');
  const [ammountAvailable, setAmmountAvailabe] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ratioMessage, setRatioMessage] = useState<string>('');
  const [measurementUnits, setMeasurementUnits] = useState<MeasurementUnit[]>(
    []
  );
  const [qualityUnits, setQualityUnits] = useState<QualityUnit[]>([]);
  const resourceCodeRef = useRef<HTMLInputElement>(null);
  const resourceNameRef = useRef<HTMLInputElement>(null);
  const ammountAvailableRef = useRef<HTMLInputElement>(null);
  const measureUnitRef = useRef<HTMLSelectElement>(null);
  const workerMeasureUnitRef = useRef<HTMLSelectElement>(null);
  const qualityRef = useRef<HTMLSelectElement>(null);
  const ratioRef = useRef<HTMLInputElement>(null);
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  const {
    isOpen: isOpenAddMeasurementUnitModal,
    onOpen: onOpenAddMeasurementUnitModal,
    onClose: onCloseAddMeasurementUnitModal,
  } = useDisclosure();

  const {
    isOpen: isOpenAddQualityUnitModal,
    onOpen: onOpenAddQualityUnitModal,
    onClose: onCloseAddQualityUnitModal,
  } = useDisclosure();

  const loadMeasurementUnits = async () => {
    const units = await getMeasurementUnits();
    setMeasurementUnits(units);
  };

  const loadQualityUnits = async () => {
    const units = await getQualityUnits();
    setQualityUnits(units);
  };

  const handleRatioChange = () => {
    let output =
      measureUnitRef.current?.value +
      ' = ' +
      ratioRef.current?.value +
      ' ' +
      workerMeasureUnitRef.current?.value;

    setRatioMessage(output);
  };

  useEffect(() => {
    loadMeasurementUnits();
    loadQualityUnits();
  }, []);

  return (
    <Box px='5'>
      <Flex flexDirection='row' mb='5' align={'center'}>
        <HStack spacing='24px' width='100%'>
          <FormControl>
            <FormLabel>Sifra materijala</FormLabel>
            <Input type='text' ref={resourceCodeRef} />
            <FormHelperText fontStyle='italic'>
              Unesite sifru novog materijala.
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>Naziv materijala</FormLabel>
            <Input
              type='text'
              ref={resourceNameRef}
              onChange={(e) => {
                if (!e.target.value || e.target.value.length === 0) return;
                setResourceName(e.target.value);
              }}
            />
            <FormHelperText fontStyle='italic'>
              Unesite naziv novog materijala.
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>Količina materijala</FormLabel>
            <Input
              type='text'
              ref={ammountAvailableRef}
              onChange={(e) => {
                if (!e.target.value || e.target.value.length === 0) return;
                setAmmountAvailabe(Number(e.target.value));
              }}
            />
            <FormHelperText fontStyle='italic'>
              Unesite količinu materijala.
            </FormHelperText>
          </FormControl>
        </HStack>
      </Flex>
      <Flex flexDirection='row' mb='50' align={'center'}>
        <HStack spacing='24px' width='100%' marginBottom={10}>
          <FormControl>
            <Box display='flex' alignItems='center'>
              <FormLabel mt={2} mb={2} mr={2}>
                {' '}
                {/* Added marginRight (mr) to push the button closer to the label */}
                Merna jedinica
              </FormLabel>
              <Button
                size='xs'
                rounded='md'
                textAlign='center'
                _hover={{ textDecor: 'none', bgColor: 'logoutBtnClr' }}
                variant='outline'
                onClick={onOpenAddMeasurementUnitModal}
              >
                +
              </Button>
            </Box>
            <Select placeholder='Izaberite mernu jedinicu' ref={measureUnitRef}>
              {measurementUnits.map((unit, index) => (
                <option key={index} value={unit.unitName}>
                  {unit.unitName}
                </option>
              ))}
            </Select>
            <FormHelperText fontStyle='italic'>
              Izaberite mernu jedinicu.
            </FormHelperText>
          </FormControl>
          <FormControl>
            <Box display='flex' alignItems='center'>
              <FormLabel mt={2} mb={2} mr={2}>
                {' '}
                {/* Added marginRight (mr) to push the button closer to the label */}
                Merna jedinica za radnika
              </FormLabel>
              <Button
                size='xs'
                rounded='md'
                textAlign='center'
                _hover={{ textDecor: 'none', bgColor: 'logoutBtnClr' }}
                variant='outline'
                onClick={onOpenAddMeasurementUnitModal}
              >
                +
              </Button>
            </Box>
            <Select
              placeholder='Izaberite mernu jedinicu'
              ref={workerMeasureUnitRef}
            >
              {measurementUnits.map((unit, index) => (
                <option key={index} value={unit.unitName}>
                  {unit.unitName}
                </option>
              ))}
            </Select>
            <FormHelperText fontStyle='italic'>
              Izaberite mernu jedinicu.
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel mt={2} mb={2} mr={2}>
              Koeficijent {ratioMessage}
            </FormLabel>
            <Input
              placeholder='Unesi koeficijent'
              type='text'
              ref={ratioRef}
              onChange={handleRatioChange}
            />
          </FormControl>
        </HStack>
      </Flex>
      <Flex mb='50'>
        <FormControl>
          <Box display='flex' alignItems='center'>
            <FormLabel mt={2} mb={2} mr={2}>
              {' '}
              {/* Added marginRight (mr) to push the button closer to the label */}
              Kvalitet
            </FormLabel>
            <Button
              size='xs'
              rounded='md'
              textAlign='center'
              _hover={{ textDecor: 'none', bgColor: 'logoutBtnClr' }}
              variant='outline'
              onClick={onOpenAddQualityUnitModal}
            >
              +
            </Button>
          </Box>
          <Select placeholder='Izaberite kvalitet' ref={qualityRef}>
            {qualityUnits.map((unit, index) => (
              <option key={index} value={unit.qualityName}>
                {unit.qualityName}
              </option>
            ))}
          </Select>
          <FormHelperText fontStyle='italic'>
            Izaberite kvalitet.
          </FormHelperText>
        </FormControl>
      </Flex>
      <AddMeasurementUnitModal
        onOpen={onOpenAddMeasurementUnitModal}
        onClose={onCloseAddMeasurementUnitModal}
        isOpen={isOpenAddMeasurementUnitModal}
        setMeasurementUnits={setMeasurementUnits}
        measurementUnits={measurementUnits}
      />
      <AddQualityUnitModal
        isOpen={isOpenAddQualityUnitModal}
        onClose={onCloseAddQualityUnitModal}
        onOpen={onOpenAddQualityUnitModal}
        qualityUnits={qualityUnits}
        setQualityUnits={setQualityUnits}
      ></AddQualityUnitModal>

      {isLoading ? (
        <GsSpinner></GsSpinner>
      ) : (
        <Button
          ml='85%'
          w='15%'
          mb='50'
          textAlign='center'
          _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
          variant='outline'
          onClick={async (e) => {
            const measurementUnit = createMeasurementUnitObject(
              measureUnitRef.current?.value
            );
            const workerMeasureUnit = createMeasurementUnitObject(
              workerMeasureUnitRef.current?.value
            );
            const qualityUnit = createQualityUnitObject(
              qualityRef.current?.value
            );

            if (
              !isResourceFormValid(
                ammountAvailableRef.current?.value,
                resourceNameRef.current?.value,
                measurementUnit,
                workerMeasureUnit,
                resourceCodeRef.current?.value,
                qualityUnit,
                ratioRef.current?.value,
                true
              )
            ) {
              return;
            }
            if (
              measureUnitRef.current?.value === undefined ||
              qualityRef.current?.value === undefined ||
              resourceCodeRef.current?.value === undefined ||
              workerMeasureUnitRef.current?.value === undefined ||
              ratioRef.current?.value === undefined
            ) {
              return;
            }

            let ratio: number = Number(ratioRef.current?.value);
            const success = await createResource(
              {
                name: resourceName,
                available: ammountAvailable,
                measureUnit: measurementUnit,
                workerMeasureUnit: workerMeasureUnit,
                quality: qualityUnit,
                resourceCode: resourceCodeRef.current?.value,
                measureUnitRatio: ratio,
              },
              setIsLoading
            );

            setIsLoading(false);

            if (success === true) {
              setAmmountAvailabe(-1);
              setResourceName('');
              setRatioMessage('');

              if (resourceNameRef.current != null) {
                resourceNameRef.current.value = '';
              }
              if (ammountAvailableRef.current != null) {
                ammountAvailableRef.current.value = '';
              }
              if (qualityRef.current != null) {
                qualityRef.current.value = '';
              }
              if (resourceCodeRef.current != null) {
                resourceCodeRef.current.value = '';
              }
              if (measureUnitRef.current != null) {
                measureUnitRef.current.value = '';
              }
              if (workerMeasureUnitRef.current != null) {
                workerMeasureUnitRef.current.value = '';
              }
              if (ratioRef.current.value) {
                ratioRef.current.value = '';
              }
              alert('materijal sačuvan');
            }
          }}
        >
          Sačuvaj materijal
        </Button>
      )}
    </Box>
  );
};

export default CreateResourcePage;
