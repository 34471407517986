import { CheckIcon } from '@chakra-ui/icons';
import {
  Tr,
  Td,
  FormControl,
  Input,
  FormErrorMessage,
  Box,
  Flex,
  HStack,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import GsIconBtn from '../../../../../../components/button/GsIconBtn';
import {
  approveReservedResource,
  convertDateTime,
  RequestedMaterialForPhase,
} from '../../../warehouse-api';

const UnusedMaterialsTableRow: FC<{
  unusedItem: RequestedMaterialForPhase;
  refreshTable: any;
}> = ({ unusedItem, refreshTable }) => {
  const [isAmountInvalid, setIsAmountInvalid] = useState(false);
  const [approvedAmount, setApprovedAmount] = useState<number>(
    +Math.abs(unusedItem.requestedAmount).toFixed(2)
  );

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = parseFloat(e.target.value);
    const updatedAmount = isNaN(newAmount) ? 0 : newAmount;
    setApprovedAmount(updatedAmount);
  };

  const handleApprove = async () => {
    if (approvedAmount < 0) {
      alert('Količina za odobravanje mora biti veća od 0');
      return;
    }

    if (approvedAmount > Math.abs(unusedItem.requestedAmount)) {
      alert(
        'Količina za odobravanje ne može biti veća od neiskorišćene količine'
      );
      return;
    }
    const success = await approveReservedResource(
      unusedItem.id,
      approvedAmount
    );
    if (success) {
      let message =
        'Uspesno odobren neiskoriscen materijal: ' + unusedItem.resourceName;
      alert(message);
      setApprovedAmount(
        unusedItem.requestedAmount - unusedItem.approvedAmount - approvedAmount
      );
      refreshTable();
    }
  };

  return (
    <Tr borderBottom='2px'>
      <Td textAlign='center' w='20%'>
        {unusedItem.resourceName}
      </Td>
      <Td textAlign='center' w='15%'>
        {convertDateTime(unusedItem.requestedAt)}
      </Td>
      <Td textAlign='center' w='15%'>
        {unusedItem.nameUser}
      </Td>
      <Td textAlign='center' w='15%' fontSize='xl' fontWeight='bold'>
        {Math.abs(unusedItem.requestedAmount).toFixed(2)}{' '}
        {unusedItem.measureUnit}
      </Td>
      <Td textAlign='center' w='15%' fontSize='xl' fontWeight='bold'>
        {Math.abs(
          unusedItem.requestedAmount * unusedItem.measureUnitRatio
        ).toFixed(2)}{' '}
        {unusedItem.workerMeasureUnit}
      </Td>
      <Td textAlign='center' w='20%'>
        <Flex flexDir='row'>
          <FormControl mt={4} isInvalid={isAmountInvalid}>
            <HStack spacing={4} align='center'>
              <Input
                placeholder='Količina'
                textAlign={'center'}
                disabled={false}
                borderWidth='2px'
                type='number'
                value={approvedAmount}
                onChange={handleAmountChange}
              />
              <Box>{unusedItem.measureUnit}</Box>
            </HStack>
            <FormErrorMessage>{'Unesite ispravan broj'}</FormErrorMessage>
          </FormControl>
          <Box mt={5} ml={5}>
            <GsIconBtn
              icon={<CheckIcon />}
              label='approve'
              onClick={handleApprove}
            />
          </Box>
        </Flex>
      </Td>
    </Tr>
  );
};

export default UnusedMaterialsTableRow;
