import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  Flex,
  FormLabel,
  Select,
  Textarea,
} from '@chakra-ui/react';
import { useState, useEffect, FC, useContext } from 'react';
import GsBtn from '../../../../../../components/button/GsBtn';
import { getSectors, Sector, User } from '../../../../sector/sector-apis';
import GsHeaderModal from '../../../../../../components/heading/GsHeaderModal';
import { RequiredResourceContext } from '../../../contexts/RequiredResourceContext';
import { NodeContext, NodeData } from '../../../contexts/NodeContext';
import { ReadOnlyContext } from '../../../contexts/ReadOnlyContext';
import PhaseResource from './resource/PhaseResource';
import {
  ALERT_RED,
  useGsDialog,
} from '../../../../../../contexts/GsConfirmDialogContext';

type ProductPhaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  nodeId?: number;
};
const ProductPhaseModal: FC<ProductPhaseModalProps> = ({
  isOpen,
  onClose,
  nodeId,
}) => {
  const [sectors, setSectors] = useState<Sector[]>();
  const [selectedSector, setSelectedSector] = useState<Sector | undefined>();
  const [currentDescription, setCurrentDescription] = useState('');
  const { requiredResources, setRequiredResources } = useContext(
    RequiredResourceContext
  );
  const { handleOnSaveNode, nodes } = useContext(NodeContext);
  const { isReadOnly } = useContext(ReadOnlyContext);
  const { alertDialog } = useGsDialog();

  useEffect(() => {
    getSectors(setSectors);
  }, []);

  useEffect(() => {
    if (nodeId) {
      const nodeToEdit = nodes.find((node) => node.id === nodeId.toString());
      setSelectedSector(
        nodeToEdit
          ? {
              id: nodeToEdit.data.sectorId,
              name: nodeToEdit.data.sector,
              minWorkTime: 0,
            }
          : undefined
      );
      setCurrentDescription(nodeToEdit?.data.description ?? '');
      setRequiredResources(nodeToEdit?.data.resources ?? []);
    } else {
      setSelectedSector(undefined);
      setCurrentDescription('');
      setRequiredResources([]);
    }
  }, [isOpen]);

  const handleOnSave = () => {
    if (!selectedSector) {
      alertDialog('Greška', 'Izaberite sektor.', ALERT_RED);
      return;
    }
    const newNodeData = {
      sectorId: selectedSector.id,
      sector: selectedSector.name,
      description: currentDescription,
      resources: requiredResources,
    } as NodeData;

    handleOnSaveNode(newNodeData, nodeId);
    setSelectedSector(undefined);
    setCurrentDescription('');
    setRequiredResources([]);
    setSelectedSector(undefined);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth={'70%'}>
        <GsHeaderModal title='Faza izrade proizvoda' />
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex flexDir='column' px='5'>
            <Flex flexDir='row' mb='35' justifyContent='space-between'>
              <FormControl mr='10' flex='1'>
                <FormLabel>Sektor:</FormLabel>
                <Select
                  placeholder='Izaberite sektor'
                  onChange={(e) => {
                    if (!e.target.value || e.target.value.length === 0) return;
                    let newSector: Sector = {
                      id: -1,
                      name: 'unknown',
                      minWorkTime: 0,
                      employee: {} as User,
                    };
                    sectors?.forEach((s) => {
                      if (s.id === Number(e.target.value)) newSector = s;
                    });
                    setSelectedSector(newSector);
                  }}
                  value={selectedSector?.id}
                  disabled={isReadOnly}
                >
                  {sectors?.map((s: Sector) => (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl flex='1'>
                <FormLabel>Instrukcije</FormLabel>
                <Textarea
                  placeholder='Instrukcije za radnika prilikom obavljanja faze izrade proizvoda.'
                  value={currentDescription}
                  onChange={(e) => setCurrentDescription(e.target.value)}
                  disabled={isReadOnly}
                />
              </FormControl>
            </Flex>
            <PhaseResource />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex alignItems='flex-end' mr='5'>
            <GsBtn
              text='Sačuvaj'
              onClick={handleOnSave}
              disabled={isReadOnly}
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ProductPhaseModal;
