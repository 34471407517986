import { Route, Routes } from 'react-router-dom';
import CreateOrderPage from './order/create-order-page';
import CreateProductPage from './product/pages/CreateProductPage';
import CreateSectorPage from './sector/page/create-sector-page';
import HomePageManager from './home/home-page';
import EditSectorPage from './sector/page/edit-sector-page';
import EditOrderPage from './order/edit-order-page';
// import EditProductPage from './product/edit-product-page';
import CreateResourcePage from './resource/pages/create-resource-page';
import EditResourcePage from './resource/pages/edit-resource-page';
import CreateUserPage from './user/page/create-user-page';
import OverviewOrderPage from './order/overview-order';
import FalloutPage from './fallout/fallout-page';
import EditUserPage from './user/page/edit-user-page';
import ReportPage from './report/report-page';
import CustomTaskPage from './customTask/customTaskPage';
import CustomTaskOverview from './customTask/customTaskOverviewPage';
import TaskInProgressPage from './task/task-in-progress-page';
import EditProductPage from './product/pages/EditProductPage';
import MaterialRequestsPage from './warehouse/page/MaterialRequestsPage';

const ManagerRoutes = () => {
  return (
    <Routes>
      <Route path='/home' element={<HomePageManager />} />
      <Route path='/create_order' element={<CreateOrderPage />} />
      <Route path='/create_product' element={<CreateProductPage />} />
      <Route path='/create_sector' element={<CreateSectorPage />} />
      <Route path='/create_resource' element={<CreateResourcePage />} />
      <Route path='/create_user' element={<CreateUserPage />} />
      <Route path='/edit_user' element={<EditUserPage />} />
      <Route path='/edit_sector' element={<EditSectorPage />} />
      <Route path='/edit_order' element={<EditOrderPage />} />
      <Route path='/edit_product' element={<EditProductPage />} />
      <Route path='/edit_resource' element={<EditResourcePage />} />
      <Route path='/overview_order' element={<OverviewOrderPage />} />
      <Route path='/fallout' element={<FalloutPage />} />
      <Route path='/reports' element={<ReportPage />} />
      <Route path='/custom_task' element={<CustomTaskPage />} />
      <Route path='/overview_custom_task' element={<CustomTaskOverview />} />
      <Route path='/task-in-progress' element={<TaskInProgressPage />} />
      {/* <Route path='/flow' element={<FlowDiagram />} /> */}
      <Route path='/material_requests' element={<MaterialRequestsPage />} />
    </Routes>
  );
};

export default ManagerRoutes;
