import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useChakra,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { CustomHeading } from '../../../../components/heading/custom-heading';
import AppLayout from '../../../../components/layout/app-layout';
import { getUsers, User } from '../user-api';
import GsSpinner from '../../../../components/spinner/gsSpinner';
import UserForm from '../components/form/user-form';
import UsersTable from '../components/table/user-table';
import { TableNavigation } from '../../../../components/TabeNavigation/TableNavigation';
import EditPwForm from '../components/form/edit-password-form';
import { useNavigate } from 'react-router-dom';

const EditUserPage = () => {
  const { theme, colorMode } = useChakra();
  return (
    <AppLayout>
      <Flex flexDir='column' w='100%'>
        <Box
          flexDirection='row'
          width='full'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
          mb={1}
          p='20'
        >
          <CustomHeading text='Pregled korisnika' />
          <EditUserForm />
        </Box>
      </Flex>
    </AppLayout>
  );
};

const EditUserForm = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const userNameFilter = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const editUserDisclosure = useDisclosure();
  const changePasswordDisclosure = useDisclosure();

  const {
    isOpen: isOpen,
    onOpen: onOpen,
    onClose: onClose,
  } = editUserDisclosure;
  const {
    isOpen: isOpenPasswordChange,
    onOpen: onOpenPasswordChange,
    onClose: onClosePasswordChange,
  } = changePasswordDisclosure;

  useEffect(() => {
    setIsLoading(true);
    getUsers(pageNumber, userNameFilter.current?.value as string)
      .then((users) => {
        setUsers(users as User[]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
        setIsLoading(false);
      });
  }, [shouldRefresh, pageNumber]);

  const onSubmitHandler = async () => {
    onClose();
    if (!checkDidUserChangeHimself()) setShouldRefresh(!shouldRefresh);
  };

  const checkDidUserChangeHimself = () => {
    const userString = localStorage.getItem('user');
    let userChangedHimself = false;
    if (userString) {
      const user = JSON.parse(userString);
      const userId: number = parseInt(user.userId);
      if (selectedUser?.id === userId) {
        userChangedHimself = true;
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        alert('Bicete izlogovani');
        navigate('/');
      }
    }
    return userChangedHimself;
  };

  const onSubmitPasswordChangeHandler = async () => {
    onClosePasswordChange();
    setShouldRefresh(!shouldRefresh);
  };

  const onUserSelectHandler = (user: User) => {
    setSelectedUser(user);
    onOpen();
  };

  const onUserSelectforPWHandler = (user: User) => {
    setSelectedUser(user);
    onOpenPasswordChange();
  };

  // slican metod kao u fajlu worker-page.tsx - treba refaktorisati TableNavigation tako da bude univerzalan za sve tabele
  // zapravo samo treba koristiti TableNavigationWorker svuda
  // i ovaj metod treba da bude isti za sve
  const pageNumberHandler = (num: number) => {
    if (num === 1) setPageNumber((prevPageNumber) => prevPageNumber + 1);
    else
      setPageNumber((prevPageNumber) =>
        prevPageNumber > 0 ? prevPageNumber - 1 : 0
      );
  };

  return (
    <Box px='5' height='100%' minHeight='600'>
      <Flex flexDirection='column' align={'center'}>
        <FormControl>
          <FormLabel>Pretražite korisnika</FormLabel>
          <InputGroup alignItems='center'>
            <InputLeftElement
              pointerEvents='none'
              children={
                <Icon
                  as={FaSearch}
                  color='gray.500'
                  fontSize='20px'
                  marginTop='5px'
                />
              }
            />
            <Input
              type='text'
              width={500}
              placeholder='Ime i prezime korisnika'
              ref={userNameFilter}
              onChange={() => setShouldRefresh(!shouldRefresh)}
            />
          </InputGroup>
        </FormControl>
        {isLoading ? (
          <GsSpinner />
        ) : (
          <UsersTable
            users={users}
            onUserSelect={onUserSelectHandler}
            onUserSelectforPW={onUserSelectforPWHandler}
          />
        )}
      </Flex>
      <Box>
        <TableNavigation
          pageNumberHandler={pageNumberHandler}
          pageNumber={pageNumber}
        />
      </Box>
      {selectedUser && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent minWidth='1100px'>
            <ModalHeader>Izmena korisnika</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <UserForm
                selectedUser={selectedUser}
                onSubmit={onSubmitHandler}
              />{' '}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      {selectedUser && (
        <Modal isOpen={isOpenPasswordChange} onClose={onClosePasswordChange}>
          <ModalOverlay />
          <ModalContent minWidth='600px'>
            <ModalHeader>Izmena sifre korisnika</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <EditPwForm
                selectedUser={selectedUser}
                onSubmit={onSubmitPasswordChangeHandler}
              />{' '}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default EditUserPage;
