import { AddIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FC, MouseEventHandler } from 'react';
import { Resource } from '../../fallout-api';

const ResourceTable: FC<{
  resources: Resource[];
  onAddHanlder: (row: Resource) => void;
}> = ({ resources, onAddHanlder }) => {
  return (
    <TableContainer marginTop="30px" marginBottom="0px" maxWidth="1200px">
      <Table variant="simple">
        <TableCaption>Materijali</TableCaption>
        <Thead>
          <Tr>
            <Th textAlign="center">Naziv materijala</Th>
            <Th textAlign="center">Dodaj škart</Th>
          </Tr>
        </Thead>
        <Tbody>
          {resources.map((row) => (
            <Tr>
              <Td textAlign="center">{row.name}</Td>
              <Td textAlign="center">
                <IconButton
                  aria-label="add resource"
                  icon={<AddIcon />}
                  variant="outline"
                  onClick={() => onAddHanlder(row)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ResourceTable;
