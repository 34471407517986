import {
  Divider,
  Flex,
  Heading,
  Text,
  Icon,
  useChakra,
} from '@chakra-ui/react';
import WorkerMenuItem from './WorkerMenuItem';
import { useNavigate } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';
import { TxtClr } from '../../../../components/theme/custom-theme';
import { Item } from '../models';
import { useEffect, useState } from 'react';
import { User } from '../../../login/login-api';
import { getSectorsByUSerId } from '../../../manager/sector/sector-apis';
import { AiOutlineTeam } from 'react-icons/ai';

export const WorkerMenu = () => {
  const navigate = useNavigate();
  const { colorMode, theme } = useChakra();
  const currentUser: User = JSON.parse(localStorage.getItem('user') as string);
  const [menuItems, setMenuItems] = useState<Item[]>([]);

  useEffect(() => {
    const callGetMenuItems = async () => {
      const sectors = await getSectorsByUSerId(Number(currentUser.userId));
      const menuItemsWorker = sectors.map((sector) => {
        return {
          id: sector.id as number,
          name: sector.name as string,
          route: `/worker/${sector.id}`,
          icon: AiOutlineTeam,
        };
      });
      setMenuItems(menuItemsWorker);
    };
    callGetMenuItems();
  }, []);

  const navigateToPage = (item: Item, create: boolean) => {
    menuItems.forEach((item) => (item.active = false));
    item.active = true;
    navigate(item.route as string);
  };

  return (
    <Flex
      pos='sticky'
      w='200px'
      flexDir='column'
      justifyContent='flex-start'
      borderRadius='10'
      borderWidth={2}
      bg={theme.colors.bg.colorMode(colorMode)}
      top='0'
    >
      <Flex mx='1' my='1' p='2' flexDir='row'>
        <Heading
          as='h3'
          size='lg'
          mr='2.5'
          style={{
            color: 'white',
            fontFamily: 'Source Sans Pro, sans-serif',
            fontSize: '28px',
            fontWeight: 'bold',
            lineHeight: '32px',
          }}
        >
          <Text>Sektori</Text>
        </Heading>
        <Icon as={EditIcon} fontSize='xl' mt='2.5' color={TxtClr()} />
      </Flex>
      <Divider />
      <Flex p='5%' flexDir='column' alignItems='flex-start' as='nav'>
        {menuItems.map((item) => (
          <WorkerMenuItem key={item.id} item={item} navigate={navigateToPage} />
        ))}
      </Flex>
    </Flex>
  );
};
