import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
} from '@chakra-ui/react';
import { TableNavigation } from '../../../../components/TabeNavigation/TableNavigation';
import { OrderTableProps, getOrders, getOrdersByFilter } from '../order-apis';
import OrderTableRow from './orderTableRow';

function OrderTable({
  orders,
  setOrders,
  setOrderToEdit,
  setOrderItems,
  setOrder,
  fieldRef,
  pageNumber,
  setPageNumber,
  onOpen,
  isLoadingOrders: isLoading,
  setOrdersLoading: setIsLoading,
  orderType,
  orderStatus,
}: OrderTableProps) {
  const tableCaption = orderTableCaptionGenerator(orders);
  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    fieldRef.current.value === ''
      ? getOrders(setOrders, newPageNumber, false, orderType, orderStatus)
      : getOrdersByFilter(fieldRef.current.value, setOrders, newPageNumber, orderType, orderStatus);
    setPageNumber(newPageNumber);
  };
  return (
    <TableContainer marginTop='30px' marginBottom='70px'>
      <Table variant='simple'>
        <TableCaption>{tableCaption}</TableCaption>
        <Thead>
          <Tr>
            <Th w='16%'>Šifra porudžbine</Th>
            <Th w='12%'>Naziv kompanije</Th>
            <Th w='10%'>Datum prijema</Th>
            <Th w='10%'>Datum isporuke</Th>
            <Th w='10%'>Prioritet</Th>
            <Th w='10%'>Stanje</Th>
            <Th w='8%'>Aktivacija</Th>
            <Th w='8%'>Izmena</Th>
            <Th w='8%'>Brisanje</Th>
            <Th w='8%'>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders.map((o) => (
            <OrderTableRow
              key={o.id}
              order={o}
              orders={[]}
              setOrders={setOrders}
              setOrderToEdit={setOrderToEdit}
              setOrderItems={setOrderItems}
              setOrder={setOrder}
              fieldRef={fieldRef}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              onOpen={onOpen}
              isLoadingOrders={isLoading}
              setOrdersLoading={setIsLoading}
            ></OrderTableRow>
          ))}
        </Tbody>
      </Table>
      <TableNavigation
        pageNumber={pageNumber}
        pageNumberHandler={pageNumberHandler}
      />
    </TableContainer>
  );
}

const orderTableCaptionGenerator = (orders: any) => {
  if (orders.length === 0) {
    return 'Ne postoji porudžbina sa tom šifrom';
  } else {
    return 'Porudžbine';
  }
};

export default OrderTable;
