import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { TableNavigation } from '../../../../../components/TabeNavigation/TableNavigation';
import { getOrdersByFilter, Order } from '../../../order/order-apis';
import {
  ConsumedMaterialReport,
  getCustomConsumedMaterialReport,
} from '../../report-api';
import ConsumedMaterialReportTableModal from './ConsumedMaterialTableModal';

function SearchedOrderTable({
  orders,
  setOrders,
  fieldRef,
  pageNumber,
  setPageNumber,
}: SearchedOrderTableProps) {
  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    getOrdersByFilter(fieldRef.current.value, setOrders, 0, 'all');
    setPageNumber(newPageNumber);
  };

  return (
    <TableContainer marginTop='30px' maxWidth='1200px'>
      <Table variant='simple' mb={5}>
        <Thead>
          <Tr>
            <Th w='80%'>Šifra porudžbenice</Th>
            <Th w='20%'>Izaberi porudžbenicu</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders?.map((o) => (
            <SearchedOrderTableRow key={o.orderCode} order={o} />
          ))}
        </Tbody>
      </Table>
      <TableNavigation
        pageNumber={pageNumber}
        pageNumberHandler={pageNumberHandler}
      />
    </TableContainer>
  );
}

function SearchedOrderTableRow({ order }: SearchedOrderTableRowProps) {
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const [customConsumedMaterialReports, setCustomConsumedMaterialReports] =
    useState<ConsumedMaterialReport[]>([]);
  const [modalTableVisible, setModalTableVisible] = useState(false);
  const [productPageNo, setProductPageNo] = useState<number>(0);

  const handleButtonClick = async () => {
    const confirmationMessage =
      'Da li zelite da kreirate izveštaj za porudžbenicu ' +
      order?.orderCode +
      '?';
    const isConfirmed = window.confirm(confirmationMessage);

    if (isConfirmed) {
      const data = await getCustomConsumedMaterialReport(
        order.orderCode,
        setCustomConsumedMaterialReports,
        productPageNo
      );

      if (
        data === null ||
        (data !== false && Array.isArray(data) && data.length === 0)
      ) {
        alert('Ne postoje podaci za traženi izveštaj');
        return;
      } else {
        setModalTableVisible(true);
      }
    }
  };
  return (
    <Tr>
      <Td w='80%'>{order?.orderCode}</Td>
      <Td w='20%'>
        <Button
          _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
          variant='outline'
          ml={5}
          onClick={handleButtonClick}
        >
          Izaberi
        </Button>
        <ConsumedMaterialReportTableModal
          isModalOpen={modalTableVisible}
          setIsModalOpen={setModalTableVisible}
          customConsumedMaterialReports={customConsumedMaterialReports}
          setCustomConsumedMaterialReports={setCustomConsumedMaterialReports}
          getCustomConsumedMaterialReport={getCustomConsumedMaterialReport}
          pageNo={productPageNo}
          setProductPageNo={setProductPageNo}
          order_code={order?.orderCode}
        />
      </Td>
    </Tr>
  );
}

export interface SearchedOrderTableProps {
  orders: Order[];
  setOrders: any;
  fieldRef: any;
  pageNumber: number;
  setPageNumber: any;
}

export interface SearchedOrderTableRowProps {
  order: Order;
}

export default SearchedOrderTable;
