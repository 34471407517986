import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Tr, Td, IconButton, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import EditOrderItemModal from '../modal/edit-order-item-modal';
import {
  OrderItemsTabelProps,
  convertPriority,
  convertDate,
  deleteOrderItem,
  Order,
} from '../order-apis';

function OrderItemsTableRow({
  orderItem,
  orderItems,
  setOrderItems,
  orderToEdit,
}: OrderItemsTabelProps) {
  let priority = convertPriority(orderItem?.priority);
  const isEdit = orderToEdit != undefined;

  const {
    isOpen: isShowModalOpen,
    onOpen: onShowModalOpen,
    onClose: onShowModalClose,
  } = useDisclosure();

  return (
    <Tr>
      <Td>{orderItem?.products.map((p) => p.productCode + ' ')}</Td>
      <Td isNumeric>{orderItem?.amount}</Td>
      <Td>{orderItem?.products.map((p) => p.measureUnit?.unitName + ' ')}</Td>
      <Td>{convertDate(orderItem?.estimatedEnd)}</Td>
      <Td>{priority}</Td>
      {isEdit && <Td alignItems='center'>
        <IconButton
          icon={<EditIcon />}
          aria-label='theme'
          alignItems='center'
          marginLeft='25px'
          onClick={() => onShowModalOpen()}
        />
      </Td>}
      <Td alignItems='center'>
        <IconButton
          aria-label='theme'
          alignItems='center'
          marginLeft='25px'
          icon={<DeleteIcon />}
          onClick={async (e) => {
            if (
              window.confirm(
                'Da li ste sigurni da želite da obrišete stavku porudžbenice?'
              )
            ) {
              let newOrderItems;

              if (orderItem != undefined) {
                newOrderItems = orderItems.filter((o) => {
                  return (
                    o.id?.toString() +
                      o.products[0].id.toString() +
                      o.estimatedEnd?.toString() +
                      o.priority?.toString() !==
                    orderItem.id?.toString() +
                      orderItem.products[0].id.toString() +
                      orderItem.estimatedEnd?.toString() +
                      orderItem.priority?.toString()
                  );
                });
                setOrderItems(newOrderItems);
              }
              if (orderItem != undefined && orderItem.id != null) {
                try {
                  await deleteOrderItem(orderItem);
                  alert('Stavka je obrisana, nastavite sa izmenom porudžbine');
                } catch (error) {
                  // Handle any errors that occurred during deleteOrderItem
                }
              }
            }
          }}
        />
      </Td>
      {isShowModalOpen && (
        <EditOrderItemModal
          isOpen={isShowModalOpen}
          onClose={onShowModalClose}
          orderItem={orderItem}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          orderToEdit={orderToEdit as Order}
        />
      )}
    </Tr>
  );
}

export default OrderItemsTableRow;
