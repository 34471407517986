import { Tr, Td, FormControl, Input, FormErrorMessage } from '@chakra-ui/react';
import {
  calculateUsedResourcesForWorker,
  RequestedResourceForTask,
} from '../../../worker-api';

export interface ReservedResourceReviewTableRowProps {
  reservedResource: RequestedResourceForTask;
  index: number;
}

const ReservedResourceReviewTableRow = ({
  reservedResource,
  index,
}: ReservedResourceReviewTableRowProps) => {
  const isCompleteApproved =
    reservedResource.requestedAmount === reservedResource.approvedAmount;
  const isPartialApproved =
    (reservedResource?.approvedAmount as Number) > 0 && !isCompleteApproved;
  const isNewStatus = reservedResource?.status === 'NEW';

  let orderedAmount = reservedResource.requestedAmount;
  let approvedAmount = reservedResource.approvedAmount;

  if (reservedResource.id) {
    orderedAmount = Number(
      calculateUsedResourcesForWorker(
        reservedResource.requestedAmount,
        reservedResource.resource.measureUnitRatio
      )
    );

    approvedAmount = Number(
      calculateUsedResourcesForWorker(
        reservedResource.approvedAmount,
        reservedResource.resource.measureUnitRatio
      )
    );
  }

  return (
    <Tr borderBottom='1px solid'>
      <Td
        textAlign='center'
        w='30%'
        borderLeft='1px solid'
        bgColor={
          isNewStatus
            ? 'darkBlue' // Change to your desired color for 'NEW' status
            : isCompleteApproved
            ? '#006400'
            : isPartialApproved
            ? 'yellow.600'
            : '#8B0000'
        }
      >
        {reservedResource.resource.name}
      </Td>
      <Td textAlign='center' w='30%' borderLeft='1px solid'>
        {orderedAmount}
      </Td>
      <Td textAlign='center' w='30%' borderLeft='1px solid'>
        {approvedAmount}
      </Td>
      <Td
        textAlign='center'
        w='10%'
        borderLeft='1px solid'
        borderRight='1px solid'
      >
        {reservedResource?.resource.workerMeasureUnit?.unitName}
      </Td>
    </Tr>
  );
};

export default ReservedResourceReviewTableRow;
