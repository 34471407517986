import React, { useState } from 'react';
import {
  ModalBody,
  Button,
  ModalFooter,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  useColorModeValue,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
} from '@chakra-ui/react';
import {
  CustomNoteReportTableRowProps,
  NoteReport,
  NoteReportTableModalProps,
} from '../../report-api';
import { exportToExcel } from '../../../../../functions/export';
import { TableNavigation } from '../../../../../components/TabeNavigation/TableNavigation';

function NoteReportTableModal({
  isModalOpen,
  setIsModalOpen,
  reportName,
  customNoteReports,
  setCustomNoteReports,
  getCustomNoteReport,
  pageNo,
  setProductPageNo,
  startDate,
  endDate,
  setIsLoading,
}: NoteReportTableModalProps) {
  const closeModal = () => {
    setIsModalOpen(false);
    setPageNumber(0);
  };

  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  const [customNoteReportsExport, setCustomNoteReportsExport] = useState<
    NoteReport[]
  >([]);

  const tableCaptionGenerator = (customNoteReports: any) => {
    if (customNoteReports === 0) {
      return 'Ne postoji nijedna stavka izvestaja';
    } else {
      return '';
    }
  };

  const handleExportButtonClick = async () => {
    try {
      const exportData = await getCustomNoteReport(
        startDate,
        endDate,
        setCustomNoteReportsExport,
        undefined,
        0,
        'true'
      );

      const headers = [
        'Napomena',
        'Naziv sektora',
        'Vreme',
        'Sifra porudžbine',
        'Sifra proizvoda',
      ];

      const data = exportData.map((item: NoteReport) => [
        item.note,
        item.sector_name,
        new Date(item.input_time)
          .toLocaleString('en-GB', {
            year: 'numeric',
            day: '2-digit',
            month: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })
          .replace(/\//g, '-'),
        item.order_code,
        item.product_code,
      ]);

      exportToExcel(headers, data, reportName);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const [pageNumber, setPageNumber] = useState<number>(0);

  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    getCustomNoteReport(
      startDate,
      endDate,
      setCustomNoteReports,
      setIsLoading,
      newPageNumber
    );
    setPageNumber(newPageNumber);
  };

  const tableCaption = tableCaptionGenerator(customNoteReports);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <ModalOverlay />
      <ModalContent minWidth='1200px'>
        <ModalHeader
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '20px',
            marginRight: '40px',
          }}
        >
          <div>{reportName}</div>
          <div>
            {startDate} - {endDate}
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer
            marginTop='10px'
            maxWidth='1200px'
          >
            <Table variant='simple'>
              <TableCaption> {tableCaption} </TableCaption>
              <Thead>
                <Tr>
                  <Th>Napomena</Th>
                  <Th>Sektor</Th>
                  <Th>Vreme</Th>
                  <Th>Šifra porudžbenice</Th>
                  <Th>Šifra proizvoda</Th>
                </Tr>
              </Thead>
              <Tbody>
                {customNoteReports?.map((c) => (
                  <CustomNoteReportTableRow customNote={c} />
                ))}
              </Tbody>
            </Table>
            <TableNavigation
              pageNumber={pageNumber}
              pageNumberHandler={pageNumberHandler}
            />
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button
            _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
            variant='outline'
            w='15%'
            onClick={handleExportButtonClick}
          >
            {' '}
            Exportuj
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function CustomNoteReportTableRow({
  customNote,
}: CustomNoteReportTableRowProps) {
  let formattedDate;
  if (customNote.input_time) {
    const date = new Date(customNote.input_time);
    formattedDate = date
      .toLocaleString('en-GB', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/\//g, '-');
  } else {
    formattedDate = '';
  }

  return (
    <Tr>
      <Td>{customNote?.note}</Td>
      <Td>{customNote?.sector_name}</Td>
      <Td>{formattedDate}</Td>
      <Td>{customNote?.order_code}</Td>
      <Td>{customNote?.product_code}</Td>
    </Tr>
  );
}

export default NoteReportTableModal;
