import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Tfoot,
} from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { ResourceRequiredAmmount, Resource } from '../../../../../product-api';
import { RequiredResourceContext } from '../../../../../contexts/RequiredResourceContext';
import ResourceTableRow from './ResourceTableRow';

const ResourceTable: FC<{
  filteredResources: Resource[];
  onAddResource: (resource: ResourceRequiredAmmount) => void;
  isResourceAdded: boolean;
  pageNumber: number;
  onChangePage: (pageNumber: number) => void;
}> = ({
  filteredResources,
  onAddResource,
  isResourceAdded,
  pageNumber,
  onChangePage,
}) => {
  const { requiredResources } = useContext(RequiredResourceContext);

  return (
    <TableContainer marginTop='30px' marginBottom='0px'>
      <Table variant='simple'>
        <Thead>
          <Tr borderBottom='solid'>
            <Th textAlign='center'></Th>
            <Th textAlign='center'>Šifra materijala</Th>
            <Th textAlign='center'>Naziv materijala</Th>
            <Th textAlign='center'>Merna jedinica</Th>
            <Th textAlign='center'>Radnikova jedinica</Th>
            <Th textAlign='center'>Kvalitet</Th>
            <Th textAlign='center'>Količina</Th>
            <Th textAlign='center'>Radnik kol.</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isResourceAdded
            ? requiredResources?.map((r) => (
                <ResourceTableRow
                  key={r.id + r.resource!.name}
                  isResourceAdded={isResourceAdded}
                  resource={r}
                  onAddResource={onAddResource}
                />
              ))
            : filteredResources.map((r) => (
                <ResourceTableRow
                  key={r.id + r.resourceCode}
                  isResourceAdded={isResourceAdded}
                  resource={r}
                  onAddResource={onAddResource}
                />
              ))}
        </Tbody>
        {filteredResources.length > 0 && (
          <Tfoot>
            <Tr>
              <Td colSpan={6}>
                <Flex alignItems='center' justifyContent='center'>
                  <ArrowLeftIcon
                    marginRight={10}
                    onClick={() => onChangePage(-1)}
                  />
                  {pageNumber + 1}
                  <ArrowRightIcon
                    marginLeft={10}
                    onClick={() => onChangePage(+1)}
                  />
                </Flex>
              </Td>
            </Tr>
          </Tfoot>
        )}
      </Table>
    </TableContainer>
  );
};

export default ResourceTable;
