import React, { useState } from 'react';
import {
  ModalBody,
  Button,
  ModalFooter,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  useColorModeValue,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
} from '@chakra-ui/react';
import {
  CustomSectorReportTableRowProps,
  SectorReport,
  SectorReportTableModalProps,
} from '../../report-api';
import { exportToExcel } from '../../../../../functions/export';

function SectorReportTableModal({
  isModalOpen,
  setIsModalOpen,
  reportName,
  customSectorReport,
  startDate,
  endDate,
  setIsLoading,
}: SectorReportTableModalProps) {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  const tableCaptionGenerator = (customSectorReport: any) => {
    if (customSectorReport === 0) {
      return 'Ne postoji nijedna stavka izvestaja';
    } else {
      return '';
    }
  };

  const handleExportButtonClick = async () => {
    try {
      const headers = [
        'Naziv Sektora',
        'Ukupno vreme (h)',
        'Prosecno vreme (h)',
        'Maksimalno vreme (h)',
        'Minimalno vreme (min)',
      ];

      const data = customSectorReport.map((item: SectorReport) => [
        item.sector_name,
        item.total_time ? parseFloat((item.total_time / 3600).toFixed(4)) : '',
        item.average_time
          ? parseFloat((item.average_time / 3600).toFixed(4))
          : '',
        item.max_time ? parseFloat((item.max_time / 3600).toFixed(4)) : '',
        item.min_time ? parseFloat((item.min_time / 60).toFixed(4)) : '',
      ]);

      exportToExcel(headers, data, reportName);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const tableCaption = tableCaptionGenerator(customSectorReport);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <ModalOverlay />
      <ModalContent minWidth='1200px'>
        <ModalHeader
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '20px',
            marginRight: '40px',
          }}
        >
          <div>{reportName}</div>
          <div>
            {startDate} - {endDate}
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer
            marginTop='10px'
            maxWidth='1200px'
          >
            <Table variant='simple'>
              <TableCaption> {tableCaption} </TableCaption>
              <Thead>
                <Tr>
                  <Th>Naziv sektora</Th>
                  <Th>Ukupno vreme (h)</Th>
                  <Th>Prosecno vreme (h)</Th>
                  <Th>Maksimalno vreme (h)</Th>
                  <Th>Minimalno vreme (min)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {customSectorReport?.map((c) => (
                  <CustomSectorReportTableRow customSector={c} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button
            _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
            variant='outline'
            w='15%'
            onClick={handleExportButtonClick}
          >
            {' '}
            Exportuj
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function CustomSectorReportTableRow({
  customSector,
}: CustomSectorReportTableRowProps) {
  let total_time = customSector?.total_time / 3600;
  let average_time = customSector?.average_time / 3600;
  let max_time = customSector?.max_time / 3600;
  let min_time = customSector?.min_time / 60;

  if (total_time !== undefined) {
    total_time = parseFloat(total_time.toFixed(4));
  }

  if (average_time !== undefined) {
    average_time = parseFloat(average_time.toFixed(4));
  }

  if (max_time !== undefined) {
    max_time = parseFloat(max_time.toFixed(4));
  }

  if (min_time !== undefined) {
    min_time = parseFloat(min_time.toFixed(4));
  }

  return (
    <Tr>
      <Td>{customSector?.sector_name}</Td>
      <Td>{total_time}</Td>
      <Td>{average_time}</Td>
      <Td>{max_time}</Td>
      <Td>{min_time}</Td>
    </Tr>
  );
}

export default SectorReportTableModal;
