import React, { useState } from 'react';
import { createMeasurementUnit, MeasurementUnit } from '../measurementUnit-api';
import { levenshteinDistance } from '../../../../functions/levenshteinDistance';
import AddUnitModal from './unit';
import { findSimilarUnits, validateNewUnit } from '../util';

export interface AddMeasurementUnitProps {
  onOpen: any;
  onClose: any;
  isOpen: any;
  setMeasurementUnits: React.Dispatch<React.SetStateAction<MeasurementUnit[]>>;
  measurementUnits: MeasurementUnit[];
}

const AddMeasurementUnitModal = ({
  onOpen,
  onClose,
  isOpen,
  setMeasurementUnits,
  measurementUnits,
}: AddMeasurementUnitProps) => {
  // State to store the new measurement unit
  const [newUnit, setNewUnit] = useState('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  async function addNewUnit(newUnit: string): Promise<void> {
    const measurementUnit: MeasurementUnit = {
      unitName: newUnit,
    };

    try {
      const addedUnit = await createMeasurementUnit(
        measurementUnit,
        setIsSaving
      );
      if (addedUnit) {
        alert('Merna jedinica uspešno dodata.');
        setMeasurementUnits((prevUnits) => [...prevUnits, addedUnit]);
        setNewUnit('');
        onClose();
      } else {
        alert('Postoji merna jedinica sa ovakvim imenom');
      }
    } catch (error) {
      console.error('Error adding measurement unit:', error);
      alert('Došlo je do greške pri dodavanju merne jedinice.');
    }
  }

  // Function to handle adding the new measurement unit
  const handleAddNewUnit = async () => {
    if (!validateNewUnit(newUnit)) return;

    const unitNames = measurementUnits.map((unit) => unit.unitName);

    if (!findSimilarUnits(newUnit, unitNames)) return;
    await addNewUnit(newUnit);
  };

  return (
    <AddUnitModal
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
      newUnit={newUnit}
      setUnit={setNewUnit}
      handleAddNewUnit={handleAddNewUnit}
      header='Dodajte novu mernu jedinicu'
      label='Nova Merna Jedinica'
      isSaving={isSaving}
    ></AddUnitModal>
  );
};

export default AddMeasurementUnitModal;
