import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { FaInfoCircle, FaExclamationCircle } from 'react-icons/fa';

export interface AlertProps {
  isOpen: boolean;
  onClose: () => void;
  color?: string;
  header: String;
  text: String;
}

const GSAlertDialog = ({
  color,
  header,
  text,
  isOpen,
  onClose,
}: AlertProps) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader color={color}>
            <Flex alignItems='center' gap='2.5%'>
              {color && (
                <Icon
                  as={
                    color.startsWith('g') ? FaInfoCircle : FaExclamationCircle
                  }
                  color={color}
                  w={6}
                  h={6}
                />
              )}

              {header}
            </Flex>
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody ml='5%'>{text}</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              _hover={{
                textDecor: 'none',
                textColor: color,
                borderColor: color,
                borderWidth: '1px',
              }}
            >
              Ok
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default GSAlertDialog;
