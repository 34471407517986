import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { Tr, Td, IconButton } from '@chakra-ui/react';
import { FC } from 'react';
import { Sector, deleteSector } from '../../sector-apis';

const SectorTableRow: FC<{
  sector: Sector;
  onSectorSelect: (sector: Sector) => void;
}> = ({ sector, onSectorSelect }) => {
  return (
    <Tr>
      <Td>{sector?.name}</Td>
      <Td alignItems='center'>
        <IconButton
          aria-label='edit-icon'
          alignItems='center'
          marginLeft='25'
          icon={<EditIcon />}
          value={sector?.id}
          onClick={() => onSectorSelect(sector)}
        />
      </Td>
      <Td>
        <IconButton
          aria-label='delete-icon'
          alignItems='center'
          marginLeft='25'
          icon={<DeleteIcon />}
          value={sector?.id}
          onClick={async (e) => {
            if (
              window.confirm('Da li ste sigurni da želite da obrišete sektor?')
            ) {
              if (sector === undefined) return;

              const success = await deleteSector(sector);

              if (success === true) {
                alert('Sektor je obrisan');
                window.location.reload();
              } else {
                alert('Greska pri brisanju sektora');
                window.location.reload();
              }
            }
          }}
        />
      </Td>
    </Tr>
  );
};

export default SectorTableRow;
