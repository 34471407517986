import { FC } from 'react';
import { Checkbox } from '@chakra-ui/react';

const SectorCheckbox: FC<{
  sectorName: string;
  isChecked: boolean;
  toggleSector: (sector: string) => void;
}> = ({ sectorName, isChecked, toggleSector }) => (
  <Checkbox
    isChecked={isChecked}
    onChange={() => toggleSector(sectorName)}
    sx={{
      marginLeft: 4,
      fontSize: 'larger',
    }}
    _hover={{
      color: '#A3C7FA',
      '.chakra-checkbox__control': {
        borderColor: '#A3C7FA',
      },
    }}
  >
    {sectorName}
  </Checkbox>
);

export default SectorCheckbox;
