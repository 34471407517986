import { CompatClient, Stomp } from '@stomp/stompjs';
import React, { FC, ReactNode, createContext, useState, useContext } from 'react';

type NotificationConnectionContextType = {
  client: CompatClient | null;
  setClient: (client: CompatClient | null) => void;
};

export const NotificationConnection = createContext<NotificationConnectionContextType>({
  client: null,
  setClient: () => null,
});

const NotificationConnectionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [client, setClient] = useState<CompatClient | null>(null);
  const value = { client, setClient };

  return (
    <NotificationConnection.Provider value={value}>
      {children}
    </NotificationConnection.Provider>
  );
};

export const useNotificationConnection = () => useContext(NotificationConnection);

export default NotificationConnectionProvider;
