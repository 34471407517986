export function levenshteinDistance(target: string, candidates: string[], threshold: number): string[] {
    const similarStrings: string[] = [];
  
    // Inner function to calculate Levenshtein distance between two strings
    function calculateDistance(a: string, b: string): number {
      const matrix: number[][] = Array(b.length + 1).fill(null).map(() => Array(a.length + 1).fill(0));
  
      for (let i = 0; i <= a.length; i++) matrix[0][i] = i;
      for (let j = 0; j <= b.length; j++) matrix[j][0] = j;
  
      for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
          const substitutionCost = a[j - 1] === b[i - 1] ? 0 : 1;
          matrix[i][j] = Math.min(
            matrix[i - 1][j] + 1, // deletion
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j - 1] + substitutionCost, // substitution
          );
        }
      }
  
      return matrix[b.length][a.length];
    }
  
    // Iterate through each candidate and calculate distance to the target
    candidates.forEach((candidate) => {
      const distance = calculateDistance(target.toLowerCase(), candidate.toLowerCase());
      if (distance <= threshold) {
        similarStrings.push(candidate);
      }
    });
  
    return similarStrings;
  }