import { AddIcon, ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  useChakra,
  useDisclosure,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useCallback, useContext, useRef, useState } from 'react';
import ReactFlow, {
  Controls,
  updateEdge,
  addEdge,
  Connection,
  Node,
  Edge,
  OnNodesChange,
  OnEdgesChange,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import GsIconBtn from '../../../../../components/button/GsIconBtn';
import { NodeContext, NodeData } from '../../contexts/NodeContext';
import RequiredResourceProvider from '../../contexts/RequiredResourceContext';
import ProductPhaseModal from './phase/ProductPhaseModal';
import CustomNode from './CustomNode';
import { ReadOnlyContext } from '../../contexts/ReadOnlyContext';
import { useGsDialog } from '../../../../../contexts/GsConfirmDialogContext';

const nodeTypes = {
  selectorNode: CustomNode,
};

type FlowDiagramProps = {
  nodes: Node<NodeData, string | undefined>[];
  onNodesChange: OnNodesChange;
  setNodes: React.Dispatch<
    React.SetStateAction<Node<NodeData, string | undefined>[]>
  >;
  newNodeId: number;
  edges: Edge<Edge<any>>[];
  onEdgesChange: OnEdgesChange;
  setEdges: React.Dispatch<React.SetStateAction<Edge<Edge<any>>[]>>;

  onAddProductPhase: (newNode: Node<NodeData>, nodeId?: number) => void;
  onDeleteProductPhase: (nodeId: number) => void;
  updateProductPhasePositions: (node: Node) => void;
};

const FlowDiagram: FC<FlowDiagramProps> = ({
  nodes,
  setNodes,
  onNodesChange,
  newNodeId,
  edges,
  setEdges,
  onEdgesChange,
  onAddProductPhase,
  onDeleteProductPhase,
  updateProductPhasePositions,
}) => {
  const edgeUpdateSuccessful = useRef(true);
  const [showDiagram, setShowDiagram] = useState(false);
  const {
    isOpen: isOpenPhase,
    onOpen: onOpenPhase,
    onClose: onClosePhase,
  } = useDisclosure();
  const { deleteElements } = useReactFlow();
  const { isReadOnly } = useContext(ReadOnlyContext);
  const { confirmDialog } = useGsDialog();

  const handleOnDeleteNode = useCallback(
    (nodeId: number) => {
      confirmDialog(
        'Brisanje Faze',
        'Da li sigurno želite da obrišete fazu u izradi proizvoda?',
        () => {
          deleteElements({ nodes: [{ id: nodeId.toString() }] });
          onDeleteProductPhase(nodeId);
        }
      );
    },
    [deleteElements, nodes, setNodes]
  );

  const handleOnSaveNode = useCallback(
    (newData: NodeData, nodeId?: number) => {
      setShowDiagram(true);
      let newNode: Node;

      if (nodeId) newNode = updateNode(newData, nodeId);
      else newNode = createNode(newData);

      onAddProductPhase(newNode, nodeId);
    },
    [nodes, setNodes]
  );

  const updateNode = (newData: NodeData, nodeId: number): Node => {
    const newNode = {
      ...nodes.find((node) => node.id === nodeId.toString()),
      data: {
        ...newData,
      },
    } as Node;
    const updatedNodes = nodes.map((node) =>
      node.id === nodeId.toString() ? newNode : node
    );
    setNodes(updatedNodes);
    return newNode;
  };

  const createNode = (newData: NodeData): Node => {
    let xPos = 100;
    let yPos = 200;
    if (nodes.length != 0) {
      xPos = nodes[nodes.length - 1].position.x;
      yPos = nodes[nodes.length - 1].position.y + 150;
    }
    const newNode: Node<NodeData> = {
      id: newNodeId.toString(),
      type: 'selectorNode',
      data: {
        sectorId: newData.sectorId,
        sector: newData.sector,
        description: newData.description,
        resources: newData.resources,
      },
      position: { x: xPos, y: yPos },
    };
    setNodes([...nodes, newNode]);
    return newNode;
  };

  const handleOnNodeDragStop = (node: Node) => {
    updateProductPhasePositions(node);
  };

  const onConnect = useCallback((params: Edge | Connection) => {
    setEdges((els: Edge[]) => addEdge(params, els));
  }, []);

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      edgeUpdateSuccessful.current = true;
      setEdges((els: Edge[]) => updateEdge(oldEdge, newConnection, els));
    },
    []
  );

  const onEdgeUpdateEnd = useCallback((_: any, edge: { id: string }) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds: any[]) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const proOptions = { hideAttribution: true };

  const { theme } = useChakra();

  return (
    <NodeContext.Provider
      value={{
        handleOnSaveNode,
        handleOnDeleteNode,
        nodes,
        setNodes,
      }}
    >
      <RequiredResourceProvider>
        <Flex flexDir='column' px='5'>
          <Flex alignItems='center' justifyContent='center' mb='5'>
            <FormControl width='auto'>
              <Flex borderWidth='2px' borderRadius='5px' p='10px'>
                <Heading
                  as='h3'
                  whiteSpace='nowrap'
                  color='white'
                  fontFamily='Source Sans Pro, sans-serif'
                  fontSize='22px'
                  fontWeight='400'
                  mr='5'
                >
                  Faze proizvoda
                </Heading>
                <GsIconBtn
                  label='add product phase'
                  onClick={onOpenPhase}
                  icon={<AddIcon />}
                  disabled={isReadOnly}
                />
                <Flex ml='2.5'>
                  <GsIconBtn
                    icon={showDiagram ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    label='showDiagramBtn'
                    onClick={() => setShowDiagram(!showDiagram)}
                  />
                </Flex>
              </Flex>
              <FormHelperText fontStyle='italic'>
                Dodajte faze u izradi proizvoda.
              </FormHelperText>
            </FormControl>
            <ProductPhaseModal isOpen={isOpenPhase} onClose={onClosePhase} />
          </Flex>
          <AnimatePresence>
            {showDiagram && (
              <motion.div
                initial='collapsed'
                animate='open'
                exit='collapsed'
                variants={{
                  open: { opacity: 1, height: 'auto' },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.8, ease: 'easeInOut' }}
              >
                <Flex
                  bg={theme.colors.gray[700]}
                  height='800px'
                  width='100%'
                  alignItems='center'
                  borderWidth='2px'
                  borderRadius='5px'
                  p='10px'
                  overflow='hidden'
                  direction='column'
                  shadow='dark-lg'
                >
                  <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onNodeDragStop={(event, node) => handleOnNodeDragStop(node)}
                    onEdgesChange={onEdgesChange}
                    snapToGrid
                    onEdgeUpdate={onEdgeUpdate}
                    onEdgeUpdateStart={onEdgeUpdateStart}
                    onEdgeUpdateEnd={onEdgeUpdateEnd}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    fitView
                    proOptions={proOptions}
                    deleteKeyCode={null}
                  >
                    <Controls />
                  </ReactFlow>
                </Flex>
              </motion.div>
            )}
          </AnimatePresence>
        </Flex>
      </RequiredResourceProvider>
    </NodeContext.Provider>
  );
};

export default FlowDiagram;
