import {
  Avatar,
  Button,
  Flex,
  Spinner,
  Td,
  Tr,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { convertDate } from '../../manager/order/order-apis';
import {
  taskAction,
  OrderItemPhaseDTO,
  getPhaseResources,
  TaskActionDTO,
  WorkerBySectorDTO,
  assignWorker,
  RequestedResourceForTask,
  getRequestedResourceForTask,
} from '../worker-api';
import WorkerPriority from './worker-priority';
import { ResourceRequiredAmmount } from '../../manager/product/product-api';
import { DownloadIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import GsIconBtn from '../../../components/button/GsIconBtn';
import WorkerShowFileModal from './file/WorkerShowFilesModal';
import ResourceReservationModal from './resourceReservation/ResourceReservationModal';
import FinishedTaskModal from './resourceValidation/FinishedTaskModal';
import InstructionsModal from './instructions/InstructionsModal';
import { FaUser } from 'react-icons/fa';
import {
  ALERT_GREEN,
  ALERT_RED,
  useGsDialog,
} from '../../../contexts/GsConfirmDialogContext';
import TaskAssignmentPopOver from './taskAssignment/TaskAssignmentPopOver';
import ReservedResourceReviewModal from './resourceReview/ReservedResourceReviewModal';
import { User } from '../../login/login-api';
import ProductDiagramModal from './product/ProductDiagramModal';

const WorkerRow: FC<{
  dataRow: {
    phaseId: number;
    phaseDescription: string;
    productId: number;
    productDescription: string;
    productCode: string;
    taskStartTime: Date;
    orderItemId: number;
    estimatedItemEnd: Date;
    priority: string;
    expectedAmount: number;
    orderCode: string;
    estimatedOrderEnd: Date;
    taskId: number;
    hasFile: boolean;
    hasPhaseResource: boolean;
    hasPreviousSector: boolean;
    lastWorker: WorkerBySectorDTO;
    hasReservedResources: boolean;
  };
  workers: WorkerBySectorDTO[];
  sectorMinWorkTime: number;
  onTaskFinishedHandler: () => void;
  isChecked: boolean;
  handleInitalAndRefreshCalls: any;
  setIsAnyModalOpen: any;
  isAnyModalOpen: boolean;
}> = ({
  dataRow,
  workers,
  sectorMinWorkTime,
  onTaskFinishedHandler,
  isChecked,
  handleInitalAndRefreshCalls,
  setIsAnyModalOpen,
  isAnyModalOpen,
}) => {
  const loggedInUser: User = JSON.parse(localStorage.getItem('user') as string);
  const itemPhaseInit: OrderItemPhaseDTO = {
    orderItemId: dataRow.orderItemId,
    phaseId: dataRow.phaseId,
    phasePid: dataRow.productId,
    taskId: dataRow.taskId,
    assignedById: Number(loggedInUser.userId),
    workerId: dataRow.lastWorker ? dataRow.lastWorker.userId : undefined,
  };
  const [itemPhase, setItemPhase] = useState<OrderItemPhaseDTO>(itemPhaseInit);
  const [btnName, setBtnName] = useState('');
  const [btnMaterialName, setBtnMaterialName] = useState('');
  const [btnClr, setBtnClr] = useState('');
  const [materialBtnClr, setMaterialBtnClr] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResourceRequired, setIsResourceRequired] = useState<boolean>(false);
  const [
    isResourceReservetionRequestSent,
    setIsResourceReservetionRequestSent,
  ] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [phaseRequiredResources, setPhaseRequiredResources] = useState<
    ResourceRequiredAmmount[]
  >([]);
  const [reservedResources, setReservedResources] = useState<
    RequestedResourceForTask[]
  >([]);
  const [phaseAllRequestedResourcesOnEnd, setPhaseAllRequestedResourcesOnEnd] =
    useState<RequestedResourceForTask[]>([]);
  const [shouldStartTask, setShouldStartTask] = useState(false);
  const [currentWorker, setCurrentWorker] = useState(dataRow.lastWorker);
  const { confirmDialog, alertDialog } = useGsDialog();
  const avatarClr = useTheme().colors.blue[700];

  const {
    isOpen: isOpenFiles,
    onOpen: onOpenFiles,
    onClose: onCloseFiles,
  } = useDisclosure();

  const {
    isOpen: isOpenProductDiagram,
    onOpen: onOpenProductDiagram,
    onClose: onCloseProductDiagram,
  } = useDisclosure();

  const {
    isOpen: isOpenResourceReservation,
    onOpen: onOpenResourceReservation,
    onClose: onCloseResourceReservation,
  } = useDisclosure();

  const {
    isOpen: isOpenResourceReview,
    onOpen: onOpenResourceReview,
    onClose: onCloseResourceReview,
  } = useDisclosure();

  const {
    isOpen: isOpenInstructions,
    onOpen: onOpenInstructions,
    onClose: onCloseInstructions,
  } = useDisclosure();

  const {
    isOpen: isOpenTaskAssignment,
    onOpen: onOpenTaskAssignment,
    onClose: onCloseTaskAssignment,
  } = useDisclosure();

  const {
    isOpen: isOpenTaskAssignmentWorker,
    onOpen: onOpenTaskAssignmentWorker,
    onClose: onCloseTaskAssignmentWorker,
  } = useDisclosure();

  useEffect(() => {
    if (dataRow.taskStartTime === null) {
      setBtnName('Počni');
      setBtnClr('green');
      dataRow.hasPhaseResource
        ? setIsResourceRequired(true)
        : setIsResourceRequired(false);

      if (dataRow.hasReservedResources) {
        setIsResourceReservetionRequestSent(true);
        setBtnMaterialName('Stanje');
        setMaterialBtnClr('blue');
      } else {
        setMaterialBtnClr('orange');
        setBtnMaterialName('Zaduži');
      }
    } else {
      setBtnName('Završi');
      setBtnClr('red');
      setBtnMaterialName('Stanje');
      setMaterialBtnClr('blue');
    }
  }, []);

  useEffect(() => {
    if (itemPhase.workerId && btnName === 'Počni') {
      handleUserAction();
    }
  }, [shouldStartTask]);

  const handleUserAction = async () => {
    if (btnName === 'Počni') {
      if (!itemPhase.workerId) {
        onOpenTaskAssignment();
        return;
      }
      const phaseRequiredResources = await getPhaseResources(itemPhase);
      setBtnName('Završi');
      setIsLoading(true);
      let taskActionDTO: TaskActionDTO = { itemPhaseDTO: itemPhase };
      await taskAction(taskActionDTO, 'start');
      setIsLoading(false);
      setBtnClr('red');
    } else {
      onOpen();
      setPhaseAllRequestedResourcesOnEnd(
        await getRequestedResourceForTask(dataRow.taskId)
      );
      // setPhaseAllRequestedResourcesOnEnd(await getPhaseResources(itemPhase));
    }
  };

  const handleMaterialAction = async () => {
    if (btnMaterialName === 'Zaduži') {
      const phaseRequiredResources = await getPhaseResources(itemPhase);
      onOpenResourceReservation();
      setPhaseRequiredResources(phaseRequiredResources);
    }
    if (btnMaterialName == 'Stanje') {
      const reservedResources = await getRequestedResourceForTask(
        dataRow.taskId
      );
      setReservedResources(reservedResources);
      onOpenResourceReview();
    }
  };

  const handleResourceReservationClose = () => {
    setIsAnyModalOpen(false);
    handleInitalAndRefreshCalls(isChecked);
    onCloseResourceReservation();
  };

  const handleOnSaveWorker = async (worker: WorkerBySectorDTO) => {
    const userId = worker.userId;
    if (userId) {
      const itemPhaseUser = {
        ...itemPhase,
        workerId: userId,
      };
      const newWorker = await assignWorker(itemPhaseUser);
      if (newWorker) {
        setItemPhase({
          ...itemPhase,
          workerId: userId,
        });
        setCurrentWorker(worker);
        alertDialog(
          'Izmena sačuvana ',
          'Uspešno ste izmenili izvođača radova.',
          ALERT_GREEN
        );
      }
    } else alertDialog('Greška', 'Izaberite izvođača radova.', ALERT_RED);
  };

  return (
    <Tr>
      <Td textAlign='center'>{dataRow.orderCode}</Td>
      <Td textAlign='center'>
        {convertDate(
          dataRow.estimatedItemEnd === null
            ? dataRow.estimatedOrderEnd.toLocaleString()
            : dataRow.estimatedItemEnd.toLocaleString()
        )}
      </Td>
      <Td textAlign='center'>{dataRow.productCode}</Td>
      <Td textAlign='center'>{dataRow.expectedAmount}</Td>
      <Td textAlign='center'>
        <WorkerPriority priority={dataRow.priority} />
      </Td>

      <Td textAlign='center'>
        {dataRow.phaseDescription ? (
          <GsIconBtn
            label='instructions'
            icon={<InfoOutlineIcon />}
            onClick={onOpenInstructions}
          />
        ) : null}
      </Td>
      {isOpenInstructions && (
        <InstructionsModal
          isOpen={isOpenInstructions}
          onClose={onCloseInstructions}
          instructions={dataRow.phaseDescription}
        />
      )}

      <Td textAlign='center'>
        <GsIconBtn
          label='product scetch'
          icon={<ExternalLinkIcon />}
          onClick={onOpenProductDiagram}
        />
        <ProductDiagramModal
          isOpen={isOpenProductDiagram}
          onClose={onCloseProductDiagram}
          productId={dataRow.productId}
          phaseId={dataRow.phaseId}
        />
      </Td>

      <Td textAlign='center'>
        {dataRow.hasFile ? (
          <GsIconBtn
            label='download pdf drawing'
            icon={<DownloadIcon />}
            onClick={onOpenFiles}
          />
        ) : null}
        <WorkerShowFileModal
          productId={dataRow.productId}
          isOpen={isOpenFiles}
          onClose={onCloseFiles}
        />
      </Td>

      <Td textAlign='center'>
        <TaskAssignmentPopOver
          button={
            <Button
              style={{ background: 'transparent' }}
              onMouseEnter={onOpenTaskAssignmentWorker}
            >
              {currentWorker ? (
                <Avatar
                  size='md'
                  bg={avatarClr}
                  textColor='white'
                  name={currentWorker.workerName}
                />
              ) : (
                <Avatar icon={<FaUser />} bg={avatarClr} size='md' />
              )}
            </Button>
          }
          taskId={dataRow.taskId}
          isOpen={isOpenTaskAssignmentWorker}
          isTaskStarted={btnName === 'Završi'}
          onClose={onCloseTaskAssignmentWorker}
          workers={workers}
          currentWorker={currentWorker}
          onSave={handleOnSaveWorker}
        />
      </Td>

      <Td textAlign='center'>
        <Button
          colorScheme={materialBtnClr}
          onClick={handleMaterialAction}
          variant='outline'
        >
          {btnMaterialName}
        </Button>
      </Td>

      <Td textAlign='center'>
        {btnName === 'Završi' && (
          <Button
            isLoading
            colorScheme='teal'
            variant='outline'
            spinnerPlacement='end'
            spinner={<Spinner speed='2s' />}
            mr='3'
          />
        )}
        {!isLoading && (
          <TaskAssignmentPopOver
            button={
              <Button
                colorScheme={btnClr}
                onClick={handleUserAction}
                variant='outline'
                disabled={
                  isResourceRequired && !isResourceReservetionRequestSent
                }
                title={
                  isResourceRequired && !isResourceReservetionRequestSent
                    ? 'Morate zadužiti materijal'
                    : ''
                }
              >
                {btnName}
              </Button>
            }
            taskId={dataRow.taskId}
            isOpen={isOpenTaskAssignment}
            isTaskStarted={btnName === 'Završi'}
            onClose={onCloseTaskAssignment}
            workers={workers}
            currentWorker={currentWorker}
            onSave={(worker) => {
              if (btnName === 'Počni' && !currentWorker)
                handleOnSaveWorker(worker).then(() =>
                  setShouldStartTask(!shouldStartTask)
                );
              else setShouldStartTask(!shouldStartTask);
            }}
          />
        )}
        {isOpen && (
          <FinishedTaskModal
            isOpen={isOpen}
            onClose={onClose}
            itemPhase={itemPhase}
            onTaskFinishedHandler={onTaskFinishedHandler}
            amount={dataRow.expectedAmount}
            sectorMinWorkTime={sectorMinWorkTime}
            requestedResourcesForTask={phaseAllRequestedResourcesOnEnd}
          />
        )}
      </Td>

      {isOpenResourceReservation && (
        <ResourceReservationModal
          isOpen={isOpenResourceReservation}
          onClose={handleResourceReservationClose}
          setIsAnyModalOpen={setIsAnyModalOpen}
          isAnyModalOpen={isAnyModalOpen}
          phaseRequiredResources={phaseRequiredResources}
          productAmount={dataRow.expectedAmount}
          taskId={dataRow.taskId}
        />
      )}

      {isOpenResourceReview && (
        <ReservedResourceReviewModal
          isOpen={isOpenResourceReview}
          onClose={onCloseResourceReview}
          reservedResources={reservedResources}
          setReservedResources={setReservedResources}
          taskId={dataRow.taskId}
        />
      )}
    </Tr>
  );
};

export default WorkerRow;
