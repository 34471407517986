import { GET, POST } from '../../../api/requests';
import { SERVER_URL } from '../../../api/config';
import { format } from 'date-fns';

export type TaskStatusBySectorDTO = {
    sector_name : string;
    kreirano : number,
    izrada : number,
    zavrseno : number
  };

  export type NumberOfTasks = {
    sector_name : string;
    tasks : number,
  };

  export const getTaskStatusBySector = async (setTaskStatusBySector: any, dateFrom?: Date) => {
    const url = new URL(SERVER_URL + '/api/sector/report/task_status_by_sector');
    
    // If dateFrom is provided, format it and append to the URL search params
    if (dateFrom) {
      const formattedDateFrom = format(dateFrom, "yyyy-MM-dd HH:mm:ss");
      url.searchParams.append('dateFrom', formattedDateFrom);
    }
    
    // Call the API
    const data = await GET<TaskStatusBySectorDTO[]>(url.toString());
    setTaskStatusBySector(data);
    return data;
  };
