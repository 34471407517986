import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { customTheme } from './components/theme/custom-theme';
import App from './App';
import UserProvider from './contexts/user-context';
import { GsDialogContextProvider } from './contexts/GsConfirmDialogContext';
import './internationalization/i18n';
import NotificationConnectionProvider from './contexts/notificationsConnectionContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Router>
    <ChakraProvider theme={customTheme}>
      <UserProvider>
        <GsDialogContextProvider>
          <NotificationConnectionProvider>
            <Routes>
              <Route path='/*' element={<App />} />
            </Routes>
          </NotificationConnectionProvider>
        </GsDialogContextProvider>
      </UserProvider>
    </ChakraProvider>
  </Router>
);
