import {
  Box,
  Button,
  Flex,
  Input,
  FormErrorMessage,
  useColorModeValue,
  Select,
} from '@chakra-ui/react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useState, useEffect, useRef, FC } from 'react';
import { PasswordInput } from '../../../../login/log-in-form';
import { createUser, User } from '../../user-api';
import GsSpinner from '../../../../../components/spinner/gsSpinner';
import MultiSelectWithCheckbox from '../../components/multi-select/multi-select-drop-down';
import {
  getSectors1,
  Sector,
  getSectorsByUSerId,
} from '../../../sector/sector-apis';

const UserForm: FC<{ selectedUser?: User; onSubmit?: () => Promise<void> }> = ({
  selectedUser,
  onSubmit,
}) => {
  const nameRef = useRef<HTMLInputElement>(null);
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const [isPassInputInvalid, setPassInputInvalid] = useState(false);
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const [isConfirmPassInputInvalid, setConfirmPassInputInvalid] =
    useState(false);
  const [roleValue, setRoleValue] = useState<string>('ROLE_MANAGER');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sectors, setSectors] = useState<string[]>();
  const [selectedSectors, setSelectedSectors] = useState<string[]>([]);
  const allSectors = 'Svi sektori';

  useEffect(() => {
    getSectors1().then((sectors: Sector[]) => {
      const sectorNames = sectors.map((sector) => sector.name!);
      setSectors([allSectors, ...sectorNames]);
      if (selectedUser) {
        nameRef.current!.value = selectedUser.name;
        usernameRef.current!.value = selectedUser.username;
        const role = selectedUser.roles![0].name;
        setRoleValue(role);
      }
    });
    if (selectedUser?.id)
      getSectorsByUSerId(selectedUser?.id).then((sectors: Sector[]) => {
        const sectorNames = sectors.map((sector) => sector.name!);
        setSelectedSectors([...sectorNames]);
      });
    return () => {
      setSectors([]);
    };
  }, []);

  const toggleSector = (sector: string) => {
    if (sector === allSectors) {
      if (selectedSectors.includes(allSectors)) {
        setSelectedSectors([]);
      } else {
        setSelectedSectors(sectors!);
      }
    } else {
      setSelectedSectors((prevSelectedSectors) =>
        prevSelectedSectors.includes(sector)
          ? prevSelectedSectors.filter((s) => s !== sector)
          : [...prevSelectedSectors, sector]
      );
    }
  };

  function isUsernameValid(username: string | undefined): boolean {
    // Regular expression pattern to match letters, digits, "@", and "."
    const pattern = /^[a-zA-Z0-9@.]+$/;

    if (username) return pattern.test(username);
    else return false;
  }

  const validateInputsAndSaveUser = async () => {
    if (nameRef === null || nameRef.current?.value === '') {
      alert('Unesite ime');
      return;
    }
    if (usernameRef === null || usernameRef.current?.value === '') {
      alert('Unesite korisnicko ime');
      return;
    }
    if (
      usernameRef &&
      usernameRef.current?.value &&
      usernameRef.current.value.includes(' ')
    ) {
      alert('Korisnicko ime ne sme da ima razmak');
      return;
    }
    const isValid = isUsernameValid(usernameRef.current?.value);

    if (!isValid) {
      alert('Korisnicko ime moze sadrzati slova, brojeve, @ i .');
      return;
    }

    if (!selectedUser) {
      if (passwordRef === null || passwordRef.current?.value === '') {
        alert('Unesite sifru');
        return;
      }
      if (
        confirmPasswordRef === null ||
        confirmPasswordRef.current?.value === ''
      ) {
        alert('Unesite potvrdu sifre');
        return;
      }
      if (passwordRef.current?.value !== confirmPasswordRef.current?.value) {
        alert('Sifre se ne poklapaju');
        return;
      }
    }
    if (roleValue === '') {
      alert('Izaberite rolu');
      return;
    }
    if (roleValue === 'ROLE_WORKER' && selectedSectors.length === 0) {
      alert('Dodelite radniku sektor');
      return;
    }

    const newUser: User = {
      id: selectedUser?.id,
      name: nameRef.current!.value,
      username: usernameRef.current?.value as string,
      password: passwordRef.current?.value as string,
      role: roleValue,
      sectors:
        selectedSectors[0] === allSectors
          ? selectedSectors!.slice(1)
          : selectedSectors,
    };

    setIsLoading(true);
    const success = await createUser(newUser);
    setIsLoading(false);

    if (success) {
      if (nameRef.current != null) nameRef.current.value = '';
      if (usernameRef.current != null) usernameRef.current.value = '';
      if (passwordRef.current != null) passwordRef.current.value = '';
      if (confirmPasswordRef.current != null)
        confirmPasswordRef.current.value = '';
      setSelectedSectors([]);

      alert('Korisnik uspesno sacuvan');
    } else {
      alert('Greska pri cuvanju korisnika');
    }

    //for the edit user page
    if (onSubmit) await onSubmit();
  };

  return (
    <Box px='5'>
      <Flex flexDirection='row' gap='6'>
        <Flex flexDirection='column' mb='50' gap='6' width={500} mr='20'>
          <FormControl>
            <FormLabel>Ime i prezime korisnika</FormLabel>
            <Input
              type='text'
              ref={nameRef}
              placeholder='Unesite ime i prezime novog korisnika'
              sx={{
                '::placeholder': {
                  fontStyle: 'italic',
                },
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Korisničko ime</FormLabel>
            <Input
              type='text'
              ref={usernameRef}
              placeholder='Unesite korisničko ime'
              sx={{
                '::placeholder': {
                  fontStyle: 'italic',
                },
              }}
            />
          </FormControl>
          {!selectedUser && (
            <>
              <FormControl isInvalid={isPassInputInvalid}>
                <FormLabel>Lozinka</FormLabel>
                <PasswordInput
                  passwordRef={passwordRef}
                  setInputInvalid={setPassInputInvalid}
                  placeholderText='Unesite lozinku za korisnika'
                />
                <FormErrorMessage>{'Popunite polje.'}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={isConfirmPassInputInvalid}>
                <FormLabel>Potvrdite lozinku</FormLabel>
                <PasswordInput
                  passwordRef={confirmPasswordRef}
                  setInputInvalid={setConfirmPassInputInvalid}
                  placeholderText='Ponovite lozinku'
                />
                <FormErrorMessage>{'Popunite polje.'}</FormErrorMessage>
              </FormControl>
            </>
          )}
        </Flex>

        <Flex
          flexDirection='column'
          mb='50'
          gap='6'
          width={500}
          alignItems='flex-start'
          position='relative'
        >
          <FormControl>
            <FormLabel>Tip korisnika</FormLabel>
            <Select
              value={roleValue}
              onChange={(e) => {
                if (!e.target.value || e.target.value.length === 0) return;
                setRoleValue(e.target.value);
              }}
            >
              <option value='ROLE_MANAGER'>Menadžer</option>
              <option value='ROLE_OFFICE_WORKER'>
                Menadžer za proizvodnju
              </option>
              <option value='ROLE_WORKER'>Radnik</option>
            </Select>
          </FormControl>

          {roleValue === 'ROLE_WORKER' && (
            <FormControl>
              <MultiSelectWithCheckbox
                selectedSectors={selectedSectors}
                sectors={sectors!}
                toggleSector={toggleSector}
              />
            </FormControl>
          )}

          {isLoading ? (
            <GsSpinner />
          ) : (
            <Button
              // position='absolute'
              // width={100}
              marginTop='50px'
              alignSelf='flex-end'
              textAlign='center'
              _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
              variant='outline'
              onClick={validateInputsAndSaveUser}
            >
              Sačuvaj
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default UserForm;
