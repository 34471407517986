import { exists } from 'fs';
import {
  COLOR_FINISHED_TASK,
  COLOR_IN_PROGRESS_TASK,
  COLOR_NEW_TASK,
} from './colorConstatns';

export function getTaskColor(status: string) {
  const backgroundColor =
    status === 'COMPLETE'
      ? COLOR_FINISHED_TASK
      : status === 'IN_PROGRESS'
      ? COLOR_IN_PROGRESS_TASK
      : COLOR_NEW_TASK;

  return backgroundColor;
}

export const tableCaptionGenerator = (
  array: any,
  notExists: string,
  exists: string
) => {
  if (array.length === 0) {
    return notExists;
  } else {
    return exists;
  }
};

export const checkTwoSpaces = (value: string) => {
  return /  /.test(value);
};

export const isNumberValid = (number: string | undefined) => {
  if (number === undefined || number === '') return false;
  if (isNaN(Number(number))) {
    return false;
  }
  return true;
};

export const isNumberValidAndGreaterThenZero = (number: string | undefined) => {
  return number && !isNaN(Number(number)) && Number(number) > 0;
};
