import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  FormErrorMessage,
  Textarea,
  Box,
  Flex,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { FC } from 'react';
import {
  OrderItemPhaseDTO,
  RequestedResourceForTask,
  taskAction,
  TaskActionDTO,
  usedResource,
} from '../../worker-api';
import { WarningIcon } from '@chakra-ui/icons';
import { Resource } from '../../../manager/resource/resource-api';
import { isNumberValid } from '../../../../utilility/util';
import WorkerPhaseResourceTable from './workerPhaseResourceTable';
import { useTranslation } from 'react-i18next';

const FinishedTaskModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  onTaskFinishedHandler: () => void;
  itemPhase: OrderItemPhaseDTO;
  amount: number;
  sectorMinWorkTime: number;
  requestedResourcesForTask: RequestedResourceForTask[];
}> = ({
  isOpen,
  onClose,
  onTaskFinishedHandler,
  itemPhase,
  amount,
  sectorMinWorkTime,
  requestedResourcesForTask,
}) => {
  const completedRef = useRef<HTMLInputElement>(null);
  const [completeAmount, setCompleteAmount] = useState<Number>(0);
  const [note, setNote] = useState('');
  const [isInputValid, setIsInputValid] = useState(true);
  const [errMsg, setErrMsg] = useState('Mora biti broj.');
  const [warrningMessage, showWarningMessage] = useState('');
  const [disableFinish, setDisableFinish] = useState<boolean>(false);
  const [usedResources, setUsedResources] = useState<usedResource[]>([]);
  const disclosureResource = useDisclosure();
  const { t } = useTranslation();

  const mustBeNumberErrorMessage = 'Mora biti broj.';

  const onSubmitEndTask = async () => {
    const completedAmount = parseFloat(completedRef.current?.value as string);

    if (isNaN(completedAmount)) {
      setErrMsg(mustBeNumberErrorMessage);
      setIsInputValid(false);
      return;
    }
    if (completeAmount < 0) {
      setErrMsg('Kolicina mora biti 0 ili veca');
      setIsInputValid(false);
      return;
    }
    if (completedAmount > amount) {
      setErrMsg(`Broj ne sme biti veći od zadate količine (${amount}).`);
      setIsInputValid(false);
      return;
    }
    itemPhase.completedAmount = completedAmount;
    itemPhase.note = note;
    itemPhase.sectorMinWorkTime = sectorMinWorkTime;

    // Transform changedPhaseResources into usedResources format
    const updatedUsedResources =
      requestedResourcesForTask.map(transformResource);

    // Set the transformed array to usedResources state
    setUsedResources(updatedUsedResources);
    console.log(updatedUsedResources);

    let taskActionDTO: TaskActionDTO = {
      itemPhaseDTO: itemPhase,
      usedResources: updatedUsedResources,
    };

    const isComplete = await taskAction(
      taskActionDTO,
      'end',
      showWarningMessage
    );
    if (isComplete) {
      onTaskFinishedHandler();
    }
  };

  const transformResource = (
    resourceAmount: RequestedResourceForTask
  ): usedResource => {
    const { resource, amount } = resourceAmount;

    // Ensure that resource and amount are defined before creating the usedResource object
    // If resource or amount is undefined, assign default values or handle it as needed
    const usedResourceObj: usedResource = {
      resource: resource as Resource,
      amount: amount as number,
    };

    return usedResourceObj;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent minWidth={'50%'}>
        <ModalHeader>Faza završena</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir='row' mb='35' justifyContent='space-between'>
            <FormControl isInvalid={!isInputValid}>
              <FormLabel>Uspešno odrađena količina:</FormLabel>
              <Input
                ref={completedRef}
                placeholder='Količina'
                fontSize={20}
                onChange={(e) => {
                  if (!isNumberValid(e.target.value)) {
                    setIsInputValid(false);
                    setErrMsg(mustBeNumberErrorMessage);
                    return;
                  }
                  setCompleteAmount(Number(e.target.value));
                  setIsInputValid(true);
                }}
              />
              <FormErrorMessage>{errMsg}</FormErrorMessage>
            </FormControl>
            <Box ml={4} mt={8}>
              <Text fontSize={30}>/</Text>
            </Box>
            <FormControl mt={8} ml={4}>
              <Input
                borderColor={'black.500'}
                borderWidth='2px'
                value={amount}
                fontSize={20}
                textAlign='left'
                isDisabled={true}
              />
            </FormControl>
          </Flex>
          <WorkerPhaseResourceTable
            requestedResourcesForTask={requestedResourcesForTask}
            completedAmount={completeAmount}
            wantedAmount={amount}
          ></WorkerPhaseResourceTable>
          <FormControl mb={5} mt={7}>
            <FormLabel>Napomena:</FormLabel>
            <Textarea
              placeholder='Ukoliko imate napomenu unesite je ovde'
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </FormControl>
          {warrningMessage !== '' && (
            <Box display='flex' alignItems='center' justifyContent='center'>
              <WarningIcon boxSize={5} mr={2} color='red.500' />
              <label style={{ color: 'red' }}>{t(warrningMessage)}</label>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme='red'
            onClick={onSubmitEndTask}
            isDisabled={disableFinish}
            w='15%'
          >
            Završi
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FinishedTaskModal;
