import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorModeValue,
} from '@chakra-ui/react';
import { ResponsiveContainer } from 'recharts';
import GsHeaderModal from '../../../../components/heading/GsHeaderModal';
import CustomBarChart from '../components/customBarChart';
import { TaskStatusBySectorDTO } from '../home-apis';

interface BarChartModalProps {
  isOpen: boolean;
  onClose: () => void;
  taskStatusBySector: TaskStatusBySectorDTO[];
  caption: string;
}

const BarChartModal: React.FC<BarChartModalProps> = ({
  isOpen,
  onClose,
  taskStatusBySector,
  caption,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
      <ModalOverlay />
      <ModalContent maxW='80vw'>
        <GsHeaderModal title={caption} />
        <ModalCloseButton />
        <ModalBody>
          <ResponsiveContainer width='100%' height={700}>
            <CustomBarChart
              taskStatusBySector={taskStatusBySector}
              caption='Broj završenih operacija po sektoru'
              showFilters={true}
            />
          </ResponsiveContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BarChartModal;
