import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Flex,
  ModalFooter,
  Button,
  Text,
  RadioGroup,
  Stack,
  Radio,
  useColorModeValue,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';

const ConfirmationOrderModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  onSave: any;
}> = ({ isOpen, onClose, onSave }) => {
  const [selectedOption, setSelectedOption] = useState('sendToProduction'); // Default option

  const handleSave = () => {
    onSave(selectedOption);
    onClose();
  };

  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='lg'>
      <ModalContent>
        <ModalHeader mb={5}>Izbor statusa porudžbenice</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup value={selectedOption} onChange={setSelectedOption}>
            <Stack spacing={8}>
              <Radio value='sendToProduction' size='lg'>
                <Text fontSize='lg'>Aktiviraj porudžbenicu za proizvodnju</Text>
                <Text fontSize='md' color='gray.500'>
                  Porudžbenica će biti automatski aktivirana i radnicima će biti
                  dodeljeni taskovi
                </Text>
              </Radio>
              <Radio value='saveForFuture' size='lg'>
                <Text fontSize='lg'>Sačuvaj i aktiviraj naknadno</Text>
                <Text fontSize='md' color='gray.500'>
                  Porudžbenica će biti sačuvana u listi neaktivnih porudžbenica
                  i moći će naknadno da se aktivira za proizvodnju
                </Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
            variant='outline'
            mr={3}
            onClick={handleSave}
          >
            Potvrdi
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationOrderModal;
