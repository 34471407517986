import { Button, useColorModeValue } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';

const GsBtn: FC<{
  text: string;
  onClick: () => void;
  disabled?: boolean;
}> = ({ text, onClick, disabled }) => {
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');

  return (
    <Button
      onClick={onClick}
      _hover={{
        textDecor: 'none',
        bgColor: logoutBtnClr,
        textColor: 'white',
        borderColor: 'white',
        borderWidth: '1px',
      }}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default GsBtn;
