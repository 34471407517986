import { Box, Flex, Center } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';

export interface TableNavigation {
  pageNumberHandler: (num: number) => void;
  pageNumber: number;
}

export function TableNavigation({
  pageNumberHandler,
  pageNumber,
}: TableNavigation) {
  return (
    <Center marginBottom={3}>
      <Box>
        <Flex
          flexDirection='row'
          // mb='50'
          align={'center'}
        >
          <ArrowLeftIcon
            marginRight={10}
            onClick={() => pageNumberHandler(-1)}
          />
          {pageNumber + 1}
          <ArrowRightIcon
            marginLeft={10}
            onClick={() => pageNumberHandler(+1)}
          />
        </Flex>
      </Box>
    </Center>
  );
}
