import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import { Node, Edge, useEdgesState, useNodesState } from 'reactflow';
import { FC, useEffect, useState } from 'react';
import GsHeaderModal from '../../../../components/heading/GsHeaderModal';
import { SaveProductDTO } from '../../../manager/product/product-api';
import { getProductInfo } from '../../worker-api';
import FlowDiagramWorker from './FlowDiagramWorker';
import { ProductPhaseContext } from './ProductPhaseContext';

type ProductDiagramModalProps = {
  isOpen: boolean;
  onClose: () => void;
  productId: number;
  phaseId: number;
};

export type NodeDataWorker = {
  sectorId: number;
  sector: string;
  description: string;
  productId: number;
  phaseId: number;
};

const ProductDiagramModal: FC<ProductDiagramModalProps> = ({
  isOpen,
  onClose,
  productId,
  phaseId,
}) => {
  const [productInfo, setProductInfo] = useState<SaveProductDTO>();
  const [nodes, setNodes, onNodesChange] = useNodesState<NodeDataWorker>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);

  useEffect(() => {
    if (isOpen)
      getProductInfo(productId).then((prodInfo) => setProductData(prodInfo));
  }, [isOpen]);

  const setProductData = (product: SaveProductDTO) => {
    setEdges(product.edges);
    setNodeData(product);
  };

  const setNodeData = (productData: SaveProductDTO) => {
    const nodesData: Node<NodeDataWorker>[] =
      productData.product.productPhases.map((phase) => {
        return {
          id: phase.id!.toString(),
          type: 'selectorNode',
          data: {
            sectorId: phase.sector.id,
            sector: phase.sector.name,
            description: phase.description,
            productId: productData.product.id,
            phaseId: phase.id,
          },
          position: { x: phase.x, y: phase.y },
          height: phase.height,
          width: phase.width,
        } as Node;
      });
    setNodes(nodesData);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
      <ModalOverlay />
      <ModalContent>
        <GsHeaderModal title='Skica proizvoda po sektorima' />
        <ModalCloseButton />
        <ModalBody pb={6}>
          <ProductPhaseContext.Provider value={{ productId, phaseId }}>
            <FlowDiagramWorker nodes={nodes} edges={edges} />
          </ProductPhaseContext.Provider>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default ProductDiagramModal;

export {};
