import { DownloadIcon } from '@chakra-ui/icons';
import { Tr, Td } from '@chakra-ui/react';
import GsIconBtn from '../../../../../components/button/GsIconBtn';
import { openFile } from '../../../worker-api';

export interface FileTableRowProps {
  productId: number;
  file: string;
}

const FileTableRow = ({ productId, file }: FileTableRowProps) => {
  return (
    <Tr borderBottom='1px'>
      <Td textAlign='center'>{file}</Td>
      <Td textAlign='center'>
        <GsIconBtn
          label='download-file-icon'
          icon={<DownloadIcon />}
          onClick={() => openFile(productId, file)}
        />
      </Td>
    </Tr>
  );
};

export default FileTableRow;
