// home-page.tsx
import { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  Button,
  useDisclosure,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import AppLayout from '../../../components/layout/app-layout';
import { TaskStatusBySectorDTO, getTaskStatusBySector } from './home-apis';
import CustomPieChart from './components/customPieChart';
import CustomBarChart from './components/customBarChart';
import PieChartModal from './modal/PieChartModal';
import BarChartModal from './modal/BarChartModal';
import OverviewOrderPage from '../order/overview-order';
import { useChakra } from '@chakra-ui/react';
import { ResponsiveContainer } from 'recharts';
import GsIconBtn from '../../../components/button/GsIconBtn';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const HomePageManager = () => {
  const { theme, colorMode } = useChakra();
  const {
    isOpen: isPieChartModalOpen,
    onOpen: onPieChartModalOpen,
    onClose: onPieChartModalClose,
  } = useDisclosure();
  const {
    isOpen: isBarChartModalOpen,
    onOpen: onBarChartModalOpen,
    onClose: onBarChartModalClose,
  } = useDisclosure();
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const [numberOfTasksBySector, setNumberOfTasksBySector] = useState<
    TaskStatusBySectorDTO[]
  >([]);
  const [recentTasksBySector, setRecentTasksBySector] = useState<
    TaskStatusBySectorDTO[]
  >([]);

  useEffect(() => {
    const callGetResources = async () => {
      // Fetch data for the pie chart for the default time period (which is handled by the API's default parameters)
      const allTasks = await getTaskStatusBySector(setNumberOfTasksBySector);
      setNumberOfTasksBySector(allTasks);

      // Calculate the date from 3 months ago
      const startDateForDataFetch = new Date();
      startDateForDataFetch.setMonth(startDateForDataFetch.getMonth() - 3);

      // Fetch data for the bar chart with dateFrom set to 3 months ago
      const recentTasks = await getTaskStatusBySector(
        setRecentTasksBySector,
        startDateForDataFetch
      );
      setRecentTasksBySector(recentTasks);
    };
    callGetResources();
  }, []);

  return (
    <AppLayout>
      <Flex flexDir='column' w='100%'>
        <OverviewOrderPage />
        <Flex flexDir='row' w='100%'>
          <Box
            w='50%'
            p='10'
            bg={theme.colors.bg.colorMode(colorMode)}
            borderRadius='10'
            borderWidth={2}
            id={'id1'}
          >
            <Flex justify='center' gap='2' alignItems='center' w='100%'>
              <Heading
                as='h2'
                size='l'
                color='white'
                fontFamily='Source Sans Pro, sans-serif'
                fontSize='24px'
                fontStyle='bold'
                fontWeight={400}
                lineHeight='32px'
              >
                Broj završenih operacija po sektoru
              </Heading>
              <GsIconBtn
                label='show bar chart'
                icon={<ExternalLinkIcon />}
                onClick={onBarChartModalOpen}
              />
            </Flex>
            <ResponsiveContainer width='100%' height='100%'>
              <CustomBarChart
                taskStatusBySector={recentTasksBySector}
                caption=''
                showFilters={false}
              />
            </ResponsiveContainer>
          </Box>
          <Box
            w='50%'
            p='10'
            bg={theme.colors.bg.colorMode(colorMode)}
            borderRadius='10'
            borderWidth={2}
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            id={'id2'}
          >
            <Flex justify='center' gap='2' alignItems='center' w='100%'>
              <Heading
                as='h2'
                size='l'
                color='white'
                fontFamily='Source Sans Pro, sans-serif'
                fontSize='24px'
                fontStyle='bold'
                fontWeight={400}
                lineHeight='32px'
              >
                Broj zadatih operacija po sektoru
              </Heading>
              <GsIconBtn
                label='show pie chart'
                icon={<ExternalLinkIcon />}
                onClick={onPieChartModalOpen}
              />
            </Flex>
            <ResponsiveContainer width='100%' height='100%'>
              <CustomPieChart
                taskStatusBySector={numberOfTasksBySector}
                caption=''
                showFilters={false}
              />
            </ResponsiveContainer>
          </Box>
        </Flex>

        <BarChartModal
          isOpen={isBarChartModalOpen}
          onClose={onBarChartModalClose}
          taskStatusBySector={recentTasksBySector}
          caption='Broj završenih operacija po sektoru'
        />

        <PieChartModal
          isOpen={isPieChartModalOpen}
          onClose={onPieChartModalClose}
          taskStatusBySector={numberOfTasksBySector}
          caption='Broj trenutno zadatih operacija po sektoru'
        />
      </Flex>
    </AppLayout>
  );
};

export default HomePageManager;
