import { Flex, Heading, Input, Select, useDisclosure } from '@chakra-ui/react';
import { FormControl, FormLabel, FormHelperText, Box, Button } from '@chakra-ui/react';
import React, { useState, useRef, FC, useEffect, useContext, ChangeEvent } from 'react';
import {
  ProductPhase,
  createProduct,
  Product,
  SaveProductDTO,
  PhaseResource,
} from '../../product-api';

import FlowDiagram from '../flow/FlowDiagram';
import {
  Node,
  Edge,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import UploadFileModal from '../file/UploadFileModal';
import GsIconBtn from '../../../../../components/button/GsIconBtn';
import { EditIcon } from '@chakra-ui/icons';
import { NodeData } from '../../contexts/NodeContext';
import { ReadOnlyContext } from '../../contexts/ReadOnlyContext';
import GsBtn from '../../../../../components/button/GsBtn';
import {
  ALERT_GREEN,
  ALERT_RED,
  useGsDialog,
} from '../../../../../contexts/GsConfirmDialogContext';

import AddMeasurementUnitModal from '../../../measurementUnit/components/addMeasurementUnit';
import {
  getMeasurementUnits,
  MeasurementUnit,
  createMeasurementUnitObject,
} from '../../../measurementUnit/measurementUnit-api';

type ProductFormProps = {
  product?: SaveProductDTO;
  onSaveProduct?: () => void;
};

const ProductForm: FC<ProductFormProps> = ({ product, onSaveProduct }) => {
  const [addedProductPhases, setAddedProductPhases] = useState<ProductPhase[]>(
    []
  );
  const [phaseResources, setPhaseResources] = useState<PhaseResource[]>([]);
  const [nodes, setNodes, onNodesChange] = useNodesState<NodeData>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const productCodeRef = useRef<HTMLInputElement>(null);
  const productDescRef = useRef<HTMLInputElement>(null);
  const [measureUnit, setMeasurementUnit] = useState<string>('');
  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const [newNodeId, setNewNodeId] = useState<number>(1);
  const [measurementUnits, setMeasurementUnits] = useState<MeasurementUnit[]>(
    []
  ); 
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const {
    isOpen: isOpenFile,
    onOpen: onOpenFile,
    onClose: onCloseFile,
  } = useDisclosure();
  const {
    isOpen: isOpenAddMeasurementUnitModal,
    onOpen: onOpenAddMeasurementUnitModal,
    onClose: onCloseAddMeasurementUnitModal,
  } = useDisclosure();
  const loadMeasurementUnits = async (setIsLoading?: any) => {
    setIsLoading(false);
    const units = await getMeasurementUnits();
    setMeasurementUnits(units);
  };
  const [isLoading, setIsLoading] = useState(false);
  const { isReadOnly } = useContext(ReadOnlyContext);
  const { alertDialog } = useGsDialog();
  
  useEffect(() => {
    if (product) setProductToEdit(product);
    loadMeasurementUnits(setIsLoading);
    console.log(product)
  }, []);

  const setProductToEdit = (product: SaveProductDTO) => {
    setAddedProductPhases(product.product.productPhases);
    setResourcesToEdit(product);
    setEdges(product.edges);
    setNodesToEdit(product);
    productCodeRef.current!.value = product.product.productCode;
    productDescRef.current!.value = product.product.description;
    setMeasurementUnit(product.product.measureUnit?.unitName);
  };

  const setResourcesToEdit = (product: SaveProductDTO) => {
    const initialPhaseResources: PhaseResource[] =
      product.product.productPhases.map((phase) => ({
        phase_id: Number(phase.id),
        resources: [],
      }));
    product.phaseResources.forEach((resource) => {
      const foundPhaseResource = initialPhaseResources.find(
        (pr) => pr.phase_id == resource.phase_id
      );
      if (foundPhaseResource) foundPhaseResource.resources = resource.resources;
    });
    setPhaseResources(initialPhaseResources);
  };

  const setNodesToEdit = (product: SaveProductDTO) => {
    const largestNodeId = Math.max(
      ...product.product.productPhases.map((phase) => Number(phase.id))
    );
    setNewNodeId(largestNodeId + 1);
    const nodesData: Node<NodeData>[] = product.product.productPhases.map(
      (phase) => {
        const resources = product.phaseResources.find((resource) => {
          return resource.phase_id === (phase.id as unknown as number);
        })?.resources;

        return {
          id: phase.id!.toString(),
          type: 'selectorNode',
          data: {
            sectorId: phase.sector.id,
            sector: phase.sector.name,
            description: phase.description,
            resources: resources ? resources : [],
          },
          position: { x: phase.x, y: phase.y },
          height: phase.height,
          width: phase.width,
        } as Node;
      }
    );
    setNodes(nodesData);
  };

  const handleOnAddFile = () => {
    fileRef.current?.click();
  };
  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    let selectedFilesArray = Array.from(selectedFiles);
    if (currentFiles)
      selectedFilesArray = selectedFilesArray.concat(currentFiles);
    setCurrentFiles(selectedFilesArray);
    setSelectedFileName(selectedFiles?.[0].name);
    event.target.value = '';
  };

  const handleOnAddProductPhase = (
    newNode: Node<NodeData>,
    nodeId?: number
  ) => {
    if (nodeId) updatePhase(newNode, nodeId);
    else createPhase(newNode);
  };
  const updatePhase = (newNode: Node<NodeData>, nodeId: number) => {
    const updatedProductPhases = addedProductPhases.map((productPhase) => {
      if (Number(productPhase.id) == nodeId)
        return {
          ...productPhase,
          description: newNode.data.description,
          sector: {
            id: newNode.data.sectorId,
            name: newNode.data.sector,
          },
          orderIndex: 1,
          height: newNode.height,
          width: newNode.width,
          x: newNode.position.x,
          y: newNode.position.y,
        } as ProductPhase;
      return productPhase;
    });
    setAddedProductPhases(updatedProductPhases);
    const updatedPhaseResources = phaseResources.map((phaseResource) => {
      if (phaseResource.phase_id == nodeId)
        return {
          ...phaseResource,
          resources: newNode.data.resources,
        };
      return phaseResource;
    });
    setPhaseResources(updatedPhaseResources);
  };
  const createPhase = (newNode: Node<NodeData>) => {
    setAddedProductPhases([
      ...addedProductPhases,
      {
        id: String(newNodeId),
        description: newNode.data.description,
        sector: {
          id: newNode.data.sectorId,
          name: newNode.data.sector,
        },
        orderIndex: 1,
        height: newNode.height,
        width: newNode.width,
        x: newNode.position.x,
        y: newNode.position.y,
      } as ProductPhase,
    ]);
    setPhaseResources([
      ...phaseResources,
      { phase_id: newNodeId, resources: newNode.data.resources },
    ]);
    setNewNodeId((newNodeId) => newNodeId + 1);
  };

  const handleOnDeleteProductPhase = (nodeId: number) => {
    const updatedProductPhases = addedProductPhases.filter(
      (phase) => phase.id != nodeId.toString()
    );
    const updatedPhaseResources = phaseResources.filter(
      (resource) => resource.phase_id != nodeId
    );
    setAddedProductPhases(updatedProductPhases);
    setPhaseResources(updatedPhaseResources);
  };

  const handleOnUpdateProductPhasePosition = (node: Node) => {
    const updatedProductPhases = addedProductPhases.map((productPhase) => {
      if (productPhase.id == node.id)
        return {
          ...productPhase,
          x: node.position.x,
          y: node.position.y,
        } as ProductPhase;
      return productPhase;
    });
    setAddedProductPhases(updatedProductPhases);
  };

  const validateForm = () => {
    const productCode = productCodeRef.current?.value;
    const productDesc = productDescRef.current?.value;
    const headerMsg = 'Greška';

    if (!productCode) {
      alertDialog(headerMsg, 'Unesite šifru proizvoda.', ALERT_RED);
      return false;
    } else if (!productDesc) {
      alertDialog(headerMsg, 'Unesite naziv proizvoda.', ALERT_RED);
      return false;
    } else if (!measureUnit) {
      alertDialog(headerMsg, 'Izaberite mernu jedinicu.', ALERT_RED);
      return false;
    } else if (addedProductPhases.length === 0) {
      alertDialog(
        headerMsg,
        'Proizvod mora imati najmanje jednu fazu izrade.',
        ALERT_RED
      );
      return false;
    }
    return true;
  };

  const handleOnSaveProduct = async () => {
    if (!validateForm()) return;
    const success = await createProduct(
      {
        product: {
          id: product ? product.product.id : undefined,
          description: productDescRef.current?.value,
          productCode: productCodeRef.current?.value,
          measureUnit: createMeasurementUnitObject(measureUnit),
          productPhases: addedProductPhases,
          requiredResources: product?.product.requiredResources,
        } as Product,
        edges: edges,
        phaseResources: phaseResources,
      } as SaveProductDTO,
      currentFiles
    );
    if (success === true) {
      setAddedProductPhases([]);
      setNodes([]);
      setEdges([]);
      setCurrentFiles([]);
      setSelectedFileName('');
      setPhaseResources([]);

      if (productCodeRef.current != null) {
        productCodeRef.current.value = '';
      }
      if (productDescRef.current != null) {
        productDescRef.current.value = '';
      }
      if (measureUnit != null) {
        setMeasurementUnit('');
      }
      if (fileRef.current != null) {
        fileRef.current.value = '';
      }

      alertDialog('Proizvod sačuvan', '', ALERT_GREEN);
    } else {
      alertDialog('Greška', 'Greška pri čuvanju proizvoda.', ALERT_RED);
    }
    if (onSaveProduct) onSaveProduct();
  };

  return (
    <Flex flexDir='column' px='5'>
      <Flex flexDir='row' mb='35' justifyContent='space-between' alignItems='flex-end'>
        <FormControl mr='10' flex='1'>
          <FormLabel>Šifra proizvoda</FormLabel>
          <Input type='text' ref={productCodeRef} isDisabled={isReadOnly} />
          <FormHelperText fontStyle='italic'>
            Unesite šifru proizvoda.
          </FormHelperText>
        </FormControl>
        <FormControl mr='10' flex='1'>
          <FormLabel>Naziv proizvoda</FormLabel>
          <Input type='text' ref={productDescRef} isDisabled={isReadOnly} />
          <FormHelperText fontStyle='italic'>
            Unesite naziv proizvoda.
          </FormHelperText>
        </FormControl>
        <FormControl mr='10' flex='1'>
          <Box display='flex' alignItems='center' mb={2}>
            <FormLabel mt='0' mb='0' mr='2'>
              Merna jedinica
            </FormLabel>
            <Button
              size='xs'
              rounded='md'
              _hover={{ textDecor: 'none', bgColor: 'logoutBtnClr' }}
              variant='outline'
              onClick={onOpenAddMeasurementUnitModal}
            >
              +
            </Button>
          </Box>
          <Select 
            placeholder='Izaberite mernu jedinicu' 
            value={measureUnit}
            onChange={(e) => {
              if (!e.target.value || e.target.value.length === 0) return;
              setMeasurementUnit(e.target.value);
            }}            
          >
            {measurementUnits.map((unit, index) => (
              <option key={index} value={unit.unitName}>
                {unit.unitName}
              </option>
            ))}
          </Select>         
          <FormHelperText fontStyle='italic'>
            Izaberite mernu jedinicu.
          </FormHelperText>
        </FormControl>
        <FormControl width='auto'>
          <Flex borderWidth='2px' borderRadius='5px' p='10px' mr='10' mt='5'>
            <Heading
              as='h3'
              whiteSpace='nowrap'
              color='white'
              fontFamily='Source Sans Pro, sans-serif'
              fontSize='22px'
              fontWeight='400'
              mr='5'
            >
              Crteži
            </Heading>
            <GsIconBtn
              label='add product drawing'
              onClick={onOpenFile}
              icon={<EditIcon />}
            />
          </Flex>
          <FormHelperText fontStyle='italic'>
            Pregled pdf crteža.
          </FormHelperText>
        </FormControl>
        <UploadFileModal
          isOpen={isOpenFile}
          onClose={onCloseFile}
          addFile={handleOnAddFile}
          fileList={currentFiles}
          setFiles={setCurrentFiles}
          productToEdit={product}
        />
        <Input
          id='fileInput'
          type='file'
          multiple={true}
          onChange={selectFile}
          ref={fileRef}
          display='none'
        />
      </Flex>
      <AddMeasurementUnitModal
        onOpen={onOpenAddMeasurementUnitModal}
        onClose={onCloseAddMeasurementUnitModal}
        isOpen={isOpenAddMeasurementUnitModal}
        setMeasurementUnits={setMeasurementUnits}
        measurementUnits={measurementUnits}
      />      
      <ReactFlowProvider>
        <FlowDiagram
          nodes={nodes}
          setNodes={setNodes}
          newNodeId={newNodeId}
          edges={edges}
          setEdges={setEdges}
          onEdgesChange={onEdgesChange}
          onNodesChange={onNodesChange}
          onAddProductPhase={handleOnAddProductPhase}
          onDeleteProductPhase={handleOnDeleteProductPhase}
          updateProductPhasePositions={handleOnUpdateProductPhasePosition}
        />
      </ReactFlowProvider>
      <Flex justifyContent='flex-end' mt='50px'>
        <GsBtn
          onClick={handleOnSaveProduct}
          text='Sačuvaj proizvod'
          disabled={isReadOnly}
        />
      </Flex>
    </Flex>
  );
};

export default ProductForm;
