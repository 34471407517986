import { GET, POST } from '../../api/requests';
import { getPaginationParams } from '../../components/TabeNavigation/table-navigation';
import { SERVER_URL } from '../../api/config';
import { Exception409 } from '../../exceptions/Exception409';
import { Exception510 } from '../../exceptions/Exception510';
import {
  ProductPhase,
  ResourceRequiredAmmount,
  SaveProductDTO,
} from '../manager/product/product-api';
import {
  GET_PHASE_RESOURCE_REQUIRED,
  GET_PREVIOUS_SECTORS,
  GET_RESERVED_RESOURCE_FOR_TASK,
} from '../../api/apiConstants';
import { Resource } from '../manager/resource/resource-api';
import { User } from '../login/login-api';
import { Sector } from '../manager/sector/sector-apis';

export type TableDataDTO = {
  sectorName: string;
  sectorMinWorkTime: number;
  workerPageDTO: {
    phaseId: number;
    phaseDescription: string;
    productId: number;
    productDescription: string;
    productCode: string;
    taskStartTime: Date;
    expectedAmount: number;
    orderItemId: number;
    estimatedItemEnd: Date;
    priority: string;
    orderCode: string;
    estimatedOrderEnd: Date;
    taskId: number;
    hasFile: boolean;
    hasPhaseResource: boolean;
    hasPreviousSector: boolean;
    lastWorker: WorkerBySectorDTO;
    hasReservedResources: boolean;
  }[];
};

export type RequestedResourceForTask = {
  id?: number;
  requestedAmount?: number;
  approvedAmount?: number;
  status?: string;
  resource: Resource;
  amount?: number;
  usedAmount?: number;
};

export const getWorkerData = async (
  sectorId: number,
  pageNo: number,
  orderCode: string,
  productCode: string,
  userId: string,
  instructions: string,
  isChecked: boolean,
  isExport?: string
) => {
  let url = new URL(SERVER_URL + '/worker/sector/' + sectorId);
  let params = new URLSearchParams();
  params = getPaginationParams(params, pageNo);

  if (productCode !== '') params.append('productCode', productCode);
  if (orderCode !== '') params.append('orderCode', orderCode);
  params.append('userId', userId);

  if (instructions !== '') params.append('instructions', instructions);

  if (isChecked) params.append('isChecked', 'true');

  if (isExport != undefined && isExport != null) {
    params.append('isExport', isExport);
  }

  url.search = params.toString();
  console.log(url.toString);
  return await GET<TableDataDTO>(url.toString());

  //return await GET<TableDataDTO>(SERVER_URL + '/worker/sector/' + sectorId);
};

export type TaskActionDTO = {
  itemPhaseDTO: OrderItemPhaseDTO;
  usedResources?: usedResource[];
};

export type usedResource = {
  resource: Resource;
  amount: number;
};

export type OrderItemPhaseDTO = {
  orderItemId: number;
  phaseId: number;
  completedAmount?: number;
  note?: string;
  phasePid: number;
  taskId: number;
  sectorMinWorkTime?: number;
  workerId?: number;
  assignedById?: number;
};

export type AdditionalResource = {
  resource: Resource;
  amount: number;
};

export type ResourceReservation = {
  id?: number;
  taskId: number;
  requestedAmount: number;
  approvedAmount?: number;
  requestedAt?: string;
  approvedAt?: string;
  approvedBy?: UserWithIDOnly;
  requestedBy: UserWithIDOnly;
  resource: Resource;
};

export type UserWithIDOnly = {
  id: string;
};

export type PreviousSector = {
  sectorName: string;
};

export type WorkerBySectorDTO = {
  userId: number;
  workerName: string;
  workerUsername: string;
};

export const taskAction = async (
  taskAction: TaskActionDTO,
  startOrEnd: string,
  showWarningMessage?: any
) => {
  try {
    await POST<void>(SERVER_URL + '/worker/task/' + startOrEnd, taskAction);
    return true;
  } catch (error: any) {
    if (error instanceof Exception409) {
      const em = error as Error;
      console.log(em.message);
      if (showWarningMessage) showWarningMessage(em.message);
      else alert(em.message);
      return false;
    } else if (error instanceof Error) {
      const em = error as Error;
      console.log('Error while ending task. ' + em.message);
      return false;
    }
  }
};

export const taskOverrideFinish = async (
  taskActionDTO: TaskActionDTO,
  startTask: string
) => {
  let url;

  url = new URL(SERVER_URL + '/worker/task/finish/override');
  let params = new URLSearchParams();

  params.append('startTask', startTask);

  url.search = params.toString();
  try {
    return await POST<void>(url.toString(), taskActionDTO);
  } catch (error) {
    if (error instanceof Exception510) {
      const em = error as Error;
      console.log(em.message);
      alert('Morate faze redom zavrsavati');
      return false;
    } else if (error instanceof Error) {
      const em = error as Error;
      console.log('Error while creating order. ' + em.message);
      alert(em.message);
      return true;
    }
  }
};

export const getPreviousSectors = async (
  productId: number,
  phaseId: number
) => {
  let url = new URL(SERVER_URL + GET_PREVIOUS_SECTORS);
  let params = new URLSearchParams();
  params.append('productId', productId.toString());
  params.append('phaseId', phaseId.toString());

  url.search = params.toString();
  try {
    return await GET<PreviousSector[]>(url.toString());
  } catch (error) {
    console.log('Error while fetching previous sectors');
  }
};

export const getPhaseResources = async (itemPhase: OrderItemPhaseDTO) => {
  let url = new URL(SERVER_URL + GET_PHASE_RESOURCE_REQUIRED);

  let params = new URLSearchParams();
  params.append('phaseId', itemPhase.phaseId.toString());
  params.append('productId', itemPhase.phasePid.toString());

  url.search = params.toString();

  return await GET<ResourceRequiredAmmount[]>(url.toString());
};

export const getRequestedResourceForTask = async (taskId: number) => {
  let url = new URL(SERVER_URL + GET_RESERVED_RESOURCE_FOR_TASK);
  let params = new URLSearchParams();
  params.append('taskId', taskId.toString());
  url.search = params.toString();

  return await GET<RequestedResourceForTask[]>(url.toString());
};

export const reserveResource = async (
  reservationResources: ResourceReservation[]
) => {
  try {
    const success = await POST<ResourceReservation[]>(
      SERVER_URL + '/api/resource/reserve/request',
      reservationResources
    );
    return true;
  } catch (error) {
    console.log('Error while sending resource reservation request');
    console.error(error);
    return false;
  }
};

export const getExistingFiles = async (productId: number, pageNo: number) => {
  try {
    let url = new URL(SERVER_URL + '/api/files/' + productId);
    let params = new URLSearchParams();
    params = getPaginationParams(params, pageNo);
    url.search = params.toString();

    const files = await GET<String[]>(url.toString());
    return files;
  } catch (error) {
    console.log('Error while fetching products.');
    console.error(error);
  }
};

export const openFile = (productId: number, fileName: String) => {
  const jwt = localStorage.getItem('token');

  fetch(SERVER_URL + '/api/file/download/' + productId + '/' + fileName, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.status !== 404) {
      console.log(response);
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.target = '_blank';
        a.title = fileName.toString();
        a.rel = 'noopener noreferrer';
        a.href = url;
        a.click();
      });
    } else {
      alert('There is no file');
    }
  });
};

export const getSectorWorkers = async (sectorId: number) => {
  const url = new URL(SERVER_URL + '/worker/users/' + sectorId);
  return await GET<WorkerBySectorDTO[]>(url.toString());
};

export const getLastAssignedWorker = async (taskId: number) => {
  const url = new URL(SERVER_URL + '/worker/users/last-user/' + taskId);
  return await GET<WorkerBySectorDTO>(url.toString());
};

export const assignWorker = async (itemPhase: OrderItemPhaseDTO) =>
  await POST<boolean>(SERVER_URL + '/worker/task/change-assignment', itemPhase);

export const getProductInfo = async (productId: number) =>
  await GET<SaveProductDTO>(SERVER_URL + '/api/product/' + productId);

export const calculateUsedResourcesForWorker = (
  amount: number | undefined,
  ratio: Number | undefined
) => {
  if (amount && ratio) return Number(amount * ratio.valueOf()).toFixed(2);
  return '';
};
