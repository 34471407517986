import {
  extendTheme,
  withDefaultColorScheme,
  withDefaultVariant,
  useColorModeValue,
  ColorMode,
  baseTheme,
} from '@chakra-ui/react';

const grayScale = baseTheme.colors.gray;

const myTheme = {
  colors: {
    blue: {
      50: '#EBF8FF',
      100: '#BEE3F8',
      200: '#90CDF4',
      300: '#63B3ED',
      400: '#4299E1',
      500: '#3182CE',
      600: '#2A69AC',
      700: '#1E4E8C',
      800: '#153E75',
      900: '#1A365D',
    },
    // gray: {
    //   50: '#FBFBFB',
    //   100: '#F5F5F5',
    //   200: '#EEEEEE',
    //   300: '#E0E0E0',
    //   400: '#BDBDBD',
    //   500: '#9E9E9E',
    //   600: '#757575',
    //   700: '#616161',
    //   800: '#424242',
    //   900: '#212121',
    // },
    bg: {
      colorMode: (mode: ColorMode) =>
        mode === 'light' ? myTheme.colors.blue[100] : grayScale[900],
    },
    text: {
      colorMode: (mode: ColorMode) => (mode === 'light' ? 'black' : 'white'),
    },
  },
};

export const customTheme = extendTheme(
  {
    colors: {
      clrschm: grayScale,
      bg: myTheme.colors.bg,
    },
    fonts: {
      heading: 'Source Sans Pro, sans-serif',
      body: 'Source Sans Pro, sans-serif',
    },
  },
  withDefaultColorScheme({
    colorScheme: 'clrschm',
  }),
  withDefaultVariant({
    variant: 'solid',
  })
);

export const BgClrBtn = () => {
  return useColorModeValue('#AED6F1', grayScale[700]);
};
export const TxtClr = () => {
  return useColorModeValue('black', 'white');
};
