import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { TaskStatusBySectorDTO, NumberOfTasks } from '../home-apis';
import { Flex, Heading, Checkbox, VStack, Box } from '@chakra-ui/react';
import { reorderSectorsByInProgressTasks } from '../functions/reorderSectorsByInProgressTasks';

type CustomPieChartProps = {
  taskStatusBySector: TaskStatusBySectorDTO[];
  caption: string;
  showFilters: boolean;
};

const CustomPieChart = ({ taskStatusBySector, caption, showFilters }: CustomPieChartProps) => {
  const [data, setData] = useState<NumberOfTasks[]>([]);
  const [selectedSectors, setSelectedSectors] = useState<string[]>([]);

  useEffect(() => {
    if (taskStatusBySector.length > 0) {
        if (!showFilters) {
            taskStatusBySector = taskStatusBySector.slice(0, 7);
        }        
        const convertedData = reorderSectorsByInProgressTasks(taskStatusBySector);
        setData(convertedData);
        setSelectedSectors(showFilters ? convertedData.map(sector => sector.sector_name) : []);
    }

  }, [taskStatusBySector, showFilters]);

  const handleSectorChange = (sectorName: string, isChecked: boolean) => {
    setSelectedSectors((prev) => {
      const updatedSelections = isChecked
        ? [...prev, sectorName]
        : prev.filter((name) => name !== sectorName);
      
      // Reorder sectors whenever the selection changes
      const updatedData = reorderSectorsByInProgressTasks(taskStatusBySector.filter(sector =>
        updatedSelections.includes(sector.sector_name)
      ));
      setData(updatedData);
      return updatedSelections;
    });
  };

  const COLORS = Array.from(
    { length: data.length },
    (_, index) => `hsl(${(index * 360) / data.length}, 70%, 50%)`
  );

  const filteredData = data.filter(({ sector_name }) => selectedSectors.includes(sector_name));
  const chartWidth = showFilters ? 1050 : 690;
  const chartHeight = showFilters ? 700 : 430;

  if(showFilters)
    return (
        <Flex direction={{ base: 'column', md: 'row' }} wrap="nowrap">
        <Flex direction="column" align="center" width={chartWidth}>
            <PieChart width={chartWidth} height={chartHeight}>
            <Pie
                data={filteredData}
                dataKey='tasks'
                cx='45%'
                cy='50%'
                labelLine={false}
                fill='#8884d8'
                label={({ sector_name, tasks }) => `${sector_name}: ${tasks}`}
            >
                {filteredData.map((entry, index) => (
                <Cell 
                key={`cell-${index}`} 
                fill={COLORS[index % COLORS.length]} 
                />
            ))}
            </Pie>
            </PieChart>
        </Flex>
            <Box
            overflowY='auto'
            maxH={chartHeight}
            p={2}
            width="100%"
            maxW="400px"
            mt={30}
            ml={14}
            >
            <VStack alignItems="flex-start" spacing={6}>
                {taskStatusBySector.map(({ sector_name }) => (
                <Checkbox
                    key={sector_name}
                    isChecked={selectedSectors.includes(sector_name)}
                    onChange={(e) => handleSectorChange(sector_name, e.target.checked)}
                    colorScheme="blue"
                >
                    {sector_name}
                </Checkbox>
                ))}
            </VStack>
            </Box>
        </Flex>
    );
  else
    return (
        <Flex alignItems='center' justifyContent='center' flexDir='column'>
        <Heading
            as='h2'
            pb='10'
            size='l'
            style={{
            color: 'white',
            fontFamily: 'Source Sans Pro, sans-serif',
            fontSize: '24px',
            fontStyle: 'bold',
            fontWeight: 400,
            lineHeight: '32px',
            margin: '0 0 0px',
            }}
        >
            {caption}
        </Heading>
        {data && (
            <PieChart width={600} height={400}>
            <Pie
                data={data.filter((entry) => entry.tasks !== 0)}
                dataKey='tasks'
                cx='50%'
                cy='50%'
                labelLine={false}
                fill='#8884d8'
                label={(entry) => `${entry.sector_name}: ${entry.tasks}`}
            >
                {data &&
                data.map((entry, index) => (
                    <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    />
                ))}
            </Pie>
            </PieChart>
        )}
        </Flex>
    );


};
  
export default CustomPieChart;
