import { FC, ReactNode, createContext, useState, useEffect } from 'react';
import { User } from '../pages/login/login-api';

type UserContextType = {
  currentUser: User | null;
  setUser: (user: User | null) => void;
};

export const UserContext = createContext<UserContextType>({
  currentUser: null,
  setUser: (user: User | null) => null,
});

const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [currentUser, setUser] = useState<User | null>(null);
  const value = { currentUser, setUser };
  useEffect(() => {
    const userFromStorage: User = JSON.parse(
      localStorage.getItem('user') as string
    );
    setUser(userFromStorage);
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
