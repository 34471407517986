import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useChakra,
  useDisclosure,
} from '@chakra-ui/react';
import AppLayout from '../../../../components/layout/app-layout';
import { CustomHeading } from '../../../../components/heading/custom-heading';
import { useState, useRef, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import GsSpinner from '../../../../components/spinner/gsSpinner';
import { TableNavigation } from '../../../../components/TabeNavigation/TableNavigation';
import SectorForm from '../components/form/sector-form';
import { getSectorsByName, Sector } from '../sector-apis';
import SectorTable from '../components/table/sector-table';

const EditSectorPage = () => {
  const { theme, colorMode } = useChakra();

  return (
    <AppLayout>
      <Flex flexDir='column' w='100%'>
        <Box
          flexDirection='row'
          width='full'
          height='100%'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
          mb={1}
          p='20'
        >
          <CustomHeading text='Pregled sektora' />
          <EditSectorForm />
        </Box>
      </Flex>
    </AppLayout>
  );
};

const EditSectorForm = () => {
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSector, setSelectedSector] = useState<Sector>();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const sectorNameFilter = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsLoading(true);
    getSectorsByName(
      pageNumber,
      sectorNameFilter.current?.value as string
    ).then((sectors) => {
      setSectors(sectors as Sector[]);
      setIsLoading(false);
    });
  }, [shouldRefresh, pageNumber]);

  const onSubmitHandler = async () => {
    onClose();
    setShouldRefresh(!shouldRefresh);
  };

  const onSectorSelectHandler = (sector: Sector) => {
    setSelectedSector(sector);
    onOpen();
  };

  const pageNumberHandler = (num: number) => {
    if (num === 1) setPageNumber((prevPageNumber) => prevPageNumber + 1);
    else
      setPageNumber((prevPageNumber) =>
        prevPageNumber > 0 ? prevPageNumber - 1 : 0
      );
  };

  return (
    <Box px='5' height='100%' minHeight='600'>
      <Flex flexDirection='column' align={'center'}>
        <FormControl>
          <FormLabel>Pretražite sektor</FormLabel>
          <InputGroup alignItems='center'>
            <InputLeftElement
              pointerEvents='none'
              children={
                <Icon
                  as={FaSearch}
                  color='gray.500'
                  fontSize='20px'
                  marginTop='5px'
                />
              }
            />
            <Input
              type='text'
              width={500}
              placeholder='Ime sektora'
              ref={sectorNameFilter}
              onChange={() => setShouldRefresh(!shouldRefresh)}
            />
          </InputGroup>
        </FormControl>
        {isLoading ? (
          <GsSpinner />
        ) : (
          <SectorTable
            sectors={sectors}
            onSectorSelect={onSectorSelectHandler}
          />
        )}
      </Flex>
      <Box marginTop={10}>
        <TableNavigation
          pageNumberHandler={pageNumberHandler}
          pageNumber={pageNumber}
        />
      </Box>
      {selectedSector && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent minWidth='1100px'>
            <ModalHeader>Izmena sektora</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <SectorForm
                selectedSector={selectedSector}
                onSubmit={onSubmitHandler}
              />{' '}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default EditSectorPage;
