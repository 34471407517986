// Assuming TaskStatusBySectorDTO is defined in a types file, import it
import { TaskStatusBySectorDTO } from '../home-apis';

/**
 * Reorders sectors in a zigzag fashion based on the number of tasks.
 * This helps in visualizing the data by alternating between the highest
 * and lowest values, preventing clutter in the chart.
 * 
 * @param sectors Array of sector data to reorder.
 * @returns The reordered array of sectors.
 */
const reorderSectorsByCompleteTasks = (sectors: TaskStatusBySectorDTO[]): { sector_name: string; tasks: number; }[] => {
    // Sort tasks by the number of tasks in descending order
    const sortedData = [...sectors].sort((a, b) => b.zavrseno - a.zavrseno);
    const reorderedData = [];

    // Interleave the largest and smallest values
    while (sortedData.length) {
      if (sortedData.length) {
        reorderedData.push(sortedData.shift()!); // Asserting non-null as we check the array length before
      }
      if (sortedData.length) {
        reorderedData.push(sortedData.pop()!); // Same here
      }
    }

    return reorderedData.map((sector) => ({
      sector_name: sector.sector_name,
      tasks: sector.zavrseno,
    }));
};

// Export the function for use in other parts of your application
export { reorderSectorsByCompleteTasks };
