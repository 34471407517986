import {
  Tr,
  Td,
} from '@chakra-ui/react';
import { FC } from 'react';
import {
  convertDateTime,
  RequestedMaterialForPhase,
} from '../../../warehouse-api';

const MaterialByRequestTableHistoryRow: FC<{
  requestItem: RequestedMaterialForPhase;
}> = ({ requestItem }) => {
  return (
    <Tr borderBottom='2px'>
      <Td textAlign='center' w='18%'>
        {requestItem.resourceName}
      </Td>
      <Td textAlign='center' w='15%'>
        {convertDateTime(requestItem.requestedAt)}
      </Td>
      <Td textAlign='center' w='15%'>
        {requestItem.nameUser}
      </Td>
      <Td textAlign='center' w='15%'>
        {convertDateTime(requestItem.approvedAt)}
      </Td>
      <Td textAlign='center' w='15%'>
        {requestItem.nameUserApproved}
      </Td>
      <Td textAlign='center' w='15%'>
        {requestItem.requestedAmount}
      </Td>
      <Td textAlign='center' w='15%'>
        {requestItem.approvedAmount}
      </Td>
      <Td textAlign='center' w='15%'>
        {requestItem.usedAmount}
      </Td>
      <Td textAlign='center' w='6%'>
        {requestItem.measureUnit}
      </Td>
    </Tr>
  );
};

export default MaterialByRequestTableHistoryRow;
