import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { getExistingFiles } from '../../worker-api';
import GsHeaderModal from '../../../../components/heading/GsHeaderModal';
import GsBtn from '../../../../components/button/GsBtn';
import FileTable from './table/FileTable';

const WorkerShowFileModal: FC<{
  productId: number;
  isOpen: boolean;
  onClose: () => void;
}> = ({ productId, isOpen, onClose }) => {
  const [existingFilesNames, setExistingFilesNames] = useState<string[]>([]);

  const loadExistingFiles = async () => {
    const fileNames = await getExistingFiles(productId, 0);
    if (fileNames) setExistingFilesNames(fileNames as string[]);
  };

  useEffect(() => {
    if (isOpen) loadExistingFiles();
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth='900'>
        <GsHeaderModal title='Crteži' />
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FileTable files={existingFilesNames} productId={productId} />
        </ModalBody>
        <ModalFooter>
          <GsBtn text='Zatvori' onClick={onClose} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WorkerShowFileModal;
