import { GET, POST } from '../../../api/requests';
import { SERVER_URL } from '../../../api/config';
import { Order } from '../order/order-apis';
import { getPaginationParams } from '../../../components/TabeNavigation/table-navigation';
import { Product } from '../product/product-api';
import {
  DELETE_CUSTOM_TASK,
  GET_CUSTOM_TASKS,
  SAVE_CUSTOM_TASK,
} from '../../../api/apiConstants';

export type CustomTask = {
  sectorId: number;
  description: string;
  estimatedStart: string;
  estimatedEnd: string;
  priority: string;
};

export type CustomTaskDisplay = {
  description: string;
  status: string;
  sector_name: string;
  end_time: string;
  start_time: string;
  product_code: string;
};

export interface CustomTaskTableProps {
  customTasks: CustomTaskDisplay[];
  getTableData: () => void;
}

export interface CustomTaskTableRowProps {
  customTask: CustomTaskDisplay;
  getTableData: () => void;
}

export const saveCustomTask = async (customTask: CustomTask) => {
  try {
    const createdTask = await POST<Order>(
      SERVER_URL + SAVE_CUSTOM_TASK,
      customTask
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const getCustomTasks = async (
  filter: string,
  searchType: string,
  pageNo: number
) => {
  try {
    let url = new URL(SERVER_URL + GET_CUSTOM_TASKS);
    let params = new URLSearchParams();
    params = getPaginationParams(params, pageNo);

    if (filter !== '') params.append('filter', filter);

    if (searchType !== '') params.append('searchType', searchType);

    url.search = params.toString();

    return await GET<CustomTaskDisplay[]>(url.toString());
  } catch (error) {
    console.log('Error while fetching custom tasks.');
    console.error(error);
    return [];
  }
};

export const deleteCustomTask = async (customTask: CustomTaskDisplay) => {
  try {
    let url = new URL(SERVER_URL + DELETE_CUSTOM_TASK);
    let params = new URLSearchParams();
    params.append('customTaskCode', customTask.product_code);
    url.search = params.toString();

    const deletedSector = await POST<CustomTaskDisplay>(
      url.toString(),
      undefined
    );
    return true;
  } catch (error) {
    alert(error);
    console.error(error);
    return false;
  }
};
