import { useState, useEffect } from 'react';
import { 
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend, 
  Tooltip,
  LabelList,
} from 'recharts';
import { TaskStatusBySectorDTO, NumberOfTasks } from '../home-apis';
import { Flex, Text, Heading, Checkbox, VStack, Box } from '@chakra-ui/react';
import { reorderSectorsByCompleteTasks } from '../functions/reorderSectorsByCompleteTasks';

type CustomBarChartProps = {
  taskStatusBySector: TaskStatusBySectorDTO[];
  caption: string;
  showFilters: boolean;
};

const CustomBarChart = ({ taskStatusBySector, caption, showFilters }: CustomBarChartProps) => {
  const [data, setData] = useState<NumberOfTasks[]>([]);
  const [isDataReady, setIsDataReady] = useState(false); 
  const [selectedSectors, setSelectedSectors] = useState<string[]>([]);

  useEffect(() => {
    let processedData = [...taskStatusBySector];

    if (!showFilters) {
      processedData = processedData.slice(0, 7);
    }

    const convertedData = reorderSectorsByCompleteTasks(processedData);
    setData(convertedData);
    setIsDataReady(true); 
    setSelectedSectors(showFilters ? convertedData.map(sector => sector.sector_name) : []);

  }, [taskStatusBySector, showFilters]);

  const handleSectorChange = (sectorName: string, isChecked: boolean) => {
    setSelectedSectors((prev) => {
      const updatedSelections = isChecked
        ? [...prev, sectorName]
        : prev.filter((name) => name !== sectorName);
      
      // Reorder sectors whenever the selection changes
      const updatedData = reorderSectorsByCompleteTasks(taskStatusBySector.filter(sector =>
        updatedSelections.includes(sector.sector_name)
      ));
      setData(updatedData);
      return updatedSelections;
    });
  };
  // Key generation for forcing re-render
  const chartKey = data.length > 0 ? 'dataReady' : 'dataNotReady';

  // Calculate the max value
  const maxValue = data.reduce((acc, curr) => Math.max(acc, curr.tasks), 0);
  // Extend the max value for the Y-axis domain
  const yAxisDomain = [0, maxValue * 1.1]; // Extend by 10%
  const chartWidth = showFilters ? 1050 : 690;
  const chartHeight = showFilters ? 700 : 430;
  
  if (!isDataReady) {
    return <Text>Loading chart data...</Text>; 
  }

  if(showFilters) {
    return (
      <Flex direction={{ base: 'column', md: 'row' }} wrap="nowrap">
        <Flex direction="column" align="center" width="100%">
          <BarChart
            width={chartWidth}
            height={chartHeight}
            data={data.filter(entry => selectedSectors.includes(entry.sector_name))} // Filter data based on selected sectors
            margin={{
              top: 25,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="sector_name" tick={false} /> {/* Hide tick labels for XAxis */}
            <YAxis domain={yAxisDomain} tickFormatter={(value) => {
              if (Number.isInteger(value)) {
                return value.toString();
              }
              return Math.round(value).toString();
            }} />
            <Tooltip />
            <Legend />
            <Bar dataKey="tasks" fill='#FE580B' >
              <LabelList dataKey="sector_name" position="top" style={{ fill: '#F9E982' }} />
            </Bar>
          </BarChart>
        </Flex>
        <Box
          overflowY='auto'
          maxH={chartHeight} 
          p={2}
          width="100%"
          maxW="400px"
          ml={14}
        >
          <VStack alignItems="flex-start" spacing={6}>
            {taskStatusBySector.map(({ sector_name }) => (
              <Checkbox
                key={sector_name}
                isChecked={selectedSectors.includes(sector_name)}
                onChange={(e) => handleSectorChange(sector_name, e.target.checked)}
                colorScheme="blue"
              >
                {sector_name}
              </Checkbox>
            ))}
          </VStack>
        </Box>
      </Flex>
    );
  } else {
    return (
      <Flex alignItems='center' justifyContent='center' flexDir='column' mr='10'>
        <Heading
          as='h2'
          pb='10'
          size='l'
          style={{
            color: 'white',
            fontFamily: 'Source Sans Pro, sans-serif',
            fontSize: '24px',
            fontStyle: 'bold',
            fontWeight: 400,
            lineHeight: '32px',
            margin: '0 0 0px',
          }}
        >
          {caption}
        </Heading>
        <BarChart
          width={chartWidth}
          height={chartHeight}
          data={data}
          margin={{
            top: 25,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="sector_name" tick={false} /> {/* Hide tick labels for XAxis */}
          <YAxis domain={yAxisDomain} tickFormatter={(value) => {
            if (Number.isInteger(value)) {
              return value.toString();
            }
            return Math.round(value).toString();
          }} />
          <Tooltip />
          <Legend />
          <Bar dataKey="tasks" barSize={60} fill='#FE580B' >
            <LabelList dataKey="sector_name" position="top" style={{ fill: '#F9E982' }} />
          </Bar>
        </BarChart>
      </Flex>
    );
  }
};

export default CustomBarChart;