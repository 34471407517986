import {
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
  } from '@chakra-ui/react';
  import { FC } from 'react';
  import { TaskInProgressDTO } from '../task-api';
  import TaskRow from './task-row';
  
  const TaskTable: FC<{
    data: TaskInProgressDTO;
  }> = ({ data }) => {
    return (
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th textAlign='center'>Sektor</Th>
              <Th textAlign='center'>Šifra porudžbine</Th>
              <Th textAlign='center'>Šifra proizvoda</Th>
              <Th textAlign='center'>Vreme započinjanja</Th>
              <Th textAlign='center'>Datum isporuke</Th>
              <Th textAlign='center'>Količina</Th>
              <Th textAlign='center'>Prioritet</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => (
              <TaskRow
                dataRow={row}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };
  
  export default TaskTable;
  