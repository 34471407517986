import {
  FormControl,
  Flex,
  TableContainer,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  FormLabel,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import ConsumedMaterialReportTableModal from '../../report/modals/consumedMaterialReport/ConsumedMaterialTableModal';
import {
  ConsumedMaterialReport,
  getCustomConsumedMaterialReport,
} from '../../report/report-api';
import { Resource } from '../../resource/resource-api';

const MissingResourcesModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  resources: Resource[];
  orderCode: string;
}> = ({ isOpen, onClose, resources, orderCode }) => {
  const [modalTableVisible, setModalTableVisible] = useState(false);
  const [customConsumedMaterialReports, setCustomConsumedMaterialReports] =
    useState<ConsumedMaterialReport[]>([]);
  const [productPageNo, setProductPageNo] = useState<number>(0);

  const handleOpenConsumedMaterialReport = async () => {
    const confirmationMessage =
      'Da li zelite da kreirate izveštaj nedostajucih materijala za porudžbenicu ' +
      orderCode +
      '?';
    const isConfirmed = window.confirm(confirmationMessage);

    if (isConfirmed) {
      const data = await getCustomConsumedMaterialReport(
        orderCode,
        setCustomConsumedMaterialReports,
        productPageNo
      );

      if (
        data === null ||
        (data !== false && Array.isArray(data) && data.length === 0)
      ) {
        alert('Ne postoje podaci za traženi izveštaj');
        return;
      } else {
        setModalTableVisible(true);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent minWidth='700px' minHeight='400px'>
        <ModalHeader textAlign={'center'} fontSize={24}>
          Materijali koji nedostaju
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer w='100%'>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th textAlign='center' fontSize={16} color={'white'}>
                    Naziv
                  </Th>
                  <Th textAlign='center' fontSize={16} color={'white'}>
                    Stanje na skladistu
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {resources.map((r) => (
                  <Tr>
                    <Td textAlign='center' fontSize={18}>
                      {r?.name}
                    </Td>
                    <Td textAlign='center' fontSize={18}>
                      {r?.available}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='gray'
            onClick={handleOpenConsumedMaterialReport}
            variant='solid'
            marginTop='10px'
            marginBottom='2px'
            marginRight='20px'
          >
            Potrebni materijali za porudzbenicu
          </Button>
        </ModalFooter>
      </ModalContent>
      <ConsumedMaterialReportTableModal
        isModalOpen={modalTableVisible}
        setIsModalOpen={setModalTableVisible}
        customConsumedMaterialReports={customConsumedMaterialReports}
        setCustomConsumedMaterialReports={setCustomConsumedMaterialReports}
        getCustomConsumedMaterialReport={getCustomConsumedMaterialReport}
        pageNo={productPageNo}
        setProductPageNo={setProductPageNo}
        order_code={orderCode}
      />
    </Modal>
  );
};

export default MissingResourcesModal;
