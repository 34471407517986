import { GET, POST } from '../../../api/requests';
import { Sector } from '../sector/sector-apis';
import { getPaginationParams } from '../../../components/TabeNavigation/table-navigation';
import { SERVER_URL } from '../../../api/config';
import { GET_RESOURCE_BY_NAME, PRODUCT_COPY } from '../../../api/apiConstants';
import { Edge } from 'reactflow';
import { MeasurementUnit } from '../measurementUnit/measurementUnit-api';
import { QualityUnit } from '../measurementUnit/qualityUnit-api';

export type SaveProductDTO = {
  product: Product;
  edges: Edge[];
  phaseResources: PhaseResource[];
  url?: string;
};

export type Product = {
  id?: number;
  description: string;
  productCode: string;
  measureUnit: MeasurementUnit;
  requiredResources: ProductItem[];
  productPhases: ProductPhase[];
};

export type Resource = {
  id: string;
  resourceCode: string;
  name: string;
  measureUnit: MeasurementUnit;
  workerMeasureUnit: MeasurementUnit;
  quality: QualityUnit;
  measureUnitRatio: number;
};

export type ResourceRequiredAmmount = {
  id?: number;
  phase_resource_required_id?: number;
  resource?: Resource;
  amount: number;
};

export type PhaseResource = {
  resources: ResourceRequiredAmmount[];
  phase_id: number;
};

export type ProductItem = {
  id?: number;
  amount: number;
  resource: Resource;
  product?: Product;
};

export type ProductPhase = {
  id?: string;
  description: string;
  startDate?: Date;
  endDate?: Date;
  sector: Sector;
  orderIndex: number;
  product?: Product;
  x?: number;
  y?: number;
  height?: number;
  width?: number;
};

export const getResourceByFilter = async (
  resourceName: string,
  pageNum: number
) => {
  let params = new URLSearchParams();
  params.append('pageNo', pageNum.toString());
  params.append('pageSize', '10');
  if (resourceName) params.append('filter', resourceName);

  let url = new URL(SERVER_URL + GET_RESOURCE_BY_NAME);
  url.search = params.toString();

  return await GET<Resource[]>(url.toString());
};

// export const getProducts = async () => {
//   try {
//     return await GET<Product[]>(SERVER_URL + '/api/products');
//   } catch (error) {
//     console.log('Error while fetching products.');
//     console.error(error);
//   }
// };

export const getProducts = async (
  setProducts: any,
  pageNo: number,
  setIsLoading: any
) => {
  try {
    let url = new URL(SERVER_URL + '/api/products');
    let params = new URLSearchParams();
    params = getPaginationParams(params, pageNo);
    url.search = params.toString();

    setIsLoading(true);
    const products = await GET<Product[]>(url.toString());
    setProducts(products);
    setIsLoading(false);
    console.log(products);
  } catch (error) {
    console.log('Error while fetching products.');
    console.error(error);
  }
};

export const getProduct = async (setProduct: any, productId: number) => {
  try {
    const product = await GET<SaveProductDTO>(
      SERVER_URL + '/api/product/' + productId
    );
    setProduct(product);
  } catch (error) {
    console.log('Error while fetching product.');
    console.error(error);
  }
};

export const createProduct = async (
  product: SaveProductDTO,
  files: File[] | undefined
) => {
  console.log(product);
  try {
    const savedProduct = await POST<SaveProductDTO>(
      SERVER_URL + '/api/product/save',
      product
    );
    if (files !== undefined)
      Array.from(files).forEach((file) => {
        upload(file, product.product.productCode);
      });

    return true;
  } catch (error) {
    alert(error);
    console.error(error);
    return false;
  }
};

export const copyProduct = async (
  product: SaveProductDTO,
  files: File[] | undefined
) => {
  try {
    const copiedProduct = await POST<SaveProductDTO>(
      SERVER_URL + PRODUCT_COPY,
      product
    );

    return true;
  } catch (error) {
    alert(error);
    console.error(error);
    return false;
  }
};

export const getProductsByFilter = async (
  filter: string,
  setProducts: any,
  pageNo: number,
  setIsLoading: any
) => {
  try {
    //char like . was breaking page if it was passed, so we do check hear
    const regex = '[A-z0-9]';
    const firstChar = filter.charAt(0);
    const found = firstChar.match(regex);

    let url = new URL(SERVER_URL + '/api/products/' + filter);
    let params = new URLSearchParams();
    params = getPaginationParams(params, pageNo);
    url.search = params.toString();

    setIsLoading(true);
    const products = await GET<Product[]>(url.toString());
    setProducts(products);
    setIsLoading(false);
    console.log(products);
  } catch (error) {
    setIsLoading(false);
    console.log('Error while fetching products.');
    console.error(error);
  }
};

export const getProductsNeizmenjeni = async (
  filter: string,
  setProducts: any,
  pageNo: number,
  setIsLoading: any
) => {
  try {
    //char like . was breaking page if it was passed, so we do check hear
    if (filter) {
      const regex = '[A-z0-9]';
      const firstChar = filter.charAt(0);
      const found = firstChar.match(regex);
      if (found == null) {
        alert('Nedozvoljen karakter na pocetku naziva proizvoda');
        return;
      }
    }

    let url = new URL(SERVER_URL + '/api/products/neizmenjeni');
    let params = new URLSearchParams();
    params = getPaginationParams(params, pageNo);
    if (filter) params.append('filter', filter);
    url.search = params.toString();

    setIsLoading(true);
    const products = await GET<Product[]>(url.toString());
    setProducts(products);
    setIsLoading(false);
    console.log(products);
  } catch (error) {
    setIsLoading(false);
    console.log('Error while fetching products.');
    console.error(error);
  }
};

export interface ProductPhaseCardsProps {
  setAddedPhases: any;
  addedPhases: ProductPhase[];
  productPhase?: ProductPhase;
}

export interface ProductModalProps {
  resources: Resource[];
  setResources: any;
  addedResources: Resource[];
  setAddedResources: any;
  setProductItems: any;
  productItems: ProductItem[];
  pageNumber: number;
  setPageNumber?: any;
}

export interface ProductItemsTabelProps {
  productItems: ProductItem[];
  setProductItems: any;
  productItem?: ProductItem;
}

//posle obrisati ovog
export interface ProductPhaseModalProps {
  onAddPhase: (selectedSector: Sector, description: string) => void;
  addedResources: Resource[];
  setAddedResources: React.Dispatch<React.SetStateAction<Resource[]>>;
  requiredResources: ResourceRequiredAmmount[];
  setReqiredResources: React.Dispatch<
    React.SetStateAction<ResourceRequiredAmmount[]>
  >;
  toggleDiagram: () => void;
  showDiagram: boolean;
}

export interface ProductTableProps {
  products: Product[];
  product?: Product;
  setProductToEdit: any;
  setAddedProductPhases: any;
  setAddedProductItems: any;
  setProduct: any;
  setSearchedProducts: any;
  fieldRef: any;
  pageNumber: number;
  setPageNumber: any;
  onOpenEdit: any;
  setIsLoading: any;
  productToEdit: SaveProductDTO | undefined;
  refreshPage: () => void;
}

export interface EditFormProps {
  onOpenEdit: any;
}

export function isAddPhaseValid(
  description: string | undefined,
  sectorName: string | undefined
) {
  if (description === undefined || sectorName === undefined) {
    console.log('undefined');
    return false;
  }

  if (description === '') {
    alert('Unesite opis faze');
    return false;
  }
  if (sectorName === 'unknown') {
    alert('Izaberite sektor');
    return false;
  }
  return true;
}

export const deleteProduct = async (product: Product) => {
  try {
    const deletedProduct = await POST<Product>(
      SERVER_URL + '/api/product/delete',
      product
    );
    return true;
  } catch (error) {
    alert(error);
    console.error(error);
    return false;
  }
};

export const isProductActiveInOrder = async (product: Product) => {
  const isActive = await POST<Boolean>(
    SERVER_URL + '/api/product/isActive',
    product
  );
  return isActive;
};

export const upload = async (file: File | undefined, fileName: string) => {
  if (file !== undefined) {
    const jwt = localStorage.getItem('token');
    const data = new FormData();
    data.append('file', file);
    data.append('productCode', fileName);
    const response = await fetch(SERVER_URL + '/api/upload', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: data,
    });
  }
};

export const downloadPdf = (productId: number, fileName: String) => {
  const jwt = localStorage.getItem('token');
  console.log(SERVER_URL + '/api/file/download/' + productId + '/' + fileName);

  fetch(SERVER_URL + '/api/file/download/' + productId + '/' + fileName, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.status !== 404) {
      console.log(response);
      response.blob().then((blob) => {
        //proveriti ako je ovo prazno ne otvarati nista
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.target = '_blank';
        a.title = fileName.toString();
        a.rel = 'noopener noreferrer';
        a.href = url;
        // a.download = fileName + '.pdf';
        a.click();
      });
      //window.location.href = response.url;
    } else {
      alert('There is no file');
    }
  });
};

export const getExistingFiles = async (productId: number, pageNo: number) => {
  try {
    let url = new URL(SERVER_URL + '/api/files/' + productId);
    let params = new URLSearchParams();
    params = getPaginationParams(params, pageNo);
    url.search = params.toString();

    const files = await GET<String[]>(url.toString());
    return files;
  } catch (error) {
    console.log('Error while fetching products.');
    console.error(error);
  }
};

export const deleteFile = async (productId: number, fileName: String) => {
  try {
    const deletedProduct = await GET<Object>(
      SERVER_URL + '/api/file/delete/' + productId + '/' + fileName
    );
    return true;
  } catch (error) {
    alert(error);
    console.error(error);
    return false;
  }
};
