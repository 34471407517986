import { SERVER_URL } from "../../../api/config";
import { GET, POST } from "../../../api/requests";
import { getPaginationParams } from "../../../components/TabeNavigation/table-navigation";
import { MeasurementUnit } from "../measurementUnit/measurementUnit-api";

export type RequestedMaterialByPhase = {
    productId: number;
    phaseId: number;
    productCode: string;
    sectorName: string;
    orderCode: string;
    estimatedEnd: string;
    latestRequestedAt: string;
}

export type RequestedMaterialForPhase = {
  id: number;
  requestedAmount: number;
  approvedAmount: number;
  usedAmount: number;
  requestedAt: string;
  approvedAt: string;
  nameUser: string;
  nameUserApproved: string;
  resourceName: string;
  resourceCode: string;
  sectorName: string;
  phaseDescription: string;
  productCode: string;
  productDescription: string;
  resourcesAvailable: number;
  measureUnit: string;
  measureUnitRatio: number;
  workerMeasureUnit: string;
}

export type ApproveReservedResource = {
  reserveResourceId: number,
  amount: number
}
export type IncreaseResource = {
  resourceName: string
  increaseValue: number
}
export type ReturnReservedResource = {
  reserveResourceId: number,
  userId: number,
  unusedAmount: number,
}
export const getMaterialRequests = async (
    isActive: string,
    isReturned: string,
    pageNo: number,
    setMaterialRequest: any,
    productFilter?: string
  ) => {
    try {
      let url = new URL(SERVER_URL + '/api/resource/reserve/requested');
      let params = new URLSearchParams();
      params = getPaginationParams(params, pageNo);
      console.log(url.toString());
  
      if(isActive != undefined) {
        params.append('isActive', isActive);
      }

      if(isReturned != undefined) {
        params.append('isReturned', isReturned);
      }

      if(productFilter != undefined && productFilter != '') {
        params.append('productCodeFilter', productFilter)
      }
  
      url.search = params.toString();
  
      const materialRequests = await GET<RequestedMaterialByPhase[]>(url.toString());
      console.log(materialRequests)
      setMaterialRequest(materialRequests)
    } catch (error) {
      console.log('Error while fetching material requests.');
      console.error(error);
    }
  };

  export const getMaterialsByRequests = async (
    productId: number,
    phaseId: number,
    orderCode: string,
    isActive: string,
    pageNo: number,
    isReturned?: string,
  ) => {
    try {
      let url = new URL(SERVER_URL + '/api/resource/reserve/requested/phase');
      let params = new URLSearchParams();
      params = getPaginationParams(params, pageNo);
      console.log(url.toString());
      if(productId != undefined) {
        params.append('productId', productId.toString());
      }
      if(phaseId != undefined) {
        params.append('phaseId', phaseId.toString());
      }
      if(orderCode != undefined) {
        params.append('orderCode', orderCode.toString());
      }
      if(isActive != undefined) {
        params.append('isActive', isActive);
      }
      if(isReturned != undefined) {
        params.append('isReturned', isReturned);
      }
  
      url.search = params.toString();

      let x = await GET<RequestedMaterialForPhase[]>(url.toString())
      console.log(x)
      return x;
    } catch (error) {
      console.log('Error while fetching materials for requests.');
      console.error(error);
    }
  };

  export const approveReservedResource = async  (reserveResourceId: number,amount:number ) => {
    try {
      let url = new URL(SERVER_URL + '/api/resource/reserve/approve');
      const data = {
        reserveResourceId: reserveResourceId,
        amount: amount
      } as ApproveReservedResource
      const updateOrderItem = await POST<ApproveReservedResource>(
        url.toString(),
        data
      );
      return true;
    } catch (error) {
      console.log('Error while approving reserved resource');
      console.error(error);
      return false;
    }
  }

  export const increaseResourceAvailable = async (resourceName: string, increaseValue: number) => {

    try {
      let url = new URL(SERVER_URL + '/api/resource/available/increase');
      const data = {
        resourceName: resourceName,
        increaseValue: increaseValue
      } as IncreaseResource
      const success = await POST<IncreaseResource>(
        url.toString(),
        data
      );
      return true;
    } catch (error) {
      console.log('Error while increasing resource value');
      console.error(error);
      return false;
    }

  };

  export function convertDateTime(value: string | undefined) {
    let formattedDateTime = '';
    if (value) {
      const date = new Date(value);
      formattedDateTime = date.toLocaleString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23', // Ensures 24-hour format is used
      }).replace(/\//g, '-').replace(',', ''); // Remove the comma between date and time
    }
    return formattedDateTime;
  }

