import { DeleteIcon, DownloadIcon } from '@chakra-ui/icons';
import { Tr, Td, IconButton, useColorModeValue } from '@chakra-ui/react';
import GsIconBtn from '../../../../../../components/button/GsIconBtn';

export interface FileTableRowProps {
  file: File;
  files: File[];
  setFiles: any;
}

const FileTableRow = ({ file, files, setFiles }: FileTableRowProps) => {
  const downloadFile = () => {
    var data = new Blob([file], { type: 'application/pdf' });
    var url = window.URL.createObjectURL(data);
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.title = file.name;
    a.rel = 'noopener noreferrer';
    a.click();
  };

  const deleteFile = () => {
    const filteredArray = files.filter((item) => item.name !== file.name);
    setFiles(filteredArray);
  };

  return (
    <Tr borderBottom='1px'>
      <Td textAlign='center'>{file.name}</Td>
      <Td textAlign='center'>
        <GsIconBtn
          label='download-file-icon'
          icon={<DownloadIcon />}
          onClick={downloadFile}
        />
      </Td>
      <Td textAlign='center'>
        <GsIconBtn
          label='delete-file-icon'
          icon={<DeleteIcon />}
          onClick={deleteFile}
        />
      </Td>
    </Tr>
  );
};

export default FileTableRow;
