import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ResourceRequiredAmmount } from '../../../manager/product/product-api';
import { Resource } from '../../../manager/resource/resource-api';
import {
  AdditionalResource,
  calculateUsedResourcesForWorker,
  RequestedResourceForTask,
} from '../../worker-api';

interface WorkerPhaseResourceTableProps {
  requestedResourcesForTask: RequestedResourceForTask[];
  phaseResourceRequired?: ResourceRequiredAmmount;
  additionalResources?: AdditionalResource[];
  handleDeleteAdditionalResource?: (resource: Resource) => void;
  completedAmount: Number;
  wantedAmount: Number;
}

interface WorkerPhaseResourceTableRowProps {
  requestedResourcesForTask: RequestedResourceForTask;
  completedAmount: Number;
  wantedAmount: Number;
}

const WorkerPhaseResourceTable = ({
  requestedResourcesForTask,
  additionalResources,
  handleDeleteAdditionalResource,
  completedAmount,
  wantedAmount,
}: WorkerPhaseResourceTableProps) => {
  const tableCaption = tableCaptionGenerator(requestedResourcesForTask);
  return (
    <TableContainer>
      <Table variant='simple'>
        <TableCaption>{tableCaption}</TableCaption>
        <Thead>
          <Tr>
            <Th textAlign='center'>Naziv materijala</Th>
            <Th textAlign='center'>Zaduzeno</Th>
            <Th textAlign='center'>Odobreno</Th>
            <Th textAlign='center'>Utroseno</Th>
            <Th textAlign='center'>Unos</Th>
            <Th textAlign='center'>Merna jedinica</Th>
            <Th textAlign='center'></Th>
          </Tr>
        </Thead>
        <Tbody>
          {requestedResourcesForTask.map((rrt) => (
            <WorkerPhaseResourceTableRow
              key={rrt.id}
              requestedResourcesForTask={rrt}
              completedAmount={completedAmount}
              wantedAmount={wantedAmount}
            ></WorkerPhaseResourceTableRow>
          ))}
        </Tbody>

        <Tbody>
          {additionalResources?.map((ar) => (
            <WorkerPhaseAdditionalResourceTableRow
              additionalResource={ar}
              handleDeleteAdditionalResource={handleDeleteAdditionalResource}
            ></WorkerPhaseAdditionalResourceTableRow>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const WorkerPhaseResourceTableRow = ({
  requestedResourcesForTask,
  completedAmount,
  wantedAmount,
}: WorkerPhaseResourceTableRowProps) => {
  const [editDisabled, setEditDisabled] = useState<boolean>(true);
  const [isAmountInvalid, setIsAmountInvalid] = useState(false);
  const [isChanged, setChanged] = useState<boolean>(false);

  const computeUsedAmount = (requestedAmount: number | undefined) => {
    if (requestedAmount && isNaN(Number(requestedAmount))) return -1;

    return (
      (Number(requestedAmount) - usedAmount) *
      (Number(completedAmount) / Number(wantedAmount)) *
      Number(requestedResourcesForTask?.resource?.measureUnitRatio)
    );
  };
  let usedAmount = 0;
  if (requestedResourcesForTask.usedAmount != undefined) {
    usedAmount = requestedResourcesForTask.usedAmount;
  }
  let value;
  if (!isChanged) {
    value = Number(
      computeUsedAmount(requestedResourcesForTask?.requestedAmount).toFixed(2)
    );
    requestedResourcesForTask.amount = value;
  }
  return (
    <Tr key={requestedResourcesForTask?.id}>
      <Td textAlign='center'>{requestedResourcesForTask?.resource?.name}</Td>
      <Td textAlign='center'>
        {calculateUsedResourcesForWorker(
          requestedResourcesForTask.requestedAmount,
          requestedResourcesForTask.resource.measureUnitRatio
        )}
      </Td>
      <Td textAlign='center'>
        {calculateUsedResourcesForWorker(
          requestedResourcesForTask.approvedAmount,
          requestedResourcesForTask.resource.measureUnitRatio
        )}
      </Td>
      <Td textAlign='center'>
        {calculateUsedResourcesForWorker(
          requestedResourcesForTask.usedAmount,
          requestedResourcesForTask.resource.measureUnitRatio
        )}
      </Td>
      <Td textAlign='center'>
        <FormControl mt={4} isInvalid={isAmountInvalid}>
          <Input
            placeholder=''
            textAlign={'center'}
            disabled={editDisabled}
            value={value}
            minWidth={'50px'}
            type='number'
            onChange={(e) => {
              setChanged(true);
              value = Number(e.target.value);
              requestedResourcesForTask.amount = value;
            }}
          />
          <FormErrorMessage>{'Unesite ispravan broj'}</FormErrorMessage>
        </FormControl>
      </Td>
      <Td textAlign='center'>
        {requestedResourcesForTask?.resource?.workerMeasureUnit?.unitName}
      </Td>
      <Td textAlign='center'>
        <IconButton
          aria-label={'resources-edit'}
          icon={<EditIcon />}
          variant='outline'
          onClick={() => {
            setEditDisabled(!editDisabled);
          }}
        />
      </Td>
    </Tr>
  );
};

interface WorkerPhaseAdditionalResourceTableRowProps {
  additionalResource: AdditionalResource;
  handleDeleteAdditionalResource?: (resource: Resource) => void;
}

const WorkerPhaseAdditionalResourceTableRow = ({
  additionalResource,
  handleDeleteAdditionalResource,
}: WorkerPhaseAdditionalResourceTableRowProps) => {
  return (
    <Tr key={additionalResource.resource.id + 'ar'}>
      <Td textAlign='center' textColor={'red'}>
        {additionalResource?.resource.name}
      </Td>
      <Td textAlign='center'>{additionalResource.amount}</Td>
      <Td textAlign='center'>
        <IconButton
          aria-label={'resources-edit'}
          icon={<DeleteIcon />}
          variant='outline'
          onClick={() => {
            if (handleDeleteAdditionalResource)
              handleDeleteAdditionalResource(additionalResource.resource);
          }}
        />
      </Td>
    </Tr>
  );
};

const tableCaptionGenerator = (prr: any) => {
  if (prr.length === 0) {
    return 'Ne postoje zaduženi materijali';
  } else {
    return 'Zaduženi materijali';
  }
};

export default WorkerPhaseResourceTable;
